import React from 'react';

import CommentInfo from './CommentInfo';


const EntryItem = props => {
  const {
    value,
    placeholder,
    updateForm,
    infoText,
    hasAttemptedSubmit,
    errorText
  } = props;

  return (
    <div>
      <textarea 
        className="non-expanding-form-input entry-item" 
        placeHolder={placeholder}
        value={value}
        onChange={(e) => updateForm(e.target.value.replace(/[\r\n\v]+/g, ""))}
        autoComplete={placeholder === "Email" ? "email" : ""}
        required
      />

      <CommentInfo infoText={infoText}/>

      <p className="center-text error-text">{hasAttemptedSubmit ? errorText : ""}</p>
      
    </div>
  )
}

export default EntryItem;
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import CartItem from '../Item';
import CartCosts from '../Costs';
import Dropdown from '../../Functional/Dropdown';

import Helper from 'Helper';

import './index.css';

let helper = new Helper();


export default class CartItems extends Component {
  constructor (props) {
    super(props);
    
  }

  render () {
    if (this.props.cart.cartLogistics.cart.length <= 0) {   //cart is empty
      //render only # of items in cart, cart title, and button to bring user back to shop
      return (
        <div id="CartItems">
          <p className="center-text" id="cart-heading">{this.props.cart.cartLogistics.numOfItems + " " + helper.pluralizer("item", this.props.cart.cartLogistics.numOfItems) + " in your cart" }</p>
          <div className="center-div" id="cart-item-empty">
            <h4 className="center-text"><Link 
              to="/shop"
              className="button-style-1">Back to shop</Link></h4>
          </div>
        </div>
      )
    } else {
      return (
        <div id="CartItems">
          <p className="center-text" id="cart-heading">{this.props.cart.cartLogistics.numOfItems + " " + helper.pluralizer("item", this.props.cart.cartLogistics.numOfItems) + " in your cart" }</p>
          <div id="cart-header" className="center-div horiz-fb flex-wrap">
            <div>
              <p>Product</p>
            </div>
            <div className="horiz-fb">
              <div className="cart-item-qty-wrapper">
                <p>Quantity</p>
              </div>
              <div className="cart-item-price-wrapper">
                <p className="text-right">Price</p>
              </div>
            </div>
        </div>
          {this.props.cart.cartLogistics.cart.map(this.renderCartItems.bind(this))}
          <CartCosts
            numOfItems={this.props.cart.cartLogistics.numOfItems}
            subtotal={this.props.cart.cartLogistics.subtotal}
            shippingCost={this.props.cart.cartLogistics.shippingCost}
            totalCost={this.props.cart.cartLogistics.totalCost}

            isInternationalOrder={this.props.cart.isInternationalOrder}
          />
        </div>
      )
    }
  }

  renderCartItems (item, i) {
    if (item && item.productKey && item.productKey < 0) {    //custom order
      return this.renderCustomOrder(item, i);
    } else {      //if regular order
      return(
        <CartItem
          isCustomOrder={false}
          canChange={this.props.canChange}
          removeFromCart={this.removeFromCart.bind(this)}
          dropdownChanged={this.dropdownChanged.bind(this)}
          item={item}
          matchingItemFromDB={this.props.cart.findDataOfItem(item)}
          matchingItemFromCart={this.props.cart.findItemInCart(item)}
          i={i}/>
      )
    }
    
  }

  renderCustomOrder(item) {
    return (
      <div className="cart-item">
          <div className="cart-item-column-first">
            {/*<Link
                            className="cart-item-link-to-product"
                            to={"/shop/" + matchingItemFromDB.key}>
              <img
                className="cart-item-img"
                src={require(`assets/${matchingItemFromDB.imgs[0].src}`)}/>*/}
              <div>
                <h4 className="cart-item-title cart-item-first-row">Custom Order</h4>
              </div>
            {/*</Link>*/}
          </div>
          <div className="cart-item-column">
            <p className="cart-item-price-txt cart-item-first-row">Quantity:</p>
            1
          </div>
          <div className="cart-item-column">
            <p className="cart-item-price-txt cart-item-first-row">Price:</p>
            <p className="cart-item-price-val">${item.price}</p>
          </div>
      </div>
    )
  }

  removeFromCart (e, i) {
    this.props.cart.removeFromCart(
      this.props.cart.cartLogistics.cart[i].productKey, 
      this.props.cart.cartLogistics.cart[i].selectedOptions);
  }

  dropdownChanged (qty, pk, options) {
    this.props.cart.changeQuantity(parseInt(qty), pk, true, options);
  }

}
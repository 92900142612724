import React from 'react';

import { Collapse } from '@material-ui/core';

import Bar from './Bar';
import Comments from './Comments';


const Footer = props => {
  const { setupData, 

    isModal,
    isPreviewMode,
    
    isFullscreen, 
    setIsFullscreen,
    
    onCommentSubmit,
    onVote } = props; 

  const [ areCommentsExpanded, setAreCommentsExpanded ] = React.useState(isModal);


  return (
    <div className="setup-display-card-footer">
      <Bar 
        comments={setupData.comments}
        upvotes={setupData.upvotes}
        downvotes={setupData.downvotes}
        id={setupData.id}

        areCommentsExpanded={areCommentsExpanded}
        setAreCommentsExpanded={setAreCommentsExpanded}
    
        isFullscreen={isFullscreen}
        setIsFullscreen={setIsFullscreen}

        onVote={onVote}
      />

      <Collapse in={areCommentsExpanded}>
        <Comments
          setupData={setupData}
          onCommentSubmit={onCommentSubmit}

          isPreviewMode={isPreviewMode}
        />
      </Collapse>

    </div>
    
  )
}

export default Footer;
import _ from 'lodash';

import Helper from 'Helper';

let helper = new Helper();

// MotorHelper helps with a ton of motor things like finding voltage and specs and stuff
// Public functions:
//  - setData: sets DATA property of MotorHelper
//      params: 
//        - DATA: motor data from db
//      just pass in motor data from db. This function needs to be called to 
//      enable many of the motor helper methods 
//
//  - findMotorsWithMatchingName: returns motor data of passed in name
//      params: 
//        - name: name of motor to find
//      Finds and returns array of motor data with the same name as the passed 
//      in name. If "stock" or "Stock motor" is passed in, return data for stock
//      motors. These data points (RPM, torque, form factor, etc) will be filled 
//      in as needed.
//
//  - voltingText: returns volting text ("undervolted", "overvolted") of motor
//      based on a motor data at a given cell count
//        - motor (object): motor data of motor 
//        - cellCount (int): cell count to check volting of motor at
//      This can be difficult to do because motor.nominalBattery can be an array
//      with multiple values, so I abstracted it into this method
//
//  - getFormFactor: returns form factor of a motor based only on the motor name
//      params:
//        - motorName (string): name of motor
//      Finds motor and returns form factor of motor as an int. If stock motor, 
//      return 130. Returns false if no matching motor found


const STOCK_MOTOR_DATA = {
  formFactor: 130,
  nominalBattery: [2]

}


//class to help with motor functions
export default class MotorHelper {
	setData (DATA) {
		this.DATA = DATA;
	}

	createNewMotorData(motorData) {
    return _.map(motorData, _.clone)
  }

  //  - findMotorsWithMatchingName: returns motor data of passed in name
  //      params: 
  //        - name: name of motor to find
  //      Finds and returns array of motor data with the same name as the passed 
  //      in name. If "stock" or "Stock motor" is passed in, return data for stock
  //      motors. These data points (RPM, torque, form factor, etc) will be filled 
  //      in as needed.
  findMotorsWithMatchingName(name) {
    // Get data for stock motors
    if (name.toLowerCase().indexOf("stock") !== -1) {  // name has 'stock' in it
      return [STOCK_MOTOR_DATA];
    }

    let newAllMotorInfo = this.createNewMotorData(this.DATA);
    let matchingMotors = _.map(newAllMotorInfo, 
      (indvMotorInfo) => {if (indvMotorInfo.name === name) {return indvMotorInfo} });

    //remove all undefines from matchingMotors
    _.remove(matchingMotors, function (item) {
        return !item;
      });

    return matchingMotors;
  }

  getSpecsAtVoltage(currentMotor, cellCount) {
    //tempCurrentMotor used to avoid mutation of original motor data
    let tempCurrentMotor = Object.assign({}, currentMotor);

    tempCurrentMotor.battery = cellCount;

    tempCurrentMotor.speed = this.getSpeedFromMoreCells(
      currentMotor.speed, 
      currentMotor.nominalBattery[0], 
      cellCount);

    tempCurrentMotor.torque = this.getTorqueFromMoreCells(
      currentMotor.torque, 
      currentMotor.nominalBattery[0], 
      cellCount);

    tempCurrentMotor.stallCurrent = this.getStallCurrentFromMoreCells(
      currentMotor.stallCurrent, 
      currentMotor.nominalBattery[0], 
      cellCount);

    return tempCurrentMotor;
  }

  getSpeedFromMoreCells(speed, baselineCellCount, actualCellCount) {
    return Number(Math.round(speed * (actualCellCount/baselineCellCount)).toFixed(2));
  } 

  getTorqueFromMoreCells(torque, baselineCellCount, actualCellCount) {
    return  Number(Math.round(torque * (actualCellCount/baselineCellCount)).toFixed(2));
  }

  getStallCurrentFromMoreCells(current, baselineCellCount, actualCellCount) {
    return  Number(Math.round(current * (actualCellCount/baselineCellCount)).toFixed(2));
  }

  //make sure battery goes from low to high
  sortMotorsByNominalBattery (motorData) {
    let newMotorData = this.createNewMotorData(motorData);
    _.map(newMotorData, (indivMotorData) => {
      indivMotorData.nominalBattery.sort();
    });

    return newMotorData;

  }

  //finds all motors with same nominal battery as numOfCells
  findAllMotorsOfSpecificBattery (numOfCells) {
    let newMotorData = this.createNewMotorData(this.DATA);
    let motorsWithMatchingBatteries = [];

    _.map(newMotorData, (indivMotorData) => {
      if (this.isCellCountNominal(numOfCells, indivMotorData)) {
        motorsWithMatchingBatteries.push(indivMotorData);
      }
    });

    return motorsWithMatchingBatteries;

  }
  
  // Checks if a cell count is nominal for that motor
  // nominalBattery is stored as an array in motorData (because motors can have more
  // than one nominal battery cell count), so this method will search that array 
  // nominal cell counts
  //
  // Returns true if numOfCells is nominal, false if not
  isCellCountNominal(numOfCells, indivMotorData) {
    let isCellCountNominal = false;
    _.map(indivMotorData.nominalBattery, nominalBattery => {
      if (nominalBattery === numOfCells) {
        isCellCountNominal = true;
      }
    });

    return isCellCountNominal;
  }

  //returns string "overvolted", "undervolted", or "nominally volted"/"nominal" depending on cell count of motor relative to nominal batteries
  //motor is data for individual motor
  voltingText (motor, cellCount) {
    // Check to make sure motor is valid
    if (!!motor && !!motor.nominalBattery) {
      if (cellCount < motor.nominalBattery[0]) {
        return "undervolted";
      } else if (cellCount > motor.nominalBattery[motor.nominalBattery.length - 1]) {
        return "overvolted";
      } else {
        return "nominal";
      }
    } 

    return ""
    
  }

  //returns nominal cell count as text
  //inserts appropriate commas and spaces where needed
  //nominalBattery member of motor should be an arr, this method will return text in same order as arr  
  nominalCellCountToText(motor) {
    let nominalCellCountText = "";    //motor may have more than one nominal cell count, so need to account for that
    for (let j = 0; j < motor.nominalBattery.length; j++) {
      nominalCellCountText += motor.nominalBattery[j] + "S";
      //add comma, b/c multiple and it's not the last one
      if (j < motor.nominalBattery.length - 1) {
        nominalCellCountText += ", ";
      }
    }

    return nominalCellCountText;
  }

  getVoltageFromCells(cells) {
    return Number((cells * 3.7).toFixed(1));
  }

  // Returns arr of motor data at different voltages
  getMultipleMotorDataForDifferentVoltages(motorData, startCellCount, endCellCount) {
    let motorDataAtDifferentVoltages = [];

    // generate data for side graphs at different voltages
    for (let i = startCellCount; i <= endCellCount; i++) {
      motorDataAtDifferentVoltages.push(this.getSpecsAtVoltage(motorData, i));
    }

    return motorDataAtDifferentVoltages;
  }

  getNominalVoltageFromCellCount(cellCount) {
    return (cellCount * 3.7).toFixed(2);
  }

  // Returns string of "Speed: xxx RPM" from speed
  // I'm too lazy to type in all the string concats so this is way easier
  speedToText(motorData) {
    return "Speed: " + motorData.speed + " RPM";
  }

  // Returns string of "Torque: xxx gf.cm" from speed
  torqueToText(motorData) {
    return "Torque: " + motorData.torque + " gf.cm";
  }

  // Returns string of "Torque: xxx gf.cm" from speed
  stallCurrentToText(motorData) {
    return "Stall Current: " + motorData.stallCurrent + "A";
  }

  // Returns string of cost of motor
  costToText(motorData) {
    let cost = !Number.isInteger(motorData.cost) ? 
      parseFloat(Math.round(motorData.cost * 100) / 100).toFixed(2) :
      motorData.cost;

    return "Usually costs $" + cost + " for one motor"
  }

  // return motor size and append appropriate shell cutting descption depending on motor size
  formFactorToText(motorInfo) {
    let formFactorText = motorInfo.formFactor + " Form Factor";
    if (motorInfo.formFactor === 180) {
      formFactorText += " - shell modifications necessary";
    } else if (motorInfo.formFactor === 132) {
      formFactorText += " - shell modifications sometimes necessary"; 
    } else if (motorInfo.formFactor === 130) {
      formFactorText += " - shell modifications not necessary"; 
    }

    return formFactorText;

  }

   // Returns text of nominal cell count (s)
  nominalBatteryToText(motorInfo) {
    let nominalVoltageText = "Designed for " + motorInfo.nominalBattery[0] + "S, " + motorInfo.nominalVoltage[0] + "V";

    //if more than 1 nominal voltage/battery
    if (motorInfo.nominalBattery.length > 1) {
      //start iterating at 1 b/c first battery already
      for (var i = 1; i < motorInfo.nominalBattery.length; i++) {
        nominalVoltageText += "; " + motorInfo.nominalBattery[i] + "S, " + motorInfo.nominalVoltage[i] + "V"
      }
    }

    return nominalVoltageText;
  }

  // Creates property nominalVoltage, an arr of nominal voltages of that motor. Corresponds to nominalBattery
  applyNominalVoltage(motorData) {
    let newMotorData = this.createNewMotorData(motorData);
    _.map(newMotorData, (indivMotorData) => {
      //every motor data has battery as arr, so need to go through that and apply nominal voltage
      //nominal voltage will be arr paralleling battery arr
      let nominalVoltage = [];

      //apply proper nominal voltages for every battery
      _.map(indivMotorData.nominalBattery, (indivBattery) => {
        nominalVoltage.push(this.getVoltageFromCells(indivBattery));
      });

      indivMotorData.nominalVoltage = _.map(nominalVoltage, _.clone);

    });

    return newMotorData;
  }

  //check if motor overvolted, undervolted, or regular
  //reg = 0, over = 1, under = -1
  isNominallyVolted(motorData, cellToCheck) {
    //nominal batteries sorted, lowest first, highest last
    if (motorData.nominalBattery[0] > cellToCheck) {
      return -1;
    } else if (motorData.nominalBattery[motorData.nominalBattery.length - 1] < cellToCheck) {
      return 1;
    }

    return 0;

  }

  // Add a battery prop to motor data
  applyBattery(allMotorData) {
    let newMotorData = this.createNewMotorData(allMotorData);
    _.map(newMotorData, indivMotorData => {
      indivMotorData.battery = indivMotorData.nominalBattery[0];
    });

    return newMotorData;
  }

  // Add a selected prop to motor data isSelected
  applyIsSelected(allMotorData, isSelected) {
    let newMotorData = this.createNewMotorData(allMotorData);
    _.map(newMotorData, indivMotorData => {
      indivMotorData.isSelected = isSelected;
    });

    return newMotorData;
  }

  // Set isSelected in arr of motors depending on what motors should be selected
  // Motors that should be selected is specified by selectedMotors
  setIsSelected(allMotorData, selectedMotors) {
    let newMotorData = this.createNewMotorData(allMotorData);
    _.map(newMotorData, indivMotorData => {
      indivMotorData.isSelected = helper.isItemInArr(selectedMotors, indivMotorData);
    });

    return newMotorData;
  }

  //  - getFormFactor: returns form factor of a motor based only on the motor name
  //      params:
  //        - motorName (string): name of motor
  //      Finds motor and returns form factor of motor as an int. If stock motor, 
  //      return 130. Returns false if no matching motor found
  getFormFactor(motorName) {
    // Return 130 for stock motors
    if (motorName.toLowerCase().includes("stock")) {
      return 130
    } 

    // Loop through all motors to find motor with motorName 
    const motor = this.findMotorsWithMatchingName(motorName)[0];

    if (!!motor) {
      return motor.formFactor;
    }


    return false;
    

  }


}
import _ from 'lodash'

// Function to generate styles and classnames for volting shapes
// Avaiable functions: 
//  - getColorClassName: Returns classname to set the color of a shape
//  - getStyles: Returns a style object that sets everything for the shape

// Returns a classname (string) to set the color of a volting shape
// Parameters:
//  - cellCount (string): cell count, ex: "2S" or "3S"
//  - isShapeTriangle (bool): bool, true if shape is a triangle, false if not
function getColorClassName(cellCount, isShapeTriangle) {
  if (cellCount.toLowerCase() === "2s") {
    return isShapeTriangle ? "two-s-triangle-color" : "two-s-color";
  } else if (cellCount.toLowerCase() === "3s") {
    return isShapeTriangle ? "three-s-triangle-color" : "three-s-color";
  } else if (cellCount.toLowerCase() === "4s") {
    return isShapeTriangle ? "four-s-triangle-color" : "four-s-color";
  } else if (cellCount.toLowerCase() === "5s") {
    return isShapeTriangle ? "five-s-triangle-color" : "five-s-color";
  } 
}

// Gets all styles and merges them into one object that can be assigned to a 
// DOM element
// Returns one style obect
//
// Parameters:
//  - size (string): Size of shape, either in px or "small", "medium", "large"
//  - shape (string): Shape of shape. Ex: "circle", "triangle", "square"
//  - isShapeTriangle (bool): bool, true if shape is a triangle, false if not
//  - style (object): Additonal styles to be applied to shape. Will be merged
//      last, so its props will take the most precedent 
function getStyles(size, shape, isShapeTriangle, style) {

  return mergeStyles();

  // Set size based on props
  // Can set to pre-defined 'large', 'medium', or 'small' or its own size value
  // Returns an object with height and width (or border-bottom if shape is a 
  // triangle) properties that can be applied to a DOM element in the style prop 
  function getSizeStyles() {
    // Getting size for triangles
    // If the shape is a triangle, the height and width can be set the same as 
    // for circles and squares
    if (isShapeTriangle) {
      return getTriangleSizeStyles(size)
    }

    // Getting size for circles and squares
    let height, width; 

    // If sized is passed in as 'large', 'medium', etc., set size to a hardcoded value
    if (size === "large") {
      height = width = "30px";
    } else if (size === "medium") {
      height = width = "30px";
    } else if (size === "small") {
      height = width = "20px";

    // Size is passed in as just a regular value
    } else {
      height = width = size;	
    }	

    return {
      height: height,
      width: width
    }

  }

  // Get triangle-specific size styling  
  function getTriangleSizeStyles() {
    let borderLeftWidth, borderRightWidth, borderBottomWidth;

    if (size === "large") {

    } else if (size === "medium") {
      // Medium size triangle will be same height and width as medium size
      // cirlce or square
      borderBottomWidth = "30px"
    } else if (size === "small") {

    // Size is passed in as just a regular value
    } else {
      borderBottomWidth = size;
    }	

    // Account for borders of circles and squares to fully match size
    borderBottomWidth = (parseInt(borderBottomWidth) + 2) + "px";

    // Maintain proportions for an equilateral triangle
    borderLeftWidth = borderRightWidth = parseInt(borderBottomWidth)/1.7 + "px";

    return {
      width: 0,
      height: 0,
      borderLeftWidth: borderLeftWidth,
      borderRightWidth: borderRightWidth,
      borderBottomWidth: borderBottomWidth
    }
  }

    // Returns object with height and width props that can be applied to shape container
  // Shape container must be same size as shape itself
  // The shape container can't just use the height and width props because triangles
  // have height = width = 0
  function getContainerSizeStyles() {
    // If the shape is triangle, the container size values need to be a bit different
    if (isShapeTriangle) {
      // Use trig to get size of a container that directly matches height and width
      // of the equilateral triangle
      return {
        containerWidth: (parseInt(getTriangleSizeStyles().borderLeftWidth) * 2) + "px",
        containerHeight: getTriangleSizeStyles().borderBottomWidth

      }
    }

    // If the shape is square or circle, height and width of container can just match
    // that of shape
    let sizeStyles = getSizeStyles();

    return {
      containerHeight: sizeStyles.height,
      containerSidth: sizeStyles.width,
    }

  }

  // Returns styles object based on shape from props 
	function getShapeStyles() {
		if (shape === "circle") {
			return {
				borderRadius: "50%"
			}
		}

	}

  // Merges all the style objects into one object to be returned
  function mergeStyles() {
    let mergedStyles = {};

    // Merge size styling
    mergedStyles = _.merge(mergedStyles, getSizeStyles());

    // Merge shape styling
    mergedStyles = _.merge(mergedStyles, getShapeStyles());

    // Merge container size styling
    mergedStyles = _.merge(mergedStyles, getContainerSizeStyles());

    // Merge props styling. Props styling should be merged last to override any other
    // styling that gets applied in this component
    mergedStyles = _.merge(mergedStyles, style);

    return mergedStyles;
  }
}


export {
  getColorClassName,
  getStyles
}
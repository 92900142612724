import React, { memo } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Modal, Backdrop } from '@material-ui/core';


// Components 
import Display from './Display';


// Custom utilities/helpers
import Helper from 'Helper';
import BackendDataSender from 'BackendDataSender';


// Styles
import './index.scss';


const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

const helper = new Helper();
let backendDataSender = new BackendDataSender();

const FLYWHEEL_SETUP_PICKER_VOTE_URL = "submit-flywheel-setup-vote";




const SetupDisplay = memo (props => {
  const { setupData, motorHelper, rootClassName, isSetupFullscreen, isPreviewMode } = props;

  const [ isFullscreen, setIsFullscreen ] = React.useState(isSetupFullscreen);
  const [ setupDataBranch, setSetupDataBranch ] = React.useState(setupData)

  const classes = useStyles();

  
  return (
    <div className={`SetupDisplay ${rootClassName}`}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"

        className={classes.modal}
        open={isFullscreen}

        onClose={() => setIsFullscreen(false)}
        onEscapeKeyDown={() => setIsFullscreen(false)}

        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}

        closeAfterTransition
      >	
        {renderDisplay(true)}
      </Modal>

      
      {renderDisplay(false)}


    </div>
  )

  // Returns Display component as jsx element. Display is used twice (modal and 
  // non modal), so I don't want to forget passing in props to an instance of 
  // Display. 

  // Need to pass setupData if in preview mode because for some weird 
  // reason, setupDataBranch doesn't get setupData.additionalSetupNotes
  // ONLY when in preview mode

  function renderDisplay(isModal) {
    return (
      <Display
        setupData={isPreviewMode ? setupData : setupDataBranch}
        motorHelper={motorHelper}

        isModal={isModal}
        isPreviewMode={isPreviewMode}

        isFullscreen={isFullscreen}
        setIsFullscreen={setIsFullscreen}

        containerClassName={isModal ? "SetupDisplay-card-modal" : ""}

        onCommentSubmit={onCommentSubmit}
        onVote={onVote}
      />
    )
  }

  function onCommentSubmit(newComment) {
    setSetupDataBranch({
      ...setupDataBranch,
      comments: [...setupDataBranch.comments, newComment]
    });
  }

  function onVote(newVote) {
    const NO_VOTE_TO_UPVOTE = 1;
    const NO_VOTE_TO_DOWNVOTE = 2;

    const UPVOTE_TO_NO_VOTE = 3;
    const UPVOTE_TO_DOWNVOTE = 4;

    const DOWNVOTE_TO_NO_VOTE = 5;
    const DOWNVOTE_TO_UPVOTE = 6;



    let newUpvote = 0, newDownvote = 0;

    if (newVote === NO_VOTE_TO_UPVOTE) {
      newUpvote = 1;
    } else if (newVote === NO_VOTE_TO_DOWNVOTE) {
      newDownvote = 1;
    } else if (newVote === UPVOTE_TO_NO_VOTE) {
      newUpvote = -1;
    } else if (newVote === UPVOTE_TO_DOWNVOTE) {
      newUpvote = -1;
      newDownvote = 1;
    } else if (newVote === DOWNVOTE_TO_NO_VOTE) {
      newDownvote = -1;
    } else if (newVote === DOWNVOTE_TO_UPVOTE) {
      newUpvote = 1;
      newDownvote = -1;
    } 

    setSetupDataBranch({
      ...setupDataBranch,
      upvotes: setupDataBranch.upvotes + newUpvote,
      downvotes: setupDataBranch.downvotes + newDownvote
    });


    // Send to db
    const normalizedVote = helper.formNormalizer({
      setupId: setupDataBranch.id,
      deltaNewUpvote: newUpvote,
      deltaNewDownvote: newDownvote
    });

    // Only send vote to backend if not in preview mode
    if (!isPreviewMode) {
      backendDataSender.sendData(FLYWHEEL_SETUP_PICKER_VOTE_URL, normalizedVote);
    }
  }

},
(prevProps, nextProps) => {
  // console.log("Prev:", prevProps)
  // console.log("nextProps:", nextProps)
  // console.log("\n\n")

  // Don't re-render if setup data is the same, but always re-render if in 
  // preview mode
  if (prevProps.setupData._id === nextProps.setupData._id 
    && !nextProps.isPreviewMode) {
    
    return true
  }

  return false
})

export default SetupDisplay
import React, { Component } from 'react';
import DataDependantComponent from '../../DataDependantComponent';

import { Redirect } from 'react-router'

import LiPoChecker from '../LiPoChecker/App';
import MotorChartApp from '../MotorChart';
import LiPoCalculator from '../LiPoCalculator';
import MotorSpecFinder from '../MotorSpecFinder'
import FlywheelSetupPicker from '../FlywheelSetupPicker'

import './index.css';

export default class ToolsDetail extends DataDependantComponent {
  render () {  
    const toolID = this.props.match.params.toolID;
    const id = this.props.match.params.id;  // subroute of tools/toolID/id

    return (
      <div id="ToolsDetail">
        {this.toRender(toolID, id)}
      </div>
    )
  }

  toRender(toolID, id) {
    if (toolID === "motor-chart") {
      return (
        <MotorChartApp 
          collectionName={"charts"}
          otherShopData={this.props.otherShopData}
          setDataToState={this.props.setDataToState}/>
      )
    } else if (toolID === "lipo-checker") {
      return (
        <LiPoChecker 
          collectionName={"charts"}
          setDataToState={this.props.setDataToState}/>
      )
    } else if (toolID === "lipo-calculator") {
      return (
        <LiPoCalculator/>
      )
    } else if (toolID === "motor-spec-finder") {
      return (
        <MotorSpecFinder
          collectionName={"charts"}
          otherShopData={this.props.otherShopData}
          setDataToState={this.props.setDataToState}/>
      )
    } else if (toolID === "flywheel-setup-picker") {
      return (
        <FlywheelSetupPicker
          setDataToState={this.props.setDataToState}
          id={id}
        />
      )
    } else {
      return (
        <Redirect to={'/not-found'}/>
      )

    }


  }

}
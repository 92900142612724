// Dropdown to be used in filter
// Abstract Dropdown component that can display dropdown filters for multiple 
// filters
//
// Props:
//  - SELECTIONS (array): Array of filter selections to be passed into Select 
//      options as props. Should already be in proper format for React Select
//  - filter (object): Object in filter (from hook) of corresponding filter item
//  - onChange (function): callback onChange. Passed in selectedOptions. 
//      The method passed in is stypically something to set filter.
//  - title (string): Text to be displayed above dropdown as a title
//  - placeholder (string): placeholder of dropdown



import React from 'react';
import Select from 'react-select';


import Helper from 'Helper';


const helper = new Helper();



const DropdownFilter = props => {
  const { SELECTIONS, filter, onChange, title, placeholder } = props;

  return (
    <div className="FilterDropdown">
      <h3 className="center-text filter-dropdown-title">{title}</h3>
      <Select className="select-entry small-select-dropdown filter-dropdown"
        placeholder={placeholder}
        closeMenuOnSelect={true}
        options={SELECTIONS}

        value={!!filter && 
          filter.map(filterItem => {
            return helper.generateOptionForReactSelect(filterItem) 
          })
        }


        onChange={onChange}
        
        isMulti
      />
    </div>
  )
}

export default DropdownFilter;
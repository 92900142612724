import React, { Component } from 'react';

import './index.scss';

export default class FlywheelSetupPickerInstructions extends Component {
  render () {  
    return (
      <div id="FlywheelSetupPickerInstructions">
        <h1>Instructions</h1>
      </div>
    )
  }

}
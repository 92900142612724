import { useState } from 'react';


import _ from 'lodash';




// Hook to manage filtersing
// When filters has been changed, this hook kicks in and sets setups through
// setSetups, forcing a re-render 
const useFilters = (onFilterApply) => {
  const [ filters, setFilters ] = useState({});


  function onApplyBtnPress() {
    onFilterApply(formatFiltersToSendToBackend(filters))
  }


  function clearFilters() {
    const newFilters = {}

    setFilters({})
    onFilterApply(newFilters)
    
  }

  // Callback when a filter(s) is selected
  // Sets filter in state in appropriate format for mongo db query-ing
  // This function is passed into every dropdown filter, so each dropdown filter
  // specifies which property it's filtering for (blaster, fps, etc) as the first
  // parameter (filterProperty)
  // Params:
  //  - filterProperty(string): key of property to filter by (blaster, price, 
  //      fps, etc). Should match property name in setup 
  //  - selectedOptions(arr): Array of selected options, typically passed in from 
  //      dropdown. If a checkbox invokes this function, it will pass in a bool
  function onFilterChange(filterProperty, selectedOptions) {
    // Handle checkboxes where selectedOptions will be true/false
    if (typeof selectedOptions === "boolean") {
      let newFilters = {
        ...filters
      }

      // Checkbox was checked, so apply this filter
      if (selectedOptions) {
        newFilters[filterProperty] = selectedOptions;

      // Checkbox not checked, so ignore this filter entirely
      } else {
        delete newFilters[filterProperty]
      }

      setFilters(newFilters)

    // Handle regular filter stuff like dropdowns
    } else {
      let selectedOptionsValues = [];
      
      // Validate selectedOptions, it can be null if press "X" on all options
      if (!!selectedOptions) {
        // All values are embedded in selectedOption.value, so extract it 
        selectedOptionsValues = selectedOptions.map(selectedOption => {
          return selectedOption.value
        });
      }
      

      let newFilters = {
        ...filters,
        [filterProperty]: {
          $in: selectedOptionsValues  // Nest options in $in prop to make querying easier
        } 
      }
      
      // If no options are selected, remove it entirely from filters to avoid 
      // issues with db query-ing
      if (selectedOptionsValues.length === 0) {
        delete newFilters[filterProperty];
      } 

      setFilters(newFilters)
    }
  }




  return [
    filters, onFilterChange, clearFilters, onApplyBtnPress
  ]
}

export default useFilters;




// Format filters and make sure everything matches with setup props on apply
// but before sending to useSetups to be sent to backend
// For example, need to format cellCount filters to remove the "S" from "3S" 
// because in setup, cellCount stored as just int
function formatFiltersToSendToBackend(filters) {
  // DEEP copy
  let newFilters = JSON.parse(JSON.stringify(filters));


  // Generate filters for muzzleVelocities which must be formatted properly
  // If there are no setup muzzleVelocities, formatMuzzleVelocityFilters() will  
  // return null, and don't set newFilters.muzzleVelocities 
  const muzzleVelocityFilters = formatMuzzleVelocityFilters(filters, newFilters);

  if (!!muzzleVelocityFilters) {
    newFilters.muzzleVelocities = muzzleVelocityFilters;
  }



  // Format cellCount to remove "S" because in setup, cellCount stored as just int
  if (!!newFilters.cellCount) {
    newFilters.cellCount.$in = cellCountToInt(filters.cellCount.$in)
  }


  // Generate filters for setup which must be formatted properly
  // If there are no setup filters, formatSetupFilters() will return null, and 
  // don't set newFilters.setup 
  const setupFilters = formatSetupFilters(filters, newFilters);

  if (!!setupFilters) {
    newFilters.setup = setupFilters;
  }


  // Filter by num of stages with a value in helperValues
  if (!!newFilters.numberOfStages) {
    newFilters["helperValues.numOfStages"] = newFilters.numberOfStages;

    delete newFilters.numberOfStages;
  }


  // Generate filters for puhser which must be formatted properly
  // If there are no pusher filters, formatPusherFilters() will return null, and 
  // don't set newFilters.pusher 
  const pusherFilters = formatPusherFilters(filters, newFilters);
  
  if (!!pusherFilters) {
    // Need to do Object.assign because I want the first-level props in pusherFilters
    // to be the first-level props in newFilters
    Object.assign(newFilters, pusherFilters)
  }


  // Re-format isSemiAuto because setup data only has prop isFullAuto
  if (!!newFilters.isSemiAuto) {
    // If isFullAuto is also set, user has basically set only semi auto and only
    // full auto, so display nothing
    if (!!newFilters.isFullAuto) {
      newFilters.isFullAuto = 1;    // No setup has isFullAuto = 1, so all setups should fail
    
    // Use isFullAuto because that prop already exists in setup data
    } else {
      newFilters.isFullAuto = !newFilters.isSemiAuto;
    }

    delete newFilters.isSemiAuto

  }


  // Format solenoid checkbox filter, this should dig into 
  // pusher.pusherMechanism === "solenoid"
  // This must be handled after pusher filter stuff because this can overwrite 
  // pusher filters
  if (newFilters.isSolenoidPusher === true) {
    newFilters["pusher.pusherMechanism"] = "Solenoid";

    delete newFilters.isSolenoidPusher;
  } else if (newFilters.isSolenoidPusher === false) {
    delete newFilters.isSolenoidPusher;
  }


  

  return newFilters
}



// Formats setup filters to send to backend
// Returns obj of muzzleVelocity filters, mutates newFilters param to delete props but 
// doesn't mutate filters
// If no muzzleVelocity filters were specified, return null
//
// Formatting is a bit different because properties embedded in arr of objs  
// where each obj is a stage. Need to do special query-ing for stuff embedded in
function formatMuzzleVelocityFilters(filters, newFilters) {
  if (!!filters.fps || !!filters.dartType) {
    // Format stuff filters for muzzleVelocities, which is embedded in setups 
    // arr. Need to use $elemMatch for proper querying because setup is arr of
    // obj
    let muzzleVelocityFilters = { $elemMatch: { } };

    if (!!filters.fps) {
      muzzleVelocityFilters.$elemMatch.fps = { $in: [ ...filters.fps.$in ] };

      delete newFilters.fps;
    }


    if (!!filters.dartType) {
      muzzleVelocityFilters.$elemMatch.dartType = { $in: [ ...filters.dartType.$in ] };


      delete newFilters.dartType;
    }



    return muzzleVelocityFilters;


  // No muzzle vel filters specified, so return null
  } else {
    return null;
  }
}



// Formats setup filters to send to backend
// Returns obj of setup filters, mutates newFilters param to delete props but 
// doesn't mutate filters
// If no setup filters were specified, return null
//
// Formatting is a bit different because properties embedded in arr of objs  
// where each obj is a stage. Need to do special query-ing for stuff embedded in
// arrs
function formatSetupFilters (filters, newFilters) {
  // If setup filters were specified
  if (!!filters.motor
    || !!filters.flywheels
    || !!filters.flywheelCage
    || !!filters.flywheelCageMaterial
    || !!filters.isMicrowheel
    || !!filters.flywheelMotorFormFactor
    || !!filters.flywheelMotorVolting
    || !!filters.flywheelMotorNominalCellCount
    || !!filters.dartGuide
    || "isMicrowheels" in filters
    || "hasDartGuide" in filters) {



    // Format stuff filters for setup, which is embedded in setups arr. 
    // Need to use $elemMatch for proper querying because setup is arr of obj
    let setupFilters = { $elemMatch: { } };


    if (!!filters.motors) {
      setupFilters.$elemMatch.motors = { $in: [ ...filters.motors.$in ] };

      delete newFilters.motors;
    }


    if (!!filters.flywheels) {
      setupFilters.$elemMatch.flywheels = { $in: [ ...filters.flywheels.$in ] };


      delete newFilters.flywheels;
    }


    if (!!filters.flywheelCage) {
      setupFilters.$elemMatch.flywheelCage = { $in: [ ...filters.flywheelCage.$in ] };


      delete newFilters.flywheelCage;
    }


    if (!!filters.flywheelCageMaterial) {
      setupFilters.$elemMatch.flywheelCageMaterial = { $in: [ ...filters.flywheelCageMaterial.$in ] };

      delete newFilters.flywheelCageMaterial;
    }


    if (!!filters.dartGuide) {
      setupFilters.$elemMatch.dartGuide = { $in: [ ...filters.dartGuide.$in ] };

      delete newFilters.dartGuide;
    }


    if (!!filters.flywheelMotorFormFactor) {
      setupFilters.$elemMatch["motorData.formFactor"] = { $in: [ ...filters.flywheelMotorFormFactor.$in ] };

      delete newFilters.flywheelMotorFormFactor;
    }


    if (!!filters.flywheelMotorVolting) {
      setupFilters.$elemMatch.volting = { 
        // Lowercase the voltings to match with format in backend
        $in: filters.flywheelMotorVolting.$in.map(volting => volting.toLowerCase()) 
      };

      delete newFilters.flywheelMotorVolting;
    }


    // Format query for nominal cell count, which is stored in 
    // motorData.nominalBattery, an array of ints
    if (!!filters.flywheelMotorNominalCellCount) {
      setupFilters.$elemMatch["motorData.nominalBattery"] = {
          $elemMatch: {
            $in: cellCountToInt(filters.flywheelMotorNominalCellCount.$in)
          }
      }

      delete newFilters.flywheelMotorNominalCellCount;
    }

    
    // Format because need to check every stage in setup if any stage has 
    // isMicroFlywheelSetup = true
    if (filters.isMicrowheels === true) {
      setupFilters.$elemMatch.isMicroFlywheelSetup = true;

      delete newFilters.isMicrowheels;
    } else if (filters.isMicrowheels === false) {
      delete newFilters.isMicrowheels;
    }


    // If user wants only setups with dart guide, query for setups with 
    // dartGuide string has a space via regex
    if (filters.hasDartGuide === true) {
      setupFilters.$elemMatch.dartGuide = { 
        $regex : ".* .*" 
      };

      delete newFilters.hasDartGuide;
    } else if (filters.hasDartGuide === true) {
      delete newFilters.hasDartGuide;
    }



    
    return setupFilters

  }

  // No setup filters submitted, return null and parent function will know 
  // that there are no setup filters and act accordingly
  return null

} 


// Formats pusher filters to send to backend
// Returns obj of pusher filters, mutates newFilters param to delete props but 
// doesn't mutate filters
// If no pusher filters were specified, return null
//
// Formatting is a bit different because properties embedded in obj 
// Embedded elements are accessed by "object.property": query. 
// object: { property: query } doesn't work because it will try to find object
// to match exactly (and only) { property: query } meaning that if object has
// id prop, the query will still fail  
function formatPusherFilters(filters, newFilters) {
  // If pusher filters were specified
  if (!!filters.pusherMechanism
    || !!filters.pusherMotor
    || !!filters.pusherSolenoid
    || !!filters.pusherBatteryCellCount

    || !!filters.pusherMotorFormFactor
    || !!filters.pusherMotorVolting
    || !!filters.pusherMotorNominalCellCount) {
      

    // Format stuff filters for pusher, which is embedded in setups.pusher obj
    let pusherFilters = {};

    if (!!filters.pusherMechanism) {
      pusherFilters["pusher.pusherMechanism"] = { $in: [ ...filters.pusherMechanism.$in ] };

      delete newFilters.pusherMechanism;
    }


    if (!!filters.pusherMotor) {
      pusherFilters["pusher.pusherMotor"] = { $in: [ ...filters.pusherMotor.$in ] };

      delete newFilters.pusherMotor;
    }


    if (!!filters.pusherSolenoid) {
      pusherFilters["pusher.solenoid"] = { $in: [ ...filters.pusherSolenoid.$in ] };

      delete newFilters.pusherSolenoid;
    }


    if (!!filters.pusherBatteryCellCount) {
      // Filter for pushers with same battery as flywheels, if specified 
      if (filters.pusherBatteryCellCount.$in.indexOf("Same as Flywheels") !== -1) {
        // If has same battery as flywheels, pusher.isBatterySameAsFlywheels is 
        // true

        pusherFilters["pusher.isBatterySameAsFlywheels"] = true
      }

      // If pusher battery filter also has actual cellcounts to filter, not just 
      // "Same as Flywheels", then apply those cell counts
      // If the only filter applied is "Same as Flywheels", then don't try to 
      // apply cell counts because it will mess up querying 
      // (pusher.batteryCellCount will just be an empty array, which will cause 
      // query to fail)
      // 
      // Check to see if there are cellcounts to apply by getting the 
      // cellcounts, if there are any cellcounts in the retrieved cellcounts,
      // then apply cellcounts as filter
      const cellCounts = cellCountToInt(filters.pusherBatteryCellCount.$in)
      if (cellCounts.length > 0) {
        pusherFilters["pusher.batteryCellCount"] = { $in: cellCounts };
      }

      delete newFilters.pusherBatteryCellCount;
    }


    if (!!filters.pusherMotorFormFactor) {
      pusherFilters["pusher.motorData.formFactor"] = {
         $in: [ ...filters.pusherMotorFormFactor.$in ] 
      };

      delete newFilters.pusherMotorFormFactor;
    }


    if (!!filters.pusherMotorVolting) {
      pusherFilters["pusher.volting"] = {
        $in: filters.pusherMotorVolting.$in.map(volting => volting.toLowerCase()) 
      };

      delete newFilters.pusherMotorVolting;
    }

    

    // Format query for nominal cell count, which is stored in 
    // motorData.nominalBattery, an array of ints
    if (!!filters.pusherMotorNominalCellCount) {
      pusherFilters["pusher.motorData.nominalBattery"] = {
          $elemMatch: {
            $in: cellCountToInt(filters.pusherMotorNominalCellCount.$in)
          }
      }

      delete newFilters.pusherMotorNominalCellCount;
    }


    return pusherFilters;
  }

  // No pusher filters submitted, return null and parent function will know 
  // that there are no setup filters and act accordingly
  return null
}


// Format arr of cell counts from "3S" to 3. 
// If there's a string that isn't in the proper format for a cell count (like 
// "fsdafasf"), don't include it in the returned arr of cell counts
function cellCountToInt(cellCounts) {
  let formattedCellCounts = []; 


  cellCounts.forEach(cellCount => {
    // Validate that the cellcount is of proper format:  
    //  - First char is int
    //  - Second char is "S"
    if (Number.isInteger(parseInt(cellCount[0])) && cellCount[1] === "S") {
      formattedCellCounts.push(parseInt(cellCount[0]))
    }
  });

  return formattedCellCounts;

}

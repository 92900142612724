import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'

import './index.css';
import Form from '../Functional/Form';

export default class StoreToolsLogin extends Component {
	constructor (props) {
		super (props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);

		this.state = {
			hasLoggedIn: false,
			hasFailedLoggedIn: false,

			username: "123",
			password: "456",

			formItemData: [
				{
					key: 0,
					label: "Username",
					name: "username",
					isRequired: true,
					formResultsRef: "username"
				},
				{
					key: 1,
					label: "Password",
					name: "password",
					isRequired: true,
					formResultsRef: "password"
				}
			],

			formResults: {
				username: "",
				password: ""
			}
		}
	}

	render () {
		return (
			<div id="StoreToolsLogin">
				{this.props.hasLoggedIn && (
          <Redirect to={'/store-tools'}/>
        )}

        <h1>Login</h1>

        {this.state.hasFailedLoggedIn && (
          <p id="invalid-credentials-text">Invalid credentials, please try again</p>
        )}

				<div id="contact-form">
          <Form
            submitButtonText={"Submit"}
            formItemsData={this.state.formItemData}
            formResults={this.state.formResults}
            handleFormSubmit={this.handleFormSubmit}/>
        </div>
			</div>
		)
	}

	handleFormSubmit () {
		//copy form results to new object
		const formResults = Object.assign(this.state.formResults);

		if (formResults.username === this.state.username && formResults.password === this.state.password) {
			this.setState({
				hasLoggedIn: true,
				hasFailedLoggedIn: false,
				formResults: {
					username: "",
					password: ""
				}
			}, function () {
				this.props.onLoggedIn(true);
			})
		} else {
			this.setState({
				hasFailedLoggedIn: true
			});
		}

		//clear state form resutls so can try again
		// this.setState({
			// formResults: {
				// username: "",
				// password: ""
			// }
		// });

		console.log(formResults);
	}
}

import React, { Component } from 'react';
import axios from 'axios';
import StripeCheckout from 'react-stripe-checkout';

import CONSTANTS from 'CONSTANTS';
let LOC_CONSTANTS = new CONSTANTS();
const STRIPE_CONSTS = LOC_CONSTANTS.getStripeConsts();
const STRIPE_PUBLISHABLE = STRIPE_CONSTS.STRIPE_PUBLISHABLE;
const PAYMENT_SERVER_URL = STRIPE_CONSTS.PAYMENT_SERVER_URL;
const CURRENCY = 'USD';



export default class StripeCheckoutComponent extends Component {
  render() {
    return (
      <StripeCheckout
        name={this.props.name}
        description={this.props.description}
        amount={this.convertUSDtoCent(this.props.amount)}
        token={this.onToken(this.props.amount, this.props.description)}
        currency={CURRENCY}
        stripeKey={STRIPE_PUBLISHABLE}
      />
    )
  }

  convertUSDtoCent = amount => amount * 100;

  successPayment = data => {
    alert('Payment Successful');
  };

  errorPayment = data => {
    debugger;
    alert('Payment Error');
  };

  onToken = (amount, description) => token =>
  axios.post(PAYMENT_SERVER_URL,
    {
      description,
      source: token.id,
      currency: CURRENCY,
      amount: this.convertUSDtoCent(amount)
    })
    .then(this.successPayment)
    .catch(this.errorPayment);


}

import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './index.css';

const SOCIAL_LINKS = [
  {
    name: "Facebook", 
    link: "https://www.facebook.com/suildtech"
  },
  {
    name: "YouTube", 
    link: "https://www.youtube.com/channel/UCMoPj80j2GxGAvUJ-isQx1Q"
  },
  {
    name: "Instagram", 
    link: "https://www.instagram.com/suildtech"
  },
  {
    name: "Email", 
    link: "mailto:suildcontact@gmail.com"
  },
  {
    name: "Reddit", 
    link: "https://www.reddit.com/user/MingBatt/"
  },
  {
    name: "GitHub", 
    link: "https://www.github.com/suild"
  }
];

const COMPANY_LINKS = [
  {
    name: "Contact", 
    type: "link",
    link: "contact"
  },
  {
    name: "Donate", 
    link: "https://www.paypal.me/suild"
  },
  {
    name: "Changelog", 
    type: "link",
    link: "changelog"
  },
  {
    name: "About", 
    type: "link",
    link: "about"
  }
]

const RESOURCES_LINKS = [
  {
    name: "Documentation", 
    type: "link",
    link: "docs"
  },
  {
    name: "Tools", 
    type: "link",
    link: "tools"
  }
]

export default class Footer extends Component {
	render () {
		return (
      <div id="Footer">
        <div className="flex-container-center center-div" id="footer-content">
          <div className="footer-links-container">
            <h2 className="footer-links-title">Social</h2>
            {this.renderLinks(SOCIAL_LINKS)}
          </div>
          <div className="footer-links-container">
            <h2 className="footer-links-title">Company</h2>
            {this.renderLinks(COMPANY_LINKS)}
          </div>
          <div className="footer-links-container">
            <h2 className="footer-links-title">Resources</h2>
            {this.renderLinks(RESOURCES_LINKS)}
          </div>
        </div>
        <p className="center-text" id="copyright">© Suild.com 2016 - 2020</p>
      </div>
		);
	}

  renderLinks(links) {
    return (
      <div>
        {links.map((link) => {
          //if type is link, so it's a react-router link
          if (link.type === "link") {
            return (
              <Link 
                to={"/" + link.link}
                className="footer-link">
                <p className="footer-link-text">{link.name}</p>
              </Link>
            )
          }

          //default
          return (
            <a 
              href={link.link}
              target="_blank"
              rel="noopener noreferrer"
              className="footer-link">
              <p className="footer-link-text">{link.name}</p>
            </a>
          )
        })}
      </div>
    )
  }

}

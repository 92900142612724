import React from 'react';
import DropdownFilter from './DropdownFilter';

import Helper from 'Helper';


const helper = new Helper();


const RateOfFireFilter = props => {
  const { RATE_OF_FIRE_SELECTIONS, filter, onFilterChange } = props;

  return (
    <DropdownFilter
      title={"Rate of Fire"}
      placeholder={"Rate of Fire"}
      filter={filter}

      SELECTIONS={RATE_OF_FIRE_SELECTIONS.map(blaster => {
        return helper.generateOptionForReactSelect(blaster) 
      })}

      onChange={selectedOptions => onFilterChange("rateOfFire", selectedOptions)}
    />

  )
}

export default RateOfFireFilter;
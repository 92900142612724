import React from 'react';
import { 
  RadioButtonChecked,
  FlashOn,
  BatteryFull } from '@material-ui/icons';

import _ from 'lodash';


const Icons = props => {
  const { pusherData, motorHelper } = props;

  const { motorData, volting } = pusherData;


  return (
    <div className="flex-container-center">
      <div className="setup-stage-icons-half-container">

        {/* Motor Size form factor icon */}
        <div className="flex-container-vertical-center setup-stage-icon-container">
          <RadioButtonChecked fontSize={"small"}/>
          <p className="inline-text setup-stage-icon-text">
            {`${motorData.formFactor} form factor`}
          </p>
        </div>

        {/* Motor Volting Icon */}
        <div className="flex-container-vertical-center setup-stage-icon-container">                
          <FlashOn 
            className={""}
            fontSize={"small"}
          />
          <p className="inline-text setup-stage-icon-text">
            {_.startCase(volting)}
          </p>
        </div>
      </div>
    
      <div className="setup-stage-icons-half-container">
        {/* Nominal Motor Battery Icon */}
        <div className="flex-container-vertical-center setup-stage-icon-container">                
        <BatteryFull 
            className={""}
            fontSize={"small"}
          />
          <p className="inline-text setup-stage-icon-text">
            {
              motorHelper.nominalCellCountToText(motorData) + " nominal"
            }
          </p>
        </div>
      </div>

    </div>
  )
}

export default Icons;
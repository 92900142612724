import React, { Component } from 'react';

// import './index.scss';

export default class MOSFETBoardPlusMoreOptions extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedInteractiveOption: ""
		}
	}

	render() {
		// Options that aren't hidden
		return (
			<div id="mosfet-board-interactive-options">
				<h3 className="option-set-title">XT60 Pigtail Options</h3>
      		<p>Click on a set of holes to change its options.</p>
	      	<div id="interactive-mosfet-board-plus-options">
	      		<div className="interactive-options-bg interactive-mosfet-board-plus-options-bg"
	      			style={{
	      				display: (this.props.toShowMoreOptions ? "block" : "none"), 
	      		 		backgroundImage: "url(" + require("assets/Shop/MOSFET Board +/mosfet_board_+_options_bg.jpg") + ")" 
	      			}}>
	      		</div>
	      		{/*this div is used so the divs below can have top margin without moving this entire div parent div*/}
	      		<div style={{height: "1px"}}></div>
	      	
	      	</div>
			</div>
		)

		return null
	}

}
import React from 'react';
import DropdownFilter from './DropdownFilter';

import Helper from 'Helper';


const helper = new Helper();


const PusherSolenoidFilter = props => {
  const { SOLENOIDS, filter, onFilterChange } = props;

  return (
    <DropdownFilter
      title={"Pusher Solenoids"}
      placeholder={"Pusher Solenoids"}
      filter={filter}

      SELECTIONS={SOLENOIDS.map(solenoid => {
        return helper.generateOptionForReactSelect(solenoid) 
      })}

      onChange={selectedOptions => onFilterChange("pusherSolenoid", selectedOptions)}
    />

  )
}

export default PusherSolenoidFilter;
import React, { Component } from 'react';
import _ from 'lodash';

import DataDependantComponent from '../../../DataDependantComponent';

import Instructions from '../Instructions';
import LiPoCheckerMotorSelector from '../LiPoCheckerMotorSelector';
import Results from '../Results';

import LiPoCalculator from '../../LiPoCalculator/App';
import HeadroomSelect from '../../Functional/HeadroomSelect';


import MotorHelper from "MotorHelper";
import Helper from "Helper";

import './index.css';


let helper = new Helper();


//this.state.RAW_DATA = all data from db
//this.state.DATA = only more data from db
export default class LiPoChecker extends DataDependantComponent {
	constructor(props) {
		super(props);

		this.motorHelper = new MotorHelper();

		this.state = {
			isLoading: true,
			DATA: [],
			toDisplayResults: false,
			selectedMotors: [],
			batteryCellCount: 0,
			batteryCapacity: 0,
			batteryDischargeRating: 0,
			headroom: 10,
			isDisplayingErrTxt: false
		}
	}

	afterDataRetrieved() {
		let allMotorData = this.state.DATA.data.sort(
			(a, b) => {
				if(a.name < b.name) { return -1; }
	  		if(a.name > b.name) { return 1; }
				return 0;
			});

		this.motorHelper.setData(allMotorData);

		this.setState({
			allMotorData: allMotorData 
		});
	}

	renderChildComponent() {
    if (!this.state.isLoading && !!this.state.allMotorData) {		  	//make sure to get data first
	    return (
	      <div id="LiPoChecker">
	      	<h1>LiPo Checker</h1>
	      	<p>Use this application to check if your battery works with the total load (motors and solenoids) you want to use. Best used with the <a href="https://suild.com/motor-chart">interactive motor chart</a> to find your ideal motor setup!</p>
          <Instructions/>
		      <LiPoCheckerMotorSelector 
		      	addMotor={this.addMotor.bind(this)}
		      	removeMotor={this.removeMotor.bind(this)}
		      	selectedMotors={this.state.selectedMotors}
		      	motorHelper={this.motorHelper}/>
		      <LiPoCalculator
		      	updateBatterySpecs={this.updateBatterySpecs.bind(this)}/>
		      <HeadroomSelect
		      	updateHeadroom={this.updateHeadroom.bind(this)}/>
		      {this.renderErrTxt()}
		      <div className="flex-container-center">
		      	<button id="calculate-btn" onClick={this.calculate.bind(this)}><p id="calculate-btn-txt">Calculate</p></button>
		      </div>
		      <Results
		      	toDisplayResults={this.state.toDisplayResults}
		      	selectedMotors={this.state.selectedMotors}
		      	motorHelper={this.motorHelper}
						batteryCellCount={this.state.batteryCellCount}
						batteryCapacity={this.state.batteryCapacity}
						batteryDischargeRating={this.state.batteryDischargeRating}
						headroom={this.state.headroom}
						areMotorsValid={this.areMotorsValid.bind(this)}
						areBatteriesSpecsValid={this.areBatteriesSpecsValid.bind(this)}/>
	      </div>
	    )
	  } else {
	  	return (
	      <div id="LiPoChecker"></div>
  		)
	  }
  }

  renderErrTxt() {
  	if (this.state.isDisplayingErrTxt) {
  		let errText = "Please fix ";
      let errTextArr = [];

      if (!this.state.selectedMotors.length) {
        errTextArr.push("selected motors");
      }

      if (!this.state.batteryCellCount) {
        errTextArr.push("battery cell count");
      }
        
      if (!this.state.batteryCapacity) {
        errTextArr.push("battery capacity");
      }

      if (!this.state.batteryDischargeRating) {
        errTextArr.push("battery discharge rating");
      }

      for (let i = 0; i < errTextArr.length; i++) {
        errText += errTextArr[i];
        if (i < errTextArr.length -1) {
          errText += ", "
        }
      }

      if (errText === "Please fix ") {
      	return;
      }

      return (
        <p className="error-text center-text">{errText}</p>
      )
  	}
  }

  addMotor(selectedMotor) {
    let newSelectedMotors;

    // 35mm solenoid isn't in motor database, so it needs to be handled separately
    if (selectedMotor.name === "12V 35mm Stroke Solenoid") {
      newSelectedMotors = this.state.selectedMotors.concat({
        name: "12V 35mm Stroke Solenoid",
        nominalBattery: [3],
        qty: selectedMotor.qty  //Passed in from wherever addMotor() is called
      })
    } else {
      // Handle motors already in the database
      let motorData = this.motorHelper.findMotorsWithMatchingName(selectedMotor.name)[0];
      selectedMotor.nominalBattery = motorData.nominalBattery;

      newSelectedMotors = this.state.selectedMotors.concat(selectedMotor)
    }

    console.log(newSelectedMotors)

  	this.setState({selectedMotors: newSelectedMotors});
  }

  removeMotor(index) {
  	let newSelectedMotors = helper.removeItemFromArrWithIndex(this.state.selectedMotors, index);
  	this.setState({selectedMotors: newSelectedMotors});
  }

  

  updateBatterySpecs(newCellCount, newBatteryCapcity, newDischargeRating) {
  	this.setState({
  		batteryCellCount: newCellCount,
  		batteryCapacity: newBatteryCapcity,
  		batteryDischargeRating: newDischargeRating
  	});
  }

  updateHeadroom(newHeadroom) {
  	this.setState({
  		headroom: newHeadroom
  	}); 
  }

  calculate() {
  	if (this.areMotorsValid() && this.areBatteriesSpecsValid()) {
  		this.setState({
  			isDisplayingErrTxt: false,
  			toDisplayResults: true
  		});
  	} else {
  		this.setState({
  			isDisplayingErrTxt: true,
  			toDisplayResults: false
  		});
  	}
  }

  areMotorsValid() {
  	if (this.state.selectedMotors.length > 0) {
  		return true;
  	} 

  	return false;
  }	

  areBatteriesSpecsValid() {
  	if (this.state.batteryCellCount > 0 && this.state.batteryCapacity > 0 && this.state.batteryDischargeRating > 0) {
  		return true;
  	}

  	return false;
  }



}

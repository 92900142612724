import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import Markdown from 'react-remarkable'

import DataDependantComponent from '../DataDependantComponent';
import ContentBox1 from '../Functional/ContentBoxes/ContentBox1'

import Navigation from './Navigation';


import './index.css';

export default class Docs extends DataDependantComponent {
	renderChildComponent () {
		const docsPreview = this.state.DATA.map(this.renderDocsPreview);

		return (
      <div id="Docs">
	    	<div id="doc-content">
					<h1>Documentation</h1>
	    		<p>Some of the best resources in the hobby to put together your dream blaster.</p>
	    		{docsPreview}
      	</div>
      	{/*
      		<Navigation DATA={this.state.DATA}/>
    		*/}
      </div>
		);
	}

	renderDocsPreview (item) {
		return (
			<div className="doc-preview section">
				<Link
					to={"/docs/" + item.key} 
					className="doc-preview-link">
					<h2 className="doc-preview-header section-header">{item.title}</h2>
					<div className="doc-preview-md">
						<Markdown 
		    			options={{html: true}}
		    			source={item.preview} />
		    	</div>
	    		<div className="inline-container">
		    		<img 
		    			src={require(`assets/clock-icon.png`)} 
		    			className="doc-preview-clock-icon clock-icon"/>
		    		<p className="doc-preview-date doc-preview-inline left-align-text">{item.date}</p>
		    		<p className="doc-preview-read-more doc-preview-inline right-align-text">Read More ></p>
		    	</div>		
		  	</Link>  			
			</div>
		)
	} 
}

import React from 'react';
import Creatable from 'react-select/creatable';

const FlywheelEntry = props => {
  const { flywheelsInForm, 
    errorText,
    updateFlywheelSetupForm,
    getUpdatedSetup,
    styles,

    FLYWHEELS,
    generateOptionForReactSelect } = props;
  
  return (
    <div className="stage-entry-form-item">
      {/* Flywheel entry */}
      <Creatable
        styles={styles}
        placeholder={"Flywheels *"}
        value={ 
          !!flywheelsInForm.value && 
          generateOptionForReactSelect(flywheelsInForm.value)}
        options={
          FLYWHEELS.map(blaster => {
            return generateOptionForReactSelect(blaster) 
          })
        }
        onChange={selectedFlywheels => {
          let updatedSetup = getUpdatedSetup(
            { flywheels: { 
              value: { $set: selectedFlywheels.value },
              isNew: { $set: !!selectedFlywheels.__isNew__ } } 
            }
          );

          updateFlywheelSetupForm(updatedSetup);
        }}
      />

      <p className="setup-entry-help">
        Enter the full name of your flywheels and specify company. If your flywheels aren't here, feel free to type in a new one to add it to the database!
      </p>
      
      <p className="center-text error-text">{errorText}</p>
    </div>
  )

}

export default FlywheelEntry;
import React, { Component } from 'react';

import './index.scss';

const Modal = props => {
	return (
		<div id={props.id} className={(props.isVisible ? "Modal-visible " : "Modal-gone ") + props.className}>
			<div className={(props.isVisible ? "modal-content-container-visible" : "modal-content-container-gone")}>
				{props.children}
			</div>
		</div>
	)

}

export default Modal;
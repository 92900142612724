import React from 'react';
import InfiniteScroll from 'react-infinite-scroller'
import FlipMove from 'react-flip-move';

import Loading from '../../../Loading';
import SetupDisplay from '../SetupDisplay';


const SetupScroll = props => {
  const {
    getMoreSetups,
    hasMoreSetups,

    setups,

    searchID,
    setupFound,
    setSetupFound,

    setIsSnackbarOpen,
    
    motorHelper
  } = props;

  // If there are setups to render, render them
  if (setups.length > 0) {
    return (
      <div id="SetupScroll">
        <InfiniteScroll
          pageStart={0}
          loadMore={getMoreSetups}
          hasMore={hasMoreSetups}
          loader={<Loading/>}
          threshold={1500}
        >
          <FlipMove id="setups-container" className="flex-container-center">
            {
              setups.map(SETUP_DATA => {
                // Setup is found
                if (!setupFound && SETUP_DATA.id == searchID) {
                  setSetupFound(!!searchID && true)
                  setIsSnackbarOpen(!!searchID && false)
                }

                return (
                  <div key={SETUP_DATA.id}>
                    <SetupDisplay
                      setupData={SETUP_DATA}
                      isPreviewMode={false}
                      motorHelper={motorHelper}
                      isSetupFullscreen={SETUP_DATA.id == searchID}
                    />
                  </div>
                )
              })
            }
          </FlipMove>
        </InfiniteScroll>

        { !hasMoreSetups &&
          <p className="center-text">That's all the setups! Try changing your filters or check your network connection.</p>
        }
      </div>
    )

  // No setups, so show message to select more setups
  } else {
    return (
      <p>No setups here! Try adjusting your filters or check your network connection.</p>
    )
  }
}


export default SetupScroll;
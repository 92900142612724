import React from 'react';

import PostDetails from './PostDetails';

const Header = props => {
  const { setupData, cellCountInWords } = props;

  return (
    <div>
      <div className={`setup-display-card-header ${cellCountInWords}-s-color center-text`}>
          <h1 className="setup-display-card-header-text"
            style={{fontSize: "1.5em"}}
          >
            {setupData.setupName}
          </h1>
          <p className="setup-display-card-header-text"
            style={{
              marginTop: "0.8em", 
              fontSize: "1em"
            }}
          >
            {setupData.blaster}
          </p>
        </div>

        {/* Subheader for card */}
        <div className="setup-display-card-subheader super-light-grey-bg center-text">
          <PostDetails setupData={setupData}/>
        </div>
    </div>
  )
}

export default Header;
import React from 'react';
import DropdownLoadSelector from '../../../../../../Functional/DropdownLoadSelector';

const PusherMotorEntry = props => {
  const { pusherMotor, 
    onChange, 
    ALL_MOTOR_DATA, 
    errorText, 
    generateOptionForReactSelect,
    styles } = props;

  return (
    <div>
      <DropdownLoadSelector className="select-entry"
        styles={styles}

        ALL_MOTOR_DATA={ALL_MOTOR_DATA}
        placeholder={"Pusher Motor"}
        isClearable={true}
        value={ 
          !!pusherMotor.value && generateOptionForReactSelect(pusherMotor.value)
        }
        additionalLoads={[ 
          {
            name: "Stock Motor",
            nominalBattery: [2]
          }
        ]}
        onChange={(selectedPusher) => {
          onChange({
            pusher: { 
              pusherMotor: { 
                value: {$set: !!selectedPusher ? selectedPusher.value : ""}
              }
            }
          })
        }}
      />

      <p className="setup-entry-help">
        Please select pusher only if your blaster is fully-automatic and your blaster uses a pusher motor. If your blaster uses a solenoid, you'll need to select "Solenoid" in pusher mechanism selection above. Stock motors are listed under 2S nominal.
      </p>

      <p className="center-text error-text">{errorText}</p>

    </div>
  )
}

export default PusherMotorEntry;
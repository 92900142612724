import React, { memo } from 'react';
import { Button } from '@material-ui/core';

import LiPoCellCountEntry from './FormItems/LiPoCellCountEntry';
import PusherMechanismEntry from './FormItems/PusherMechanismEntry';
import PusherMotorEntry from './FormItems/PusherMotorEntry';
import SolenoidEntry from './FormItems/SolenoidEntry';
import PusherBatteryCellCountEntry from './FormItems/PusherBatteryCellCountEntry';
import StageSelectionEntry from './FormItems/StageSelectionEntry';


import _ from "lodash";


import './index.scss';


const SetupEntry = memo(props => {
  const {
    // Static Values
    FLYWHEEL_SETUP_PICKER_METADATA,
    errorStyles,

    // Dynamic values
    formValues,
    errors,
    
    // Callbacks or functions
    updateFormWrapper,
    generateErrorText,
    generateOptionForReactSelect,

    // Random stuff
    motorHelper,
    SetupStage
  } = props;


  return (
    <div className="setup-entry-form-section">
      <h2>Setup</h2>

      <LiPoCellCountEntry
        cellCount={formValues.cellCount}
        onChange={updateFormWrapper}
        errorText={generateErrorText(errors.cellCount)}
      />
      
      {/* Setup Selection for all Stages */}
      <div id="stage-selection-entry-forms">
        {/* Forms for each stage */}
        {formValues.setup.map(
          (currentStage, index) => {
            // Generate error text for this entire stage. Each individual 
            // component's errors (motors, flywheels, etc) is saved as a 
            // member of errors
            let stageErrors = generateErrorText(
              // Set errors for this stage if this stage's errors exist
              (!!errors.setup && !!errors.setup[index]) ? errors.setup[index] : {}
            );
            
            // Generate styles for every component (motors, wheels, etc). 
            // Right now, these styles are only if the component in the 
            // stage has errors. 
            let stageStyles = {}

            // Generate style for each component in the stage
            for (let componentErrorKey in stageErrors) {
              // If this component has an error, apply the styles to this
              // compoennt
              if (!!stageErrors[componentErrorKey] && stageErrors[componentErrorKey].length > 0) {
                stageStyles[componentErrorKey] = errorStyles
              }
            }  

            return (
              <StageSelectionEntry
                currentStage={currentStage}
                stageIndex={index}

                setup={formValues.setup}

                ALL_MOTOR_DATA={motorHelper.DATA}
                FLYWHEELS={FLYWHEEL_SETUP_PICKER_METADATA.flywheels}
                FLYWHEEL_CAGES={FLYWHEEL_SETUP_PICKER_METADATA.flywheelCages}
                FLYWHEEL_CAGE_MATERIALS={FLYWHEEL_SETUP_PICKER_METADATA.flywheelCageMaterials}
                DART_GUIDES={FLYWHEEL_SETUP_PICKER_METADATA.dartGuides}
                
                
                generateOptionForReactSelect={generateOptionForReactSelect}
                updateFlywheelSetupForm={updateFormWrapper}

                errors={stageErrors}
                styles={stageStyles}
              />
            )
          })
        }

        {/* Add a Stage button */}
        <Button className="add-btn-color setup-entry-form-btn" variant="contained"
          onClick={() => {
            // Push a new blank stage into the state
            updateFormWrapper({
              setup: { 
                // $push requires an array, all items in array will be pushed 
                $push: [
                  new SetupStage()
                    .generateNewStage(formValues.setup.length + 1)
                ] 
              }
            });
          }}
        >
          + Add a Stage
        </Button>
      </div>

      {/* Pusher Selection */}
      {/* Only render if setup is full auto */}
      { formValues.isFullAuto.value && 
        <div>
          <h3>Pusher</h3>

          <PusherMechanismEntry
            pusherMechanism={formValues.pusher.pusherMechanism}
            onChange={updateFormWrapper}
            PUSHER_MECHANISMS={FLYWHEEL_SETUP_PICKER_METADATA.PUSHER_MECHANISMS}
            generateOptionForReactSelect={generateOptionForReactSelect}
            errorText={generateErrorText(!!errors.pusher ? errors.pusher.pusherMechanism : "")}
            styles={!!generateErrorText(!!errors.pusher ? errors.pusher.pusherMechanism : "") ? errorStyles : {}}
          />

          { formValues.pusher.pusherMechanism.value &&
            formValues.pusher.pusherMechanism.value !== "Solenoid" &&
            // Only provide option for Pusher Motor Entry if user didn't
            // select solenoid pusher and a pusher mechanism was selected
            <PusherMotorEntry
              pusherMotor={formValues.pusher.pusherMotor}
              onChange={updateFormWrapper}
              ALL_MOTOR_DATA={motorHelper.DATA}
              generateOptionForReactSelect={generateOptionForReactSelect}
              errorText={generateErrorText(!!errors.pusher ? errors.pusher.pusherMotor : "")}
              styles={!!generateErrorText(!!errors.pusher ? errors.pusher.pusherMotor : "") ? errorStyles : {}}
            />
          }

          { formValues.pusher.pusherMechanism.value &&
            formValues.pusher.pusherMechanism.value === "Solenoid" &&
            // Only provide option for Solenoid Entry if user did
            // select solenoid pusher as pusher mechanism
            <SolenoidEntry
              solenoid={formValues.pusher.solenoid}
              onChange={updateFormWrapper}
              SOLENOIDS={FLYWHEEL_SETUP_PICKER_METADATA.SOLENOIDS}
              generateOptionForReactSelect={generateOptionForReactSelect}
              errorText={generateErrorText(!!errors.pusher ? errors.pusher.solenoid : "")}
              styles={!!generateErrorText(!!errors.pusher ? errors.pusher.solenoid : "") ? errorStyles : {}}
            />
          }
          
          <PusherBatteryCellCountEntry 
            pusherBatteryCellCount={formValues.pusher.batteryCellCount}
            onChange={updateFormWrapper}
            errorText={generateErrorText(!!errors.pusher ? errors.pusher.batteryCellCount : "")}
            styles={!!generateErrorText(!!errors.pusher ? errors.pusher.batteryCellCount : "") ? errorStyles : {}}

            flywheelCellCount={formValues.cellCount.value}
          />
      
        </div> 
      }
    </div>
  )

    // Determine whether or not to re-render based on props only
}, (prevProps, nextProps) => {
  const prevFormVals = prevProps.formValues;
  const nextFormVals = nextProps.formValues;

  const prevErrors = prevProps.errors;
  const nextErrors = nextProps.errors;


  // To re-render, return false
  // Don't re-render if all of the following are unchanged:
  //  - cell count formvalue and errors
  //  - flywheel formvalue and errors
  //  - pusher formvalue and errors
  //  - hasAttemptedSubmit remains the same: hasAttemptedSubmit from false to
  //      true will always trigger re-render becaus eerrors are displayed when
  //      that happens
  //  - rateOfFire, changes in this can cause changes in pusher entry
  //
  // If even 1 of these things changes, just re-render SetupDisplay because these
  // are slow inputs (ie not typing) so if the form's a bit slow when entering 
  // these values, it doesn't matter too much
  if (prevProps.hasAttemptedSubmit === nextProps.hasAttemptedSubmit
    
    && prevFormVals.cellCount.value === nextFormVals.cellCount.value 
    && prevErrors.cellCount === nextErrors.cellCount

    // Check for changes in setup 
    && prevFormVals.setup === nextFormVals.setup        // Form vals can use === to compare b/c mutable 
    && _.isEqual(prevErrors.setup, nextErrors.setup)    // Errors can't use === to deep compare b/c immutable

    // Check for changes in pusher
    && prevFormVals.pusher === nextFormVals.pusher
    && _.isEqual(prevErrors.pusher, nextErrors.pusher)
    
    // Check for changes in rate of fire
    && prevFormVals.rateOfFire.value === nextFormVals.rateOfFire.value
    && _.isEqual(prevErrors.rateOfFire, nextErrors.rateOfFire)) {
    return true
  }

  return false
})


export default SetupEntry
import React from 'react';
import Select from 'react-select';



const SORTING_OPTIONS = [
  {
    label: "Top",
    value: "top:high-to-low"
  },
  {
    label: "Bottom",
    value: "bottom:low-to-high"
  },
  {
    label: "Price: High to Low",
    value: "price:high-to-low"
  },
  {
    label: "Price: Low to High",
    value: "price:low-to-high"
  },
  {
    label: "Muzzle Velocity: High to Low",
    value: "muzzleVelocity:high-to-low"
  },
  {
    label: "Muzzle Velocity: Low to High",
    value: "muzzleVelocity:low-to-high"
  },
  {
    label: "Rate of Fire: High to Low",
    value: "rateOfFire:high-to-low"
  },
  {
    label: "Rate of Fire: Low to High",
    value: "rateOfFire:low-to-high"
  },
  {
    label: "Alphabetically: A - Z",
    value: "alphabetically:high-to-low"
  },
  {
    label: "Alphabetically: Z - A",
    value: "alphabetically:low-to-high"
  },
  {
    label: "Date: New to Old",
    value: "date:high-to-low"
  },
  {
    label: "Date: Old to New",
    value: "date:low-to-high"
  }
]


const Sorting = props => {
  const { sort, setSort } = props;


  return (
    <div id="Sorting" className="flex-container-right" style={{marginTop: "2em"}}>
      <Select className="small-select-dropdown"
        placeholder={"Sort"}
        value={ 
          sort
        }
        options={
          SORTING_OPTIONS.map(sortingOption => {
            return sortingOption
          })
        }
        onChange={(selectedSort) => setSort(selectedSort)}
        isSearchable={false}
      />
    </div>
  )
}

export default Sorting;
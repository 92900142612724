// Default state of entryFormContent
// Saves space in SetupEntry/index.js file

import SetupStage from './SetupStage';
import MuzzleVelocity from './MuzzleVelocity';

const DEFAULT_ENTRY_FORM = {
  setupName: {
    isRequired: true,
    key: "Setup Name",
    value: ""
  },
  username: {
    isRequired: true,
    key: "Username",
    value: ""
  },
  email: {
    isRequired: false,
    key: "Email",
    value: ""
  },
  blaster: {
    isRequired: true,
    key: "Blaster",
    value: "",
    isNew: false
  },
  powerLevel: {
    isRequired: true,
    key: "power level",
    value: ""
  },
  muzzleVelocities: [
    new MuzzleVelocity(0)
  ],
  rateOfFire: {
    isRequired: true,
    key: "Rate of Fire",
    value: ""
  },
  isFullAuto: {
    isRequired: false,
    key: "Full auto?",
    value: false
  },
  price: {
    isRequired: true,
    key: "Price",
    value: ""
  },
  priceGroup: {
    isRequired: false,
    key: "Price Group",
    value: ""
  },
  cellCount: {
    isRequired: true,
    key: "LiPo Cell Count",
    value: "0"
  },
  setup: [
    new SetupStage().generateNewStage(1)
  ],
  pusher: {
    pusherMechanism: {
      isRequired: false,
      key: "Pusher Mechanism",
      value: ""
    },
    pusherMotor: {
      isRequired: false,
      key: "Pusher Motor",
      value: ""
    },
    solenoid: {
      isRequired: false,
      key: "Pusher Solenoid",
      value: ""
    },
    batteryCellCount: {
      isRequired: false,
      key: "Pusher Battery",
      value: "0"
    }
  },
  additionalSetupNotes: {
    isRequired: false,
    key: "Additional Setup Notes",
    value: ""
  }
}

export default DEFAULT_ENTRY_FORM;
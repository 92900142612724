import React from 'react';
import { TextField } from '@material-ui/core';

const UsernameEntry = props => {
  const { username, onChange, errorText } = props;

  return (
    <div>
      <TextField 
        className="outlined-basic setup-entry-text-field" 
        label="Username" 
        variant="outlined"
        required={true}
        value={username.value}
        onChange={
          (e) => onChange(
            {username: { value: {$set: e.target.value}}}
          )
        }
        error={!!errorText && errorText.length > 0}
      />

      <p className="setup-entry-help">
        Enter the username that this setup will be displayed under. It can be your YouTube channel, Reddit name, anything! If you comment on a setup, you can use that same username. If you entered an email with that comment, you'll also need to enter that same email here.
      </p>

      <p className="center-text error-text">{errorText}</p>

    </div>
  )
}

export default UsernameEntry;
import React, { Component } from 'react';

import './index.css';

export default class NotFound extends Component {
	constructor (props) {
    super(props);

    this.dropdownChanged = this.dropdownChanged.bind(this);
    this.renderDropdownOptions = this.renderDropdownOptions.bind(this);

    this.state = {
      "quantity": 1
    }
  }

  render () {
    return (
      <div className="dropdown-container">
        <p className="checkboxes-title">{this.props.title}</p>
        <select 
       		className="Dropdown"
  	      value={this.props.quantity} 
  	      onChange={this.dropdownChanged}>
          {this.renderPlaceholderDropdownDefault()}
  	      {this.populateDropdownOptions().map(this.renderDropdownOptions)}
      	</select>
      </div>
    )
  }

  //empty defualt value if needed
  //https://stackoverflow.com/questions/3518002/how-can-i-set-the-default-value-for-an-html-select-element
  renderPlaceholderDropdownDefault() {
    if (this.props.placeholderDefaultVal) {
      return (
        <option value="" selected disabled hidden>Choose here</option>
      )
    }
  }

  //how many items to render
  //returns arr of num, starting at one
  populateDropdownOptions() {
    let arr = [];

    //if arr of text passed in to render, render those as options. If not, stick with numbers
    if (this.props.itemTextArr) {
      for (var i = 0; i < this.props.itemTextArr.length; i++) {
        arr.push(this.props.itemTextArr[i])
      } 
    } else {
      for (var i = 1; i <= this.props.qtyToRender; i++) {   //starts iterating at 1, inclusive
        arr.push(i);
      } 
    }

    return arr;
  }

  renderDropdownOptions(item, i) {
    if (this.props.defaultVal && this.props.defaultVal === item) {
      return (
        <option className="dropdown-text" value={item} selected>{item}</option>
      )
    } else {
      return (
        <option className="dropdown-text" value={item}>{item}</option>
      )
    }

    
  }

  dropdownChanged (e) {
  	this.props.dropdownChanged(e.target.value, e);
  }

}
//methods: 
// - isItemInArr: checks if item is in arr, returns true if it is
// - removeItemFromArr: returns new arr with passed in item removed
// - removeItemFromArrWithIndex: returns new arr without the item at the 
//        corresponding passed in index
// - renderList: returns dom el div with list
// - pluralizer: determines whether or not to add 's' depending on qty
// - formNormalizer: Expecting a completed form obj to be passed in, adds id, 
//        date, and time fields, returns new form obj with these members
// - getDate: Returns date as string in formats: 1. DAY, MO/DATE/YR 
//        2. DAY(shortened), MO/DATE/YR, 3. DAY, MO DATE, YR, 4. DAY(shortened), 
//        MO(shortened) DATE, YR
// - getMonth: returns month, has flag isShortened. If isShortened true, returns 
//       shortened month, else returns full name of month
// - getDay: returns day, has flag isShortened. If isShortened true, returns 
//      shortened day, else returns full name of day
// - getTime: returns time, with seconds, in 24hr format
// - sortArrayInAlphabeticalOrder: returns array of objs with props name in 
//      alphabetical order from name prop
// - removeUndefinedItemsFromArr: returns arr with all undefined elements 
//      removed
// - validateItem: Returns item if it's defined/not null/true, false if item 
//      doesn't exist/false/null
// - getArrayAsTextList: returns string of array items in a human-readable list 
//      format
// - generateOptionForReactSelect: returns option object in proper format for 
//      react-select 



import React from 'react';
import _ from 'lodash';

//helper class b/c I'm lazy
export default class Helper {
	isItemInArr (arr, item) {
    if (typeof item === "object") {
      for (var i = 0; i < arr.length; i++) {
        if (_.isEqual(item, arr[i])) {
          return true;
        }
      }
    } else {
      return _.indexOf(arr, item) !== -1
    }

    return false;
  }

  removeItemFromArr(arr, itemToRemove) {
    let newArr = _.remove(arr, (item) => {
      return !_.isEqual(itemToRemove, item);
    });

    return newArr;
  }

  removeItemFromArrWithIndex(arr, index) {
    let newArr = [];

    for (let i = 0; i < arr.length; i++) {
      if (i !== index) {
        newArr.push(arr[i]);
      }
    }

    return newArr;
  }

  renderList(list, className) {
    return (
      <div className={"list " + className}>
        {list.map((item) => {
          return (
            <li>{item}</li>
          )
        })}
      </div>
    )
  }

  pluralizer(text, qty) {
    if (qty === 1) {
      return text;
    }

    return text + "s";
  }

  formNormalizer(form) {
    let newForm = Object.assign({}, form);
    let date = new Date();

    // If form already has an id property, don't overwrite it 
    newForm.id = !!form.id ? form.id : Date.now();
    
    newForm.date = date;
    newForm.formattedDate = this.getDate(3);
    newForm.time = this.getTime();

    return newForm;
  }

  //1. DAY, MO/DATE/YR 
  //2. DAY(shortened), MO/DATE/YR, 
  //3. DAY, MO DATE, YR, 
  //4. DAY(shortened), MO(shortened) DATE, YR
  getDate(format) {
    let date = new Date();
    let isShortened = false;

    //format is even, so date will be shortened
    if (!(format % 2)) {    
      isShortened = true;
    }

    //format 1 or 2
    if (format < 3) {
      return this.getDay(isShortened) + ", " + date.getMonth() + "/" + date.getDate() + "/" + date.getFullYear();
    }

    //format 3 or 4
    return this.getDay(isShortened) + ", " + this.getMonth(isShortened) + " " + date.getDate() + ", " + date.getFullYear();
  }

  getDay(isShortened) {
    let date = new Date();
    //return shortened months
    if (isShortened) {
      let day = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      return day[date.getDay()];
    }

    //return regular months
    let day = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    return day[date.getDay()];
  }

  getMonth(isShortened) {
    let date = new Date();
    //return shortened months
    if (isShortened) {
      let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
      return months[date.getMonth()];
    }

    //return regular months
    let months = ["January", "Febuary", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    return months[date.getMonth()];
  }

  getTime() {
    let date = new Date();

    return date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
  }

  sortArrayInAlphabeticalOrder(arr) {
    return arr.sort(
      (a, b) => {
        if(a.name < b.name) { return -1; }
        if(a.name > b.name) { return 1; }
        return 0;
      })
  }

  // removes all undefined elements from array
  removeUndefinedItemsFromArr(arr) {
    let filteredArr = [];

    for (let i = 0; i < arr.length; i++) {
      if (typeof arr[i] !== 'undefined') {
        filteredArr.push(arr[i])
      }
    }

    return filteredArr;
  }

  // Returns item if it's defined/not null/true
  // returns salse if item doesn't exist/false/null
  validateItem(item) {
    if (!!item) {
      return item
    }

    return false
  }

  // returns string of array items in a human-readable list format
  // Ex: arr = ["apples", "pears", bananas"] => apples, pears, and bananas
  getArrayAsTextList(arr) {
    if (arr.length === 0) return null
    if (arr.length === 1) return arr[0]

    let textList = ""

    _.forEach(arr, (item, index) => {
      textList += " " + item;
      if (index < arr.length - 1 && arr.length > 2) {
        textList += ","
      } 

      if (index === arr.length - 2) {
        textList += " and"
      }
      
    })

    return textList
  }

  // Generates an option in proper format for React-select
  // Option (string): Option text
  //
	// In this form: 
	// {
	// 	label: option,
	// 	value: option
	// }
	// Value and option don't need to be the same, but this method will return an
	// object where they are the same for simplicty sake
	generateOptionForReactSelect(option) {
		return {
			label: option,
			value: option
		}
  }
  
}
import React, { memo } from 'react';

import MuzzleVelocityEntry from './FormItems/MuzzleVelocityEntry';
import RateOfFireEntry from './FormItems/RateOfFireEntry';

import _ from "lodash";


const PerformanceEntry = memo(props => {
  const {
    // Static Values
    FLYWHEEL_SETUP_PICKER_METADATA,
    errorStyles,
    MuzzleVelocity,

    // Dynamic values
    formValues,
    errors,
    
    // Callbacks or functions
    updateFormWrapper,
    generateErrorText,
    generateOptionForReactSelect

  } = props;


  return (
    <div className="setup-entry-form-section">
      <h2>Performance</h2>

      <MuzzleVelocityEntry
        FPS_SELECTIONS={FLYWHEEL_SETUP_PICKER_METADATA.FPS_SELECTIONS}
        DART_TYPES={FLYWHEEL_SETUP_PICKER_METADATA.dartTypes}
        MuzzleVelocity={MuzzleVelocity}

        updateFormWrapper={updateFormWrapper}
        generateOptionForReactSelect={generateOptionForReactSelect}
        generateErrorText={generateErrorText}

        muzzleVelocities={formValues.muzzleVelocities}
        errors={errors.muzzleVelocities}
        errorStyles={errorStyles}
      />

      <RateOfFireEntry
        rateOfFire={formValues.rateOfFire}
        onChange={updateFormWrapper}
        RATE_OF_FIRE_SELECTIONS={FLYWHEEL_SETUP_PICKER_METADATA.RATE_OF_FIRE_SELECTIONS}
        generateOptionForReactSelect={generateOptionForReactSelect}
        errorText={generateErrorText(errors.rateOfFire)}
        styles={!!generateErrorText(errors.rateOfFire) ? errorStyles : {}}
      />
    </div>
  )

    // Determine whether or not to re-render based on props only
}, (prevProps, nextProps) => {
  const prevFormVals = prevProps.formValues;
  const nextFormVals = nextProps.formValues;

  const prevErrors = prevProps.errors;
  const nextErrors = nextProps.errors;

  // To re-render, return false
  // Don't re-render if all of the following are unchanged:
  //  - muzzleVelocities errors and form
  //  - rateOfFire errors and form
  //  - hasAttemptedSubmit remains the same: hasAttemptedSubmit from false to
  //      true will always trigger re-render becaus eerrors are displayed when
  //      that happens
  if (prevProps.hasAttemptedSubmit === nextProps.hasAttemptedSubmit

    // Check for changes in muzzleVelocities 
    && prevFormVals.muzzleVelocities === nextFormVals.muzzleVelocities        // Form vals can use === to compare b/c mutable 
    && _.isEqual(prevErrors.muzzleVelocities, nextErrors.muzzleVelocities)    // Errors can't use === to deep compare b/c immutable

    && prevFormVals.rateOfFire.value === nextFormVals.rateOfFire.value 
    && prevErrors.rateOfFire === nextErrors.rateOfFire) {

    return true
  }

  return false
})


export default PerformanceEntry
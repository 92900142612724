import React, { Component } from 'react';

import ContentBox1 from '../../Functional/ContentBoxes/ContentBox1';

import './index.css';

export default class NotFound extends Component {
  render () {
  	let data = [
  		{
  			key: 0,
  			imgSrc: "Tools/motor-chart.png",
  			title: "Interactive Motor Chart",
  			description: "Easily compare and select almost every aftermarket motor used for Nerf applications for your flywheel blaster. View torque, RPM, and stall current at various voltages, motor information, and purchase locations for each and every motor.",
  			link: "tools/motor-chart"
  		},
  		{
  			key: 1,
  			imgSrc: "Tools/lipo-checker.png",
  			title: "LiPo Checker",
  			description: "Use this application to check if your battery works with the motors you want to use. Supports a selection for almost every aftermarket motor used for Nerf applications at any quantity, overvolting, undervolting, and headroom selection.",
  			link: "tools/lipo-checker"
			},
      {
        key: 2,
        imgSrc: "Tools/flywheel-setup-picker.png",
        title: "Flywheel Setup Picker",
        description: "Find your ideal flywheel setup! Filter and sort setups through to best fit your needs and even comment, vote, and add your own setups! ",
        link: "tools/flywheel-setup-picker"
      },
      {
        key: 3,
        imgSrc: "Tools/lipocalculator.png",
        title: "LiPo Calculator",
        description: "Too lazy to calculator your LiPo specs? LiPo Calculator does that all for you!",
        link: "tools/lipo-calculator"
      },
      {
        key: 4,
        imgSrc: "Tools/motor-spec-finder.png",
        title: "Motor Spec Finder",
        description: "With so many motors on the market, it may be overwhelming to find the specifications of a particular motor. This application does that and more!",
        link: "tools/motor-spec-finder"
      }
  	]

    return (
      <div id="Tools">
        <h1>Tools</h1>
        <p>The top tools specially designed to make selecting your best setup as easy as possible.</p>
        <div className="flex-container-center">
	        {data.map((item) => {
	        	return (
		        	<ContentBox1
		        		className="tools-content-box"
		        		imgClassName="tools-content-box-img"
		        		textContainerClassName="tools-content-box-text-container"
								imgSrc={item.imgSrc}
								title={item.title}
								description={item.description}
								link={item.link}/>
						)
	        })}
        </div>
      </div>
    )
  }

}
// Class to represent a single stage for a flywheel setup

export default class SetupStage {
	// returns blank object in proper formatting for a stage that can be used in 
	// SetupEntry
	//
	// stageNumber: what order the stage is in (ie first stage, 2nd stage, etc)
	generateNewStage(stageNumber) {
		return {
			isRequired: true,
			key: "Stage " + stageNumber + " Setup",
			value: {
				motors: {
					isRequired: true,
					key: "Motors",
					value: ""
				},
				volting: {
					isRequired: false,
					key: "Volting",
					value: ""
				},
				flywheels: {
					isRequired: true,
					key: "Flywheels",
					value: "",
					isNew: false
				},
				flywheelCage: {
					isRequired: true,
					key: "Flywheel Cage",
					value: "",
					isNew: false
				},
				flywheelCageMaterial: {
					isRequired: true,
					key: "Cage Material",
					value: "",
					isNew: false
				},
				dartGuide: {
					isRequired: false,
					key: "Dart Guide",
					value: "",
					isNew: false
				},
				isMicroFlywheelSetup: {
					isRequired: true,
					key: "Micro Flywheel Setup Checkbox",
					value: false,
					isNew: false
				}
			}
		}
	}


}
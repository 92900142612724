import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import { IconContext } from 'react-icons';

import Dropdown from '../../../Functional/Dropdown';

import './index.css';

export default class Regular extends Component {
	render() {
    return (
      <div className="CartItem">
        <div className="cart-item-container center-div horiz-fb flex-wrap">
          <div className="horiz-fb cart-item-product-info">
            <div className="">
              <Link
                className="cart-item-link-to-product"
                to={"/shop/" + this.props.matchingItemFromDB.key}>
                <img
                  className="cart-item-img"
                  src={require(`assets/${this.props.matchingItemFromDB.imgs[0].src}`)}/>
              </Link>
            </div>

            <div>
              <Link
                  className="cart-item-link-to-product"
                  to={"/shop/" + this.props.matchingItemFromDB.key}>
                <h3 className="cart-item-title">{this.props.matchingItemFromDB.name}</h3>
              </Link>

              <div className="options-container">
                {this.renderOptions()}
              </div>
              {this.renderRemoveFromCartButton(this.props.i)}
            </div>
          </div>
          <div className="horiz-fb">
            <div className="cart-item-qty-wrapper">
              {this.renderQuantity(this.props.item)}
            </div>

            <div className="cart-item-price-wrapper">
              <p className="cart-item-price-val text-right">${(this.props.matchingItemFromCart.price * this.props.matchingItemFromCart.quantity).toFixed(2)}</p>
              {this.renderEachText()}
            </div>
          </div>
        </div>
        {this.renderShippingMessage()}
      </div>
    )
		
	}

	renderIndex(i) {
    return (
      <div className="product-index-container center-align-text">
        <p className="product-index">{i + 1}</p>
      </div>  
    )
  }

  //render all options, don't render any options if there are no options
  renderOptions() {
  	if (this.props.item.selectedOptions && this.props.item.selectedOptions !== -1) {
      return this.props.item.selectedOptions.map(this.renderOption.bind(this));
    }
  }

  //render individual option
  renderOption(selectedOption) {
  	//if there's a price change, also show the price change
  	if (selectedOption.dPrice !== 0) {
      // // Display price so negative sign (if applicable) is on the outside of the dollar sign
      // let priceText = selectedOption.dPrice > 0 ? "($" + selectedOption.dPrice + ")" : " (-$" + Math.abs(selectedOption.dPrice) + ")"

  		return (
        <div>
          <p className="options-text">{selectedOption.optionSetTitle}</p>
          <li className="options-text">{selectedOption.label}</li>
        </div>
      )
  	} else { 
  		return (
        <div>
          <p className="options-text">{selectedOption.optionSetTitle}</p>
          <li className="options-text">{selectedOption.label}</li>
        </div>
      )
  	}
  }

  //i is index of corresponding item
  renderRemoveFromCartButton(i) {
    if (this.props.canChange) {
      return (
        <div
          className="button-style-1 remove-from-cart-btn"
          onClick={(e) => this.props.removeFromCart(e, i)}>
          <p className="button-text center-text">Remove</p>
        </div>
      )
    }
  }

	renderQuantity(item) {
    if (this.props.canChange) {
      return (
        <Dropdown 
          qtyToRender={10} 
          dropdownChanged={(qty) => this.props.dropdownChanged(qty, item.productKey, item.options)}
          defaultVal={item.quantity} />
      )
    } else {
      return (
        <p className="cart-item-quantity">{item.quantity}</p>

      )
    }
  }

  renderEachText() {
  	if (this.props.matchingItemFromCart.quantity > 1) {
	    return (
	      <p className="listing-cost-each">${this.props.matchingItemFromCart.price.toFixed(2)} each</p>  
	    ) 
	  }
  }

  renderShippingMessage() {
    if (!!this.props.matchingItemFromDB.shippingMessage) {
      return (
        <div className="flex-container-left flex-container-vertical-center">
          <IconContext.Provider 
            value={{ size: "1.5em"}}>
            <IoIosInformationCircleOutline/>
          </IconContext.Provider>
          <p className="cart-item-shipping-message">{this.props.matchingItemFromDB.shippingMessage}</p>
        </div>
      )
    }
    
  }

}

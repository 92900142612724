import React, { Component } from 'react';
import _ from 'lodash';
import { LineChart, Scatter, CartesianGrid,
	XAxis, YAxis, Line, Tooltip } from 'recharts';


import Image from '../../../Functional/Image';
import MotorInfoTabToolTip from './Tooltip';
import NominalSpecs from '../NominalSpecs';

import MotorHelper from 'MotorHelper';

import './index.css';


let motorHelper = new MotorHelper();

export default class MotorInfoTab extends Component {
  render () {
  	let motorInfo = Object.assign({}, this.props.motorInfo);

  	if (!!motorInfo && !_.isEqual({}, motorInfo)) {			
			//new lines in description will reflet on site
  		let newLineTextDescription = motorInfo.description.split('\n').map((item, i) => {
		    return <p key={i}>{item}</p>;
			});

  		return (
  			<div className="flex-container" id="MotorChartMotorInfo" >
  				<div className="motor-info-box">
  					<h2 id="motor-info-title">{motorInfo.name}</h2>
	  				<Image 
	  					className="motor-info-img" 
	  					src={motorInfo.imgSrc}
	  					alt={motorInfo.name}/>
	  				{this.renderSpecs(motorInfo)}
	  			</div>
	  			<div className="motor-info-box">
	  				<div className="motor-info-description">
	  					<h3>Description</h3>
	  					{newLineTextDescription}
	  				</div>
  					{this.handleRenderOtherShopLinks(motorInfo)}
	  			</div>
  			</div>
			)
  	} 

  	return null
  } 

  renderSpecs(motorInfo) {
		return (
			<div>
				{this.renderSpecsAtPoint(motorInfo)}
				<NominalSpecs 
					motorData={motorInfo}
					containerClassName="specs-container"
					titleClassName="motor-info-title-2"
					toRenderCostText={true}/>
				{this.renderPusherSpecs(motorInfo)}
				{this.renderOvervoltingChart(motorInfo)}
			</div>
		)
  }

  //render specs at point where user clicks
  renderSpecsAtPoint(motorInfo) {
  	let batteryText = this.renderBattery(motorInfo);
  	let overvoltedWarning = this.renderOvervoltedWarning(motorInfo);		

		return (
			<div className="specs-container">
				<h3 className="motor-info-title-2">{"Specs on " + motorInfo.battery + "S:"}</h3>
				<p>At selected data point.</p>
				{batteryText}
				<li>{motorInfo.stallCurrent + "A Stall Current"}</li>
				<li>{motorInfo.speed + " RPM No Load Speed"}</li>
				<li>{motorInfo.torque + " gf.cm Stall torque"}</li>
				{overvoltedWarning}
			</div>
		)

  }

  //render text for battery
  renderBattery(motorInfo) {
  	return <li>{motorInfo.battery + "S, " + motorHelper.getVoltageFromCells(motorInfo.battery) + "V"}</li>
  }

  renderOvervoltedWarning(motorInfo) {
  	if (motorInfo.battery > motorInfo.nominalBattery[motorInfo.nominalBattery.length - 1]) {
  		return <li>{"Overvolted to " + motorInfo.battery + "S, motor may experience damage over use for and any possible warranties will be voided."}</li>
  	}
  }

  renderPusherSpecs(motorInfo) {
  	return (
  		<div className="specs-container" id="pusher-info-container">
				<h3 className="motor-info-title-2">Pusher Specs:</h3>
				<p>At selected data point.</p>
				<li>{(motorInfo.speed/3600).toFixed(0) + " darts/second in a Rapidstrike"}</li>
				<li>{(motorInfo.speed/2880).toFixed(0) + " darts/second in plastic yellow gearboxes"}</li>
				{/*<p>{"This information applies if the " + motorInfo.name + " is used as a pusher motor in a Rapidstrike."}</p>
								<p>To find the fire rate in a Rapidstrike, take the motor speed (in RPM), divide by 60 (to convert minutes to seconds) and again divide by 60 (for the Rapidstrike's 60:1 pusher assembly gear ratio).</p>*/}
  		</div>
		)
  }

  // Render charts on side of motor info 
  renderOvervoltingChart(motorInfo) {
  	let motorDataAtDifferentVoltages = motorHelper.getMultipleMotorDataForDifferentVoltages(motorInfo, 0, 6);

  	return (
	  	<div className="specs-container" id="pusher-info-container">
				<h3 className="motor-info-title-2">Specs vs Voltage:</h3>
				<p>Speed vs Voltage</p>
				{this.renderSpecsVsVoltageChart(motorDataAtDifferentVoltages, "speed", "RPM")}
				<p>Torque vs Voltage</p>
				{this.renderSpecsVsVoltageChart(motorDataAtDifferentVoltages, "torque", "gf.cm")}
				<p>Stall Current vs Voltage</p>
				{this.renderSpecsVsVoltageChart(motorDataAtDifferentVoltages, "stallCurrent", "A")}
			</div>
		)
  }

  renderSpecsVsVoltageChart(data, dataKey, units) {
  	return (
	  	<LineChart width={280} height={280} 
	  		data={data} 
	  		margin={{top: 15, left: 0, right: 15}}>
	  		<Tooltip 
	  			wrapperStyle={{
			  		backgroundColor: "white",
			  		paddingLeft: 10,
			  		paddingRight: 10,
			  		paddingBottom: 10,
			  		border: "1px solid #939393"
			  	}}
	  			content={
	  				<MotorInfoTabToolTip/>
	  			}/>
				<CartesianGrid strokeDasharray="3 3" />
			  <XAxis
			  	dataKey="battery"
			  	unit="S" />
		  	<YAxis 
			  	dataKey={dataKey}
			  	unit={" " + units}/>
			  <Line 
			  	dataKey={dataKey}/>
			</LineChart>
		)
  }



  handleRenderOtherShopLinks(motorInfo) {
  	if (motorInfo.availableHere && motorInfo.availableHere.length > 0) {
	  	let shops = this.aphabetizeShopLocations(this.findShops(motorInfo.availableHere))
	  		.map(shop => {
	  			return this.renderOtherShopLinks(shop);
	  		});

	  	return (
	  		<div className="shop-links-container">
					<h3>{motorInfo.name + " motors available here:"}</h3>
					<p>Shops listed in alphabetical order by location.</p>
					{shops}
				</div>
			)
		}

  	return null;
  	
  }

  //find and return shops corresponding to names from motor data
  findShops(availableHere) {
  	let foundShops = [];

  	//loop through arr of all shops, then find if any shops are in availableHere
  	for (var i = 0; i < this.props.otherShopData.length; i++) {
			for (var n = 0; n < availableHere.length; n++) {
  			if (this.props.otherShopData[i].name === availableHere[n].name) {
  				//availabeHere will be member obj of updateShopData that matches the shop data
  				let updatedShopData = Object.assign(this.props.otherShopData[i]);
  				updatedShopData.availableHere = Object.assign(availableHere[n]);

  				foundShops.push(updatedShopData);
  			}
  		}
  	}

  	return foundShops;
  }

  //sort shops abc order by state
  aphabetizeShopLocations(shops) {
		shops.sort(function(a, b) {
	    var textA = a.location.toUpperCase();
	    var textB = b.location.toUpperCase();
	    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
		});

		return shops;
  }

  renderOtherShopLinks(shopInfo) {
  	return (
  		<a className="no-underline-link black-text" href={shopInfo.availableHere.link}>
	  		<div className="shop-info flex-container">
		  		<div className="shop-info-flex-item">
				    <img className="shop-info-logo" src={shopInfo.logo}/>
				    <a className="no-underline-link black-text underline-on-hover" href={shopInfo.link}>
				    	<h4>{shopInfo.name}</h4>
				    </a>
					</div>
					<div className="shop-info-flex-item">
						<p>{"Located in " + shopInfo.location}</p>
						<p>{shopInfo.description}</p>
					</div>
	  		</div>
	  	</a>
		)
  }

}
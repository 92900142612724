import React from 'react';
import Creatable from 'react-select/creatable';

const PusherMechanismEntry = props => {
  const { pusherMechanism,
    onChange,
    PUSHER_MECHANISMS,
    generateOptionForReactSelect,
    errorText,
    styles } = props;

  return (
    <div>
      <Creatable 
        styles={styles}

        placeholder={"Pusher Mechanism *"}
        value={ 
          !!pusherMechanism.value && generateOptionForReactSelect(pusherMechanism.value)
        }
        options={
          PUSHER_MECHANISMS.map(pusherMechanism => {
            return generateOptionForReactSelect(pusherMechanism) 
          })
        }
        onChange={selectedPusherMechanism => {
          onChange({
            pusher: { 
              pusherMechanism: { 
                value: {$set: selectedPusherMechanism.value},
                isNew: {$set: !!selectedPusherMechanism.__isNew__}
              }
            }
          })
        }}
      />

      <p className="setup-entry-help">
        Please select 'Stock' if the pusher mechanism came with the blaster. If you have modified the stock pusher mechanism, still select 'Stock' and indicate any modifications in the "Notes" section. If your pusher mechanism isn't here, feel free to add one to the database! 
      </p>

      <p className="center-text error-text">{errorText}</p>
    </div>
  )
}

export default PusherMechanismEntry;
import React from 'react';
import DropdownFilter from './DropdownFilter';

import Helper from 'Helper';


const helper = new Helper();


const DartTypeFilter = props => {
  const { DART_TYPES, filter, onFilterChange } = props;
  
  return (
    <DropdownFilter
      title={"Dart Types"}
      placeholder={"Dart Types"}
      filter={filter}

      SELECTIONS={DART_TYPES.map(dartType => {
        return helper.generateOptionForReactSelect(dartType) 
      })}

      onChange={selectedOptions => onFilterChange("dartType", selectedOptions)}
    />

  )
}

export default DartTypeFilter;
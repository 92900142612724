// Available constants:
// 	- stripe
// 	- data server API url
// 	- API Links

export default class CONSTANTS {
	constructor (props) {
		
	}

	getStripeConsts () {
		return {
			PAYMENT_SERVER_URL: process.env.NODE_ENV === 'production'
		  ? 'https://hidden-forest-71418.herokuapp.com/api'
		  : 'http://localhost:3001/',
	  	STRIPE_PUBLISHABLE: process.env.NODE_ENV === 'production'
			  ? 'pk_live_MY_3OG3MUxponwOFfk2XuwUl8nA'
			  : 'pk_test_3OG3MUxponwOFfk2XuwUl8nA'

		}

	}

	//return data server url depending if production or dev
	getDataServerURL() {
		if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {	//dev
			return "http://localhost:3001/api"
		} else {	//production
			return "https://hidden-forest-71418.herokuapp.com/api"
		}
		
	}

	// Returns api link based off of some key
	// This is useful if I need an API link that's different than apiLinkKey
	getAPILink(apiLinkKey) {


		return apiLinkKey
	}

	// Returns url (either suild.com or localhost)
	getURL() {
		if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {	//dev
			return "http://localhost:3000"
		} else {	//production
			return "https://suild.com"
		}
	}

}

import React from 'react';
import Creatable from 'react-select/creatable';

const DartGuideEntry = props => {
  const { dartGuideInForm,    // Object from SetupStage, raw form values for only dartGuide 
    errorText,
    updateFlywheelSetupForm,
    getUpdatedSetup,
    styles,

    DART_GUIDES,
    generateOptionForReactSelect } = props;

  

  return (
    <div>
      {/* Dart Guide entry */}
      <Creatable
        styles={styles}
        placeholder={"Dart Guide"}
        isClearable={true}

        value={ 
          !!dartGuideInForm.value && 
          generateOptionForReactSelect(dartGuideInForm.value)
        }

        options={
          DART_GUIDES.map(dartGuide => {
            return generateOptionForReactSelect(dartGuide) 
          })
        }

        onChange={selectedDartGuide => {
          // If user clears dart guide, then selectedDartGuide will be null.
          // Need to account for that, if selectedDartGuide is null, then basically
          // clear dartGuide.value and .isNew
          let newValue = !!selectedDartGuide ? selectedDartGuide.value : "";
          let newIsNew = !!selectedDartGuide ? !!selectedDartGuide.__isNew__ : false;

          let updatedSetup = getUpdatedSetup(
            { dartGuide: { 
              value: { $set: newValue },
              isNew: { $set: newIsNew } } 
            } 
          );

          updateFlywheelSetupForm(updatedSetup);
        }}
      />

      <p className="setup-entry-help">
        Enter the full name of your dart guide and specify company. If your dart guide is custom, please specify that and follow the existing format! If your dart guide aren't here, feel free to type in a new one to add it to the database! If you're not using a dart guide, do not select one.
      </p>
      
      <p className="center-text error-text">{errorText}</p>
    </div>
  )

}

export default DartGuideEntry;
import React from 'react';
import { Button } from '@material-ui/core';

// Custom components 
import Loading from '../../../../Loading';

import BasicInfoEntry from './FormSections/BasicInfoEntry';
import PerformanceEntry from './FormSections/PerformanceEntry';
import SetupEntry from './FormSections/SetupEntry';

import PriceEntry from './FormSections/PriceEntry';
import SetupNotesEntry from './FormSections/SetupNotesEntry';

import AllErrorText from './AllErrorText';


// Custom hooks
import useDataFromBackend from '../../../../../Hooks/useDataFromBackend';
import useFormHelper from '../../../../../Hooks/useFormHelper';


// External utilities/helpers
import update from 'immutability-helper';


// Custom utilites/helpers
import { validateOnChange, validateOnSubmit } from './validateForm';

import DEFAULT_ENTRY_FORM from './formTemplates/defaultEntryForm'; 
import SetupStage from './formTemplates/SetupStage';
import MuzzleVelocity from './formTemplates/MuzzleVelocity';


import './index.scss';


// Styles for react-select components when there's an error. Make inside text 
// and border red 
const errorStyles = {
  control: (provided, state) => ({
    ...provided, 
    borderColor: "red !important"
  }),
  placeholder: (provided) => ({
    ...provided, 
    color: "red"
  }),
  singleValue: (provided) => ({
    ...provided, 
    color: "red"
  }) 
}



const EntryForm = props => {
  const { isSetupEntryExpanded, motorHelper, onSubmit, setFlywheelSetupPickerMetadata, children } = props;

  const [ FLYWHEEL_SETUP_PICKER_METADATA, isFlywheelSetupPickerMetaDataLoading ] = useDataFromBackend("flywheel-setup-picker");

  const [ formValues, 
    updateForm, 
    errors, 
    isFormValid,
    handleSubmit,
    isSubmitting,
    hasAttemptedSubmit
  ] = useFormHelper(
      DEFAULT_ENTRY_FORM, 
      onFormUpdate, 
      {
        validateOnChange: validateOnChange,
        validateOnSubmit: validateOnSubmit
      },
      () => {},
      onSuccessfulFormSubmit
    );

  // console.log("formValues: ", formValues)
  // console.log("errors: ", errors)

  // Set FLYWHEEL_SETUP_PICKER_METADATA in parent after it's retrieved
  if (!isFlywheelSetupPickerMetaDataLoading) {
    setFlywheelSetupPickerMetadata(FLYWHEEL_SETUP_PICKER_METADATA)
  }


  // If setup entry is expanded
  if (isSetupEntryExpanded) {
    // Data has finished loading
    if (!isFlywheelSetupPickerMetaDataLoading) {
      return (
        <div className="expanded-setup-entry-form">
          <p>Some notes when entering your setup:</p>
          <li>
            Please follow the proper format when adding new items to the database. If your newly added item is in the wrong format, it may not get indexed properly which can mess up filtering and sorting. See similar items for the proper format. For example, when adding Worker Black High Crush flywheels, you should enter "Worker Black High Crush".
          </li>
          <li>
            When measuring rate of fire or muzzle velocity, please use fresh darts and make sure battery you're using is at nominal charge and can supply sufficient amperage to your setup.
          </li>
          <li>
            If you've made a mistake or want to make modifications to your setup, you'll need to contact me directly to make changes. You will need to confirm that the setup is your's by providing a matching email. Be sure to double check your setup in the preview before submitting! 
          </li>

          <BasicInfoEntry
            // Static Values
            FLYWHEEL_SETUP_PICKER_METADATA={FLYWHEEL_SETUP_PICKER_METADATA}
            errorStyles={errorStyles}

            // Dynamic values
            formValues={formValues}
            errors={errors}
            hasAttemptedSubmit={hasAttemptedSubmit}
            
            // Callbacks or functions
            updateFormWrapper={updateForm}
            generateErrorText={_generateErrorText}
            generateOptionForReactSelect={generateOptionForReactSelect}
          />

          <PerformanceEntry
            // Static Values
            FLYWHEEL_SETUP_PICKER_METADATA={FLYWHEEL_SETUP_PICKER_METADATA}
            MuzzleVelocity={MuzzleVelocity}
            errorStyles={errorStyles}

            // Dynamic values
            formValues={formValues}
            errors={errors}
            hasAttemptedSubmit={hasAttemptedSubmit}
            
            // Callbacks or functions
            updateFormWrapper={updateForm}
            generateErrorText={_generateErrorText}
            generateOptionForReactSelect={generateOptionForReactSelect}
          />

          <PriceEntry
            price={formValues.price}
            onChange={updateForm}
            PRICE_SELECTIONS={FLYWHEEL_SETUP_PICKER_METADATA.PRICE_SELECTIONS}
            generateOptionForReactSelect={generateOptionForReactSelect}
            errorText={_generateErrorText(errors.price)}
            styles={!!_generateErrorText(errors.price) ? errorStyles : {}}
          />

          <SetupEntry
            // Static Values
            FLYWHEEL_SETUP_PICKER_METADATA={FLYWHEEL_SETUP_PICKER_METADATA}
            errorStyles={errorStyles}

            // Dynamic values
            formValues={formValues}
            errors={errors}
            hasAttemptedSubmit={hasAttemptedSubmit}
            
            // Callbacks or functions
            updateFormWrapper={updateForm}
            generateErrorText={_generateErrorText}
            generateOptionForReactSelect={generateOptionForReactSelect}

            // Random stuff
            motorHelper={motorHelper}
            SetupStage={SetupStage}
          />

          <div className="setup-entry-form-section">
            <h2>Notes</h2>
            <SetupNotesEntry
              setupNotes={formValues.additionalSetupNotes}
              onChange={updateForm}
              errorText={_generateErrorText(errors.additionalSetupNotes)}
              styles={!!_generateErrorText(errors.additionalSetupNotes) ? errorStyles : {}}
            />
            
          </div>

          {children}

          <AllErrorText errors={_generateErrorText(errors)}/>

          {/* Submit button */}
          <div className="flex-container-center">
            <Button id="submit-flywheel-setup-btn" 
              className={"setup-entry-form-btn " + (isSubmitting ? "": "add-btn-color")} 
              variant="contained" 
              size="large"
              disabled={isSubmitting}
              onClick={e => handleSubmit(e)}>
                Submit
            </Button>
          </div>

        </div>
      )
    // Data not finished loading, but setup entry expanded
    } else {
      return <Loading/>
    }
  // Setup entry not expanded
  } else {
    return <div></div>
  } 

  // Generates an option in proper format for React-select
	// In this form: 
	// {
	// 	label: option,
	// 	value: option
	// }
	// Value and option don't need to be the same, but this method will return an
	// object where they are the same for simplicty sake
	function generateOptionForReactSelect(option) {
		return {
			label: option,
			value: option
		}
  }

  // Generate error text (or really anything, including objects) depending on 
  // hasAttemptedSubmit. Returns errorText if hasAttemptedSubmit, empty string
  // otherwise. I decided to use a separate function because I've been swapping
  // between displaying on hasAttemptedSubmit and I don't want to change every 
  // error text every single time
  //
  // This should be called at the lowest level component. For ex, if FPSEntry
  // is rendered in PerformanceEntry, PerformanceEntry passes errors into 
  // FPSEntry, then FPSEntry uses this function to render the error text
  //
  // This function is local because it needs ref to hasAttemptedSubmit
  function _generateErrorText(errorText) {
    return (hasAttemptedSubmit ? errorText : "")
  }

  // Callback that gets executed by useFormHelper when the form is updated
  function onFormUpdate(updatedForm, errors, hasErrors) {
    props.onFormUpdate(updatedForm, !hasErrors);

  }

  // Callback on successful form submit
  function onSuccessfulFormSubmit() {
    onSubmit(FLYWHEEL_SETUP_PICKER_METADATA);

  }

}

export default EntryForm;
import React from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import SliderWithTooltipAndText from "../../../../../../../Functional/SliderWithTooltipAndText";

const PusherBatteryCellCountEntry = props => {
  const { pusherBatteryCellCount, 
    onChange, 
    errorText,
    flywheelCellCount } = props;

  const [ isChecked, setIsChecked ] = React.useState(true)

  React.useEffect(() => {
    if (isChecked) {
      onChange({
        pusher: { 
          batteryCellCount: { 
            value: {$set: isChecked ? flywheelCellCount : "0"}
          }
        }
      })
    }
  }, [isChecked, flywheelCellCount])
  
  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox 
            checked={isChecked}
            color="primary"
            onChange={event => {
              setIsChecked(event.target.checked)
            }} 
          />
        }
        classes={{
          root: "select-entry",
          label: "setup-entry-checkbox-label"
        }}
        label="Does the pusher use the same battery as the flywheels? *"
      />

      { // Display battery cell count picker if not using same battery as 
        // flywheels
        !isChecked &&
        <div>
          <SliderWithTooltipAndText
            containerClassName="flywheel-setup-picker-entry-form-cell-count-slider-container"
            title="Pusher Battery Cell Count"
            sliderPos={pusherBatteryCellCount.value}
            sliderValSuffix="S"
            min={0} max={5} step={1}
            marks={function () {
              let marks = {}
              for (var i = 0; i <= 5; i++) {
                marks[i] = i + "S";
              }

              return marks;
              }()
            }
            value={pusherBatteryCellCount.value}
            onChange={(selectedCellCount) => {
              onChange({
                pusher: { 
                  batteryCellCount: { 
                    value: {$set: selectedCellCount}
                  }
                }
              })
            }}
          />

          <p className="setup-entry-help">
            LiPo pack cell count in series: '2S' or '3S'
          </p>
        </div>
      }

      <p className="center-text error-text">{errorText}</p>
    </div>
  )
}

export default PusherBatteryCellCountEntry;
import React, { Component } from 'react';

import Helper from 'Helper'

import './index.css';


export default class CartCosts extends Component {
	render() {
		let helper = new Helper();

		return (
			<div id="CartCosts">
				<div className="horiz-fb right-fb">
            <p className="cart-cost-price-description">
              {"Subtotal (" + this.props.numOfItems + " " + helper.pluralizer("item", this.props.numOfItems) + "): "}
            </p>
            <p className="cart-cost-price-val" >{" $" + this.props.subtotal}</p>
          </div>
          <div className="horiz-fb right-fb">
            <p className="cart-cost-price-description">
              {`Shipping (${this.props.isInternationalOrder ? "International" : "Domestic"}):`}
            </p>
            <p className="cart-cost-price-val" >{" $" + this.props.shippingCost}</p>
          </div>
          <div className="horiz-fb right-fb">
            <p className="cart-cost-price-description">Total:</p>
            <p className="cart-cost-price-val" >{" $" + this.props.totalCost}</p>
          </div>
			</div>
		)
	}

}
import React from 'react';

import DropdownLoadSelector from '../../../Functional/DropdownLoadSelector';




const MotorFilter = props => {
  const { filter, onFilterChange, ALL_MOTOR_DATA } = props;

  return (
    <div className="FilterDropdown">
      <h3 className="center-text filter-dropdown-title">Flywheel Motors</h3>

      <DropdownLoadSelector
        className="select-entry small-select-dropdown"
        ALL_MOTOR_DATA={ALL_MOTOR_DATA}
        placeholder={"Flywheel Motors"}
        isMulti={true}

        additionalLoads={[ 
          {
            name: "Stock Motors",
            nominalBattery: [2]
          }
        ]}
        
        values={filter}

        onChange={selectedOptions => onFilterChange("motors", selectedOptions)}

      />
    </div>
  )
}

export default MotorFilter;
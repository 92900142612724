//methods to help with LiPo calculations and management

export default class BatteryHelper {
	maxBatteryCurrent(dischargeRating, capacity) {
		return dischargeRating*(capacity/1000);
	}

	maxVoltage(cellCount) {
		return cellCount*4.2;
	}

	nominalVoltage(cellCount) {
		return cellCount*3.7;
	}

	minVoltage(cellCount) {
		return cellCount*3.2;
	}
}
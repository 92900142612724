import React from 'react';
import Creatable from 'react-select/creatable';

const FlywheelCageEntry = props => {
  const { flywheelCageInForm, 
    errorText,
    updateFlywheelSetupForm,
    getUpdatedSetup,
    styles,

    FLYWHEEL_CAGES,
    generateOptionForReactSelect } = props;
  
  return (
    <div>
      <Creatable
        styles={styles}

        placeholder={"Flywheel Cage *"}
        value={ 
          !!flywheelCageInForm.value && 
          generateOptionForReactSelect(flywheelCageInForm.value)}
        options={
          FLYWHEEL_CAGES.map(flywheelCage => {
            return generateOptionForReactSelect(flywheelCage) 
          })
        }
        onChange={selectedCage => {
          let updatedSetup = getUpdatedSetup(
            { flywheelCage: { 
              value: { $set: selectedCage.value },
              isNew: { $set: !!selectedCage.__isNew__ } } 
            }
          );

          updateFlywheelSetupForm(updatedSetup);
        }}
      />

      <p className="setup-entry-help">
        Enter the full name of your flywheel cage and specify company. If your cage and/or crush isn't here, feel free to type in a new one to add it to the database! You don't need to specify the blaster of the cage because you've alredy specified blaster above. If your cage has options for crush, please specify the crush. 
      </p>
      
      <p className="center-text error-text">{errorText}</p>
    </div>
  )

}

export default FlywheelCageEntry;
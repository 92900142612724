import React from 'react';
import DropdownFilter from './DropdownFilter';

import Helper from 'Helper';


const helper = new Helper();


const FlywheelMotorNominalCellCountFilter = props => {
  const { filter, onFilterChange } = props;

  return (
    <DropdownFilter
      title={"Flywheel Motor Nominal Cell Count"}
      placeholder={"Flywheel Motor Nominal Cell Count"}
      filter={filter}

      SELECTIONS={["1S", "2S", "3S", "4S", "5S"].map(blaster => {
          return helper.generateOptionForReactSelect(blaster) 
        })
      }

      onChange={selectedOptions => onFilterChange("flywheelMotorNominalCellCount", selectedOptions)}

    />

  )
}

export default FlywheelMotorNominalCellCountFilter;
import React, { Component } from 'react';
import _ from 'lodash';

import Checkboxes from '../../../Functional/Checkboxes';
import RadioBtns from '../../../Functional/RadioBtns';

import MoreOptionsBtn from './MoreOptionsBtn';
import MOSFETBoard from './InteractiveOptions/MOSFETBoard';
import MOSFETBoardPlus from './InteractiveOptions/MOSFETBoard+';
import MOSFETBoardPlusMoreOptions from './InteractiveOptions/MOSFETBoard+/MoreOptions';

import Helper from 'Helper';

import './index.scss';


let helper = new Helper();

export default class Options extends Component  {
	constructor(props) {
		super(props);

		this.state = {
			// hasMoreOptions: this.checkForMoreOptions(this.props.itemOptionsFromDB),
			toShowMoreOptions: false,
			selectedInteractiveOption: ""
		}
	}

	render() {
		return (
			<div id="item-options-container">
	      <h3 className="shop-item-info-heading">Customize:</h3>
	      <p style={{fontSize: ".8em"}}>Find out more about the options for this item in the option description below</p>
	      <div id="item-options">
	      	{this.renderOptionsFromDB(0)}
	      	{this.renderInteractiveOptions(false)}
	      </div>

	      {
	      	// Don't render More Options btn and More options stuff if there 
	      	// aren't any More Options options
      		this.checkForMoreOptions(this.props.itemOptionsFromDB) && 
    				<div>
	      			<MoreOptionsBtn onClick={() => this.setState(
				     			{toShowMoreOptions: !this.state.toShowMoreOptions})}/>
			     		<div id={"more-options-container-" + (this.state.toShowMoreOptions ? "expanded" : "collapsed")}>
				     		{this.renderOptionsFromDB(1)}
		     				{this.renderMoreOptionsInteractiveOptions()}
			     		</div>
	      		</div>
	      }

	     </div>
		)

	}

	// Check to see if there are any "More Options" that should be rendered
	// If there are options that are under "More Options", return true
	// otherwise, return false
	// Checks for options that are under "More Options" by looking at the 
	// optionRenderType prop that every option has
	// If optionRenderType === 1, then that options is under "More Options"
	checkForMoreOptions(options) {
		for (let i = 0; i < options.length; i++) {
			if (options[i].optionRenderType === 2) {
				return true;
			}
		}

		return false;
	}

	// Only renders options that are specified in db
	// Iterates through all the options in the db and redners each of them
	// optionRenderType argument specifies what sort of options to render:
	// 0 - regular, shown by defauly
	// 1 - more options
	// 2 - custom
	renderOptionsFromDB(optionRenderType, containerClassName) {
		return this.props.itemOptionsFromDB.map(option => {
			if (option.optionRenderType === optionRenderType) {
				return this.renderOptionFromDB(option, containerClassName)
			}	
		})
	}

	// Renders only 1 option set
	// option argument is entire option data, including option set
	renderOptionFromDB(option, containerClassName) {
		// Format options to be sent to Checkbox, Radiobtn etc properly
		// Format option items to be like this:
		// {
    // 		labelClassName: "option-checkbox-label",
    //   	label: "XT60s pigtails soldered on (recommended)",
    //   	toReturn: 0
    // 	}, 
		const formattedOptionItems = this.formatOptionItems(option.items)

		if (option.optionSelectionType === "RadioBtns") {
			// Find initially selected options which should be the default options (isDefault flag set to true)
			const initiallySelectedOption = (formattedOptionItems.find(item => !!item.isDefault)).label;

			return (
				<RadioBtns 
      		initiallySelectedOption={initiallySelectedOption}
      		allowDeselection={option.allowDeselection}
      		containerClassName={containerClassName}
      		title={option.title}
      		titleClassName="option-set-title"
      		handleChange={(e, selectedOption, optionLabel, dPrice) => {
      				//callback when options change. Sends changed options to this.onOptionsChange
							this.onOptionsChange(option.title, optionLabel, dPrice)
      			}
    			}
      		radioBtnsData={formattedOptionItems}/>

			)
		}
	} 

	// Renders interactive options that are always shown
	// Each product should have its own interactive options
	renderInteractiveOptions() {
		// Render inteactive options for MOSFET board +
		if (this.props.itemKey === 1) {
			return (
				<div id="mosfet-board-interactive-options">
					<MOSFETBoardPlus
						toShowMoreOptions={this.state.toShowMoreOptions}
						setInteractiveOptionSelection={this.setInteractiveOptionSelection.bind(this)}/>
	      	{this.renderInteractiveOptionSelection(false)}
	      </div>
      )
		}

		return null
	}

	// Only renders interactive options that should be shown when more options is visible
	renderMoreOptionsInteractiveOptions() {


		return null

	}

	// sets state.selectedInteractiveOption which determines which interactive option selection should be displayed 
	setInteractiveOptionSelection(newSelectedInteractiveOption) {
		this.setState({selectedInteractiveOption: newSelectedInteractiveOption.selectedInteractiveOption})
	}

	// Format options to be sent to Checkbox, Radiobtn etc properly
	// Format option items to be like this:
	// {
  // 		labelClassName: "option-checkbox-label",
  //   	label: "XT60s pigtails soldered on (recommended)",
  //   	toReturn: 0
  // 	}, 
  // Takes in arr of option items to be formatted, returns formatted arr
	formatOptionItems(optionItems, className) {
		return optionItems.map(optionItem => {
			const priceTxt = optionItem.dPrice !== 0 ? 
				"(" + (optionItem.dPrice > 0 ? "" : "-") + "$" + Math.abs(optionItem.dPrice).toFixed(2) + ")"
			 : ""

			const recommendedTxt = optionItem.isRecommended ? ("(recommended)") : ""

			return (
				{
					labelClassName: !className ? "option-checkbox-label" : className,
					isDefault: optionItem.isDefault,
					isRecommended: optionItem.isRecommended,
					label: optionItem.label + " " + recommendedTxt + " " + priceTxt, 
					toReturn: optionItem.dPrice
				}
			)
		})
	}

	// Renders selection for interactive options (radio btns, dropdown, checkbox, etc)
	// If the toRenderMoreOptions flag is true, render the options that should be showed when more options is clicked
	renderInteractiveOptionSelection(toRenderMoreOptions) {
		if (this.props.itemKey == 0) {
			let pinHeaderContainerClassName = this.state.selectedInteractiveOption === "expandable-header" ? "exists" : "gone"
			let leftJSTContainerClassName =  this.state.selectedInteractiveOption === "jst-left" ? "exists" : "gone"
			let rightJSTContainerClassName =  this.state.selectedInteractiveOption === "jst-right" ? "exists" : "gone"

			let pinHeaderOptionSetData = this.findOptionSetDataFromDBFromOptionSetTitle("Expandable Header Style")
			let leftJSTConnecterStyleOptionSetData = this.findOptionSetDataFromDBFromOptionSetTitle("Left JST Connector Style")
			let rightJSTConnecterStyleOptionSetData = this.findOptionSetDataFromDBFromOptionSetTitle("Right JST Connector Style")

			return (
				<div>
					{this.renderOptionFromDB(pinHeaderOptionSetData, pinHeaderContainerClassName)}
					{this.renderOptionFromDB(leftJSTConnecterStyleOptionSetData, leftJSTContainerClassName)}
					{this.renderOptionFromDB(rightJSTConnecterStyleOptionSetData, rightJSTContainerClassName)}
				</div>
			)
		// MOSFET Board plus interactive option selection
		} else if (this.props.itemKey == 1) {
			// Not options under "More Optins"
			if (!toRenderMoreOptions) {
				let batteryXT60PigtailClassName = this.state.selectedInteractiveOption === "battery-xt60-pigtail" ? "exists" : "gone";
				let leftMotorXT60PigtailClassName = this.state.selectedInteractiveOption === "left-motor-xt60-pigtail" ? "exists" : "gone";
				let middleMotorXT60PigtailClassName = this.state.selectedInteractiveOption === "middle-motor-xt60-pigtail" ? "exists" : "gone";
				let rightMotorXT60PigtailClassName = this.state.selectedInteractiveOption === "right-motor-xt60-pigtail" ? "exists" : "gone";

				let batteryXT60PigtailData = this.findOptionSetDataFromDBFromOptionSetTitle("Battery XT60 Pigtail");
				let leftMotorXT60PigtailData = this.findOptionSetDataFromDBFromOptionSetTitle("Left Motor XT60 Pigtail");
				let middleMotorXT60PigtailData = this.findOptionSetDataFromDBFromOptionSetTitle("Middle Motor XT60 Pigtail");
				let rightMotorXT60PigtailData = this.findOptionSetDataFromDBFromOptionSetTitle("Right Motor XT60 Pigtail");

				return (
					<div>
						{this.renderOptionFromDB(batteryXT60PigtailData, batteryXT60PigtailClassName)}
						{this.renderOptionFromDB(leftMotorXT60PigtailData, leftMotorXT60PigtailClassName)}
						{this.renderOptionFromDB(middleMotorXT60PigtailData, middleMotorXT60PigtailClassName)}
						{this.renderOptionFromDB(rightMotorXT60PigtailData, rightMotorXT60PigtailClassName)}
					</div>
				)
			}
		}

		return null
	}

	// Finds option set data from db based on the option set's title
	// returns entire option set arr, returns false if nothing found
	// props.itemOptionsFromDB contains all the option sets
	findOptionSetDataFromDBFromOptionSetTitle(title) {
		let foundOptionSet = this.props.itemOptionsFromDB.find(optionSet => {
			return optionSet.title === title
		});

		// Make sure foundOptionSet is returned if something was found. Otherwise, return false
		return helper.validateItem(foundOptionSet)
	}

	// Finds option set data from db based on an option items's label and dPrice
	// returns entire option set arr, returns false if nothing found
	// props.itemOptionsFromDB contains all the option sets
	findOptionSetDataFromDBFromOptionItem(label, dPrice) {
		// Loop through itemOptionsFromDB which contains all of the option sets
		let foundOptionSet = this.props.itemOptionsFromDB.find(optionSet => {
			// Loop through option.items, arr of just option items
			// loop through optionitems/option.items (it's in an option set)
			// If label and price passed in match that of the option item, return true
			let indexOfOptionItem = optionSet.items.find(optionItem => {
				// Using label.contains(optionItem.label) because label (parameter) contains optionItem.label with other stuff concat to it
				return (label.includes(optionItem.label) && optionItem.dPrice === dPrice)
			})

			//find() can return undefined if nothing found, !! it to make it a bool
			// the function paseed into find() should only return a bool
			return !!indexOfOptionItem
		})

		// Make sure foundOptionSet is returned if something was found. Otherwise, return false
		return helper.validateItem(foundOptionSet)

	}

	// Finds option item from db based on an option's label and dPrice
	// Returns entire option item if found, false if not found
	findOptionItemFromDB(label, dPrice) {	
		// If the option set can be found, search for the option item
		// findOptionSetDataFromDBFromOptionItem returns entire option set, only want the item options of that
		let foundOptionSet = this.findOptionSetDataFromDBFromOptionItem(label, dPrice);

		if (!!foundOptionSet) {
			let foundOptionItem = foundOptionSet.items.find(optionItem => {
				return (label.includes(optionItem.label) && optionItem.dPrice === dPrice)
			})

			// if the optionItem was found, return it
			if (!!foundOptionItem) {
				return foundOptionItem
			}

		}

		// option set can't be found, so the option (based on label and dPrice) can't be found
		return false
	}

	// callback when options change
	// Handles price difference calculations and proper options add/removal
	// All parameters required:
	// 	- optionSetTitle: optionSetTitle of option set
	// 	- label: text of option
	// 	- dPrice: change in price of item when the option is selected
	onOptionsChange(optionSetTitle, label, dPrice) {
		let newOptions = _.map(this.props.selectedOptions, _.clone);

		// new option that should be added or removed
		let newOption = {
			optionSetTitle: optionSetTitle,
			label: label,
			dPrice: dPrice
		}

		// Option set that new option belongs to
		let optionSetOfNewOption = this.findOptionSetDataFromDBFromOptionItem(label, dPrice);

		// Find new option from db
		let newOptionItemFromDB = this.findOptionItemFromDB(label, dPrice);

		// If the option set can only have 1 option, remove the old option and push the new one in
		if (optionSetOfNewOption.maxQtyAbleToSelect === 1) {
			// Find option of the same option set
			let optionsOfSameOptionSet = helper.removeUndefinedItemsFromArr(
				newOptions.map(option => {
					// If options have the same option set title, but aren't the exact same option as newOption
					if (option.optionSetTitle === newOption.optionSetTitle) {
						return option
					}
				}));

			// Remove option of the same option set
			// This removes all options of the same option set name
			newOptions = helper.removeItemFromArr(newOptions, optionsOfSameOptionSet[0]);
			
		}

		newOptions.push(newOption)

		// If selected options are the same as the default options, then it's basically like there are no options, so remove 
		// all options
		// Arr of selected options only gets populated if the option is changed. It can be added then removed. If the option 
		// is the same as default, it won't be in the arr of options
		// This means that the arr of options may have the same, but not all, options that are in the arr of default options
		// To validate that all the options in the arr of options are default, every option in the arr of options will
		// be checked against the default arr of options, and not vice versa
		// if newOptions only had 1 item that happens to be a default option and that option gets removed, the mapping will 
		// return undefined, resulting in an undefined array that breaks stuff later on. helper.removeUndefinedItemsFromArr
		// removes these undefined items so they don't break stuff later on
		newOptions = helper.removeUndefinedItemsFromArr(
			newOptions.map(option => {
				// if the new option to add is default
				if (!newOptionItemFromDB.isDefault) {
					return option
				}
			})
		);

		// calculate total dPrice
		let totaldPrice = 0;
		newOptions.map(option => totaldPrice += option.dPrice)
		
		this.props.onOptionsChanged(newOptions, totaldPrice)
	}
	
	
}

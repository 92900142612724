import React, { Component } from 'react';

import Custom from './Custom';
import Regular from './Regular';

import './index.css';

export default class CartItem extends Component {
	render() {
		if (this.props.isCustomOrder) {
			return (
				<Custom item={this.props.item}/>
			)
		} else {
			return (
				<Regular
					canChange={this.props.canChange}
					removeFromCart={this.props.removeFromCart}
          dropdownChanged={this.props.dropdownChanged}
					item={this.props.item}
          matchingItemFromDB={this.props.matchingItemFromDB}
          matchingItemFromCart={this.props.matchingItemFromCart}
          i={this.props.i}/>
			)
		}
		
	}

}

import React, { useState, useReducer, useEffect } from 'react';

import Loading from '../../Loading';

import Instructions from './Instructions';
import Disclaimer from './Disclaimer';
import SetupEntry from './SetupEntry';
import Filter from './Filter';
import Sorting from './Sorting';
import Setups from './Setups';


// Custom hooks/utilities
import useDataFromBackend from '../../../Hooks/useDataFromBackend';
import useSetups from './useSetups';
import useFilters from './useFilters';
import useSort from './useSort';

import MotorHelper from "MotorHelper";

import './index.scss';



const FlywheelSetupPicker = props => {
  const { id } = props; // search id

  // FSP Metadata, not setup data
  const [ FLYWHEEL_SETUP_PICKER_DATA, isFlywheelSetupPickerDataLoading ] = useDataFromBackend("flywheel-setup-picker");

  // Motor data
  const [ MOTOR_DATA_RAW, isMotorDataLoading ] = useDataFromBackend("charts");
  const MOTOR_DATA = MOTOR_DATA_RAW.data;
  let motorHelper = new MotorHelper();
  motorHelper.setData(MOTOR_DATA);

  // Hook to manage retrieving setups 
  const [ setups, 
    searchID,
    
    // Functions
    getMoreSetups, 
    onNewSetupSubmit,
    onFilterApply,
    onSortApply,
    finishScrollToSetup,
    
    // Flags
    isSetupsDataLoading, 
    hasMoreSetups ] = useSetups(id);


  const [ filter, onFilterChange, clearFilters, onFilterApplyBtnPress ] = useFilters(onFilterApply);

  const [ sort, setSort ] = useSort(onSortApply);
  

  
  // console.log(hasMoreSetups)
  // console.log(setups)
  // console.log(isSetupsDataLoading)


  // Render stuff only if all data has been retrieved
  if (!isFlywheelSetupPickerDataLoading && !isMotorDataLoading) {
    return (
      <div id="FlywheelSetupPicker">
        <h1>Flywheel Setup Picker</h1>
        <p>
          This web application was made to find your ideal flywheel setup. Users can enter their own flywheel setups and you can search for the best one to suite your blaster needs!
        </p>
        <p>
          See a bug, have some feedback, or want to request a cool feature? Contact me! 
        </p>

        <Disclaimer/>
        <SetupEntry
          motorHelper={motorHelper}
          FLYWHEEL_SETUP_PICKER_METADATA={FLYWHEEL_SETUP_PICKER_DATA}

          addSetup={(newSetup) => {onNewSetupSubmit(newSetup)}}
        />
        <Filter
          FLYWHEEL_SETUP_PICKER_DATA={FLYWHEEL_SETUP_PICKER_DATA}
          ALL_MOTOR_DATA={MOTOR_DATA}

          filter={filter} 
          onFilterChange={onFilterChange}
          clearFilters={clearFilters}
          onApplyBtnPress={onFilterApplyBtnPress}
        />
        <Sorting
          sort={sort}
          setSort={setSort}
        />
        <Setups
          isLoading={isSetupsDataLoading}

          searchID={searchID}
          motorHelper={motorHelper}
          setups={setups}

          getMoreSetups={getMoreSetups}
          hasMoreSetups={hasMoreSetups}

          finishScrollToSetup={finishScrollToSetup}
        />
  
      </div>
    )
  }

  // If data hasn't been retrieved yet, show loading screen
  return (
    <Loading/>
  )


}




export default FlywheelSetupPicker;
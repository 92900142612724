import React from 'react';
import { useState } from 'react';
import { Collapse, Button } from '@material-ui/core';


import CollapsibleTextTrigger from '../../../Functional/CollapsibleTextTrigger';

import BlasterFilter from './Filters/BlasterFilter';
import MuzzleVelocityFilter from './Filters/MuzzleVelocityFilter';
import DartTypeFilter from './Filters/DartTypeFilter';
import RateOfFireFilter from './Filters/RateOfFireFilter';
import PriceGroupFilter from './Filters/PriceGroupFilter';
import PriceFilter from './Filters/PriceFilter';
import CellCountFilter from './Filters/CellCountFilter';

import SeeMoreOrLess from '../../../Functional/SeeMoreOrLess';

import MotorFilter from './Filters/MotorFilter';
import FlywheelsFilter from './Filters/FlywheelsFilter';
import FlywheelCageFilter from './Filters/FlywheelCageFilter';
import FlywheelCageMaterialFilter from './Filters/FlywheelCageMaterialFilter';
import DartGuideFilter from './Filters/DartGuideFilter';
import NumberOfStagesFilter from './Filters/NumberOfStagesFilter';

import FlywheelMotorFormFactorFilter from './Filters/FlywheelMotorFormFactorFilter';
import FlywheelMotorVoltingFilter from './Filters/FlywheelMotorVoltingFilter';
import FlywheelMotorNominalCellCountFilter from './Filters/FlywheelMotorNominalCellCountFilter';

import PusherMechanismFilter from './Filters/PusherMechanismFilter';
import PusherMotorFilter from './Filters/PusherMotorFilter';
import PusherSolenoidFilter from './Filters/PusherSolenoidFilter';
import PusherBatteryCellCountFilter from './Filters/PusherBatteryCellCountFilter';

import PusherMotorFormFactorFilter from './Filters/PusherMotorFormFactorFilter';
import PusherMotorVoltingFilter from './Filters/PusherMotorVoltingFilter';
import PusherMotorNominalCellCountFilter from './Filters/PusherMotorNominalCellCountFilter';

import FullAutoFilter from './Filters/FullAutoFilter';
import SemiAutoFilter from './Filters/SemiAutoFilter';
import SolenoidPusherFilter from './Filters/SolenoidPusherFilter';
import MicrowheelsFilter from './Filters/MicrowheelsFilter';
import DartGuideCheckboxFilter from './Filters/DartGuideCheckboxFilter';


import './index.scss';




// Generates array of selected items for Dropdown Filter
// Filters for an item are stored in filter.item.$in, but if item doesn't exist,
// then filter.item.$in will cause a runtime error. To avoid that, return []
// if filter.item doesn't exist
// I may need to fix this implementation in the future, so that's why it's 
// being abstracted into another function
function _generateFilter(filter) {
  return !!filter ? filter.$in : []
}



const Filter = props => {
  const { FLYWHEEL_SETUP_PICKER_DATA,
    ALL_MOTOR_DATA,

    filter,
    onFilterChange,
    clearFilters,
    onApplyBtnPress } = props;

  // State for whether or not all filters are expanded
  const [ areFiltersExpanded, setAreFiltersExpanded ] = useState(true);

  // State for more filters section is expanded
  const [ areMoreFiltersExpanded, setAreMoreFiltersExpanded ] = useState(false);


  return (
    <div id="Filter" className="super-light-grey-bg">
      <CollapsibleTextTrigger
        isExpanded={areFiltersExpanded}
        setIsExpanded={setAreFiltersExpanded}

        containerClassName="flex-container-center"
      >
        <h1 className="center-text">Filters</h1>  
      </CollapsibleTextTrigger>
      
      <Collapse in={areFiltersExpanded}>
        <div className="flex-container-center">
          <BlasterFilter
            BLASTERS={FLYWHEEL_SETUP_PICKER_DATA.blasters}
            filter={_generateFilter(filter.blaster)}
            onFilterChange={onFilterChange}
          />

          <MuzzleVelocityFilter
            FPS_SELECTIONS={FLYWHEEL_SETUP_PICKER_DATA.FPS_SELECTIONS}
            filter={_generateFilter(filter.fps)}
            onFilterChange={onFilterChange}
          />
          
          <DartTypeFilter
            DART_TYPES={FLYWHEEL_SETUP_PICKER_DATA.dartTypes}
            filter={_generateFilter(filter.dartType)}
            onFilterChange={onFilterChange}
          />

          <RateOfFireFilter
            RATE_OF_FIRE_SELECTIONS={FLYWHEEL_SETUP_PICKER_DATA.RATE_OF_FIRE_SELECTIONS}
            filter={_generateFilter(filter.rateOfFire)}
            onFilterChange={onFilterChange}
          />

          <PriceGroupFilter
            PRICES={FLYWHEEL_SETUP_PICKER_DATA.PRICE_SELECTIONS}
            filter={_generateFilter(filter.priceGroup)}
            onFilterChange={onFilterChange}
          />

          <PriceFilter
            PRICES={FLYWHEEL_SETUP_PICKER_DATA.PRICE_SELECTIONS}
            filter={_generateFilter(filter.price)}
            onFilterChange={onFilterChange}
          />

          <CellCountFilter
            filter={_generateFilter(filter.cellCount)}
            onFilterChange={onFilterChange}
          />
        </div>



        <SeeMoreOrLess id={"expand-trigger"}
          control={areMoreFiltersExpanded}
          setControl={setAreMoreFiltersExpanded}
        />

        {/* Expanded Filters */}
        <Collapse in={areMoreFiltersExpanded}>
          <h2 className="filter-section-text center-text">
            Flywheel Setup Filters
          </h2>
          <div className="flex-container-center">
            <MotorFilter 
              ALL_MOTOR_DATA={ALL_MOTOR_DATA}
              filter={_generateFilter(filter.motors)}
              onFilterChange={onFilterChange}
            />

            <FlywheelsFilter 
              FLYWHEELS={FLYWHEEL_SETUP_PICKER_DATA.flywheels}
              filter={_generateFilter(filter.flywheels)}
              onFilterChange={onFilterChange}
            />

            <FlywheelCageFilter 
              FLYWHEEL_CAGES={FLYWHEEL_SETUP_PICKER_DATA.flywheelCages}
              filter={_generateFilter(filter.flywheelCage)}
              onFilterChange={onFilterChange}
            />    

            <FlywheelCageMaterialFilter 
              FLYWHEEL_CAGE_MATERIALS={FLYWHEEL_SETUP_PICKER_DATA.flywheelCageMaterials}
              filter={_generateFilter(filter.flywheelCageMaterial)}
              onFilterChange={onFilterChange}
            />

            <DartGuideFilter 
              DART_GUIDES={FLYWHEEL_SETUP_PICKER_DATA.dartGuides}
              filter={_generateFilter(filter.dartGuide)}
              onFilterChange={onFilterChange}
            />

            <NumberOfStagesFilter 
              filter={_generateFilter(filter.numberOfStages)}
              onFilterChange={onFilterChange}
            />

          </div>

          <h2 className="filter-section-text center-text">
            Flywheel Motor Filters
          </h2>
          <div className="flex-container-center">
            <FlywheelMotorFormFactorFilter 
              filter={_generateFilter(filter.flywheelMotorFormFactor)}
              onFilterChange={onFilterChange}
            />
            
            <FlywheelMotorVoltingFilter 
              filter={_generateFilter(filter.flywheelMotorVolting)}
              onFilterChange={onFilterChange}
            />

            <FlywheelMotorNominalCellCountFilter 
              filter={_generateFilter(filter.flywheelMotorNominalCellCount)}
              onFilterChange={onFilterChange}
            />
          </div>

          <h2 className="filter-section-text center-text">
            Pusher Filters
          </h2>
          <div className="flex-container-center">
            <PusherMechanismFilter 
              PUSHER_MECHANISMS={FLYWHEEL_SETUP_PICKER_DATA.PUSHER_MECHANISMS}
              filter={_generateFilter(filter.pusherMechanism)}
              onFilterChange={onFilterChange}
            />

            <PusherMotorFilter 
              ALL_MOTOR_DATA={ALL_MOTOR_DATA}
              filter={_generateFilter(filter.pusherMotor)}
              onFilterChange={onFilterChange}
            />

            <PusherSolenoidFilter 
              SOLENOIDS={FLYWHEEL_SETUP_PICKER_DATA.SOLENOIDS}
              filter={_generateFilter(filter.pusherSolenoid)}
              onFilterChange={onFilterChange}
            />

            <PusherBatteryCellCountFilter 
              filter={_generateFilter(filter.pusherBatteryCellCount)}
              onFilterChange={onFilterChange}
            />
          </div>

          <h2 className="filter-section-text center-text">
            Pusher Motor Filters
          </h2>
          <div className="flex-container-center">
            <PusherMotorFormFactorFilter 
              filter={_generateFilter(filter.pusherMotorFormFactor)}
              onFilterChange={onFilterChange}
            />

            <PusherMotorVoltingFilter 
              filter={_generateFilter(filter.pusherMotorVolting)}
              onFilterChange={onFilterChange}
            />
            
            <PusherMotorNominalCellCountFilter 
              filter={_generateFilter(filter.pusherMotorNominalCellCount)}
              onFilterChange={onFilterChange}
            />


            
          </div>
        </Collapse> 



        <div id="checkbox-filter-container">
          <FullAutoFilter
            filter={filter}
            onFilterChange={onFilterChange}
          />

          <SemiAutoFilter
            filter={filter}
            onFilterChange={onFilterChange}
          />

          <SolenoidPusherFilter
            filter={filter}
            onFilterChange={onFilterChange}
          />
          
          <MicrowheelsFilter
            filter={filter}
            onFilterChange={onFilterChange}
          />

          <DartGuideCheckboxFilter
            filter={filter.hasDartGuide}
            onFilterChange={onFilterChange}
          />
        </div>
        

        <div style={{marginTop: "2em"}}>
          <Button className="remove-btn-color" variant="contained" color="primary"
            onClick={clearFilters}
          >
            Clear
          </Button>

          <Button className="add-btn-color" 
            variant="contained" 
            style={{marginLeft: "1em"}}
            onClick={onApplyBtnPress}
          >
            Apply
          </Button>
        </div>

        
      </Collapse>
      
      
      
    </div>
  )
}

export default Filter;
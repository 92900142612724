import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import './index.css';

export default class NotFound extends Component {
  render () {
    return (
      <div id="NotFound">
        <h1 id="not-found-title">Nothing here!</h1>
        <h3><Link to="/" className="button-style-1">Go Home</Link></h3>
        {this.redirect()}
      </div>
    )
  }

  redirect() {
    const link = this.props.match.params[0];

  	if (link === "motor-chart") {
  		return <Redirect to={'/tools/motor-chart'}/>
  	} else if (link === "lipo-checker") {
  		return <Redirect to={'/tools/lipo-checker'}/>
  	}
  }

}
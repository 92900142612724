import React from 'react';

import MotorHelper from 'MotorHelper';


import './index.scss';


let motorHelper = new MotorHelper();

const NominalSpecs = props => {
	let specRatio = props.motorData.nominalBattery[0]/props.motorData.battery;
	let nominalRPM = (props.motorData.speed * specRatio).toFixed(0);
	let nominalTorque = (props.motorData.torque * specRatio).toFixed(0);
	let nominalStallCurrent = (props.motorData.stallCurrent * specRatio).toFixed(0);

	return (
		<div className={"nominal-specs-container " + props.containerClassName}>
			<h3 className={props.titleClassName}>Nominal Specs:</h3>
			{props.toRenderCostText && <li className={props.textClassName}>{motorHelper.costToText(props.motorData)}</li>}
			<li className={props.textClassName}>{motorHelper.formFactorToText(props.motorData)}</li>
			<li className={props.textClassName}>{motorHelper.nominalBatteryToText(props.motorData)}</li>
			<li className={props.textClassName}>{nominalRPM + " RPM on " + props.motorData.nominalBattery[0] + "S"}</li>
			<li className={props.textClassName}>{nominalTorque + " gf.cm on " + props.motorData.nominalBattery[0] + "S"}</li>
			<li className={props.textClassName}>{nominalStallCurrent + "A stall current on " + props.motorData.nominalBattery[0] + "S"}</li>
		</div>
	)

}

export default NominalSpecs;
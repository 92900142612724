import React from 'react';

import { Tooltip } from '@material-ui/core';


import TimeAgo from 'javascript-time-ago';
// Load locale-specific relative date/time formatting rules.
import en from 'javascript-time-ago/locale/en';


// Add locale-specific relative date/time formatting rules.
TimeAgo.addLocale(en);

// Create relative date/time formatter.
const timeAgo = new TimeAgo('en-US')


const TimeAgoText = props => {
  const { time, formattedTime, textClassName } = props;

  return (
    <Tooltip title={formattedTime} placement="top">
      <p className={textClassName}>
        { timeAgo.format(time) }
      </p>
    </Tooltip>
  )
}

export default TimeAgoText;
import React from 'react';
import { TextareaAutosize } from '@material-ui/core';

const SetupNotesEntry = props => {
  const { setupNotes, onChange, errorText } = props;

  return (
    <div>
      <TextareaAutosize 
        className="outlined-basic" 
        label="Additional Setup Notes" 
        variant="outlined"
        required={false}
        label="Additional Setup Notes"
        multiline
        rowsMin={10}
        style={{ width: "100%" }}
        value={setupNotes.value}
        onChange={(e) => {
          onChange({
            additionalSetupNotes: { 
              value: {
                $set: e.target.value
              }
            }
          })
        }}
      />
      <p className="setup-entry-help">
        What were the conditions of your measurements? Do you have any raw data? What else did you add to your setup? Maybe you did an awesome integration? Or select-fire and PWM control?
      </p>

      <p className="center-text error-text">{errorText}</p>

    </div>
  )
}

export default SetupNotesEntry;
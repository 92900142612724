import _ from 'lodash';

import BackendDataSender from 'BackendDataSender';


// Validades all formValues in form, returns object of errors that matches
// structure of formValues
// 
// Passed into useFormHelper hook and gets executed when formItems change
export const validateOnChange = (formValues) => {
  let errors = {};

  if (!formValues.setupName.value) {
    errors.setupName = "Required Setup Name";
  }

  if (!formValues.username.value) {
    errors.username = "Required Username";
  }

  if (!formValues.blaster.value) {
    errors.blaster = "Required Blaster";
  }

  // if (!formValues.fps.value) {
  //   errors.fps = "Required Muzzle Velocity";
  // }

  errors.muzzleVelocities = validateMuzzleVelocities(formValues);


  if (!formValues.rateOfFire.value) {
    errors.rateOfFire = "Required Rate of Fire";
  }

  if (!formValues.price.value) {
    errors.price = "Required Price";
  }

  if (formValues.cellCount.value == 0) {
    errors.cellCount = "Required Battery Cell Count";
  }

  errors.setup = validateSetup(formValues);

  errors.pusher = validatePusher(formValues);


  // Only returns truthy values
  return _.pickBy(errors, _.identity);;
}


// Validates setup. Returns arr of error if there are errors, returns null if 
// no errors. 
//
// Returns arr of objects, each object represents a single muzzle velocity  
// entry. The object can have props like fps or darts that correspond to  
// fps/darts. If the prop exists, then there's an error in that corresponding 
// part. If the prop doesn't exist, then there's no error in that corresponding 
// part.
// Ex: 
//  return [{ fps: "abc" }, {}, { dartType: "abc" }]
//    errors in muzzleVelocityEntry1.fps, muzzleVelocityEntry1.dartType,
//    muzzleVelocityEntry2 has no errors
//
// Returns null if no errors in the setup. This is because need to let the calling
// function know that there are no errors so it knows not to add 
// cerrors.muzzleVelocities
function validateMuzzleVelocities(formValues) {
  // Flag if there are any errors. Raised to true if there's an error in any 
  // muzzle velocity entry
  // If this value is false, then there are no muzzle velocity entry errors, so 
  // return null 
  let hasErrors = false;

  const muzzleVelocityErrors = formValues.muzzleVelocities.map(
    (muzzleVelocityEntry, index) => {

      let errorsForThisMuzzleVelocityEntry = {}

      // Has errors if doesn't exist
      if (!muzzleVelocityEntry.value.fps.value) {
        hasErrors = true;
        errorsForThisMuzzleVelocityEntry.fps = `Required Muzzle Velocity Entry #${index + 1} FPS`;
      }

      // Has errors if doesn't exist
      if (!muzzleVelocityEntry.value.dartType.value) {
        hasErrors = true;
        errorsForThisMuzzleVelocityEntry.dartType = `Required Muzzle Velocity Entry #${index + 1} Dart Type`;
      }

      return errorsForThisMuzzleVelocityEntry;
    }
  );


  // If there are errors, return muzzleVelocityErrors, otherwise return null 
  // because no errors
  if (hasErrors) {
    return muzzleVelocityErrors;
  } else {
    return null
  }

}


// Validates setup. Returns arr of error if there are errors, returns null if 
// no errors. 
//
// Returns arr of objects, each object represents a stage. The object can have 
// props like motors or flywheels that correspond to their respective parts. If 
// the prop exists, then there's an error in that corresponding part. If the prop
// doesn't exist, then there's no error in that corresponding part.
// Ex: 
//  return [{ motors: "abc" }, {}, { flywheels: "abc" }]
//    errors in stage1.motors, stage3.flywheels, stage2 has no errors
//
// Returns null if no errors in the setup. This is because need to let the calling
// function know that there are no errors so it knows not to add errors.setup
function validateSetup(formValues) {
  // Validation cases for setup which is harder to validate because it's deeper
  // and error text should indicate which stage the error is in 
  let setupErrors = formValues.setup.map((stage, index) => {
    let errorsForThisStage = {};
    const stageIndex = index + 1;

    if (!stage.value.motors.value) {
      errorsForThisStage.motors = `Required Stage ${stageIndex} Motors`;
    }

    if (!stage.value.flywheels.value) {
      errorsForThisStage.flywheels = `Required Stage ${stageIndex} Flywheels`;
    }

    if (!stage.value.flywheelCage.value) {
      errorsForThisStage.flywheelCage = `Required Stage ${stageIndex} Flywheel Cage`;
    }

    if (!stage.value.flywheelCageMaterial.value) {
      errorsForThisStage.flywheelCageMaterial = `Required Stage ${stageIndex} Flywheel Cage Material`;
    }

    if (!stage.value.dartGuide.value) {
      // errorsForThisStage.dartGuide = `Required Stage ${stageIndex} Dart Guide`;
    }

    if (!stage.value.isMicroFlywheelSetup.value) {
      // errorsForThisStage.isMicroFlywheelSetup = `Required Stage ${stageIndex} Microflywheel Indicator`;
    }

    return errorsForThisStage;
  });

  // If there are no errors in any of the stages, setupErrors will be an array 
  // of empty objects. If this is the case, then we need to indicate that there
  // are no errors by returning null
  let isSetupErrorsEmpty = true;
  setupErrors.forEach(stage => {
    // Stage isn't empty, so there are errors in the stage
    if (!_.isEmpty(stage)) {
      isSetupErrorsEmpty = false;
    }
  })

  // There are no errors, isSetupErrorsEmpty === true
  if (isSetupErrorsEmpty) {
    return null;
  // There are errros
  } else {
    return setupErrors;
  }
}

// Validates pusher. Returns obj of errors if there are errors, returns null if 
// no errors
function validatePusher(formValues) {
  // Pusher valiation only if setup is full auto
  if (formValues.isFullAuto.value) {
    let pusherErrors = {};

    if (!formValues.pusher.pusherMechanism.value) {
      pusherErrors.pusherMechanism = "Required Pusher Mechanism";
    }

    if (formValues.pusher.pusherMechanism.value.length > 1 &&
      formValues.pusher.pusherMechanism.value !== "Solenoid" && 
      !formValues.pusher.pusherMotor.value) {

      pusherErrors.pusherMotor = "Required Pusher Motor";
    }

    if (formValues.pusher.pusherMechanism.value === "Solenoid" && 
      !formValues.pusher.solenoid.value) {

      pusherErrors.solenoid = "Required Solenoid";
    }

    if (formValues.pusher.batteryCellCount.value == 0) {
      pusherErrors.batteryCellCount = "Required Pusher Battery Cell Count. Make sure your setup's battery cell count is valid too!";
    }

    // No pusher errors
    if (_.isEmpty(pusherErrors)) {
      return null;
    }

    return pusherErrors;
  }

  return null
}


// Validation that happens onSubmit, not onChange
export const validateOnSubmit = async (formValues) => {
  let errors = {}

  // Username validation in backend
  if (!!formValues.username.value) {
    // BackendDataSender().sendData() returns data received, use await to keep 
    // code looking sync when it's actually async
    let { auth } = await new BackendDataSender().sendData('simple-auth', {
      username: formValues.username.value,
      email: formValues.email.value
    });

    // username and email combo failed auth
    if (!auth) {
      errors.username = "Invalid username and email combination"
      errors.email = "Invalid username and email combination"
    }

  }

  return errors
}
import Helper from 'Helper';

let helper = new Helper();



// Used to format entry form from raw entry form data 
// (straight from useFormHelper and the dropdowns and everything that makes the
// data ugly) to data that can easily be displayed and searched/filter/sorted
// by db
// This should be a pure function
//
// Formatting includes:
//  - Squashing something.value to something
//  - Adding helper props to make db queries easier
// 
// Params:
//  - entryFormContent (obj): content of entry form straight from 
//      useFormHelper.formValues
//  - motorHelper (obj): instance of MotorHelper, should be same one used where
//      motor data is already set
//  - FLYWHEEL_SETUP_PICKER_METADATA (obj): metadata for fsp from backend
//
// Formats setup data so it can be rendered in SetupDisplay. It
// reads the state and returns a new object with formatted setup data
//
//
// Most of this formatting is applying helper.formNormalizer and saving data
// values at the root level (not as a value prop in an object)
//
// This isn't the same data that's passed to backend because backend needs 
// isNew prop (which is nested)
function formatEntryFormContent (entryFormContent, motorHelper, FLYWHEEL_SETUP_PICKER_METADATA) {
  const currentFlywheelSetupForm = helper.formNormalizer(entryFormContent);
  let formattedFlywheelSetupForm = { ...currentFlywheelSetupForm };

  formattedFlywheelSetupForm.isProd = false;  // Flag for dev/prod, if dev/!prod, then don't show in prod  

  formattedFlywheelSetupForm.upvotes = 1;
  formattedFlywheelSetupForm.downvotes = 0;
  formattedFlywheelSetupForm.comments = [];

  formattedFlywheelSetupForm.setupName = currentFlywheelSetupForm.setupName.value.trim();
  formattedFlywheelSetupForm.username = currentFlywheelSetupForm.username.value.trim();
  formattedFlywheelSetupForm.email = currentFlywheelSetupForm.email.value.trim();
  formattedFlywheelSetupForm.blaster = currentFlywheelSetupForm.blaster.value.trim();
  formattedFlywheelSetupForm.powerLevel = currentFlywheelSetupForm.powerLevel.value.trim();
  formattedFlywheelSetupForm.rateOfFire = currentFlywheelSetupForm.rateOfFire.value.trim();
  formattedFlywheelSetupForm.isFullAuto = currentFlywheelSetupForm.isFullAuto.value;
  formattedFlywheelSetupForm.price = currentFlywheelSetupForm.price.value.trim();
  formattedFlywheelSetupForm.priceGroup = currentFlywheelSetupForm.priceGroup.value.trim();
  formattedFlywheelSetupForm.cellCount = currentFlywheelSetupForm.cellCount.value;

  formattedFlywheelSetupForm.muzzleVelocities = formatMuzzleVelocities(currentFlywheelSetupForm);
  formattedFlywheelSetupForm.setup = formatSetup(currentFlywheelSetupForm, formattedFlywheelSetupForm, motorHelper);
  formattedFlywheelSetupForm.pusher = formatPusher(currentFlywheelSetupForm, formattedFlywheelSetupForm, motorHelper);

  formattedFlywheelSetupForm.additionalSetupNotes = currentFlywheelSetupForm.additionalSetupNotes.value.trim();
  

  formattedFlywheelSetupForm.helperValues = generateHelperValues(formattedFlywheelSetupForm, FLYWHEEL_SETUP_PICKER_METADATA);

  formattedFlywheelSetupForm.newValues = getNewValues(currentFlywheelSetupForm);
  


  // console.log("Formatted", formattedFlywheelSetupForm)


  return formattedFlywheelSetupForm;
}



// Formats only the setup stuff, returns new setup
function formatSetup(currentFlywheelSetupForm, formattedFlywheelSetupForm, motorHelper) {
  return currentFlywheelSetupForm.setup.map(
    (stage, i) => {
      const motorData = motorHelper.findMotorsWithMatchingName(stage.value.motors.value)[0];

      return {
        stageIndex: (i + 1),
        motors: stage.value.motors.value.trim(),
        flywheels: stage.value.flywheels.value.trim(),
        flywheelCage: stage.value.flywheelCage.value.trim(),
        flywheelCageMaterial: stage.value.flywheelCageMaterial.value.trim(),
        dartGuide: stage.value.dartGuide.value.trim(),
        isMicroFlywheelSetup: stage.value.isMicroFlywheelSetup.value,

        // Helper values
        motorData: motorData,
        volting: motorHelper.voltingText(motorData, formattedFlywheelSetupForm.cellCount)
      }
    });
}


// Formats only the pusher stuff, returns object of formatted pusher stuff
function formatPusher(currentFlywheelSetupForm, formattedFlywheelSetupForm, motorHelper) {
  let formattedPusher = {}

  // Pusher, only handle if setup is full auto
  if (!formattedFlywheelSetupForm.isFullAuto) {
    // If not full auto, clear pusher stuff. Pusher stuff can still be set if 
    // user selected full-auto stuff, changed pusher stuff, then went back to 
    // select semi-auto
    formattedPusher = {
      pusherMechanism: "",
      pusherMotor: "",
      solenoid: "",
      batteryCellCount: "",

      // Helper values, should be blank because setup isn't full auto 
      motorData: {},
      volting: "",
      isBatterySameAsFlywheels: ""		// This can't be true/false (whhen no full auto), otherwise it might accidently pass filter
    };
  } else {
    formattedPusher = {
      pusherMechanism: currentFlywheelSetupForm.pusher.pusherMechanism.value.trim(),
      batteryCellCount: currentFlywheelSetupForm.pusher.batteryCellCount.value,

      // Helper values
      isBatterySameAsFlywheels: currentFlywheelSetupForm.pusher.batteryCellCount.value === formattedFlywheelSetupForm.cellCount
    };


    // If pusher mech is solenoid, the clear motor stuff and apply solenoid stuff
    if (formattedPusher.pusherMechanism === "Solenoid") {
      formattedPusher.solenoid = currentFlywheelSetupForm.pusher.solenoid.value.trim();

      formattedPusher.pusherMotor = "";
      formattedPusher.motorData = {};
      formattedPusher.volting = "";
      
    // If Pusher mech is motor, then clear solenoid stuff and apply pusher stuff
    } else {
      const pusherMotorData = motorHelper.findMotorsWithMatchingName(currentFlywheelSetupForm.pusher.pusherMotor.value)[0];

      formattedPusher.pusherMotor = currentFlywheelSetupForm.pusher.pusherMotor.value.trim();
      formattedPusher.motorData = pusherMotorData;
      formattedPusher.volting = motorHelper.voltingText(pusherMotorData, formattedFlywheelSetupForm.cellCount);

      formattedPusher.solenoid = "";
    }

  }

  return formattedPusher;
}

// Formats only the muzzle velocity stuff, returns object of formatted muzzle 
// velocity stuff
function formatMuzzleVelocities(currentFlywheelSetupForm) {
  return currentFlywheelSetupForm.muzzleVelocities.map(
    (singleMuzzleVelocityEntry, index) => {

      return {
        entryNumber: index + 1,
        fps: singleMuzzleVelocityEntry.value.fps.value.trim(),
        dartType: singleMuzzleVelocityEntry.value.dartType.value.trim(),
      }
    }
  );
}


// Generate helper values, returns object of helper values
function generateHelperValues(formattedFlywheelSetupForm, FLYWHEEL_SETUP_PICKER_METADATA) {
  // helper values, help when sorting/filtering setups.
  // These values would normally be computed, but because can't really compute
  // stuff in mongodb queries, I'll just save them as props here
  const { FPS_SELECTIONS, 
    RATE_OF_FIRE_SELECTIONS, 
    PRICE_SELECTIONS } = FLYWHEEL_SETUP_PICKER_METADATA;

  const {
    setup,
    upvotes,
    downvotes,
    muzzleVelocities,
    rateOfFire, 
    price
  } = formattedFlywheelSetupForm

  return {
    numOfStages: setup.length,
    popularity: upvotes - downvotes,	// Has to be updated whenever a vote is casted 
    fpsIndex: findIndexOfNestedOption(FPS_SELECTIONS, muzzleVelocities[0].fps),
    rateOfFireIndex: RATE_OF_FIRE_SELECTIONS.indexOf(rateOfFire),
    priceIndex: findIndexOfNestedOption(PRICE_SELECTIONS, price)
  };
}


// Finds index of item that's nested in options (options is arr of obj, each obj
// has its own arr, and item can reside in these arr)
// 
// Used to find index of options where the options are stored in categories in 
// the following format (react-select format for having option categories):
//
//  selections: [
//    {
//      label: "category_1",
//      options: [
//        {
//          value: "",
//          label: ""
//        }
//        ...
//      ]
//    },
//    ... 
// ]
//
// The index is used for sorting/filtering because it can easily be queried with
// mongodb queries
//
// Need to iterate through selections, then iterate through options for each 
// selection, incremting the index only when looping through selection.options
// and return index when item is found
// If item not found, return index = -1
//
// Params:
//  - selections (arr): selections to find items in, should be in proper format 
//      as described above
//  - item (string): Item to find index of in options. Should match one of 
//      selections.options.value
function findIndexOfNestedOption(selections, item) {
  let index = 0;

  // Need to use for loop so can return mid-loop
  for (let i = 0; i < selections.length; i++) {
    const options = selections[i].options;

    for (let n = 0; n < options.length; n++) {
      const nestedOptions = options[n]; 

      if (nestedOptions.value === item) {
        return index;
      }

      index++;
    }
  } 

  return -1;
}


// Looks through setup form (pre-formatted) for new values. Only a few values
// can be new, so check those.
// Return object of new values, each new values is just a string or an array
// of the new values like this:
// newValues = {
//   blaster: "New gun",
//   flywheels: ["Worker", "Artifact"]
// }
// 
// If a value is new, then newValues should have a prop for that new value. 
// If the value isn't new, newValues shouldn't have a prop for that value
// Ex: If there isn't a new blaster, newValues.blaster doesn't exist
//
// Here are the values that can be new, so check for them explicitly: 
//  - blaster
//  - flywheels
//  - flywheel cage
//  - flywheel cage material
//  - dart types
function getNewValues(currentFlywheelSetupForm) {
  let newValues = {};

  const { blaster, setup, muzzleVelocities, pusher } = currentFlywheelSetupForm; 



  // Get new blaster, if any
  if (blaster.isNew) {
    newValues.blaster = blaster.value; 
  }  


  // Add new dart types
  let newDartTypes = [];
  muzzleVelocities.forEach(singleMuzzleVelocityEntry => {
    const { dartType } = singleMuzzleVelocityEntry.value;

    if (dartType.isNew && newDartTypes.indexOf(dartType.value) === -1) {
      newDartTypes.push(dartType.value);
    }
  });

  if (newDartTypes.length > 0) {
    newValues.dartTypes = newDartTypes;
  }



  // Get new components in setup (flywheels, flywheel cage, etc)
  let newFlywheels = [];
  let newFlywheelCages = [];
  let newFlywheelCageMaterials = [];
  let newDartGuides = [];
  
  setup.forEach(stage => {
    const { flywheels, flywheelCage, flywheelCageMaterial, dartGuide } = stage.value;

    // Make sure not to re-add the same new parts, this can happen if user adds
    // new components in 2 or more stages
    if (flywheels.isNew && newFlywheels.indexOf(flywheels.value) === -1) {
      newFlywheels.push(flywheels.value)
    }  


    if (flywheelCage.isNew && newFlywheelCages.indexOf(flywheelCage.value) === -1) {
      newFlywheelCages.push(flywheelCage.value)
    }  

    if (flywheelCageMaterial.isNew && newFlywheelCageMaterials.indexOf(flywheelCageMaterial.value) === -1) {
      newFlywheelCageMaterials.push(flywheelCageMaterial.value)
    } 

    if (dartGuide.isNew && newDartGuides.indexOf(dartGuide.value) === -1) {
      newDartGuides.push(dartGuide.value)
    }  
  });

  if (newFlywheels.length > 0) {
    newValues.flywheels = newFlywheels;
  }

  if (newFlywheelCages.length > 0) {
    newValues.flywheelCages = newFlywheelCages;
  }

  if (newFlywheelCageMaterials.length > 0) {
    newValues.flywheelCageMaterials = newFlywheelCageMaterials;
  }

  if (newDartGuides.length > 0) {
    newValues.dartGuides = newDartGuides;
  }


  // Get new pusher mech, if any
  if (pusher.pusherMechanism.isNew) {
    newValues.pusherMechanism = pusher.pusherMechanism.value; 
  }  



  return newValues
}

export default formatEntryFormContent;
import React from 'react';

import CellCount from './CellCount';
import FPSPerformance from './FPSPerformance';


const FPSPerformanceAndCellCount = props => {
  const { muzzleVelocities, cellCount, cellCountInWords } = props; 

  return (
    <div className={"setup-display-card-body-section center-text grey-border-bottom"}>

      <CellCount 
        cellCount={cellCount}
        cellCountInWords={cellCountInWords}

        containerStyle={{
          marginTop: "0.5em",
          marginBottom: "1em"
        }}
      />

      <FPSPerformance
        muzzleVelocities={muzzleVelocities}
      />
      
    </div>
  
  )
}

export default FPSPerformanceAndCellCount;
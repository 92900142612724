import React, { Component } from 'react';

import './index.css';

export default class Checkbox extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isChecked: this.props.isInitiallyChecked || this.props.isChecked || false
		}
	}

	render() {
		return (
			<div>
				<p id={this.props.titleId} className={this.props.titleClassName}>{this.props.title}</p>
        <label className="checkbox-container">
          <input
            type="checkbox"
            checked={this.state.isChecked}
            onChange={e => this.toggleCheckboxChange(e)}
          />
          <span class="checkmark"></span>
          <p id={this.props.labelId} className={this.props.labelClassName}>{this.props.label}</p>
        </label>
			</div>
		)
	}

	toggleCheckboxChange (e) {
		this.setState({isChecked: !this.state.isChecked}, () => {
			if (!!this.props.handleChange){
				this.props.handleChange(e, this.state.isChecked, this.props.toReturn);
			}
		});
	}

}

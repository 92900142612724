import React, { Component } from 'react';

import './index.css';

export default class Contact extends Component {  
  render () {
    return (
      <div id="About" className="flex-container-center"> 
        <div>
          <img id="about-img"
            src={require(`assets/tutorials.png`)} alt="about-img"/>
        </div>
        <div id="about-txt">
          <h1>About</h1>
          <p>I'm Monty, and I run Suild. I strive to develop quality documentation, write-ups, tutorials, tools, web applications, hardware, and firmware for the Nerf community.</p>
          <p>I started everything in my junior year of high school when I posted a tutorial for a nerf ammo counter. People offered to pay for them, so I began to make them. Since then, Suild has only continued to grow to encompass almost everything software and electrical in the Nerf community. I never intended for this to be a largley profitable endeavor, most of my projects are for fun or for the community, but continue to expect nothing but the highest of quality!</p>
          <p>I ship six days a week via USPS, most orders ship out on the next shipping day. All orders ship from San Francisco, CA or San Luis Obispo, CA.</p>
        </div>
        
      </div>
    )    
  }

}
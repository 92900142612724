import React, { Component } from 'react';
import ReactTable from "react-table";

import BatteryHelper from "BatteryHelper";

import "react-table/react-table.css";
import './index.css';

let batteryHelper = new BatteryHelper();

export default class LiPoCheckerResults extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedMotors: []
    }
  }

	render () {
    if (this.props.toDisplayResults && this.props.areBatteriesSpecsValid() && this.props.areMotorsValid()) {
      let newSelectedMotors = this.applyStallCurrent();

      return (
        <div id="LiPoCheckerResults" className="lipo-checker-section">
          <h2>Results</h2>
          {this.renderResult(newSelectedMotors)}
          <h3 className="center-text results-table-title">Basic Statistics</h3>
          <ReactTable
            className="basic-statistic-table results-table -striped -highlight"
            defaultPageSize={3}
            showPaginationBottom={false}
            data={[
              {
                statistic: "Output Current of Battery",
                val: batteryHelper.maxBatteryCurrent(this.props.batteryDischargeRating, this.props.batteryCapacity).toFixed(1) + "A"
              },
              {
                statistic: "Total Current Draw of Loads",
                val: this.calculateTotalCurrent(newSelectedMotors).toFixed(1) + "A"
              },
              {
                statistic: "Total Current Draw of Loads + Headroom",
                val: (this.calculateTotalCurrent(newSelectedMotors)*this.headroomToPercent(this.props.headroom)).toFixed(1) + "A"
              }
            ]}
            columns={[
              {
                Header: "Statistic",
                accessor: "statistic"
              },
              {
                Header: "Value",
                accessor: "val"
              }
            ]}/>
          <h3 className="center-text results-table-title">Battery Statistics</h3>
          <ReactTable
            className="basic-statistic-table results-table -striped -highlight"
            defaultPageSize={4}
            showPaginationBottom={false}
            data={[
              {
                statistic: "Cell Count",
                val: this.props.batteryCellCount + "S"
              },
              {
                statistic: "Capacity",
                val: this.props.batteryCapacity + "mAh"
              },
              {
                statistic: "Discharge Rating",
                val: this.props.batteryDischargeRating + "C"
              },
              {
                statistic: "Output Current of Battery",
                val: batteryHelper.maxBatteryCurrent(this.props.batteryDischargeRating, this.props.batteryCapacity).toFixed(1) + "A"
              }
            ]}
            columns={[
              {
                Header: "Statistic",
                accessor: "statistic"
              },
              {
                Header: "Value",
                accessor: "val"
              }
            ]}/>
          <h3 className="center-text results-table-title">Equivalent Current Draw at Different Headrooms</h3>
          <ReactTable
            className="basic-statistic-table results-table -striped -highlight"
            defaultPageSize={this.calculateCurrentDrawAtDifferentHeadroomsForTable(newSelectedMotors).length}
            showPaginationBottom={false}
            data={this.calculateCurrentDrawAtDifferentHeadroomsForTable(newSelectedMotors)}
            columns={[
              {
                Header: "Headroom",
                accessor: "headroom"
              },
              {
                Header: "Equivalent Current Draw",
                accessor: "currentDraw"
              }
            ]}/>
          <h3 className="center-text results-table-title">Current Draw of Individual Motors</h3>
          <ReactTable
            className="basic-statistic-table results-table -striped -highlight"
            defaultPageSize={newSelectedMotors.length}
            showPaginationBottom={false}
            data={this.calculateSpecsOfIndividualMotors(newSelectedMotors)}
            columns={[
              {
                Header: "Motor",
                accessor: "name"
              },
              {
                Header: "Qty",
                accessor: "qty"
              },
              {
                Header: "Nominal Cell Count",
                accessor: "nominalCellCountForTable"
              },
              {
                Header: "Over/undervolted",
                accessor: "volting"
              },
              {
                Header: "Total Stall Current",
                accessor: "totalStallCurrent"
              }
            ]}/>
        </div>
      );
    } else {
      return (
        <div id="LiPoCheckerResults" className="lipo-checker-section">
          <h2>4. Results</h2>
          <p className="error-text">Please finish entering motor and battery information!</p>
        </div>
      );
    }
		
	}

  renderResult(selectedMotors) {
    if (batteryHelper.maxBatteryCurrent(this.props.batteryDischargeRating, this.props.batteryCapacity) > this.calculateTotalCurrent(selectedMotors)*this.headroomToPercent(this.props.headroom)) {
      return (
        <h3 id="lipo-result-good" className="success-text center-text">It works!</h3>
      )

    } else {
      return (
        <h3 id="lipo-result-bad" className="error-text center-text">It doesn't work!</h3>
      )
    }
  }

  headroomToPercent(headroom) {
    return 1 + (headroom/100);
  }

  applyStallCurrent() {
    let newSelectedMotors = [];    //arr for selected motors with stall current member vars

    this.props.selectedMotors.map((selectedMotor) => {
      let newSelectedMotor = Object.assign(selectedMotor, {});    //new obj to prevent mutation

      // Stall current for solenoid isn't found in the database of motors (because solenoids aren't in 
      // the database), so the stall current needs to be added manually (it's 8A)
      if (selectedMotor.name === "12V 35mm Stroke Solenoid") {
        newSelectedMotor.stallCurrent = 8;
      // Motors should just have their stall currents calculated/derived from the database
      } else {
        let motorData = this.props.motorHelper.findMotorsWithMatchingName(selectedMotor.name)[0];   //find data of motor
        newSelectedMotor.stallCurrent = this.props.motorHelper.getSpecsAtVoltage(motorData, this.props.batteryCellCount).stallCurrent;    //apply stall current
      }

      newSelectedMotors.push(newSelectedMotor);
    });

    return newSelectedMotors;
  }

  calculateTotalCurrent(selectedMotors) {
    let totalCurrent = 0;
    for (var i = 0; i < selectedMotors.length; i++) {
      totalCurrent += selectedMotors[i].stallCurrent * selectedMotors[i].qty;
    }

    return totalCurrent;
  }

  //returns arr that can be used as data to be rendered as a table
  calculateCurrentDrawAtDifferentHeadroomsForTable(newSelectedMotors) {
    //first item is at selected headroom
    let currentDraws = [
      {
        headroom: this.props.headroom + "% (selected headroom)",
        currentDraw: (this.calculateTotalCurrent(newSelectedMotors)*this.headroomToPercent(this.props.headroom)).toFixed(1) + "A"
      }
    ];

    for (let i = 0; i < 21; i+=5) {
      //make sure headroom being calculated isn't default headroom
      if (this.headroomToPercent(i) !== this.headroomToPercent(this.props.headroom)) {
        currentDraws.push({
          headroom: i + "%",
          currentDraw: (this.calculateTotalCurrent(newSelectedMotors)*this.headroomToPercent(i)).toFixed(1) + "A"
        });
      }
    }

    return currentDraws;
  }

  //returns arr that can be used as data to be rendered as a table
  //data for table that shows specs of individual motors
  calculateSpecsOfIndividualMotors(selectedMotors) {
    let specsOfIndividualMotors = [];
    for (let i = 0; i < selectedMotors.length; i++) {
      let nominalCellCountForTable = this.props.motorHelper.nominalCellCountToText(selectedMotors[i]);
      let voltingText = this.props.motorHelper.voltingText(selectedMotors[i], this.props.batteryCellCount);

      selectedMotors[i].nominalCellCountForTable = nominalCellCountForTable;
      selectedMotors[i].volting = voltingText;
      selectedMotors[i].totalStallCurrent = selectedMotors[i].stallCurrent * selectedMotors[i].qty + "A";

      specsOfIndividualMotors.push(selectedMotors[i]);
    }

    return specsOfIndividualMotors;
  }


}

import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import './index.css';

export default class ContactSuccess extends Component {
  render () {
    return (
      <div id="ContactSuccess">
        <h3>Thank you for contacting Suild.</h3>
        <p>We will return your messsage at the provided email in up to two business days.</p>
        <p>If you do not receive a response after 48 hours, please submit a contact form again.</p>
        <p><Link to="/" className="button-style-1">Return Home</Link></p>
      </div>
    )
  }

}
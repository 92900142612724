import React, { Component } from 'react';
import Dropdown from 'react-dropdown';

import SliderWithTooltipAndText from "../../../Functional/SliderWithTooltipAndText";


import './index.css';

export default class LiPoCalculator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cellCount: 0,
      capacity: 0,
      dischargeRating: 0
    }
  }

	render () {
		return (
      <div id="LiPoCalculator" className="lipo-checker-section">
      	<h2>Enter your LiPo Specs</h2>
        <div id="battery-selector-sliders-container">
          <p>Position the slider to your desired value.</p>
          <SliderWithTooltipAndText
            containerClassName="lipo-checker-slider-container"
            title="Battery Cell Count"
            description="Cell count in series: '2S' or '3S'"
            sliderPos={this.state.cellCount}
            value={this.state.cellCount}
            sliderValSuffix="S"
            min={0} max={5} step={1}
            marks={function () {
              let marks = {}
              for (var i = 0; i <= 5; i++) {
                marks[i] = i + "S";
              }

              return marks;
              }()
            }
            onChange={(newSliderPos) => {this.setState({cellCount: newSliderPos}, this.submitBatterySpecs)}}/>
          <SliderWithTooltipAndText
            containerClassName="lipo-checker-slider-container"
            title="Battery Capacity"
            description="in mAh"
            sliderPos={this.state.capacity}
            value={this.state.capacity}
            sliderValSuffix="mAh"
            min={0} max={5000} step={25} 
            marks={function () {
              let marks = {}
              for (var i = 0; i <= 5000; i+=1000) {
                marks[i] = i + "mAh";
              }

              return marks;
              }()
            }
            onChange={(newSliderPos) => {this.setState({capacity: newSliderPos}, this.submitBatterySpecs)}}/>
          <SliderWithTooltipAndText
            containerClassName="lipo-checker-slider-container"
            title="Battery Discharge Rating"
            description="Also called 'C rating'. If two are listed, chose the higher one: '40C' or '65C/120C' or '65C Constant/130C Burst'"
            sliderPos={this.state.dischargeRating}
            value={this.state.dischargeRating}
            sliderValSuffix="C"
            min={0} max={200} step={5} 
            marks={function () {
              let marks = {}
              for (var i = 0; i <= 200; i+=50) {
                marks[i] = i + "C";
              }

              return marks;
              }()
            }
            onChange={(newSliderPos) => {this.setState({dischargeRating: newSliderPos}, this.submitBatterySpecs)}}/>
        </div>
      </div>
		);
	}

  submitBatterySpecs() {
    this.props.updateBatterySpecs(this.state.cellCount, this.state.capacity, this.state.dischargeRating);
  }

}

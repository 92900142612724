import React, { Component } from 'react';
import _ from 'lodash';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';


import 'react-accessible-accordion/dist/fancy-example.css';

import './index.css';

const INSTRUCTIONS = [
{
  title: "1. Select your loads (motors and/or solenoids)",
  content: "Select your loads (motors and/or solenoids) from a collection of the most common aftermarket motors used for Nerf. Be sure to select how many of each motors you're using! Flywheel motors require that you select two motors, while for  pusher motors you only need to select one. If you're running a multistage setup, be sure to account for that as well! If you're undervolting or overvolting the motors or solenoids, this application will also account for that. Only motors on the selected motor list will be used in calculations. Press the green + button to add motors to the list for calculations. To remove added motors from the list, click the red - button."
},
{
  title: "2. Find your LiPo Specs",
  content: "Enter the specs of the LiPo that you have chosen. Be sure to enter the number of cells, battery capacity, and C rating. If you see two numbers in the C rating, just use the higher one (this is the burst rating). Make sure that the battery capacity is is mAh (milliamp hours). For most batteries, this number will be in the hundreds or small thousands. This application assumes that the battery used has no cells in parallel. You won't need to worry about that for most batteries that we use. Motor specifications can be found under the 'specifications' tab on HobbyKing."
},
{
  title: "3. Choose your desired headroom",
  content: "The headroom is the buffer between the total current draw of the motors and the maximum current output of the motors. For example, if you have a total current draw of 100A with a headroom of 10%, then your batteries should be able to provide 110A of current."
},
{
  title: "4. Hit calculate!",
  content: "This application will do all the calculations to see if the battery chosen will work for the loads provided. You will be able to see the result afterwards."
}
]

//this.state.RAW_DATA = all data from db
//this.state.DATA = only more data from db
export default class LipoCheckerInstructions extends Component {
  constructor(props) {
    super(props);

  }

	render() {
    let instructions = INSTRUCTIONS.map(this.renderInstuctions);

    return (
    	<div id="HowLiPoCheckerWorks"> 
    		<h2 className="center-text lipo-checker-title-txt">Instructions</h2>
    		<div id="lipo-checker-steps-container" className="flex-container-center">
          <Accordion 
            className={"lipo-checker-instructions-accordian"}
            allowZeroExpanded={true}
            allowMultipleExpanded={true}>
            {instructions}
          </Accordion>
    		</div>
    	</div>
		)
  }

  renderInstuctions(instruction) {
    return (
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <p className={"inline-p"}>{instruction.title}</p>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>{instruction.content}</p>
        </AccordionItemPanel>
      </AccordionItem>

    )
  }

}

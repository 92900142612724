import React, { Component } from 'react';

import './index.scss';

export default class MOSFETBoard extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedInteractiveOption: ""
		}
	}

	render() {
		return (
			<div id="mosfet-board-interactive-options">
				<h3 className="option-set-title">Board Options</h3>
      		<p>Click on a box to change its options.</p>
	      	<div id="interactive-mosfet-board-2-options">
	      		<div className="interactive-options-bg interactive-mosfet-board-2-options-bg"
	      			style={{
	      				display: (this.props.toShowMoreOptions ? "block" : "none"), 
	      		 		backgroundImage: "url(" + require("assets/Shop/MOSFET Board 2/MOSFET_board_2_options_bg_compressed.jpg") + ")" 
	      			}}>
	      		</div>
	      		<div id="interactive-mosfet-board-2-expandable-header-options-selector" 
	      			className="interactive-options-selector"
		      			onClick={() => this.props.setInteractiveOptionSelection({selectedInteractiveOption: "expandable-header"})}></div>

      			<div id="interactive-mosfet-board-2-jst-options-selector-container"
      				className="flex-container-center">
	      			<div id="interactive-mosfet-board-2-left-jst-options-selector" 
		      			className="interactive-options-selector"
		      			onClick={() => this.props.setInteractiveOptionSelection({selectedInteractiveOption: "jst-left"})}></div>

	      			<div id="interactive-mosfet-board-2-right-jst-options-selector" 
		      			className="interactive-options-selector"
		      			onClick={() => this.props.setInteractiveOptionSelection({selectedInteractiveOption: "jst-right"})}></div>
	      		</div>
	      	</div>
			</div>
		)
	}
}
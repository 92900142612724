import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import './index.css';

export default class ComingSoon extends Component {
  render () {
    return (
      <div id="ComingSoon">
        <h1>Coming soon!</h1>
        <h2><Link to="/">Go Home</Link></h2>
      </div>
    )
  }

}
import React from 'react';
import { Link } from 'react-router-dom';

import Image from '../../Image';

import './index.css';

const ContentBox1 = props => {
  return (
    <Link className={"ContentBox1 shadow-border " + props.className} to={"/" + props.link}>
      <Image className={"content-box-1-img " + props.imgClassName} 
        src={require(`assets/${props.imgSrc}`)} alt="content-box-1-img"/>
      <div className={props.textContainerClassName}>
        <h2 className={props.titleClassName}>{props.title}</h2>
        <p className={props.descriptionClassName}>{props.description}</p>
        {props.children}
      </div>
    </Link>
  )

}

export default ContentBox1;
import React, { Component } from 'react';

import Helper from 'Helper';

import './index.css';

let helper = new Helper();

export default class Form extends Component {
  constructor (props) {
    super(props);

    this.renderFormItems = this.renderFormItems.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);

    this.state = {
      formResults: Object.assign({}, this.props.formResults)
    }

  } 

  componentWillReceiveProps(nextProps) {
    this.setState({formResults: Object.assign({}, nextProps.formResults)});
  }

  render () {
    let formItems = this.props.formItemsData.map(this.renderFormItems);

    return (
      <div className="Form">
        <h2>{this.props.formTitle}</h2>
        <form>

          {formItems}

          {this.props.children}
        </form>
        <button className="form-submit-btn button-style-1" 
          onClick={e => this.handleFormSubmit(e)}>
          <h4 className="button-text">{this.props.submitButtonText}</h4>
        </button>
      </div>
    )
  }

  renderFormItems (item) {
    if (item.isTextArea && item.isRequired) {
      return (
        <p>
          <label>
            {item.label + ":"}<p className="red-asterisk">*</p> : 
            <textarea 
              name={item.name}
              id="order-notes-input" 
              className="non-expanding-form-input" 
              placeHolder={item.placeHolder}
              onChange={(e) => this.handleFormChange(item.formResultsRef, e)} required>
            </textarea>
          </label>
        </p>
      )
    } else if (item.isRequired) {
      return (
        <p>
          <label>
            {item.label}<p className="red-asterisk">*</p> : 
            <textarea 
              type={item.type === "email" ? "email" : "text"} 
              name={item.name} 
              className="non-expanding-form-input" 
              placeHolder={item.placeHolder}
              value={this.state.formResults[item.formResultsRef] || ""}
              onChange={(e) => this.handleFormChange(item.formResultsRef, e)}required/>
          </label>
        </p>
      )
    } if (item.isTextArea) {
      return (
        <p>
          <label>
            {item.label + ":"}
            <textarea 
              name={item.name}
              id="order-notes-input" 
              className="form-input" 
              placeHolder={item.placeHolder}
              onChange={(e) => this.handleFormChange(item.formResultsRef, e)}>
            </textarea>
          </label>
        </p>
      )
    } else if (!item.isRequired) {
      return (
        <p>
          <label>
            {item.label}: 
            <textarea 
              type={item.type === "email" ? "email" : "text"} 
              name={item.name} 
              className="non-expanding-form-input" 
              placeHolder={item.placeHolder}
              value={this.state.formResults[item.formResultsRef] || ""}
              onChange={(e) => this.handleFormChange(item.formResultsRef, e)}/>
          </label>
        </p>
      )
    }
  }

  handleFormChange (formResultsRef, e) {
    let newForm = Object.assign({}, this.state.formResults);

    newForm[formResultsRef] = e.target.value;

    this.setState({formResults: newForm}, () => {
      // update parent's state if specified in props
      if (this.props.updateParentOnFormUpdate) {
        this.props.handleFormUpdate(this.state.formResults);
      }
    });
  }

  handleFormSubmit (e) {
    this.props.handleFormSubmit(helper.formNormalizer(Object.assign({}, this.state.formResults)));

  }


}
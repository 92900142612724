import React from 'react';
import Select from 'react-select';

const RateOfFireEntry = props => {
  const { rateOfFire, 
    onChange, 
    RATE_OF_FIRE_SELECTIONS,
    generateOptionForReactSelect,
    errorText,
    styles } = props;

  return (
    <div>
      <h3>Rate of Fire</h3>
      <Select className="small-select-dropdown"
        styles={styles}
        placeholder={"Rate of Fire *"}
        value={ 
          !!rateOfFire.value && generateOptionForReactSelect(rateOfFire.value)
        }
        options={
          // Convert data in db to data that's compatible with Select component
          RATE_OF_FIRE_SELECTIONS.map(rofSelection => {
            return generateOptionForReactSelect(rofSelection) 
          })
        }
        onChange={(selectedRateOfFire) => {
          // Make sure the option selected is valid before trying to set it 
          // to the state
          // if user clicks 'x' to deselect, don't want anything breaking
          if (!!selectedRateOfFire) {
            onChange({
              rateOfFire: { 
                value: {$set: selectedRateOfFire.value}},
              isFullAuto: { 
                value: {$set: (selectedRateOfFire.value !== "Semi-automatic")}}
            })
          } else {
            onChange(
              {rateOfFire: { value: {$set: ""}}})
          }
        }}
      />
      <p className="setup-entry-help">
        Rate of fire, in darts-per-second (DPS), if your blaster features fully-automatic capabilites. If your blaster is semi-automatic, please select "Semi-automatic".
      </p>

      <p className="center-text error-text">{errorText}</p>

    </div>
  )
}

export default RateOfFireEntry;
import React, { Component } from 'react';
import _ from 'lodash';

import DataDependantComponent from '../../DataDependantComponent';

import MotorChart from './Chart';
import MotorInfo from './MotorInfo';
import MotorCredits from '../Functional/MotorCredits';

import MotorHelper from 'MotorHelper';

import './index.css';


let motorHelper = new MotorHelper();

//this.state.RAW_DATA = all data from db
//this.state.DATA = only more data from db
export default class MotorChartApp extends DataDependantComponent {
	constructor (props) {
		super(props);

		this.handleMotorInfoChange = this.handleMotorInfoChange.bind(this);

    this.dotColors = [
      "#ADD8E6",
      "#66b266",
      "#ffae19",
      "#ff1919"
    ]

    //first el undervolt, second nominal, third over
    this.cellShape = [
      "triangle",
      "circle",
      "square"
    ]

		this.state = {
      //from db
			isLoading: true,
			isUnderMaintenance: false,
			RAW_DATA: [],
			DATA: [],			//all data from backend with nominal voltages and Z axis applied
      allMotorData:[],

			zRange: [400, 2000],
			hiddenMotorParams: [],		//parameters of motors not to display
			motorInfo: {},						//motor data of motor info displayed under chart
			selectedMotors: []		//arr of selected motor that need to be highlighted
		}

	}

	afterDataRetrieved() {
    //fix data: applyZAxisFromSize, sortBattery, applyNominalVoltage
		let motorData = this.applyZAxisFromSize(
      this.applyBatteries(
        this.applyNominalVoltage(
          this.sortNominalBattery(
            this.state.DATA.data)), [2, 3, 4, 5]));

    this.setState({
      RAW_DATA: this.state.DATA,
      allMotorData: motorData,
    	isUnderMaintenance: false,
    	isLoading: false,
      motorInfo: {}
    });
  }

	renderChildComponent() {
  	if (this.state.isUnderMaintenance) {		//under maintenance
  		return (
  			<div id="MotorChartApp">
  				<h1>Motor chart is under maintenance and will be back up shortly. Apologies for any inconveniences this may cause.</h1>
  			</div>
			)
  	} if (!this.state.isLoading && !_.isEqual(this.state.allMotorData, [])) {		  	//make sure to get data first
	    return (
	      <div id="MotorChartApp">
	      	<div id="motor-chart-container">
		        <h1 className="center-text">Motor Chart</h1>
            <p className="chart-instructions center-align-text">Hover and/or click on dots for more infomation. Click and scroll down for even more information.</p>
            <p className="chart-instructions center-align-text">Click on the legend to hide/show dots corresponding to that legend item.</p>
		        <MotorChart
							DATA={this.state.allMotorData}
							handleMotorInfoChange={this.handleMotorInfoChange}
							getZCoordFromFormFactor={this.getZCoordFromFormFactor}
              dotColors={this.dotColors}
              replaceSInCell={this.replaceSInCell}
              getCellCountFromColor={this.getCellCountFromColor}
              isNominallyVolted={this.isNominallyVolted}
              getVoltingStateFromShape={this.getVoltingStateFromShape.bind(this)}/>
		       </div>

					<MotorInfo
						motorInfo={this.state.motorInfo}
						otherShopData={this.props.otherShopData}/>

          <MotorCredits/>
	      </div>
	    )
	  } else {
	  	return (
	      <div id="MotorChartApp"></div>
  		)
	  }
  }

  //im lazy, dont't want mutation
  //creates copy of motorData
  createNewMotorData(motorData) {
    return _.map(motorData, _.clone)
  }

  getZCoordFromFormFactor(formFactor) {
  	if (formFactor == 130) {
  		return 400;
  	} else if (formFactor == 132) {
  		return 1000;
  	} else if (formFactor == 180) {
  		return 2000;
  	}
  }

  //make sure battery goes from low to high
  sortNominalBattery (motorData) {
    let newMotorData = this.createNewMotorData(motorData);
    _.map(newMotorData, (indivMotorData) => {
      indivMotorData.nominalBattery.sort();
    });

    return newMotorData;

  }

  applyNominalVoltage(motorData) {
    let newMotorData = this.createNewMotorData(motorData);
    _.map(newMotorData, (indivMotorData) => {
      //every motor data has battery as arr, so need to go through that and apply nominal voltage
      //nominal voltage will be arr paralleling battery arr
      let nominalVoltage = [];

      //apply proper nominal voltages for every battery
      _.map(indivMotorData.nominalBattery, (indivBattery) => {
        nominalVoltage.push(motorHelper.getVoltageFromCells(indivBattery));
      });

      indivMotorData.nominalVoltage = _.map(nominalVoltage, _.clone);

    });

  	return newMotorData;
  }

  //also used for over/undervolting
  applyBatteries(allMotorData, cellArr) {
    let newAllMotorData = this.createNewMotorData(allMotorData);    //iterate through this b/c appliedOvervoltageMotorData changes
    let appliedOvervoltageMotorData = [];

    //apply over volting of every cell to every battery
    _.map(cellArr, (indivCell) => {
      _.map(newAllMotorData, (indivMotorData) => {
        let overvoltedMotorData = this.getSpecsAtVoltage(indivMotorData, indivCell);

        //add overvolted battery
        overvoltedMotorData.battery = indivCell;

        //check undervoltage/overvoltage
        overvoltedMotorData.voltingState = this.isNominallyVolted(overvoltedMotorData, indivCell);

        //only push if vals not too high so graph not too messed up
        if (overvoltedMotorData.speed <= 80000 && overvoltedMotorData.torque <= 2000) {
          appliedOvervoltageMotorData.push(overvoltedMotorData);
        }


      });
    });

    return appliedOvervoltageMotorData;
    
  }

  getSpecsAtVoltage(currentMotor, cellCount) {
    let tempCurrentMotor = Object.assign({}, currentMotor);
    
    tempCurrentMotor.speed = this.getSpeedFromMoreCells(
      currentMotor.speed, 
      currentMotor.nominalBattery[0], 
      cellCount);

    tempCurrentMotor.torque = this.getTorqueFromMoreCells(
      currentMotor.torque, 
      currentMotor.nominalBattery[0], 
      cellCount);

    tempCurrentMotor.stallCurrent = this.getStallCurrentFromMoreCells(
      currentMotor.stallCurrent, 
      currentMotor.nominalBattery[0], 
      cellCount);

    return tempCurrentMotor;
  }

  getSpeedFromMoreCells(speed, baselineCellCount, actualCellCount) {
    return Number(Math.round(speed * (actualCellCount/baselineCellCount)).toFixed(2));
  } 

  getTorqueFromMoreCells(torque, baselineCellCount, actualCellCount) {
    return  Number(Math.round(torque * (actualCellCount/baselineCellCount)).toFixed(2));
  }

  getStallCurrentFromMoreCells(current, baselineCellCount, actualCellCount) {
    return  Number(Math.round(current * (actualCellCount/baselineCellCount)).toFixed(2));
  }

  //check if motor overvolted, undervolted, or regular
  //reg = 0, over = 1, under = -1
  isNominallyVolted(motorData, cellToCheck) {
    //nominal batteries sorted, lowest first, highest last
    if (motorData.nominalBattery[0] > cellToCheck) {
      return -1;
    } else if (motorData.nominalBattery[motorData.nominalBattery.length - 1] < cellToCheck) {
      return 1;
    }

    return 0;

  }

  //set z member of motor based on motor size
  applyZAxisFromSize(motorData) {
    let newMotorData = Object.assign(motorData);

    for (var i = 0; i < newMotorData.length; i++) {
      newMotorData[i].z = this.getZCoordFromFormFactor(newMotorData[i].formFactor);
    }


    return newMotorData;
  }

  handleMotorInfoChange(newMotorInfo) {
    this.setState({
      motorInfo: Object.assign({}, newMotorInfo)
    });
  }

  //"3s" => 3
  replaceSInCell(s) {
    return Number(s.replace("S", ""));
  }

  getCellCountFromColor(color) {
    let index = _.indexOf(this.dotColors, color);
    if (index === -1) {   //not found
      return index;
    }

    return index + 2;
  }

  //get over/under/nominal volting depending on shape
  getVoltingStateFromShape(shape, value) {
    if (value > 100) {    //make sure to only set volting if legend items for volting
      return;
    }

    if (shape === this.cellShape[0]) {
      return -1;
    } else if (shape === this.cellShape[2]) {
      return 1;
    } 

    return 0;
  }


}

import React from 'react';
import { Button } from '@material-ui/core';

import MotorEntry from './MotorEntry';
import FlywheelEntry from './FlywheelEntry';
import FlywheelCageEntry from './FlywheelCageEntry';
import FlywheelCageMaterialEntry from './FlywheelCageMaterialEntry';
import DartGuideEntry from './DartGuideEntry';
import MicroFlywheelEntry from './MicroFlywheelEntry';


// External utilities/helpers
import update from 'immutability-helper';
  

// Renders selection form for one stage 
// Parameters: 
//	- currentStage (obj): Stage data object from state
//	- stageIndex (int): index of stage
const StageSelectionEntry = props => {
  const { currentStage, 
    stageIndex,
    
    setup,
  
    ALL_MOTOR_DATA,
    FLYWHEELS,
    FLYWHEEL_CAGES,
    FLYWHEEL_CAGE_MATERIALS,
    DART_GUIDES,

    generateOptionForReactSelect,
    updateFlywheelSetupForm,
    
    errors,
    styles } = props;

  return (
    <div className="stage-selection-entry-form">
      <h3>{"Stage " + (stageIndex + 1)}</h3>
      <div className="stage-entry-form-items flex-container-center">
        <MotorEntry
          motorsInForm={currentStage.value.motors}
          errorText={errors.motors}
          styles={styles.motors}
          updateFlywheelSetupForm={updateFlywheelSetupForm}
          getUpdatedSetup={getUpdatedSetup}

          ALL_MOTOR_DATA={ALL_MOTOR_DATA}
          generateOptionForReactSelect={generateOptionForReactSelect}
        />

        <FlywheelEntry
          flywheelsInForm={currentStage.value.flywheels}
          errorText={errors.flywheels}
          styles={styles.flywheels}
          updateFlywheelSetupForm={updateFlywheelSetupForm}
          getUpdatedSetup={getUpdatedSetup}

          FLYWHEELS={FLYWHEELS}
          generateOptionForReactSelect={generateOptionForReactSelect}
        />

        <div className="stage-entry-form-item">
          <FlywheelCageEntry
            flywheelCageInForm={currentStage.value.flywheelCage}
            errorText={errors.flywheelCage}
            styles={styles.flywheelCage} 
            updateFlywheelSetupForm={updateFlywheelSetupForm}
            getUpdatedSetup={getUpdatedSetup}

            FLYWHEEL_CAGES={FLYWHEEL_CAGES}
            generateOptionForReactSelect={generateOptionForReactSelect}
          />

          <FlywheelCageMaterialEntry
            flywheelCageMaterialInForm={currentStage.value.flywheelCageMaterial}
            errorText={errors.flywheelCageMaterial}
            styles={styles.flywheelCageMaterial} 
            updateFlywheelSetupForm={updateFlywheelSetupForm}
            getUpdatedSetup={getUpdatedSetup}

            FLYWHEEL_CAGE_MATERIALS={FLYWHEEL_CAGE_MATERIALS}
            generateOptionForReactSelect={generateOptionForReactSelect}
          />
        </div>

        <div className="stage-entry-form-item">
          <DartGuideEntry
            dartGuideInForm={currentStage.value.dartGuide}
            errorText={errors.dartGuide}
            styles={styles.dartGuide}
            updateFlywheelSetupForm={updateFlywheelSetupForm}
            getUpdatedSetup={getUpdatedSetup}

            DART_GUIDES={DART_GUIDES}
            generateOptionForReactSelect={generateOptionForReactSelect}
          />

          <MicroFlywheelEntry
            microFlywheelInForm={currentStage.value.isMicroFlywheelSetup}
            errorText={errors.isMicroFlywheelSetup}
            updateFlywheelSetupForm={updateFlywheelSetupForm}
            getUpdatedSetup={getUpdatedSetup}
          />
        </div>
      </div>

      {/* Remove a Stage button */}
      {/* Only render if there is more than 1 state */}
      {(setup.length > 1) && 
        <Button className="remove-btn-color setup-entry-form-btn" variant="contained"
          onClick={() => {
            // Remove this state from the state
            updateFlywheelSetupForm({
              setup: { 
                $splice: [[stageIndex, 1]]
              }
            });
          }}
        >
          Remove this Stage
        </Button>}
    </div>
  )

  // Gets updated setup based on an update to a specific stage and returns an
  // object in the correct formatting that can be used in updateFlywheelSetupForm
  // The return value of this must be passed into updateFlywheelSetupForm() to
  // fully update the setup
  // 
  // updateValue (object) should be in proper format for immutable-helper: 
  // 	{blaster: {
  // 		name: {$set: selectedOption.value},
  // 		isNew: {$set: !!selectedOption.__isNew__}
  // 	}}
  // 
  function getUpdatedSetup(updateValue) {
    // I can't update motors in the stage directly because it's too deep
    // in an array, so I'll just update a copy of the entire stage and 
    // store that new copy of the entire stage (in it's correct index) 
    let updatedStage = update(currentStage, { value: updateValue });

    // Replace the old stage with updatedStage
    return {
      setup: { $splice: [[stageIndex, 1, updatedStage]] }
    }

  }
}

export default StageSelectionEntry;
//class to send data to backend using a post request

import axios from 'axios';

import CONSTANTS from 'CONSTANTS';

export default class BackendDataSender {
	constructor () {
		this.dataServerURL = new CONSTANTS().getDataServerURL();
	}

	//collection should be string that matches collection name of collection from DB
	sendData(collection, data) {
		return new Promise((rs, rj) => {
			rs(axios.post(this.dataServerURL + "/" + collection, data)
				.then(res => {
					return res.data
				})
			);	//rs
		})	// Promise
		.then((val) => {		//called after promise fulfulled
			return val
		})

  }

}

import React from 'react';

import './index.scss';

const FloatingBtn = props => {
	return (
		<div className={"floating-btn flex-container-fully-centered " + props.className} id={props.id}
			onClick={e => props.onClick(e)}>
			{props.children}
		</div>
	)
}

export default FloatingBtn;
// Hook for managing setups, including retrieve from db, apply sorts/filter, etc

import { useEffect, useReducer } from 'react';

import _ from 'lodash'


// Custom hooks/services
import BackendDataSender from 'BackendDataSender';
import Constants from 'CONSTANTS';



const API_LINK_KEY = "flywheel-setup-picker-setups"


const initialSetupsState = {
  setups: [],                 // Setups being displayed
  numOfSetupsDisplayed: 0,
  hasMoreSetups: true,        // Flag if there are more setups to be displayed (use for infinite scroll)
  needsMoreSetups: false,     // Flag if more setups are needed, raised from getMoreSetups()

  searchID: 0,                // ID of setup to search for 

  filters: false,             // Selected filters, initialize to false 
  newFilters: false,          // Flag if new filters are selected, or if the same filter is selected but want to re-fetch with same filters

  sort: {},                   // selected sort 

  areSetupsLoading: true      // Flag if setups are initially loading for the first time

} 

const setupsStateReducer = (currentSetupsState, action) => {
  const { type, payload } = action;

  switch (type) {
    // When setups grabbed for the first time
    case "SET_INITIAL_SETUPS":
      return {
        ...currentSetupsState,
        setups: payload,
        numOfSetupsDisplayed: payload.length,

        areSetupsLoading: false
      }

    // Set newly grabbed setups
    case "SET_SETUPS": 
      return {
        ...currentSetupsState,
        setups: payload,
        numOfSetupsDisplayed: payload.length,

        needsMoreSetups: false,
        areSetupsLoading: false,

        newFilters: false
      }
    
    // Tried to grab more setups, but no more
    case "NO_MORE_SETUPS":
      return {
        ...currentSetupsState,

        hasMoreSetups: false,
        needsMoreSetups: false
      }

    // Action to get more setups after scrolling is done 
    case "GET_MORE_SETUPS":
      return {
        ...currentSetupsState,
        needsMoreSetups: true
      }

    // Action to add new setup and scroll to it
    // Evoked when a new setup is added
    // Need to set searchID and re-fetch setups
    case "ADD_NEW_SETUP":
      // console.log("Added")
      return {
        ...currentSetupsState,
        
        searchID: payload,
        areSetupsLoading: true
      } 

    // Set the search id
    // can be triggered intially (a search id in link) or on new setup entered 
    // This should be invoked after 
    case "SET_SEARCH_ID": 
      return {
        ...currentSetupsState,
        searchID: payload
      }

    // Clear search ID 
    // Clear state.searchID so future actions don't get messed up by the search ID
    // Typically invoked after id to search for has been scrolled to 
    case "CLEAR_SEARCH_ID":
      // console.log("Clear search id")
      return {
        ...currentSetupsState,
        searchID: initialSetupsState.searchID
      }
      
    // Action to apply filter
    // Triggered when by onFilterApply() which happens when a filter is applied 
    // (cleared or apply btn press). 
    // When this happens, set filters in state, then do a backend request with 
    // new the filters
    case "SET_FILTERS": 
      return {
        ...currentSetupsState,

        filters: payload,
        newFilters: true,

        areSetupsLoading: true
      }

    // Action to apply sort 
    case "SET_SORT": 
      return {
        ...currentSetupsState,

        sort: payload,

        areSetupsLoading: true
      }

    // Action to apply search 
    default:
      throw new Error()
      
  }

}

// Sends post request to backend with appropriate payload to fetch setups
// Payload indicates stuff like needsMoreSetups, filters, searchID, etc. passed in
// Returns setups fetched from db
async function fetchSetups(options) {
  const fetchedSetups = await new BackendDataSender().sendData(
    new Constants().getAPILink(API_LINK_KEY), options
  );

  return fetchedSetups

}

const useSetups = (searchID) => {
  // Reducer for managing setupsState stuff, including:
  //  - setups being displayed
  //  - filters selected
  //  - sort selected
  //  - whether or not there are more setups to be displayed (use for infinite scroll)
  const [ setupsState, setupsStateDispatch ] = useReducer(setupsStateReducer, initialSetupsState);


  // Fetch initial setups data on mount
  // Need to use a post request via BackendDataSender, so can't use
  // useDataFromBackend hook
  useEffect(() => {
    // Set searchID on mount, if specified 
    if (!!searchID) {
      setupsStateDispatch({
        type: "SET_SEARCH_ID",
        payload: searchID
      })
    }

    async function fetchIntialSetups() {
      const fetchedSetups = await fetchSetups({
        searchID: searchID,

        needsMoreSetups: true,
        numOfSetupsDisplayed: 0
      });

      setupsStateDispatch({
        type: "SET_INITIAL_SETUPS",
        payload: fetchedSetups
      })
    }

    fetchIntialSetups();
  }, []);


  // Hook to get more setups
  // called when needsMoreSetups = true, makes call to 
  // backend to load more setups. When more setups are loaded, the above hook
  // will run
  useEffect(() => {  
    if (setupsState.needsMoreSetups) {
      async function getNewSetups () {
        // Tell backend which setups to grab based on the currently selected filters
        // and other setupsState stuff
        const newSetups = await fetchSetups({
          needsMoreSetups: true,
          numOfSetupsDisplayed: setupsState.numOfSetupsDisplayed,
  
          filters: setupsState.filters,
          sort: setupsState.sort
        });
        
        // The setups fetched are the same amount of setups that are currently 
        // being displayed, so there aren't any more setupst to be displayed
        // at these fitler/sort/search values
        if (newSetups.length === setupsState.numOfSetupsDisplayed) {
          setupsStateDispatch({
            type: "NO_MORE_SETUPS"
          })
        // There are still more setups, so set and display them
        } else {
          setupsStateDispatch({
            type: "SET_SETUPS",
            payload: newSetups
          });
        }
  
      }
      
      getNewSetups();
    }

  }, [setupsState.needsMoreSetups]);


  // Hook when setupID changes such that a new setup is added
  useEffect(() => {
    // console.log("Getting more")

    // Make sure the new ID isn't the same one being passed in, the newID should
    // be of the recently added setup
    if (setupsState.searchID !== searchID 
      && setupsState.searchID != initialSetupsState.searchID) {

      // console.log("Added, getting new setups")

      // Fetch new setups, 
      async function getNewSetups () {
        // Tell backend which setups to grab based on the currently selected filters
        // and other setupsState stuff
        const newSetups = await fetchSetups({
          searchID: setupsState.searchID,

          needsMoreSetups: false,
          numOfSetupsDisplayed: setupsState.numOfSetupsDisplayed,
  
          filters: setupsState.filters,
          sort: setupsState.sort
        });

        setupsStateDispatch({
          type: "SET_SETUPS",
          payload: newSetups
        });
  
      }
      
      getNewSetups();
    }
  }, [setupsState.searchID]);


  // Hook when filters or sort change
  useEffect(() => {
    // Fetch new setups if there are new filters
    // Don't apply filter stuff during initialization, don't want to spam backend
    // with unnecessary requests 
    // filters is init to false, so if it's an object or anything, it's legit
    if ((setupsState.newFilters && !!setupsState.filters)

      // Fetch new setups when a new sort is applied
      // Make sure sort has actually been set, this hook can be called during init
      // and I don't want the below code to execute during init 
      || !_.isEqual(setupsState.sort, initialSetupsState.sort)) {

      // Fetch new setups, async
      async function getNewSetups () {
        // Tell backend which setups to grab based on the currently selected filters
        // and other setupsState stuff
        const newSetups = await fetchSetups({
          needsMoreSetups: false,
          numOfSetupsDisplayed: setupsState.numOfSetupsDisplayed,
  
          filters: setupsState.filters,
          sort: setupsState.sort
        });

        setupsStateDispatch({
          type: "SET_SETUPS",
          payload: newSetups
        });
  
      }
      
      getNewSetups();
    }
  }, [setupsState.newFilters, setupsState.sort]);


  // Get more setups to display after done scrolling through all displayed setups
  function getMoreSetups() {
    setupsStateDispatch({type: "GET_MORE_SETUPS"})
  }

  // Callback when a new setup is submitted
  // Need to re-fetch data with this new setup as the search ID so it gets 
  // scrolled to. That means also need to re-load setups.
  function onNewSetupSubmit(newSetup) {
    setupsStateDispatch({
      type: "ADD_NEW_SETUP",
      payload: newSetup.id
    });

  }


  // Callback when filters are applied (cleared or applied)
  // Set filters in state, then do a backend request with the filters
  function onFilterApply(newFilters) {
    setupsStateDispatch({
      type: "SET_FILTERS",
      payload: newFilters
    });

  }


  // Callback when sorts are applied
  function onSortApply(newSort) {
    const encodedSort = newSort.value;

    // Decode encodedSort to find what to sort and in what direction
    const toSort =  encodedSort.substr(0, encodedSort.indexOf(':')); 
    const sortDirection = encodedSort.substr(encodedSort.indexOf(':') + 1, encodedSort.length); 

    // Sort direction for query should be 1 (ascending) or -1 (descending)
    const sortDirectionForQuery = sortDirection[0] === "h" ? -1 : 1;

    // console.log(`${toSort}: ${sortDirection}`)

    let sortQuery = {};

    switch(toSort) {
      case "date":
        sortQuery = { id: sortDirectionForQuery }
        break;

      case "alphabetically":
        sortQuery = { setupName: sortDirectionForQuery * -1 }
        break;

      case "top":
        sortQuery = { "helperValues.popularity": -1 }
        break

      case "bottom":
        sortQuery = { "helperValues.popularity": 1 }
        break
      
      case "price":
        sortQuery = { "helperValues.priceIndex": sortDirectionForQuery }
        break

      case "muzzleVelocity":
        sortQuery = { "helperValues.fpsIndex": sortDirectionForQuery }
        break

      case "rateOfFire":
      sortQuery = { "helperValues.rateOfFireIndex": sortDirectionForQuery }
      break

    }

    setupsStateDispatch({
      type: "SET_SORT",
      payload: sortQuery
    });


  }


  // Callback when finished scrolling to a setup
  // Clear state.searchID so future actions don't get messed up by the search ID
  function finishScrollToSetup() {
    // console.log("Finish scroll")
    setupsStateDispatch({
      type: "CLEAR_SEARCH_ID"
    });
    
  }




  return [
    // Big values
    setupsState.setups,
    setupsState.searchID,
        
    // Functions
    getMoreSetups,
    onNewSetupSubmit,
    onFilterApply,
    onSortApply,
    finishScrollToSetup,

    // Flags
    setupsState.areSetupsLoading,
    setupsState.hasMoreSetups,

  ]
}

export default useSetups;
import React, { Component } from 'react';

import DataDependantComponent from '../DataDependantComponent';

import Helper from "Helper";

import './index.scss';

let helper = new Helper();

export default class Changelog extends DataDependantComponent {
	renderChildComponent () {
		return (
      <div id="Changelog">
	    	<h1>Changelog</h1>
	    	<p>Log of all major changes and updates to Suild.com.</p>
	    	<p>If you are to stumble upon this, you may realize that the most recent changes may not been deployed yet. Those are features in development that have not been pushed to production yet. Enjoy!</p>
	    	<div id="changelog-items" className="center-div">
	    		{this.renderChangelogItems()}
	    	</div>
      </div>
		);
	}

	renderChangelogItems() {
		return (
			//data needs to be iterated backwards because latest should be displayed first, but the latest is last in the arr
			this.state.DATA.slice(0).reverse().map((change) => {
				return (
					<div className="change-container flex-container-center">
						<div className="change-date">
							<p className="change-date-txt">{change.date}</p>
						</div>
						<div className="change-description">
							{this.renderChangeCategory("Changed", change.changed)}
							{this.renderChangeCategory("Added", change.added)}
							{this.renderChangeCategory("Removed", change.removed)}
						</div>
					</div>
				)
			})	
		)
	}

	//change category is arr of strings that pertain to a specific type of change
	renderChangeCategory(title, changeCategory) {
		if (changeCategory.length > 0) {		//make sure changeCategory isn't empty
			return (
				<div className="change-category-container">
					<h3 className="change-category-title">{title}</h3>
					{helper.renderList(changeCategory)}
				</div>
			) 
		}
	}


}

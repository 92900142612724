import React, { Component } from 'react';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';

const FAQs = props => {
	// Only render FAQs like this if they exit
	if (!!props.data) {
		// Map all items in the data, each will be rendered with react-accessible-accordion
		const accordionFAQItems = props.data.items.map(faqItem => {
			return (
				<AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              <p className={"inline-p"}>{faqItem.text}</p>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p>{faqItem.listItem}</p>
          </AccordionItemPanel>
      	</AccordionItem>
			)
		});

		return (
			<div id="FAQs">
				<Accordion 
					allowZeroExpanded={true}
					allowMultipleExpanded={true}>
					{accordionFAQItems}
				</Accordion>
				
			</div>
		)
	}

	return null
}	

export default FAQs;
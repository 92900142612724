import React, {Component} from 'react';

import './index.scss';

export default class HamburgerIcon extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isClicked: false
		}
	}

	render () {
		return (
			<div id={this.props.id} className={"HamburgerIcon flex-container-fully-centered " + (this.props.animateOnClick && this.state.isClicked ? "animate-hamburger-icon " : "" ) + this.props.className}
				onClick={e => {
					this.setState({isClicked: !this.state.isClicked})}}>
				<div>
					<div className="top-hamburger-icon-bar hamburger-icon-bar"></div>
					<div className="middle-hamburger-icon-bar hamburger-icon-bar"></div>
					<div className="bottom-hamburger-icon-bar hamburger-icon-bar"></div>
				</div>
				
			</div>
		)
	}
}
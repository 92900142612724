import React, { Component } from 'react';
import './index.css';

export default class Loading extends Component {
  render () {
    return (
      <div id="Loading">
      	<div id="loading-container">
      		<div className="loader"></div>
        	<h1 className="center-text">Loading</h1>
      	</div>
      </div>
    )
  }

}
import React from 'react';
import Select from 'react-select';

const solenoidEntry = props => {
  const { solenoid,
    onChange,
    SOLENOIDS,
    generateOptionForReactSelect,
    errorText,
    styles } = props;

  return (
    <div>
      <Select 
        styles={styles}
        placeholder={"Solenoid *"}
        value={ 
          !!solenoid.value && generateOptionForReactSelect(solenoid.value)
        }
        options={
          SOLENOIDS.map(solenoid => {
            return generateOptionForReactSelect(solenoid) 
          })
        }
        onChange={selectedSolenoid => {
          onChange({
            pusher: { 
              solenoid: { 
                value: {$set: selectedSolenoid.value},
                isNew: {$set: !!selectedSolenoid.__isNew__}
              }
            }
          })
        }}
      />

      <p className="setup-entry-help">
        Please select a solenoid only if your blaster is fully-automatic and your blaster uses a solenoid for the pusher. If your blaster uses a pusher motor, you'll need to select an appropriate pusher mechanism above.
      </p>

      <p className="center-text error-text">{errorText}</p>
    </div>
  )
}

export default solenoidEntry;
import React, { Component } from 'react';

import Image from "../../../Functional/Image";
import NominalSpecs from '../NominalSpecs';

import Helper from "Helper";
import MotorHelper from "MotorHelper";

import './index.scss';

let helper = new Helper();
let motorHelper = new MotorHelper();

export default class MotorSelector extends Component {
  render () {  
    return (
      <div id="MotorSelector" className="flex-container-center">
        {this.props.DATA.map(this.renderSelectableMotorBoxes.bind(this))}
      </div>
    )
  }

  renderSelectableMotorBoxes(motorData) {
    return (
      <div className={(motorData.isSelected ? "selected-" : "unselected-") + "selectable-motor-box"}
        onClick={(e) => this.props.onClick(e, motorData)}>
        <div className="selectable-motor-box-fade-overlay">
          <Image 
            className="selectable-motor-box-img"
            src={motorData.imgSrc}/>
          <NominalSpecs 
            motorData={motorData}
            containerClassName="selectable-motor-box-overlay-container"
            titleClassName="selectable-motor-box-overlay-title"
            textClassName="selectable-motor-box-overlay-txt"
            toRenderCostText={false}/>
        </div>
        
        <p className="selectable-motor-box-name center-text">{motorData.name}</p>
        <p className="selectable-motor-box-cost center-text">{"avg. $" + motorData.cost}</p>
      </div>
    )
  }

}
import React from 'react';
import { Share } from '@material-ui/icons';
import { Tooltip, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';


import copy from 'copy-to-clipboard';

import CONSTANTS from 'CONSTANTS';


const ShareIcon = props => {
  const { id } = props;

  const [ isSnackbarOpen, setIsSnackbarOpen ] = React.useState(false);

  const constants = new CONSTANTS();
  const URL = constants.getURL()

  return (
    <div className="item flex-container-center">
      <Tooltip className="pointer-on-hover"
        title={"Share"} 
        placement="top"

        onClick={e => {
          setIsSnackbarOpen(true)
          copy(`${URL}/tools/flywheel-setup-picker/${id}`)
        }}
      >
        <Share fontSize="small"/>
      </Tooltip>

      <Snackbar className="pointer-on-hover"
        open={isSnackbarOpen} 
        onClose={() => setIsSnackbarOpen(false)}
        autoHideDuration={6000}
        onClick={() => setIsSnackbarOpen(false)}
      >
        <Alert severity="success">
          <p style={{margin: 0}}>Copied to clipboard!</p>
        </Alert>
      </Snackbar>
    </div>
    
  )
}

export default ShareIcon;
// Function using react hooks to grab data and set it to state 

import { useState, useEffect } from 'react';

import BackendDataRetreiver from 'BackendDataRetreiver';
import Constants from 'CONSTANTS';

// Function that retrieves data from backend and sets the data into the state
// using hook useState
// 
// Returns retrieved data and isLoading flag in an array 
// 
// Params:
//  - API_LINK_KEY (string): Name of API link key that maps to actual API link
//      in Constants
function useDataFromBackend (API_LINK_KEY) {
  const [ data, setData ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(true);

  // Returns documents from the collection. If the collection has one document, 
  // the document (object) is returned. If the collection has more than one 
  // document, an array containing all documents is returned
  // Useful because when the collection is retrieved, an array is always returned,
  // even if the collection only has one document. 


  // Takes in retrieved data and returns an object or array of the data 
  // accordingly
  // There are a few different cases of data:
  //  - Array:
  //      - Collection with multple documents, returns array full of all documents
  //      - Collection with single document, returns object of single document
  //  - Object:
  //      - A single document that wasn't returned as part of a collection, 
  //        returns just that object
  let validateRetrievedData = (retrievedData) => {
    if (retrievedData.length !== undefined) {   // If data is an array
      if (retrievedData.length === 1) {
        // Retrieved collection only has one document, so return that document 
        // itself
        // On the db side, I embedded all data in the 'data' prop of the 
        // collection
        return retrievedData[0].data;
      } else {
        // Retrieved collection is an array with multiple documents
        return retrievedData.map(retrievedDocument => {
          return retrievedDocument;
        })
      }
    } else {  // Data is an object 
      return retrievedData;
    } 


  }

  useEffect(() => {
    const retrieveData = async() => {
      const retrievedData = await new BackendDataRetreiver()
        .retreiveData(new Constants().getAPILink(API_LINK_KEY));

      // console.log(retrievedData)

      setData(validateRetrievedData(retrievedData))
      setIsLoading(false);
      
    }

    retrieveData();
  }, []);

  return [
    data,
    isLoading
  ]

}

export default useDataFromBackend;
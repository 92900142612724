import React from 'react';
import DropdownFilter from './DropdownFilter';

import Helper from 'Helper';


const helper = new Helper();


const FlywheelCageFilter = props => {
  const { FLYWHEEL_CAGES, filter, onFilterChange } = props;

  return (
    <DropdownFilter
      title={"Flywheel Cages"}
      placeholder={"Flywheel Cages"}
      filter={filter}

      SELECTIONS={FLYWHEEL_CAGES.map(flywheelCage => {
        return helper.generateOptionForReactSelect(flywheelCage) 
      })}

      onChange={selectedOptions => onFilterChange("flywheelCage", selectedOptions)}

    />

  )
}

export default FlywheelCageFilter;
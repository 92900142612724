import React from 'react';

import MotorHelper from 'MotorHelper';

let motorHelper = new MotorHelper();

const tooltip = props => {
	const { active } = props;

	if (active) {
		// data about point from recharts
		const payload = props.payload[0];

		const motorData = payload.payload;

		return (
			<div className="motor-info-tab-tooltip">
				<p>{motorData.battery + "S, " + motorHelper.getNominalVoltageFromCellCount(motorData.battery) + "V"}</p>
				<li>{"Speed: " + motorData.speed + " RPM"}</li>
				<li>{"Torque: " + motorData.torque + " gf.ncm"}</li>
				<li>{"Stall Current: " + motorData.stallCurrent + "A"}</li>
			</div>
		)
	}

	return null;

}

export default tooltip;
import React, { Component } from 'react';

// import TextField from 'material-ui/TextField';
// import { TextField } from 'material-ui/TextField';
import { ClickAwayListener, TextField } from '@material-ui/core';

// Custom components
import EntryForm from './EntryForm';
import SetupDisplay from '../SetupDisplay';

// External utilities/helpers
import _ from 'lodash';

// Custom utilities/helpers
import Helper from 'Helper';
import BackendDataSender from 'BackendDataSender';
import formatEntryFormContent from './formatEntryForm';


import './index.scss';


let helper = new Helper();
let backendDataSender = new BackendDataSender();

const FLYWHEEL_SETUP_PICKER_POST_URL = "submit-flywheel-setup";

export default class SetupEntry extends Component {
	constructor(props) {
		super(props);

		this.FLYWHEEL_SETUP_PICKER_METADATA = {}

		this.state = {
			isSetupEntryExpanded: false,
			entryFormContent: {},
			isFormValid: false
		}
	}


	render() {
		return (
			<ClickAwayListener 
				onClickAway={() => {
					this.setState({isSetupEntryExpanded: false})
				}}>

				<div id="SetupEntry">

					{/* Header */}
					<div id="expanded-setup-entry-form-header" 
						className={this.state.isSetupEntryExpanded ? "visible" : "gone"}>
						<h1 className="center-text">Create Your Setup</h1>
					</div>

					{/* Create a Setup box */}
					<form id="setup-entry-form" noValidate autoComplete="off">
						{ !this.state.isSetupEntryExpanded && 
							<TextField 
								className="outlined-basic setup-entry-text-field" 
								label={!!this.state.entryFormContent.setupName && this.state.entryFormContent.setupName.value.length > 0 ? "Contine Your Setup" : "Add Your Setup"} 
								variant="outlined" 
								required={true}
								onFocus={() => this.setState({isSetupEntryExpanded: true})}
							/>
						}

						<EntryForm
							isSetupEntryExpanded={this.state.isSetupEntryExpanded}
							motorHelper={this.props.motorHelper}
							onFormUpdate={this.onFormUpdate.bind(this)}
							onSubmit={this.submitFlywheelSetupForm.bind(this)}
							setFlywheelSetupPickerMetadata={this.setFlywheelSetupPickerMetadata.bind(this)}
						>
					
							{/* Preview */}
							{	this.state.isFormValid && 	// Only render preview if form is valid
								!!this.state.entryFormContent.setup && // And entry form actually has content
								<div className="setup-entry-form-section">
									<h2>Preview</h2>
									<p>Preview updates in real-time</p>
									<SetupDisplay
										motorHelper={this.props.motorHelper}
										setupData={formatEntryFormContent(
											this.state.entryFormContent, 
											this.props.motorHelper, 
											this.FLYWHEEL_SETUP_PICKER_METADATA)
										}
										isPreviewMode={true}
										rootClassName="flex-container-center"
									/>
								</div>
							}

						</EntryForm>

					</form>
				</div>

			</ClickAwayListener>
    )

	}

	// Set Flywheel setup picker metadata in this component to be used in 
	// formatFlywheelSetupData
	// FLYWHEEL_SETUP_PICKER_METADATA is fetched and used in EntryForm component, 
	// so once that's received, set it here
	setFlywheelSetupPickerMetadata(FLYWHEEL_SETUP_PICKER_METADATA) {
		this.FLYWHEEL_SETUP_PICKER_METADATA = FLYWHEEL_SETUP_PICKER_METADATA;
	}

	// Callback when the form updates
	// Set state to form content
	onFormUpdate(newForm, isFormValid) {
		this.setState({
			entryFormContent: newForm,
			isFormValid: isFormValid});
	}

	// Handles submitting flywheel setup form , executed as callback from submit btn
	// Validates form items, if form items pass validation, send the form to backend
	async submitFlywheelSetupForm() {
		// If form items fail validation, don't continue with submitting the form
		if (!this.state.isFormValid) {
			return;
		}	

		const finalizedSetupForm = helper.formNormalizer(
			formatEntryFormContent(
				this.state.entryFormContent, 
				this.props.motorHelper,
				this.FLYWHEEL_SETUP_PICKER_METADATA
			)
		);

		// Send setup to backend
		const res = await backendDataSender.sendData(FLYWHEEL_SETUP_PICKER_POST_URL, finalizedSetupForm);


		// Wait until setup saved in db to collapse entry form and execute callback
		if (res.isSuccessfullySubmitted) {
			// Collapse entry form and clear entry form
			this.setState({
				isSetupEntryExpanded: false,
				entryFormContent: {}
			});

			// Format and send to parent 
			this.props.addSetup(finalizedSetupForm);
		}

		
	}


}
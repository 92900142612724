import React from 'react';

import './index.scss';

const Sidebar = props => {
	return (
		<div id={props.id} className={"Sidebar " + props.className}>
			{props.children}
		</div>
	)
}

export default Sidebar;
import React, { Component } from 'react';
import Slider, {Range} from 'rc-slider';
import Tooltip from 'rc-tooltip';

import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';

import './index.css';

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Handle = Slider.Handle;

export default class SliderWithTooltipAndText extends Component {
	render () {
		return (
      <div className={"SliderWithTooltipAndText center-div " + this.props.containerClassName}> 
        <h3 className={"center-text " + this.props.titleClassName}>{this.props.title}</h3>
        <p className={"center-text " + this.props.descriptionClassName}>{this.props.description}</p>
        <p className={"center-text slider-val-text " + this.props.sliderValClassName}>{this.props.sliderPos + this.props.sliderValSuffix}</p>
        <Slider 
          min={this.props.min} 
          max={this.props.max} 
          step={this.props.step} 
          handle={handle} 
          defaultValue={this.props.defaultValue}
          value={this.props.value}
          marks={this.props.marks}
          onChange={this.props.onChange}/>
      </div>
		)
	}
}

const handle = (props) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <Tooltip
      prefixCls="rc-slider-tooltip"
      overlay={value}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Handle value={value} {...restProps} />
    </Tooltip>
  );
};


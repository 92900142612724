import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import './index.css';

export default class PaymentSuccess extends Component {
  render () {
    return (
      <div id="PaymentSuccess">
        <h1>Thank you for your purchase!</h1>
        <p className="payment-success-text">A confirmation email has just been sent to your provided email address.</p>
        <h3><Link to="/" className="button-style-1">Go Home</Link></h3>
      </div>
    )
  }

}
import React from 'react';
import { Collapse } from '@material-ui/core';

import PusherDisplay from './PusherDisplay';


const ExpandedSetup = props => {
  const { SETUP_DATA, motorHelper } = props;

  const [ areAdditionalSetupNotesExpanded, 
    setAreAdditionalSetupNotesExpanded ] = React.useState(true);


  return (
    <div className="ExpandedSetup">
      {/* Pusher Info */}
      {/* Only render if full auto */} 
      { SETUP_DATA.isFullAuto &&
        <PusherDisplay
          SETUP_DATA={SETUP_DATA}
          motorHelper={motorHelper}
        />
      }

      {/* Additional setup notes */} 
      { SETUP_DATA.additionalSetupNotes.length > 0 &&
        <div className="setup-display-card-body-section grey-border-bottom" >
          <h3 className="pointer-on-hover"
            onClick={e => setAreAdditionalSetupNotesExpanded(prev => !prev)}
          >
            Setup Notes
          </h3>
          <Collapse in={areAdditionalSetupNotesExpanded}>
            <p className="text-with-newlines" style={{marginTop: 0}}>
              { SETUP_DATA.additionalSetupNotes }
            </p>
          </Collapse>
        </div>
      }

    </div>
  )
}

export default ExpandedSetup;
import React from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';

const MicroFlywheelEntry = props => {
  const { microFlywheelInForm, 
    errorText,
    updateFlywheelSetupForm,
    getUpdatedSetup } = props;
  
  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox 
            checked={microFlywheelInForm.value}
            color="primary"
            onChange={event => {
              let updatedSetup = getUpdatedSetup(
                { isMicroFlywheelSetup: { 
                  value: { 
                    $set: event.target.checked } } 
                }
              );

              updateFlywheelSetupForm(updatedSetup);
            }} 
          />
        }
        classes={{
          root: "select-entry",
          label: "setup-entry-checkbox-label"
        }}
        label="Does this stage use micro-flywheels?"
      />

      <p className="setup-entry-help">
        If this flywheel stage uses micro-wheels (FTW, Night Owl, Hurricane, etc.), please check this checkbox!
      </p>

      <p className="center-text error-text">{errorText}</p>
    </div>
  )

}

export default MicroFlywheelEntry;
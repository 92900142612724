import React from 'react';
import DropdownFilter from './DropdownFilter';

import Helper from 'Helper';


const helper = new Helper();


const PusherMotorVoltingFilter = props => {
  const { filter, onFilterChange } = props;

  return (
    <DropdownFilter
      title={"Pusher Motor Volting"}
      placeholder={"Pusher Motor Volting"}
      filter={filter}

      SELECTIONS={["Undervolted", "Nominal", "Overvolted"].map(volting => {
          return helper.generateOptionForReactSelect(volting) 
        })
      }

      onChange={selectedOptions => onFilterChange("pusherMotorVolting", selectedOptions)}

    />
  )
}

export default PusherMotorVoltingFilter;
import React from 'react';
import DropdownFilter from './DropdownFilter';

import Helper from 'Helper';


const helper = new Helper();


const FlywheelCageMaterialFilter = props => {
  const { FLYWHEEL_CAGE_MATERIALS, filter, onFilterChange } = props;
  
  return (
    <DropdownFilter
      title={"Flywheel Cage Materials"}
      placeholder={"Flywheel Cage Materials"}
      filter={filter}

      SELECTIONS={FLYWHEEL_CAGE_MATERIALS.map(flywheelCageMaterial => {
        return helper.generateOptionForReactSelect(flywheelCageMaterial) 
      })}

      onChange={selectedOptions => onFilterChange("flywheelCageMaterial", selectedOptions)}
    />

  )
}

export default FlywheelCageMaterialFilter;
import React, { Component } from 'react';
import Select from 'react-select';

import _ from 'lodash';

import MotorHelper from "MotorHelper";
import Helper from 'Helper';



import './index.scss';



let motorHelper = new MotorHelper();
const helper = new Helper();



const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

const formatGroupLabel = data => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

export default class DropdownLoadSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedMotor: ""
    }
    
  }

  componentWillMount() {
    motorHelper.setData(this.props.ALL_MOTOR_DATA);

    this.setState({
      ALL_MOTOR_DATA: this.props.ALL_MOTOR_DATA 
    });
  }

	render() {
    if (!this.props.isMulti) {
      return (
        <div id="DropdownLoadSelector"> 
          {this.props.children}
          <Select
            className={this.props.className + " motor-selector-dropdown"}
            options={this.generateMotorSelectionsForSelect()}
            placeholder={this.props.placeholder}
            onChange={selectedOption => this.props.onChange(selectedOption)}
            formatGroupLabel={formatGroupLabel}
            value={this.props.value}
            isClearable={this.props.isClearable}
            styles={this.props.styles}
          />
        </div>
      )
    } else if (this.props.isMulti) {
      return (
        <div id="DropdownLoadSelector"> 
          {this.props.children}
          <Select
            className={this.props.className + " motor-selector-dropdown"}
            
            placeholder={this.props.placeholder}
            formatGroupLabel={formatGroupLabel}
            closeMenuOnSelect={false}
            isClearable={this.props.isClearable}

            options={this.generateMotorSelectionsForSelect()}
            onChange={this.props.onChange}
            value={!!this.props.values && 
              this.props.values.map(value => {
                return helper.generateOptionForReactSelect(value) 
              })
            }
            styles={this.props.styles}

            isMulti
          />
        </div>
      )
    }
    
  }

  // Generate motor selections that will be rendered as dropdown items in react-select
  // These need to be specially formatted because they'll be rendered as grouped elements
  // in dropdown
  generateMotorSelectionsForSelect() {
    // Use this.generateMotorSelectionFor1CellCountForSelect() to generate data for 1 cell count
    // at a time x2
    return [
      this.generateMotorSelectionFor1CellCountForSelect(2, "2S Nominal"),
      this.generateMotorSelectionFor1CellCountForSelect(3, "3S Nominal")
    ];
  }

  // Generates motor selections for 1 cell count that's in proper format for react-select
  // Meant to be executed multiple times to get formatted data for all motor cell counts
  generateMotorSelectionFor1CellCountForSelect(cellCount, label) {
    // Option data
    let options = motorHelper.findAllMotorsOfSpecificBattery(cellCount).map(
      motorData => {
        return {
          value: motorData.name,
          label: motorData.name
        }
      });

    // Add additional loads that aren't in the motor db
    // additional loads, like motors, should be added based on their nominal cell count
    _.map(this.props.additionalLoads, additionalLoad => {
      if (motorHelper.isCellCountNominal(cellCount, additionalLoad)) {
        options.push({
          value: additionalLoad.name,
          label: additionalLoad.name
        });
      }
    });

    // abc items in options[] (which will be motor and/or load data to be rendered in
    // react-select) in abc order
    const optionsInABCOrder = options.sort(
      (a, b) => {
        if(a.value < b.value) { return -1; }
        if(a.value > b.value) { return 1; }
        return 0;
      });

    // Group + option data in same obj
    return {
      label: label,
      options: optionsInABCOrder
    };
  }

}

//prototype class for components that require data from db
//
//any component that needs data from db will set the state of App upon receiving data
//this feature is built in: set state of app upon data receive
// 
//any component will also render Loading compoent until data has been retreived 
//when data has been retreived, it will render from renderChildComponent
//renderChildComponent should be added upon in child component that inherets from this class

import React, { Component } from 'react';

import BackendDataRetreiver from 'BackendDataRetreiver';

import Loading from '../Loading';

export default class DataDependantComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			DATA: []
		}
	}

	componentWillMount() {
		//if data already passed in from App, don't need to retreive it again
		if (!!this.props.data) {
			this.setState({
				isLoading: false,
				DATA: this.props.data
			}, this.afterDataRetrieved);

			return
		}

		//retrieve data from DB, set it to this state, then send it to App state
    this.retreiveDataFromDB(this.props.collectionName);

	}

	render() {
		if (!!this.state.isLoading) {    //if loading
      return (
        <Loading/>
      )
    } else {        
      return this.renderChildComponent();
    }
	}

	retreiveDataFromDB(collection, callback, context) {
    new Promise((rs, rj) => {
      rs(new BackendDataRetreiver().retreiveData(collection));
    })
    .then((retreivedData) => {
			let newData = retreivedData[0].data;
			
      //set state after reteiving data
      this.setState({
        isLoading: false,
        "DATA": newData}, 
        () => {		//callback after setting state
        	this.afterDataRetrieved();

        	//set state of App with new data
        	this.props.setDataToState(newData, this.props.collectionName.replace("-data", ""));

        	//callback if passed in
        	if (!!callback && !!context && !!context.callback) {
        		context.call(callback(newData, context));
        	} else if (!!callback) {	
        		callback(newData);
        	}
        }	
      );		//setState
    });
  }

  //should be overwritten/whatever when used and needed
	afterDataRetrieved() {
		if (this.props.isItemComponent) {
			this.findDataOfItemAfterDataRetrieved();
		}
	}

	//often useful for details where data needs to be filtered to find data of specific item
	//should only be used after data just retrieved
	findDataOfItemAfterDataRetrieved() {
		//when data retrieved from db and data of specific item not set yet
    //data of item will be stored in state
    if (!!this.state.DATA && !!!this.state.itemData) {   //data retreived from db, it's being stored in state
    	this.findDataOfItem();
    }
	}

	// find data of item based on link, will be updated in state as itemData
	findDataOfItem() {
    const link = "/" + this.props.match.params.id;
    const itemData = (this.state.DATA.filter(data => {
      if (link === "/" + data.key) {
        return data;
      }
    }))[0];

    this.afterItemDataRetrieved(itemData);
	}

	//called after item data (often used in detailed components) filtered from backend data. 
	//by default, it sets itemData to state
	//meant to be overwritten/whatever
	afterItemDataRetrieved(itemData) {
		this.setState({itemData: itemData});
	}

	//default renderChildComponet in case child component doesn't have one
	renderChildComponent() {
		//if child component is a detail component, render loading until detail data has been retrieved
		if (!!this.state.itemData) {		//itemData exists
			return this.renderDetailChildComponent();
		} else if (!this.state.itemData) {
			return <Loading/>
		}

		return this.oops();
	}

	//default method for rendering child components that happen to be details
	renderDetailChildComponent() {
		return this.oops(); 
	}

	renderOops() {
		return <div>Oops</div>
		
	}

}
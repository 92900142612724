import React, { Component } from 'react';

import './index.css';

export default class RadioBtns extends Component {
	constructor(props) {
		super(props);

		this.state = {
			// Selected option is a string that corresponds to a radio btn
			selectedOption: this.props.initiallySelectedOption || this.props.selectedOption
		}
	}

	render() {
		return (
			<div id={this.props.containerId} className={this.props.containerClassName}>
				<h3 id={this.props.titleId} className={this.props.titleClassName}>{this.props.title}</h3>
				<form>
					{	// Render multiple instances of RadioBtn by mapping radioBtnsData props 
						this.props.radioBtnsData.map(
							radioBtnData => {
								return (
									<RadioBtn 
										selectedOption={this.state.selectedOption}
										toReturn={radioBtnData.toReturn}
										title={radioBtnData.title}
										titleId={radioBtnData.titleId}
										titleClassName={radioBtnData.titleClassName}
										label={radioBtnData.label}
										labelId={radioBtnData.labelId}
										labelClassName={radioBtnData.labelClassName}
										handleChange={(e, label, toReturn) => this.handleChange(e, label, toReturn)}
										handleClick={(e, label, toReturn) => this.handleClick(e, label, toReturn)}/>
							)}
					)}
				</form>
			</div>
		)
	}

	// Callback when radio btns change
	// toReturn is value that's returned in callback to parent
	handleChange (e, label, toReturn) {
		// Set new selected radio btn, pass this via props callback
		this.setState({selectedOption: e.target.value}, () => {
			if (!!this.props.handleChange){
				this.props.handleChange(e, this.state.selectedOption, label, toReturn);
			}
		});
	}

	// Callback when a radio btn is clikced
	// toReturn is value that's returned in callback to parent
	handleClick (e, label, toReturn) {
		// if the clicked radiobtn was already selected
		if (this.state.selectedOption === e.target.value) {
			// only deselection if it's allowed by props
			if (this.props.allowDeselection) {
				// Deselect the radio btn by setting selectedOption to an empty string, pass this via props callback 
				this.setState({selectedOption: ""}, () => {
					if (!!this.props.handleChange){
						this.props.handleChange(e, this.state.selectedOption, label, toReturn);
					}
				});
			}
			
		}
	}


}


const RadioBtn = props => {
	return (
		<div>
			<p id={props.titleId} className={props.titleClassName}>{props.title}</p>
      <label className="radio-btn-container">
        <input
          type="radio"
          name="radio"
          checked={props.selectedOption === props.label}
          value={props.label}
          onChange={e => props.handleChange(e, props.label, props.toReturn)}
          onClick={e => props.handleClick(e, props.label, props.toReturn)}
        />
        <span class="radio-btn-checkmark"></span>
        <p id={props.labelId} className={props.labelClassName}>{props.label}</p>
      </label>
		</div>
	)
}
import React from 'react';

import Header from './Header';
import Body from './Body';
import Footer from './Footer';


// Utilities
import numberToWords from 'number-to-words';


// Can't import toWorks directly from number-to-words module
let toWords = numberToWords.toWords;

const Display = props => {
  const { setupData, 
    motorHelper, 

    isModal,
    isPreviewMode,

    isFullscreen, 
    setIsFullscreen, 

    containerClassName,
    containerStyle,

    onCommentSubmit,
    onVote } = props;

  let cellCountInWords = toWords(setupData.cellCount);


  return (
    <div id={`setup-${setupData.id}`}
      style={{
        ...containerStyle
      }}
      className={`SetupDisplay-card ${cellCountInWords}-s-border-color ${containerClassName}`}>
      <Header
        setupData={setupData}
        cellCountInWords={cellCountInWords}
      />

      <Body
        setupData={setupData}
        cellCountInWords={cellCountInWords}
        motorHelper={motorHelper}
      />
            
      <Footer
        setupData={setupData}

        isModal={isModal}
        isPreviewMode={isPreviewMode}

        isFullscreen={isFullscreen}
        setIsFullscreen={setIsFullscreen}

        onCommentSubmit={onCommentSubmit}
        onVote={onVote}
      />

    </div>
  )
}

export default Display;
import React from 'react';

import { TextareaAutosize, Button, Collapse } from '@material-ui/core';

import CollapsibleTextTrigger from '../../../../../../Functional/CollapsibleTextTrigger';
import EntryItem from './EntryItem';


// Custom utilities/helpers
import Helper from 'Helper';
import BackendDataSender from 'BackendDataSender';

import useFormHelper from '../../../../../../../Hooks/useFormHelper';

import validateCommentForm from './validateCommentForm';





let helper = new Helper();
let backendDataSender = new BackendDataSender();

const FLYWHEEL_SETUP_PICKER_COMMENT_URL = "submit-flywheel-setup-comment";


const Entry = props => {
  const { setupData, onCommentSubmit, isPreviewMode } = props;


  const initialCommentState = {
    setupId: setupData.id,
    username: "",
    email: "",
    comment: ""
  }

  const [ comment, 
    updateForm, 
    errors, 
    isFormValid,
    handleSubmit,
    isSubmitting
  ] = useFormHelper(
    initialCommentState, 
    () => {}, 
    validateCommentForm,
    () => {},
    onSuccessfulFormSubmit
  );

  const [ isExpanded, setIsExpanded ] = React.useState(true);

  // console.log(errors)
  // console.log(hasAttemptedSubmit)

 
  return (
    <div className="comment-entry">

      <CollapsibleTextTrigger
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
      >
        <h3 className="inline-txt">Enter a Comment</h3>
      </CollapsibleTextTrigger>
      

      <Collapse in={isExpanded}>
        <TextareaAutosize 
          className="outlined-basic" 
          label="Add a comment"
          placeholder="Add a comment" 
          variant="outlined"
          required={false}
          label="Add a comment"
          multiline
          rowsMin={5}
          style={{ width: "100%" }}
          value={comment.comment}
          onChange={(e) => {
            updateForm({
              ...comment,
              comment: e.target.value})
          }}
        /> 

        <p className="center-text error-text">{errors.comment}</p>

        
        <EntryItem
          value={comment.username}
          placeholder="Username *"
          updateForm={(newUsername) => {
            updateForm({
              ...comment,
              username: newUsername})
          }}
          infoText="Enter the username that this setup will be displayed under. It  can be your YouTube channel, Reddit name, anything! If you posted a setup, you can use that same username. If you entered an email with that setup, you'll also need to enter that same email here."
          hasAttemptedSubmit={true}
          errorText={errors.username}
        />

        <EntryItem
          value={comment.email}
          placeholder="Email"
          updateForm={(newEmail) => {
            updateForm({
              ...comment,
              email: newEmail})
          }}
          infoText="Only used for username verification. If someone tries to steal your username to enter a setup or comment, they'll need to enter your email correctly too! Feel free to leave this blank if you're not worried about username sharing."
          hasAttemptedSubmit={true}
          errorText={errors.email}
        />


        <p className="error-text center-text">
          {generateAllErrorText()}
        </p>

        {/* Submit button */}
        <div className="flex-container-right">
          <Button 
            className={"comment-submit-btn " + (isSubmitting ? "": "add-btn-color")} 
            variant="contained" 
            size="medium"
            onClick={e => handleSubmit()}
            disabled={isSubmitting}
          >
              Submit Comment
          </Button>
        </div>
      </Collapse>
      
      


    </div>
  )

  function onSuccessfulFormSubmit() {
    const normalizedComment = helper.formNormalizer(comment);

    onCommentSubmit(normalizedComment);

    // Only send comment to backend if not in preview mode
    if (!isPreviewMode) {
      backendDataSender.sendData(FLYWHEEL_SETUP_PICKER_COMMENT_URL, normalizedComment);
    }

  }

  function generateAllErrorText() {
    let allErrorText = [];

    for (let [key, value] of Object.entries(errors)) {
      allErrorText.push(value);
    }

    return helper.getArrayAsTextList(
      helper.removeUndefinedItemsFromArr(allErrorText));
  }

  
}

export default Entry;
import React from 'react';
import {  ExpandMore, ExpandLess } from '@material-ui/icons';

const SeeMoreOrLess = props => {
  // Control = true => seeMore
  const { id, className, control, setControl } = props;

  return (
    <div>
      {/* See more */}
      { !control && 
        <div id={id}
          className={"pointer-on-hover center-text no-margin " + className}
          onClick={e => setControl(true)}
        >
          <p className="no-margin-bottom">See More</p>
          <ExpandMore/>
        </div>
      }

      {/* See less */}
      { control && 
        <div id={id}
          className={"pointer-on-hover center-text " + className}
          onClick={e => setControl(false)}
        >
          <p className="no-margin-bottom">See Less</p>
          <ExpandLess/>
        </div>
      }
    </div>
  )
}

export default SeeMoreOrLess;
import React from 'react';

import MotorHelper from 'MotorHelper';

let motorHelper = new MotorHelper();

const CellCount = props => {
  const { cellCount, cellCountInWords, containerStyle, ish3 } = props;

  return (
    <div className="CellCount-setup-display"
      style={containerStyle}
    >
      { ish3 && 
        <h3 className={`${cellCountInWords}-s-font-color inline-txt`}
          style={{
            fontWeight: "bold",
            marginTop: 0,
            marginRight: "5px"
          }}
        >
          { `${cellCount}S` } 
        </h3>
      }

      { !ish3 && 
        <h2 className={`${cellCountInWords}-s-font-color inline-txt`}
          style={{
            fontWeight: "bold",
            marginTop: 0,
            marginRight: "5px"
          }}
        >
          { `${cellCount}S` } 
        </h2>
      }
      

      <p className="inline-txt"
        style={{
          fontSize: "0.8em",
          marginTop: 0
        }}
      >
        { `(${motorHelper.getVoltageFromCells(cellCount)}V)` }
      </p>
    </div>
  )
}

export default CellCount;
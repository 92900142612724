import { useState, useEffect } from 'react';

import _ from 'lodash';


const useSort = (onSortApply) => {
  const [ sort, setSort ] = useState();
  
  // Execute callback from useSetups once sorts have been selected to do sorts
  // in backend
  useEffect(() => {
    if (!!sort) {
      onSortApply(sort)
    }
  }, [sort]);


  return [
    sort,
    setSort
  ]
}

export default useSort;


import React from "react";

import { makeStyles } from '@material-ui/core/styles';
import { Modal, 
	Backdrop,
	Fade } from '@material-ui/core';
import { Close } from "@material-ui/icons";



const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const Disclaimer = props => {
	const classes = useStyles();

	const [ open, setOpen ] = React.useState(false);

	return (
		<div id="Disclaimer">
			{ /* Disclaimer intro and trigger for modal */ }
			<div className="Disclaimer-FlywheelSetupPicker pointer-on-hover"
				onClick={() => setOpen(true)}>
				<p className="inline-txt error-text">Disclaimer: </p> 
				<p className="inline-txt">This application offers user-entered data that may not be of highest accuracy. Please vote and/or comment if you see incorrect data. Metrics are provided in ranges to help you get a good, but not exact, idea of what the setup will achieve. Click to read more.
        </p> 
			</div>

			{ /* Modal itself */ }
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"

        className={classes.modal}
        
        open={open}
        onClose={() => setOpen(false)}
        
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}

        closeAfterTransition
      >	
        <Fade in={open}>
	      	<div className={`${classes.paper}`}
	      		style={ {width: "80%"} }>
              <div className="flex-container-right pointer-on-hover" 
                onClick={() => setOpen(false)}>
                <Close/>
              </div>
              
		          <p>
                Flywheel performance is difficult to fully characterize. This application offers ballpark performance benchmarks and if you re-create the setup, you are likely to achieve similar, but not exact, performance. There are many variables that can't be controlled or captured and this application is only meant to provide surface-level metrics that you can use to achieve your desired performance, with margins. I broke up FPS performance into multiple ranges because if you replicate a setup, you are likely to acheive performance in the specified range. 
              </p>
         	</div>
       	</Fade>
      </Modal>
		</div>
	)
}

export default Disclaimer;
import React, { Component } from 'react';
import { Redirect } from 'react-router';
import './index.css';

import Form from '../Functional/Form';

export default class Login extends Component {
	constructor (props) {
		super (props);

		this.handleFormSubmit = this.handleFormSubmit.bind(this);

		this.state = {
			invalidCredentials: false,
			formItemData: [
				{
          key: 0,
          label: "Username",
          name: "username",
          placeHolder: "",
          isRequired: true,
          formResultsRef: "username"
        },
        {
          key: 1,
          label: "Password",
          name: "password",
          placeHolder: "",
          isRequired: true,
          formResultsRef: "password"
        }
			],

			formResults: {
				username: "",
				password: ""
			}
		}
	}

  render () {
  	if (this.props.credentialsData && typeof (this.props.credentialsData) !== 'undefined') {
  		let invalidCredentials = this.renderInvalidCredentials();
  		return (
	      <div id="Login">
	        <Form
            formTitle={"Login"}
            submitButtonText={"Submit"}
            formItemsData={this.state.formItemData}
            formResults={this.state.formResults}
            handleFormSubmit={this.handleFormSubmit}/>
          {invalidCredentials}
	      </div>
	    )
  	} else {
      return(
        <Redirect to={'/error404235894116545641313486'}/>
      )
  		
  	}

  }

  handleFormSubmit () {
  	//crednetials match 
  	if (this.props.credentialsData.username === this.state.formResults.username 
  		&& this.props.credentialsData.password === this.state.formResults.password) {
  		this.props.hasLoggedIn();		//login user
  	} else {
  		this.setState({
  			invalidCredentials: true,
	  		formResults: {
					username: "",
					password: ""
				},
			})
  	}

  }	//method

  renderInvalidCredentials () {
  	if (this.state.invalidCredentials) {
  		return (
  			<p id="invalid-credentials">Usename or password incorrect, try again.</p>
			) 
  	}
  }

}
import React, { memo } from 'react';

import SetupNameEntry from './FormItems/SetupNameEntry';
import UsernameEntry from './FormItems/UsernameEntry';
import EmailEntry from './FormItems/EmailEntry';
import BlasterEntry from './FormItems/BlasterEntry';


const BasicInfoEntry = memo(props => {
  const {
    // Static Values
    FLYWHEEL_SETUP_PICKER_METADATA,
    errorStyles,

    // Dynamic values
    formValues,
    errors,
    
    // Callbacks
    updateFormWrapper,
    generateErrorText,
    generateOptionForReactSelect

  } = props;


  return (
    <div className="setup-entry-form-section">
      <SetupNameEntry 
        setupName={formValues.setupName}
        onChange={updateFormWrapper}
        errorText={generateErrorText(errors.setupName)}
      />

      <UsernameEntry 
        username={formValues.username}
        onChange={updateFormWrapper}
        errorText={generateErrorText(errors.username)}
      />

      <EmailEntry 
        email={formValues.email}
        onChange={updateFormWrapper}
        errorText={generateErrorText(errors.email)}
      />
      
      <BlasterEntry
        blaster={formValues.blaster}
        onChange={updateFormWrapper}
        BLASTERS={FLYWHEEL_SETUP_PICKER_METADATA.blasters}
        generateOptionForReactSelect={generateOptionForReactSelect}
        errorText={generateErrorText(errors.blaster)}
        styles={!!generateErrorText(errors.blaster) ? errorStyles : {}}
      />
    </div>
  )

    // Determine whether or not to re-render based on props only
}, (prevProps, nextProps) => {
  const prevFormVals = prevProps.formValues;
  const nextFormVals = nextProps.formValues;

  const prevErrors = prevProps.errors;
  const nextErrors = nextProps.errors;

  // To re-render, return false
  // Don't re-render if all of the following are unchanged:
  //  - setupName errors and form
  //  - username errors and form
  //  - email errors and form
  //  - blaster errors and form
  //  - hasAttemptedSubmit remains the same: hasAttemptedSubmit from false to
  //      true will always trigger re-render becaus eerrors are displayed when
  //      that happens
  if (prevProps.hasAttemptedSubmit === nextProps.hasAttemptedSubmit
    
    && prevFormVals.setupName.value === nextFormVals.setupName.value 
    && prevErrors.setupName === nextErrors.setupName

    && prevFormVals.username.value === nextFormVals.username.value 
    && prevErrors.username === nextErrors.username

    && prevFormVals.email.value === nextFormVals.email.value 
    && prevErrors.email === nextErrors.email

    && prevFormVals.blaster.value === nextFormVals.blaster.value 
    && prevErrors.blaster === nextErrors.blaster) {

    return true
  }

  return false
})


export default BasicInfoEntry
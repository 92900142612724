import React from 'react';
import { Link } from 'react-router-dom';

import NavLink from './NavLink';

import './index.css'; 

const Links = props => {
	let url = window.location.pathname;

	return (
		<div id={props.id} className={props.className}>
			{/*<p><Link to="/" 
        className={"navbar-link " + (url === "/" ? "selected-navbar-link" : "")}>
        Home</Link></p>*/}

      <NavLink txt="Shop"/>
      <NavLink txt="Tools"/>
      <NavLink txt="Docs"/>
      <NavLink txt="Contact"/>
		</div>
	)
}

export default Links;
import React from 'react';

const ShippingInfo = props => {
	if (props.preOrder) {
    return (
      <div id="shipping-info">
        <h2>Shipping</h2>
        <p>Becuase this item is in pre-order status, it may take some time to get this product out to the world. <b>This product is expected to be ready to ship in {props.itemData.preOrderOptions.expectedDate}</b>. </p>
        <p>Please allow up to 5 business days to process and assemble your order after your order is ready to ship. Most orders ship within business 3 days. High volume of orders may delay shipping times.</p>
        <h4>$3.50 shipping to the United States via USPS First-Class package service. International shipping will cost $15 USD.</h4>
      </div>
    )
  } else {
    return (
      <div id="shipping-info">
        <h2>Shipping</h2>
        <p>{props.itemData.shippingMessage}</p>
        <p>Please allow up to 5 business days to process and assemble your order. Most orders ship within business 3 days. High volume of orders may delay shipping times.</p>
        <h4>$3.50 shipping to the United States via USPS First-Class package service. International shipping will cost $15 USD.</h4>
      </div>
    )
  }
}

export default ShippingInfo
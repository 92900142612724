import React from 'react';
import Select from 'react-select';


const FPSEntry = props => {
  const { fps, 

    getNewMuzzleVelocities, 
    updateFormWrapper,

    FPS_SELECTIONS,
    generateOptionForReactSelect,
    errorText,
    styles } = props;


  return (
    <div>
      <Select className="small-select-dropdown"
        styles={styles}
        placeholder={"FPS *"}
        value={ 
          !!fps.value && 
          generateOptionForReactSelect(fps.value)
        }
        options={FPS_SELECTIONS}
        onChange={(selectedFps) => {
          // Find group/power level that selected fps is in
          let powerLevel = ""
          FPS_SELECTIONS.map(
            fpsGroup => {
              fpsGroup.options.map(fpsGroupOption => {
                if (fpsGroupOption === selectedFps) {
                  powerLevel = fpsGroup.label;
                }
              })
            }
          );

          const newMuzzleVelocities = getNewMuzzleVelocities({
            fps: { 
              value: {
                $set: selectedFps.value
              }
            },
            powerLevel: { 
              value: {
                $set: powerLevel
              }
            }
          });

          updateFormWrapper(newMuzzleVelocities)


        }}
      />
      
      <p className="setup-entry-help">
        How hard can your setup hit? Please enter the average muzzle velocity of your setup, in FPS (feet per second). Feel free to link to your data in the "Additional Setup Notes" section below. The FPS entries are broken up into groups because there are a ton of variables that can't be controlled that play into muzzle velocity readings. Read the disclaimer at the top for more info! 
      </p>

      <p className="center-text error-text">{errorText}</p>
    </div>
  )

}

export default FPSEntry;
import React, { Component } from 'react';
import Markdown from 'react-remarkable';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import Docs from './Docs';
import Options from './Options';
import FAQs from './FAQs';

import 'react-tabs/style/react-tabs.css';
import './index.scss';

export default class Description extends Component  {
	constructor(props) {
		super(props);

	}

	render() {
		return (
      <div id="Shop-item-description"> 
        <h1>Description</h1>
        <Tabs>
			    <TabList>
			      <Tab><p>Description</p></Tab>
			      <Tab><p>Docs</p></Tab>	
			      <Tab><p>Options</p></Tab>	
			      <Tab><p>FAQs</p></Tab>
			    </TabList>

			    <TabPanel>
            <div className="markdown-container" id="shop-item-description-markdown-container">
		          <Markdown source={this.props.itemData.description} options={{html: true}}/>
		          {/* Render Parts Description and title */}
		          { !!this.props.itemData.partsDescription && <h2>Parts</h2> }
		          { !!this.props.itemData.partsDescription && 
	          	<Markdown source={this.props.itemData.partsDescription} options={{html: true}}/> }
		        </div>
			    </TabPanel>
			    <TabPanel>
			    	<Docs data={this.props.itemData.docs}/>
			    </TabPanel>
			    <TabPanel>
			    	<Options optionsData={this.props.itemData.optionsDescription}/>
			    </TabPanel>
			    <TabPanel>
			      <FAQs data={this.props.itemData.FAQs}/>
			    </TabPanel>
			  </Tabs>


        <p>Don't hesitate to contact me if you have any questions. Please contact us for any bulk orders.</p>
	    </div>
		)

	}

	
}

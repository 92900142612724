import React from 'react';
import DropdownFilter from './DropdownFilter';

import Helper from 'Helper';


const helper = new Helper();


const FlywheelMotorVoltingFilter = props => {
  const { filter, onFilterChange } = props;

  return (
    <DropdownFilter
      title={"Flywheel Motor Volting"}
      placeholder={"Flywheel Motor Volting"}
      filter={filter}

      SELECTIONS={["Undervolted", "Nominal", "Overvolted"].map(volting => {
          return helper.generateOptionForReactSelect(volting) 
        })
      }

      onChange={selectedOptions => onFilterChange("flywheelMotorVolting", selectedOptions)}

    />
  )
}

export default FlywheelMotorVoltingFilter;
import React from 'react';
import { TextField } from '@material-ui/core';

const EmailEntry = props => {
  const { email, onChange, errorText } = props;

  return (
    <div>
      <TextField 
        className="outlined-basic setup-entry-text-field" 
        label="Email" 
        variant="outlined"
        value={email.value}
        onChange={
          (e) => onChange(
            {email: { value: {$set: e.target.value}}}
          )
        }
        error={!!errorText && errorText.length > 0}
      />

      <p className="setup-entry-help">
        Only used for username verification. If someone tries to steal your username to enter a setup or comment, they'll need to enter your email correctly too! Feel free to leave this blank if you're not worried about username sharing. Also, if you would like to make changes to your setup after it's submitted, you'll need to contact me directly and confirm the email that was used for this setup.
      </p>

      <p className="center-text error-text">{errorText}</p>

    </div>
  )
}

export default EmailEntry;
import React, { Component } from 'react';
import ReactTable from "react-table";

import BatteryHelper from "BatteryHelper";

import "react-table/react-table.css";
import './index.css';

export default class Results extends Component {
  render () { 
    //all battery specs entered and valid
    if (!!this.props.batteryCellCount && !!this.props.batteryCapacity && !!this.props.batteryDischargeRating) { 
      let batteryHelper = new BatteryHelper();

      return (
        <div id="LiPoCalculatorContainerResults">
          <h2>Results</h2>
          <p className="center-text">Calculated Values</p>
          <ReactTable
            className="basic-statistic-table results-table -striped -highlight"
            defaultPageSize={4}
            showPaginationBottom={false}
            data={[
              {
                statistic: "Max Output Current",
                val: batteryHelper.maxBatteryCurrent(this.props.batteryDischargeRating, this.props.batteryCapacity).toFixed(1) + "A"
              },
              {
                statistic: "Nominal voltage",
                val: batteryHelper.nominalVoltage(this.props.batteryCellCount).toFixed(1) + "V"
              },
              {
                statistic: "Maximum voltage",
                val: batteryHelper.maxVoltage(this.props.batteryCellCount).toFixed(1) + "V"
              },
              {
                statistic: "Minimum voltage",
                val: batteryHelper.minVoltage(this.props.batteryCellCount).toFixed(1) + "V"
              }
            ]}
            columns={[
              {
                Header: "Statistic",
                accessor: "statistic"
              },
              {
                Header: "Value",
                accessor: "val"
              }
            ]}
          />
          <p className="center-text">Selected Values</p>
          <ReactTable
            className="basic-statistic-table results-table -striped -highlight"
            defaultPageSize={3}
            showPaginationBottom={false}
            data={[
              {
                statistic: "Cell Count",
                val: this.props.batteryCellCount + "C"
              },
              {
                statistic: "Capacity",
                val: this.props.batteryCapacity + "mAh"
              },
              {
                statistic: "Discharge Rating",
                val: this.props.batteryDischargeRating + "C"
              }
            ]}
            columns={[
              {
                Header: "Selected Value",
                accessor: "statistic"
              },
              {
                Header: "Value",
                accessor: "val"
              }
            ]}
          />
        </div>
      )
    } else {
      return (
        <div id="LiPoCalculatorContainerResults">
          <h2>Results</h2>
          <p>Please complete all fields to complete results.</p>
        </div>
      )
    }
  }
  

}
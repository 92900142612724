import React from 'react';

import {
	getColorClassName,
	getStyles
} from './getStyles' 

import './index.scss';

// Props:
// 	Looks (shape, size, etc.): 
// 	- cellCount (string): Cell count, suffixed with "S"
// 	- size (string): Size of volting shape - "Small" (usually for 130s),  
//			"Medium" (132s), "Large" (180s). Can also be given as a px value. 
//			If a px value is entered for the triangle, the size will be the 
//			base length of the triangle
// 	- shape (string): Shape of volting shape - "Triangle", "Circle", "Square"
//
//	CSS-Related:
//	- style (object): Style of volting shape in standard style format 
// 	- containerClassName (string): classname of container that also wraps 
//			children
// 	- shapeContainerClassName (string): classname of container that wraps 
//			shape and inside text
// 	- shapeClassName (string): classname of shape itself
//
//
// 	Functional:
// 	- containerOnClick (function): Callback when container (shape + children) 
//		are clicked
// 
// 	Other
// 	- insideText (string): text that gets displayed inside the shape 
// 	- insideTextClassname (string): classname of insideText


const VoltingShape = props => {
	// Logical props
	const { cellCount } = props;

	// Render/UI props
	const { containerClassName, containerOnClick } = props; 
	const { shapeContainerClassName } = props; 
	const { size, shape, style, shapeClassName } = props;
	const { insideText, insideTextClassname } = props;
	const { children } = props;


	const isShapeTriangle = (shape.toLowerCase() === "triangle")

	let colorClassName = getColorClassName(cellCount, isShapeTriangle);
	
  // Merge all style objects into one object that can be set to DOM elements 
	const styles = getStyles(size, shape, isShapeTriangle, style); 


	return (
		<div className={containerClassName}
			onClick={ (e) => {
				if (!!containerOnClick) containerOnClick(e)
			} }
		>
			{/* Container for shape and inside text only */}  
			<div className={shapeContainerClassName}
				style={{ 
					position: "relative",
					width: styles.containerWidth,
					height: styles.containerHeight,
					lineHeight: styles.containerHeight }}
			>
				{/* Shape */}
				<div className={ `${"volting-shape" + (isShapeTriangle ? "-triangle" : "")} ${shapeClassName} ${colorClassName}` }
					style={ styles }>
				</div>

				{/* Text inside shape */}
				<div className="center-text text-container-inside-volting-shape">
					<p className={`no-margin ${insideTextClassname}`}>
						{insideText}
					</p>
				</div>

			</div>
			{ children }
		</div>
	)


}

export default VoltingShape;
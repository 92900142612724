import React from 'react';
import { Collapse } from '@material-ui/core';

import Icons from './Icons';
import CollapsibleTextTrigger from '../../../../../Functional/CollapsibleTextTrigger';


import "./index.scss";


const StageDisplay = props => {
  let { setupData, stageData, motorHelper } = props;

  let [ isStageExpanded, setIsStageExpanded ] = React.useState(true);

  const MOTOR_DATA = motorHelper.findMotorsWithMatchingName(stageData.motors)[0];

  
  return (
    <div className="StageDisplay">

      <CollapsibleTextTrigger
        isExpanded={isStageExpanded}
        setIsExpanded={setIsStageExpanded}
      >
        <h3>
          {`Stage ${stageData.stageIndex}`}
        </h3>
      </CollapsibleTextTrigger>

      <div className="setup-stage-body grey-border-bottom">
        <Collapse in={isStageExpanded}>
          {/* Motors */}
          <div className="setup-stage-spec">
            <p className="inline-text setup-stage-key-text">Motors:</p>
            <p className="inline-text setup-stage-value-text">
              {stageData.motors}
            </p>
          </div>

          {/* Flywheels */}
          <div className="setup-stage-spec">
            <p className="inline-text setup-stage-key-text">Flywheels:</p>
            <p className="inline-text setup-stage-value-text">
              {stageData.flywheels}
            </p>
          </div>

          {/* Flywheel cage */}
          <div className="setup-stage-spec">
            <p className="inline-text setup-stage-key-text">Flywheel Cage:</p>
            <p className="inline-text setup-stage-value-text">
              {stageData.flywheelCage}
            </p>
          </div>

          <Icons
            MOTOR_DATA={MOTOR_DATA}
            motorHelper={motorHelper}
            setupData={setupData}
            stageData={stageData}
          />


        </Collapse>

      </div>
      
    </div>
  
  )
}

export default StageDisplay;
import React from 'react';

import TimeAgoText from '../../../../Functional/TimeAgoText';

import { Tooltip } from '@material-ui/core';


const PostDetails = props => {
  const { setupData } = props;

  const percentUpvoted = ((setupData.upvotes/(setupData.upvotes + setupData.downvotes)) * 100).toFixed(0)
  
	return (
		<div>
      {/* Username */}
      <p className="setup-entry-help inline-txt" >
        {
          "Submitted by " +  
          ( setupData.username.length === 0 ? 
            "Your username goes here" : setupData.username )
        }
      </p>

      {/* Middot */}
      <p className="setup-entry-help middot">·</p>

      {/* Time */}
      <TimeAgoText
        time={setupData.id} 
        formattedTime={setupData.formattedDate}
        textClassName={"setup-entry-help inline-txt"}
      />

      {/* Middot */}
      <p className="setup-entry-help middot">·</p>

      {/* Percent upvoted */}
      <Tooltip title={(setupData.upvotes + " upvoted, " +  
        setupData.downvotes + " downvoted")} 
        placement="top">
        <p className="setup-entry-help inline-txt">
          {percentUpvoted + "% upvoted"} 
        </p>
      </Tooltip>
    </div>
	)


}

export default PostDetails;
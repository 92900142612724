import React from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';

const MicrowheelsFilter = props => {
  const { filter, onFilterChange } = props;
  
  return (
    <FormControlLabel
      control={
        <Checkbox 
          checked={!!filter.isMicrowheels}
          color="primary"
          onChange={e => {onFilterChange("isMicrowheels", e.target.checked)}}
        />
      }
      classes={{
        root: "select-entry",
        label: "setup-entry-checkbox-label"
      }}
      label="Only Microwheel Setups"
    />
  )

}

export default MicrowheelsFilter;
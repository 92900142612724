import React, { Component } from 'react';

import LiPoCalculator from './App';
import Results from './Results';

import './index.css';

export default class LiPoCalculatorContainer extends Component {
  constructor (props) {
    super(props);

    this.state = {
      batteryCellCount: 0,
      batteryCapacity: 0,
      batteryDischargeRating: 0
    }
  }

  render () {  
    return (
      <div id="LiPoCalculatorContainer">
        <LiPoCalculator updateBatterySpecs={this.updateBatterySpecs.bind(this)}/>
        <Results 
          batteryCellCount={this.state.batteryCellCount} 
          batteryCapacity={this.state.batteryCapacity} 
          batteryDischargeRating={this.state.batteryDischargeRating}/>
      </div>
    )
  }

   updateBatterySpecs(newCellCount, newBatteryCapcity, newDischargeRating) {
    this.setState({
      batteryCellCount: newCellCount,
      batteryCapacity: newBatteryCapcity,
      batteryDischargeRating: newDischargeRating
    });
  }
  

}
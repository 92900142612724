import React from 'react';
import DropdownFilter from './DropdownFilter';


const PriceFilter = props => {
  const { PRICES, filter, onFilterChange } = props;

  return (
    <DropdownFilter
      title={"Price"}
      placeholder={"Price"}
      filter={filter}

      SELECTIONS={PRICES}

      onChange={selectedOptions => onFilterChange("price", selectedOptions)}
    />
  )
}

export default PriceFilter;
import React, { Component } from 'react';

import './index.scss';

export default class MOSFETBoardPlus extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedInteractiveOption: ""
		}
	}

	render() {
		// Options that aren't hidden
		return (
			<div id="mosfet-board-interactive-options">
				<h3 className="option-set-title">XT60 Pigtail Options</h3>
      		<p style={{fontSize: "0.8em"}}>Click on a set of holes to change its options.</p>
	      	<div id="interactive-mosfet-board-plus-options">
	      		<div className="interactive-options-bg interactive-mosfet-board-plus-options-bg"
	      			style={{
	      		 		backgroundImage: "url(" + require("assets/Shop/MOSFET Board +/mosfet_board_+_options_bg.jpg") + ")" 
	      			}}>
	      		</div>
	      		{/*this div is used so the divs below can have top margin without moving this entire div parent div*/}
	      		<div style={{height: "1px"}}></div>
	      		<div id="interactive-mosfet-board-plus-options-holes-container" className="flex-container">
	      			{/*Holes for battery*/}
	      			<div className="mosfet-board-plus-interactive-options-selector"
	      				onClick={e => this.props.setInteractiveOptionSelection({selectedInteractiveOption: "battery-xt60-pigtail"})}>
	      				<div className="interactive-options-selector interactive-mosfet-board-plus-options-holes"></div>
		      			<div style={{marginTop: "4px"}}
			      			className="interactive-options-selector interactive-mosfet-board-plus-options-holes">
		      			</div>
	      			</div>
	      			{/*Holes for motor left*/}
	      			<div className="mosfet-board-plus-interactive-options-selector"
	      				style={{marginLeft: "53px"}}
	      				onClick={e => this.props.setInteractiveOptionSelection({selectedInteractiveOption: "left-motor-xt60-pigtail"})}>
	      				<div className="interactive-options-selector interactive-mosfet-board-plus-options-holes"></div>
		      			<div style={{marginTop: "4px"}}
			      			className="interactive-options-selector interactive-mosfet-board-plus-options-holes">
		      			</div>
	      			</div>
	      			{/*Holes for motor middle*/}
	      			<div className="mosfet-board-plus-interactive-options-selector"
	      				style={{marginLeft: "4px"}}
	      				onClick={e => this.props.setInteractiveOptionSelection({selectedInteractiveOption: "middle-motor-xt60-pigtail"})}>
	      				<div className="interactive-options-selector interactive-mosfet-board-plus-options-holes"></div>
		      			<div style={{marginTop: "4px"}}
			      			className="interactive-options-selector interactive-mosfet-board-plus-options-holes">
		      			</div>
	      			</div>
	      			{/*Holes for motor right*/}
	      			<div className="mosfet-board-plus-interactive-options-selector"
	      				style={{marginLeft: "3px"}}
	      				onClick={e => this.props.setInteractiveOptionSelection({selectedInteractiveOption: "right-motor-xt60-pigtail"})}>
	      				<div className="interactive-options-selector interactive-mosfet-board-plus-options-holes"></div>
		      			<div style={{marginTop: "4px"}}
			      			className="interactive-options-selector interactive-mosfet-board-plus-options-holes">
		      			</div>
	      			</div>

	      		</div>
	      	</div>
			</div>
		)

		return null
	}

}
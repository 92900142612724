import React from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';

const DartGuideCheckboxFilter = props => {
  const { filter, onFilterChange } = props;
  
  return (
    <FormControlLabel
      control={
        <Checkbox 
          checked={!!filter}
          color="primary"
          onChange={e => {onFilterChange("hasDartGuide", e.target.checked)}}
        />
      }
      classes={{
        label: "setup-entry-checkbox-label"
      }}
      label="Only Setups with Dart Guides"
    />
  )

}

export default DartGuideCheckboxFilter;
import React, { Component } from 'react';


//class to render custom tool tip
//from ex here: https://jsfiddle.net/alidingling/vxq4ep63/
//from docs of custom tool tips here: http://recharts.org/en-US/examples/CustomContentOfTooltip
//from dcs tooltips here: http://recharts.org/en-US/api/Tooltip
export default class MotorChartTooltip extends Component {
	render() {
		const { active } = this.props;
		if (active) {
      //stuff passed from recharts
      const { payload } = this.props;

      const motor = this.props.findDataOfNode(this.generateNodeData(payload), this.props.DATA);

      return (
        <div className="motor-chart-tooltip">
          <p>{motor.name}</p>
          <li>{"Speed: " + motor.speed + " RPM"}</li>
          <li>{"Torque: " + motor.torque + " gf.cm"}</li>
          <li>{"Form Factor: " + motor.formFactor}</li>
          <li>{"Stall Current: " + motor.stallCurrent + "A"}</li>
        </div>
      );
    }

    return null;
		
	}

  //generate node data from payload
  //payload arr. el in node correspond to dimension of node/dot
  generateNodeData(payload) {
    let node = {
      x: payload[0].value,
      y: payload[1].value
    };

    return node
  }


}

import React, { Component } from 'react';

const Docs = props => {	
	// Make sure docs data actually exists before trying to render it 
	if (!!props.data) {
		let renderedDocLinks = props.data.map(docItem => {
			return <a href={docItem.link} target="_blank"><p>{docItem.text}</p></a>
		});

		return (
	    <div id="Shop-item-description-docs"> 
	      {renderedDocLinks}
	    </div>
		)
	}
	
	return null;
	
}

export default Docs;

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import axios from 'axios';
import Markdown from 'react-remarkable';

import './index.css';

import Login from '../Login';

export default class CustomOrders extends Component {
	constructor (props) {
    super(props);

    this.initLoginData = this.initLoginData.bind(this);
    this.hasLoggedIn = this.hasLoggedIn.bind(this);
    this.bought = this.bought.bind(this);

    this.state = {
    	isLoggedIn: false,
    	invalidLink: false,
    	orderData: {},
     	credentialsData: {}
    }
	}

	componentDidMount () {
		//get data
		axios.get("https://hidden-forest-71418.herokuapp.com/api/custom-orders")
			.then(res => {
				this.setState({"DATA": res.data[0]}, function () {
					this.initLoginData();
				});
			});
  }

  render () {
  	if (this.state.DATA && (this.state.credentialsData !== {}) ) {		//make sure to get data before doing stuff 
  		
	  	if (this.state.invalidLink) {		//make sure link/order exists
	  		return (<Redirect to={'/error404235894116545641313486'}/>)
	  	} else if (!this.state.isLoggedIn) {			//not logged in, must log in
	  		return (
	  			<Login hasLoggedIn={this.hasLoggedIn} credentialsData={this.state.credentialsData}/>
				)
	  	} else {		//show the good stuff!
		    return (
		      <div id="CustomOrders">
		        <h1>Custom Orders</h1>
		        <div id="custom-order-info">
	            <div className="horiz-fb">
				        <h3 className="custom-orders-basic-info-title">For: </h3> 
				        <p className="custom-orders-basic-info-text">{this.state.orderData.for}</p>
				       </div>
				       <div className="horiz-fb">
				        <h3 className="custom-orders-basic-info-title">Date: </h3> 
				        <p className="custom-orders-basic-info-text">{this.state.orderData.date}</p>
				       </div>
				       <div className="horiz-fb">
				        <h3 className="custom-orders-basic-info-title">Price: </h3> 
				        <p className="custom-orders-basic-info-text">${this.state.orderData.price}</p>
				       </div>
				        <h3 className="custom-orders-basic-info-title">Description: </h3> 
				        <div className="markdown-container">
	          			<Markdown source={this.state.orderData.details} options={{html: true}}/>
				       </div>
   			       <p>If something about the order is wrong, please contact us and we will fix the order.</p>
   			       <h4>
   			       	<Link 
	                to="/checkout"
	                id="shop-item-order-now"
	                onClick={this.bought}>
	                Buy it Now
                </Link>
              </h4>
			       </div>
		      </div>
		    )
		  }		//else login
  	} else {
  		return (
  			<div>
	  			Loading
	  		</div>
			)
  	}
  	
  }	//render

  initLoginData() {
  	let dataCpy = Object.assign(this.state.DATA);
  	let credentialsData = {};
  	const link = "/" + this.props.match.params.id;

  	//find corresponidng credentials data based on url so can properly login 
  	for (var i = 0; i < dataCpy.orders.length; i++) {
  		//find link in db matching current link
  		if ( ("/" + dataCpy.orders[i].link) === link) {
  			//save credentials data to state
  			this.setState({
  				invalidLink: false,
  				orderData: Object.assign(dataCpy.orders[i]),
  				credentialsData: Object.assign(dataCpy.orders[i].credentials)
  			});

  			break;
  		} else {
  			this.setState({invalidLink: true});
  		}
		}

  }		//method initLoginData

  hasLoggedIn () {
  	this.setState({isLoggedIn: true})
  }

  bought () {
		this.props.addToCart(1, -1, this.state.orderData.price);
  }

}
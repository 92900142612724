import React from 'react';
import SliderWithTooltipAndText from "../../../../../../../Functional/SliderWithTooltipAndText";

const LiPoCellCountEntry = props => {
  const { cellCount, onChange, errorText } = props;
  
  return (
    <div>
      <SliderWithTooltipAndText
        containerClassName="flywheel-setup-picker-entry-form-cell-count-slider-container"
        title="Battery Cell Count"
        sliderPos={cellCount.value}
        sliderValSuffix="S"
        min={0} max={5} step={1}
        marks={function () {
          let marks = {}
          for (var i = 0; i <= 5; i++) {
            marks[i] = i + "S";
          }

          return marks;
          }()
        }
        value={cellCount.value}
        onChange={(selectedCellCount) => {
          onChange({
            cellCount: { value: {$set: selectedCellCount}}})
        }}
      />

      <p className="setup-entry-help">
        LiPo pack cell count in series: '2S' or '3S'
      </p>

      <p className="center-text error-text">{errorText}</p>

    </div>
  )
}

export default LiPoCellCountEntry;
import React, { Component } from 'react';
import _ from 'lodash';
import { LineChart, Scatter, CartesianGrid,
	XAxis, YAxis, Line } from 'recharts';

import './index.css';

import MotorInfoTab from '../../Functional/MotorInfoTab';


//funcitonal classes
import Helper from 'Helper';
let helper = new Helper();


export default class MotorInfo extends Component {
	constructor(props) {
		super(props);

		this.state = {
			tabs: [],
			focusedTab: 0
		}
	}

	componentWillReceiveProps(nextProps) {
		//create copy to avoid pointer to returnObj from updateTabs
		this.setState({tabs: _.map(this.updateTabs(nextProps.motorInfo), _.clone)});
	}

  render () {
    if (!_.isEqual(this.props.motorInfo, {})) {
  		return (
  			<div id="MotorInfo">
  				<MotorInfoTab
						motorInfo={this.props.motorInfo}
						otherShopData={this.props.otherShopData}/>
  				
  			</div>
			)
  	} else {
	  	return (
	      <div></div>
	    )
	  }

  } 		//render

  updateTabs(motorInfo) {
  	let newTabsState = _.map(this.state.tabs, _.clone);		//copy, don't want mutate
  	//if motor info already in tabs, remove it. If not, add to it
  	if (!helper.isItemInArr(this.state.tabs, motorInfo)) {
  		newTabsState.push(motorInfo) 
  		return newTabsState;
  	}

  	return helper.removeItemFromArr(newTabsState, motorInfo);
  }

}
import React, { Component } from 'react';
import Markdown from 'react-remarkable';
import {CircleArrow as ScrollUpButton} from "react-scroll-up-button";


import DataDependantComponent from '../../DataDependantComponent';

import Navigation from '../Navigation';


import './index.css';

export default class DocsDetail extends DataDependantComponent {
	renderDetailChildComponent () {
		let data = this.state.itemData;

		// If the what's being shown doesm't match the link, update what's being shown to match the link
		// This can happen if the link to get here was pressed from the sidebar or modal
		if (data.key !== parseInt(this.props.match.url.replace("/docs/", ""))) {
			this.findDataOfItem();
		}

		return (
      <div id="DocsDetail">
      	<ScrollUpButton ContainerClassName="scroll-up-btn"/>
      	<h2 className="docs-detail-title">{data.title}</h2>
      	<div className="inline-container">
	    		<img 
	    			src={require(`assets/clock-icon.png`)} 
	    			className="doc-preview-clock-icon clock-icon"/>
	    		<p className="doc-preview-date doc-preview-inline left-align-text">{data.date}</p>
	    	</div>
	    	<Markdown source={data.content} options={{html: true}}/>	
	    	{ /*
	    		<Navigation DATA={this.state.DATA}/>	
      	*/ }
      </div>
		);
		
	}

}

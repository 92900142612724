import React from 'react';
import { Tooltip } from '@material-ui/core';
import { ArrowUpward, ArrowDownward } from '@material-ui/icons';


const UPVOTE = 1, NO_VOTE = 0, DOWNVOTE = -1;

const NO_VOTE_TO_UPVOTE = 1;
const NO_VOTE_TO_DOWNVOTE = 2;

const UPVOTE_TO_NO_VOTE = 3;
const UPVOTE_TO_DOWNVOTE = 4;

const DOWNVOTE_TO_NO_VOTE = 5;
const DOWNVOTE_TO_UPVOTE = 6;

const Voting = props => {
  const { upvotes, downvotes, onVote } = props;

  const [ hasVotingChanged, setHasVotingChanged ] = React.useState(false);
  const [ votingState, setVotingState ] = React.useState(NO_VOTE);
  const [ nextVotingState, setNextVotingState ] = React.useState(NO_VOTE);


  React.useEffect(() => {
    if (hasVotingChanged) {
      // Previously no vote
      if (votingState === NO_VOTE) {
        setVotingState(nextVotingState);

        // No vote to upvote
        if (nextVotingState === UPVOTE) {
          onVote(NO_VOTE_TO_UPVOTE)
        // No vote to downvote
        } else if (nextVotingState === DOWNVOTE) {
          onVote(NO_VOTE_TO_DOWNVOTE)
        }

      // Previously upvote
      } else if (votingState === UPVOTE) {
        // Upvote to upvote, so un-upvote
        if (nextVotingState === UPVOTE) {
          setVotingState(NO_VOTE);
          onVote(UPVOTE_TO_NO_VOTE);
        // Upvote to downvote  
        } else if (nextVotingState === DOWNVOTE) {
          setVotingState(DOWNVOTE);
          onVote(UPVOTE_TO_DOWNVOTE);
        }

      // Previously downvote
      } else if (votingState === DOWNVOTE) {
        // Downvote to upvote
        if (nextVotingState === UPVOTE) {
          setVotingState(UPVOTE);
          onVote(DOWNVOTE_TO_UPVOTE);
        // Downvote to downvote, so un-downvote
        } else if (nextVotingState === DOWNVOTE) {
          setVotingState(NO_VOTE);
          onVote(DOWNVOTE_TO_NO_VOTE);
        }
      }

    }
    
    setHasVotingChanged(false);

  }, [hasVotingChanged]);


  return (
    <div className="flex-container-vertical-center item"> 
      <Tooltip 
        title="Upvote" 
        placement="top"
      >
        <ArrowUpward 
          className="pointer-on-hover"
          color={votingState === UPVOTE ? "primary" : "action"}
          onClick={() => {
            setNextVotingState(UPVOTE);
            setHasVotingChanged(true);
          }}
        />
      </Tooltip>
      
      <Tooltip 
        title={`${upvotes} upvoted, ${downvotes} downvoted`} 
        placement="top"
      >
        <p className="inline-text" style={{margin: "0 1em"}}>{upvotes - downvotes}</p>
      </Tooltip>

      <Tooltip 
        title="Downvote" 
        placement="top"
      >
        <ArrowDownward 
          className="pointer-on-hover"
          color={votingState === DOWNVOTE ? "secondary" : "action"}
          onClick={() => {
            setNextVotingState(DOWNVOTE);
            setHasVotingChanged(true);
          }}        
        />
      </Tooltip>
    </div>
  )


}

export default Voting;
import React, { Component } from 'react';
import _ from 'lodash';
import Dropdown from 'react-dropdown';

import './index.css';

export default class LiPoCheckerMotorSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedMotor: "",
      qty: 0,
      isDisplayingErrTxt: false
    }
    
  }

	render() {
    return (
    	<div id="LiPoCheckerMotorSelector" className="lipo-checker-section"> 
    		<h2>Select your loads</h2>
        <p>Only loads (motors and solenoids) on the selected motor list will be used in calculations. Press the green + button to add motors to the list for calculations. To remove added loads from the list, click the red - button.</p>
        {this.renderErrText()}
        {this.renderMotorSelectForm()}
        <div id="selected-motors-container">
          <h3>Selected Loads</h3>
          {this.renderSelectedMotorsTable()}
        </div>
        
    	</div>
		)
  }

  renderMotorSelectForm() {
    return (
      <div id="motor-selection-form" className="flex-wrap">
        <div className="plus-btn" onClick={this.addMotor.bind(this)}>+</div>
        <Dropdown 
          options={this.createMotorDropdownSelectOptions()} 
          onChange={(selectedMotor) => {this.setState({selectedMotor: selectedMotor.value})}} 
          value={this.state.selectedMotor}
          placeholder="Select a load"
          className="lipo-checker-motor-selector-dropdown"
          placeholderClassName="motor-selector-placeholder"
          controlClassName="motor-selector-dropdown-control"
          menuClassName="motor-selector-dropdown-menu"
          optionClassName="motor-selector-dropdown-option"
          arrowClosed={<span className="arrow-closed" />}
          arrowOpen={<span className="arrow-open" />}/>

        <Dropdown 
          options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]} 
          onChange={(selectedQty) => {this.setState({qty: selectedQty.value})}} 
          value={this.state.qty === 0 ? "qty" : this.state.qty + ""}
          placeholder="qty"
          className="qty-selector-dropdown"
          placeholderClassName="motor-selector-placeholder"
          controlClassName="motor-selector-dropdown-control"
          menuClassName="motor-selector-dropdown-menu"
          optionClassName="motor-selector-dropdown-option"
          arrowClosed={<span className="arrow-closed" />}
          arrowOpen={<span className="arrow-open" />}/>

      </div>
    )
  }

  createMotorDropdownSelectOptions() {
    // 3S loads include all 3S motors and the solenoid
    let loads3S = this.props.motorHelper.findAllMotorsOfSpecificBattery(3)
          .map((motorData) => {return motorData.name});
    loads3S.push("12V 35mm Stroke Solenoid")
    loads3S.sort();


    return [
      {
        type: 'group',
        name: "2S Nominal",
        items: this.props.motorHelper.findAllMotorsOfSpecificBattery(2).map((motorData) => {return motorData.name})
      },
      {
        type: 'group',
        name: "3S Nominal",
        items: loads3S
      }
    ]

  }

  renderSelectedMotorsTable() {
    if (this.props.selectedMotors.length === 0) {
      return (
        <p>None</p>
      )
    } else {
      return (
        <div id="selected-motors">
          {this.renderSelectedMotorsTableCaptions()}
          {this.renderSelectedMotors()}
        </div>
      )
    }
    
  }

  renderSelectedMotorsTableCaptions() {
    return (
      <div className="flex-wrap" id="selected-motors-table-captions">
        <p id="selected-motors-table-captions-name" className="selected-motors-table-captions">Motor</p>
        <p id="selected-motors-table-captions-qty" className="selected-motors-table-captions">Quanitity</p>
      </div>
    )
  }

  renderSelectedMotors() {
    return this.props.selectedMotors.map((selectedMotor, index) => {
      return (
        <div className="selected-motor">
          <div className="minus-btn" onClick={() => {this.props.removeMotor(index)}}>-</div>
          <p className="selected-motor-name selected-motor-txt">{selectedMotor.name}</p>
          <p className="selected-motor-qty selected-motor-txt">{selectedMotor.qty}</p>
        </div>
      )
    })
  }

  addMotor() {
    if ((this.state.selectedMotor != "Select a motor" || this.state.selectedMotor != "") && this.state.qty > 0) {
      this.props.addMotor({
        name: this.state.selectedMotor,
        qty: this.state.qty
      }); 

      this.setState({isDisplayingErrTxt: false});
    } else {
      this.setState({isDisplayingErrTxt: true});
    }
  }

  renderErrText() {
    if (this.state.isDisplayingErrTxt && !((this.state.selectedMotor != "Select a motor" || this.state.selectedMotor != "") && this.state.qty > 0)) {
      let errText = "Please select ";
      let errTextArr = [];

      if (this.state.selectedMotor === "Select a motor" || this.state.selectedMotor === "") {
        errTextArr.push("motors");
      }
        
      if (this.state.qty === 0) {
        errTextArr.push("quanitity");
      }

      for (let i = 0; i < errTextArr.length; i++) {
        errText += errTextArr[i];
        if (i < errTextArr.length -1) {
          errText += ", "
        }
      }

      return (
        <p className="error-text">{errText}</p>
      )
    }

  }



}

import React from 'react';
import DropdownFilter from './DropdownFilter';

import Helper from 'Helper';


const helper = new Helper();


const PriceGroupFilter = props => {
  const { PRICES, filter, onFilterChange } = props;
  
  return (
    <DropdownFilter
      title={"Price Group"}
      placeholder={"Price Group"}
      filter={filter}

      SELECTIONS={PRICES.map(priceGroup => {
        return helper.generateOptionForReactSelect(priceGroup.label) 
      })}

      onChange={selectedOptions => onFilterChange("priceGroup", selectedOptions)}
    />
  )
}

export default PriceGroupFilter;
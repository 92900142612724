import React from 'react';
import DropdownFilter from './DropdownFilter';

import Helper from 'Helper';


const helper = new Helper();


const FlywheelMotorFormFactorFilter = props => {
  const { filter, onFilterChange } = props;

  return (
    <DropdownFilter
      title={"Flywheel Motor Form Factor"}
      placeholder={"Flywheel Motor Form Factor"}
      filter={filter}

      SELECTIONS={[130, 132, 180].map(motorFormFactor => {
          return helper.generateOptionForReactSelect(motorFormFactor) 
        })
      }

      onChange={selectedOptions => onFilterChange("flywheelMotorFormFactor", selectedOptions)}

    />
  )
}

export default FlywheelMotorFormFactorFilter;
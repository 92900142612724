import { useState, useReducer, useEffect } from 'react';

// External utilities/helpers
import update from 'immutability-helper';
import _ from 'lodash';

const useFormHelper = (
  initialFormValues, 
  onFormUpdate, 
  validateForm, 
  onSubmit,
  onSuccessfulFormSubmit) => {

  const [formState, formDispatch] = useReducer(_formReducer, {
    values: initialFormValues,
    hasAttemptedSubmit: false
  });
  const [errors, setErrors] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);



  // Listen for updates in formState
  useEffect(() => {
    if (!_.isEqual(initialFormValues, formState.values)) {    // Do this to ignore on init
      // Handle onChange validation. This format is needed to handle async stuff 
      // in useEffect hook
      async function validateOnChange() {
        let newErrors = await validateForm.validateOnChange(formState.values);
        setErrors(newErrors)
      }

      // Handle validations on every form change (specified by 
      // validateForm.onFormChange) if validateForm.onFormChange exists
      if (!!validateForm.validateOnChange) {
        validateOnChange()
      }
    }
    
  }, [formState.values]);

  // Listen for updates in errors
  useEffect(() => {
    if (!_.isEqual(initialFormValues, formState.values)) {    // Do this to ignore on init
      // Update hasErrors flag whenever errors changes, not only during submissions.
      // This is so events can react to isFormValid without hitting submit
      // If errors is empty, then there are no errors
      const hasErrors = !_.isEmpty(errors);
      setIsFormValid(!hasErrors);

      if (isSubmitting) {
        // No errors/form is valid, reset and call callbacks that form has been 
        // successfully submitted
        if (!hasErrors) {
          updateForm({}, "RESET");
          onSuccessfulFormSubmit();
          setIsSubmitSuccessful(true);
        }
        
        setIsSubmitting(false);

      }

      onFormUpdate(formState.values, errors, hasErrors);

    }
  }, [errors])
  

  // Update form values using actions 
  //  - updateValue (object): immutability-helper command or just a regular object
	//      updateValue should be in proper format for immutable helper. Here's an 
	//      example format:
	//        {blaster: {
	// 	        name: {$set: selectedOption.value},
	// 	        isNew: {$set: !!selectedOption.__isNew__}
  //      }}
  //      
  //      Alternatively, if you pass in an entirely new state (not through imm-helper),
  //      then form vals will just be updated to that new state
  //  - actionType (string): Type of action. Default is set, but supports:
  //      - RESET: resets form to initial state 
  function updateForm(updateValue, actionType) {
    // Reset action
    if (actionType === "RESET") {
      formDispatch({
        type: "RESET"
      })

      return
    // submit attempt action
    } else if (actionType === "SUBMIT ATTEMPT") {
      formDispatch({
        type: "SUBMIT ATTEMPT"
      })
    }


    // Set values default action type
    formDispatch({
      type: 'SET_VALUES',
      payload: {values: updateValue}
    })
    
  }

  // Callback when submit btn pressed, need to validate errors and set 
  // arropriate flags
  async function handleSubmit(e) {
    setIsSubmitting(true);
    updateForm({}, "SUBMIT ATTEMPT");

    onSubmit();


    // Handles final form validation on submit. This is the final validation, so
    // if the onChange validation is passed in, it's also called. 
    if (!!validateForm) {
      let newErrors = {}

      // validateForm is only a function, meaning it's not an object with 
      // validateOnSubmit and validateOnChange. Simply call validateForm, it will
      // return errors
      if (typeof(validateForm) === "function") {
        newErrors = await validateForm(formState.values)

      // validateForm is an object, so it has validateOnSubmit and/or validateOnChange
      // functions. Call both, if they exist
      } else if (typeof(validateForm) === "object") {
        let onChangeErrors = {}, onSubmitErrors = {}
        
        // Verify that validateOnChange and validateOnSubmit exist before calling
        if (!!validateForm.validateOnChange) {
          onChangeErrors = await validateForm.validateOnChange(formState.values)
        }

        if (!!validateForm.validateOnSubmit) {
          onSubmitErrors = await validateForm.validateOnSubmit(formState.values)
        }

        // Merge onChangeErrors and onSubmitErrors 
        newErrors = {
          ...onChangeErrors,
          ...onSubmitErrors
        }

      }

      setErrors(newErrors)
    }
    
  }

  // Reducer function to manipulate form stuff. Using immutability-helper, so 
  // make sure payload is a proper immutability-helper command
  function _formReducer(currentformState, action) {
    const { type, payload } = action;

    switch (type) {
      case 'SET_VALUES':
        // If update() (from immutability helper) fails, it will be caught in the
        // catch statement. update() can fail if payload actually isn't in the
        // immutability helper form, so it's like a regular object or something.
        // if that's the case, just set values to payload
        try {
          return update(currentformState, payload);
        } catch (err) {
          return {
            ...currentformState,
            ...payload
          }
        }
      case "SUBMIT ATTEMPT":
        return {
          ...currentformState,
          hasAttemptedSubmit: true
        }
      case "RESET":
        return {
          values: initialFormValues,
          hasAttemptedSubmit: false
        }

      default:
        throw new Error()
    }
  }


  return [
    // Values
    formState.values,   // Current form values
    updateForm,
    errors,
    isFormValid,
    handleSubmit,

    // Flags
    isSubmitting,
    formState.hasAttemptedSubmit,
    isSubmitSuccessful
  ]
}

export default useFormHelper;
import React, { Component } from 'react';

import SliderWithTooltipAndText from "../../../Functional/SliderWithTooltipAndText";

import './index.css';

export default class LiPoCheckerHeadroomSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      headroom: 10
    }
  }

	render () {
		return (
      <div id="LiPoCheckerHeadroomSelect" className="lipo-checker-section">
      	<h2>Enter your desired headroom</h2>
        <p>Position the slider to your desired value.</p>
        <SliderWithTooltipAndText
          containerClassName="lipo-checker-slider-container"
          title="Headroom"
          description="% extra as headroom or buffer, recommended 10%"
          sliderPos={this.state.headroom}
          value={this.state.headroom}
          sliderValSuffix="%"
          min={0} max={20} step={1} 
          defaultValue={this.state.headroom}
          marks={function () {
            let marks = {}
              for (var i = 0; i <= 20; i+=2) {
                marks[i] = i + "%";
              }

              return marks;
              }()
          }
          onChange={(newSliderPos) => {this.setState({headroom: newSliderPos}, 
            () => {
              if (this.state.headroom > 0) {
                this.props.updateHeadroom(this.state.headroom);
              }
            })}}
          />
      </div>
		);
	}


}

import React from 'react';

import Comment from './Comment';


import './index.scss';


const CommentsDisplay = props => {
  const { comments } = props;

  // If no comments, display no comments message
  if (!comments.length) {
    return (
      <div className="comments-display-container grey-border-bottom">
        <p>There are no comments</p>
      </div>
    )
  }

  return (
    <div className="comments-display-container grey-border-bottom">
      {
        comments.map(comment => {
          return (
            <Comment
              comment={comment}
            />
          )
        })
      }
    </div>
  )
  
}

export default CommentsDisplay;
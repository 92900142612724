import React, { useState, useEffect } from 'react';

import { Link, Redirect } from 'react-router-dom';
import Markdown from 'react-remarkable'
import _ from 'lodash';
// import Checkbox from 'rc-checkbox';
// import 'rc-checkbox/assets/index.css';
import './index.css';

import Loading from '../../Loading';
import Carousel from '../../Functional/Carousel';
import Dropdown from '../../Functional/Dropdown';

import Options from './Options';
import ShippingInfo from './ShippingInfo';
import Description from './Description';

import useDataFromBackend from '../../../Hooks/useDataFromBackend';



const ShopItem = props => {
  const KEY = props.match.params.id;

  const { cart } = props;

  const [ SHOP_DATA, isShopDataLoading ] = useDataFromBackend("shop-data");

  const [ ITEM_DATA, setItemData ] = useState(null);
  const [ isItemDataLoading, setIsItemDataLoading ] = useState(true);

  const [quantity, setQuantity] = useState(1); 
  const [price, setPrice] = useState(0); 
  const [ selectedOptions, setSelectedOptions ] = useState([]);
  const [ canHaveOptions, setCanHaveOptions ] = useState(false); 
  // Text for action button to add to cart, pre-order, or indicate out of stock
  // default value is out of stock
  const [ outOfStock, setOutOfStock] = useState(true);
  const [ actionBtnTxt, setActionBtnTxt ] = useState("Out of Stock") 

  const [toRedirect, setToRedirect] = useState(false); 


  // update ITEM_DATA when SHOP_DATA is set
  useEffect(() => {
    if (!isShopDataLoading) {
      // Find matching item data that might not have the same KEY as index in arr
      setItemData(SHOP_DATA.find(shopItem => shopItem.key == KEY))
      setIsItemDataLoading(false);
    } 
  }, [ isShopDataLoading ] );



  // Update/assign values that rely on ITEM_DATA when item data finshes loading
  useEffect(() => {
    if (!isItemDataLoading) {
      setCanHaveOptions(!!ITEM_DATA.options && ITEM_DATA.options.length > 0);
      setPrice(parseInt(ITEM_DATA.price));

      cart.setShopData(SHOP_DATA);

      // Comment out these to bypass outOfStock:
      // Out of stock
      if (ITEM_DATA.stock < 1) {
        setActionBtnTxt("Out of Stock");
        setOutOfStock(true);
      // Preorder
      } else if (ITEM_DATA.preorder) {
        setActionBtnTxt("Pre-Order Now");
        setOutOfStock(false);
      // Backorder
      } else if (ITEM_DATA.backorder) {
        setActionBtnTxt("Backorder");
        setOutOfStock(false);
      // Regular: in stock, not preorder, not backorder
      } else {
        setActionBtnTxt("Add to Cart");
        setOutOfStock(false);
      }

      // Uncomment out these to bypass outOfStock:
      // setOutOfStock(false);
    }
  }, [ isItemDataLoading ]);
  


  // Callback to handle everything when the purhcase button is clicked
  const onPuchaseButtonClick = e => {
    setToRedirect(true);

    if (selectedOptions.length === 0) {   //no options selected
      if (canHaveOptions) {    //has no options, but can have options: -1 flag
        cart.addToCart(quantity, ITEM_DATA.key, price, -1);
        return;
      }

      cart.addToCart(quantity, ITEM_DATA.key, price, false);
      return;
    }

    cart.addToCart(quantity, ITEM_DATA.key, price, selectedOptions);

  }

  

  if (!isShopDataLoading && !isItemDataLoading) {
    return (
      <div id="ShopItem">
        <div id="shop-item-content">

          { /* Carousel */ }
          <div id="carousel-container">
            <Carousel 
              imgs={ITEM_DATA.imgs} 
              options={{
                minWidth: "20em",
                "width": "45em" ,
                "height": "45em",
                "smallImgs": true,
                "zoomOnHover": true,
                "zoomScale": 2
              }}
            />
          </div>

          <div id="shop-item-details">
            { /* Content to the right of carousel - main shop interaction */}
            <div className="grey-border-bottom">
              <h1>{ITEM_DATA.name}</h1>
              <h2>{"$" + price.toFixed(2)}</h2>

              { /* Qty select dropdown */}
              <h3 className="shop-item-info-heading">Quantity:</h3>
              <Dropdown 
                qtyToRender={ITEM_DATA.stock > 10 ? 10 : ITEM_DATA.stock} 
                dropdownChanged={
                  selectedQty => { setQuantity(parseInt(selectedQty)) }
                } 
              />

              { /* Options */ }
              {/* Don't render options if the item doesn't have any */}
              {
                canHaveOptions && 
                <Options 
                  itemKey={ITEM_DATA.key}
                  selectedOptions={selectedOptions}
                  itemOptionsFromDB={ITEM_DATA.options}
                  onOptionsChanged={
                    (newOptions, dPrice) => {
                      setPrice(ITEM_DATA.price + dPrice);
                      setSelectedOptions(newOptions);
                    }}
                /> 
              }

              { /* Purchase btn */ } 
              <h4>
                <Link 
                  to="/cart"
                  className={"button-style-1 " + (outOfStock ? "disabled-link" : "")}
                  onClick={onPuchaseButtonClick}>
                  {actionBtnTxt}
                </Link>
              </h4>

              { /* Shipping message */ } 
              <p>{ITEM_DATA.shippingMessage}</p>
            </div>

            { /* Shipping Info */ } 
            <ShippingInfo 
              preOrder={ITEM_DATA.preOrder}
              cart={cart}
              quantity={quantity}
              itemData={ITEM_DATA}
            />

          </div>
        </div>

        <Description itemData={ITEM_DATA}/>

        { toRedirect && <Redirect to={'/cart'}/>}
      </div>
    )
  }

  return <Loading/>
}

export default ShopItem;


import React from 'react';
import Creatable from 'react-select/creatable';

const BlasterEntry = props => {
  const { blaster, 
    onChange, 
    BLASTERS,
    generateOptionForReactSelect,
    errorText,
    styles } = props;

  return (
    <div>
      <Creatable className="select-entry"
        styles={styles}
        placeholder={"Blaster *"}
        value={ 
          /* If no blaster is selected, default value is null and placeholder 
          should be displayed */
          blaster.value.length > 0 ? 
            { value: blaster.value, label: blaster.value } 
            : null
        }
        options={
          BLASTERS.map(blaster => {
            return generateOptionForReactSelect(blaster) 
          })
        }
        onChange={selectedOption => onChange(
          {blaster: { 
            value: {$set: selectedOption.value},
            isNew: {$set: !!selectedOption.__isNew__}
          }}
        )}	
      />
      
      <p className="setup-entry-help">
        Enter your blaster, it can be Nerf, aftermarket, or custom! If your blaster isn't listed here, create a new one and it will be added to the database.
      </p>

      <p className="center-text error-text">{errorText}</p>

    </div>
  )
}

export default BlasterEntry;
import React from 'react';

import Helper from 'Helper';


const helper = new Helper();


const AllErrorText = props => {
  const { errors } = props

  let allErrorTextArr = [];

  allErrorTextArr.push(errors.setupName);
  allErrorTextArr.push(errors.username);
  allErrorTextArr.push(errors.blaster);
  allErrorTextArr.push(errors.fps);
  allErrorTextArr.push(errors.rateOfFire);
  allErrorTextArr.push (errors.price);
  allErrorTextArr.push(errors.cellCount);

  // Error text for setups
  if (!!errors.setup) {
    errors.setup.forEach(stage => {
      for (let [key, value] of Object.entries(stage)) {
        allErrorTextArr.push(value);
      }
    });
  }

  // Error text for pusher
  if (!!errors.pusher) {
    for (let [key, value] of Object.entries(errors.pusher)) {
      allErrorTextArr.push(value);
    }
  }

  let allErrTxt = helper.getArrayAsTextList(
    helper.removeUndefinedItemsFromArr(allErrorTextArr));
  
  return (
    <p className="error-text center-text">
      {allErrTxt}
    </p>
  )
}

export default AllErrorText
import React from 'react';
import DropdownFilter from './DropdownFilter';

import Helper from 'Helper';


const helper = new Helper();


const PusherMotorFormFactorFilter = props => {
  const { filter, onFilterChange } = props;

  return (
    <DropdownFilter
      title={"Pusher Motor Form Factor"}
      placeholder={"Pusher Motor Form Factor"}
      filter={filter}

      SELECTIONS={[130, 132, 180].map(motorFormFactor => {
          return helper.generateOptionForReactSelect(motorFormFactor) 
        })
      }

      onChange={selectedOptions => onFilterChange("pusherMotorFormFactor", selectedOptions)}

    />
  )
}

export default PusherMotorFormFactorFilter;
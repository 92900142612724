import React from 'react';
import { Comment } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';

import Helper from "Helper";


const CommentIcon = props => {
  const { comments,
    areCommentsExpanded, 
    setAreCommentsExpanded, 
    
    isFullscreen } = props;

  const helper = new Helper();

  return (
    <Tooltip 
      title={areCommentsExpanded ? "Hide comments" : "Show comments"} 
      disableFocusListener={isFullscreen}
      disableHoverListener={isFullscreen}
      disableTouchListener={isFullscreen}
      placement="top"
    >
      <div 
        className={`item flex-container-center flex-container-vertical-center ${(isFullscreen ? "" : "pointer-on-hover")}`}
        onClick={e => {
          if (!isFullscreen) {
            setAreCommentsExpanded(prev => !prev)
          } 
        }}
      >
        <Comment fontSize="small"/>
        <p style={{
          margin: 0,
          marginLeft: "0.5em"}}>
          {`${comments.length} ${helper.pluralizer("Comment", comments.length)}`}
        </p>          
      </div>
    </Tooltip>
  )
}

export default CommentIcon;
import React from 'react';
import DropdownFilter from './DropdownFilter';

import Helper from 'Helper';


const helper = new Helper();



const CellCountFilter = props => {
  const { filter, onFilterChange } = props;

  return (
    <DropdownFilter
      title={"Battery Cell Count"}
      placeholder={"Battery Cell Count"}
      filter={filter}

      SELECTIONS={["1S", "2S", "3S", "4S", "5S"].map(blaster => {
        return helper.generateOptionForReactSelect(blaster) 
      })}

      onChange={selectedOptions => onFilterChange("cellCount", selectedOptions)}
    />

  )
}

export default CellCountFilter;
import React from 'react';
import { Collapse } from '@material-ui/core';

import CollapsibleTextTrigger from '../../../../Functional/CollapsibleTextTrigger';


const FPSPerformance = props => {
  const { muzzleVelocities } = props;

  const [ areMuzzleVelocityEntriesExpanded, setAreMuzzleVelocityEntriesExpanded ] = React.useState(true);


  // If there's only 1 muzzle velocity entry to render, don't do the collapsible
  if (muzzleVelocities.length === 1) {
    return (
      <div className="FPSPerformance" 
        style={{ paddingBottom: "0.7em" }}
      >
        
        <div className="primary-muzzle-velocity-entry">
          <h2 className="inline-txt"
            style={{
              marginRight: "5px"
            }}
          >
            { muzzleVelocities[0].fps.replace(" FPS", "") }
          </h2>

          <p className="inline-txt">FPS</p>

          <p className="setup-entry-help">
            {`with ${muzzleVelocities[0].dartType}`}
          </p>
        </div>
        
      </div>
    )
  // There are multiple muzz vel entries to render, so put them in a collapsible
  // that's open by default
  } else {
    return (
      <div className="FPSPerformance" 
        style={{ paddingBottom: "0.7em" }}
      >
        <div className="primary-muzzle-velocity-entry">
          <CollapsibleTextTrigger
            isExpanded={areMuzzleVelocityEntriesExpanded}
            setIsExpanded={setAreMuzzleVelocityEntriesExpanded}
            containerClassName="flex-container-center"
          >
            <div>
              <h2 className="inline-txt"
                style={{
                  marginRight: "5px"
                }}
              >
                { muzzleVelocities[0].fps.replace(" FPS", "") }
              </h2>

              <p className="inline-txt">FPS</p>

            </div>
          </CollapsibleTextTrigger>

          <p className="setup-entry-help">
            {`with ${muzzleVelocities[0].dartType}`}
          </p>
        </div>

          
        <Collapse in={areMuzzleVelocityEntriesExpanded}>
          {/* Every other Muzzle Velocity Entry/not the primary entry */}
          <div className="muzzle-velocity-entries">
            {
              muzzleVelocities.map((muzzleVelocity, index) => {
                if (index !== 0) {  // Skip first/primary entry
                  return (
                    <li className="setup-entry-help">
                      {`${muzzleVelocity.fps} with ${muzzleVelocity.dartType}`}
                    </li>
                  )
                }
              })
            }
          </div>
        </Collapse>


        

      </div>
    )
  }

}



export default FPSPerformance;
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Sidebar from '../../Functional/Sidebar';
import FloatingBtn from '../../Functional/FloatingBtn';
import HamburgerIcon from '../../Functional/HamburgerIcon';
import Modal from '../../Functional/Modal';

import './index.css';

export default class Navigation extends Component {
	constructor(props) {
		super(props);

		this.state = {
			toShowModal: false
		}
	}

	render() {
		const LINKS_DATA = this.generateLinksData();
		return (
			<div id="docs-navigation">
				<Sidebar id="docs-sidebar">
					<div id="docs-sidebar-content">
						{this.renderLinks(LINKS_DATA)}
					</div>
				</Sidebar>
				<Modal id={"docs-modal"} isVisible={this.state.toShowModal}>
	    		<div id="docs-modal-content">
	    			{this.renderLinks(LINKS_DATA)}
	    		</div>	
	    	</Modal>
	    	<FloatingBtn id="docs-floating-btn" onClick={e => this.handleOnClick()}>
	    		<HamburgerIcon animateOnClick={true}/>
	    	</FloatingBtn>
			</div>
		)
	}

	generateLinksData() {
		let linksData = [{
			to: "/docs",
			txt: "Getting Started",
			className: "docs-modal-link docs-modal-link-main ",
			selectedClassName: "docs-modal-link-selected",
			txtClassName: "docs-modal-link-txt"
		}];

		this.props.DATA.map(docData => {
			linksData.push({
				to: "/docs/" + docData.key,
				txt: docData.title,
				className: "docs-modal-link docs-modal-link-main ",
				selectedClassName: "docs-modal-link-selected",
				txtClassName: "docs-modal-link-txt"
			});
		});

		return linksData;
	}

	renderLinks(LINKS_DATA) {
		return (
			LINKS_DATA.map(LINK_DATA => {
				return (
					<Link 
    				to={LINK_DATA.to} 
    				className={LINK_DATA.className + (this.checkIsSelected(LINK_DATA.to) ? LINK_DATA.selectedClassName : "")}
    				onClick={e => this.handleOnClick(e)}>
    				<p className={LINK_DATA.txtClassName}>{LINK_DATA.txt}</p>
    			</Link>
			)
			})
		);
	}

	checkIsSelected(to) {
		const url = window.location.pathname;

		return ((url === to) || (url === to + "/")) ? true : false;
	}

	handleOnClick(e) {
		this.setState({toShowModal: !this.state.toShowModal});
	}

}


import React, { Component } from 'react';
import { ScatterChart, Scatter, CartesianGrid, Tooltip,
 XAxis, YAxis, ZAxis, ReferenceArea } from 'recharts';
import _ from 'lodash';

import MotorChartTooltip from './Tooltip';
import Legend from './Legend';

import './index.css';

//funcitonal classes
import Helper from 'Helper';
let helper = new Helper();

export default class MotorChart extends Component {
	constructor (props) {
		super(props);

		this.onClickNode = this.onClickNode.bind(this);

		this.state = {
			motorDataToDisplay: _.map(this.props.DATA, _.clone),		//data of all motors to be displayed
			zRange: [400, 2000],
			hiddenMotorParams: [],		//parameters of motors not to display. Arr of obj: {cell: x, volting: y} if for volting, only int if for size
			motorInfo: {},						//motor data of motor info displayed under chart
			selectedMotors: []		//arr of selected motor that need to be highlighted
		}

	}

  render() {
  	let allScatters = this.renderScatters();

    return (
      <div id="MotorChart">
				<ScatterChart width={1000} height={500}
					margin={{top: 15, right: 25}}>
				  <CartesianGrid strokeDasharray="3 3" />
				  <XAxis
				  	type='number'
				  	dataKey="speed"
				  	name="Speed"
				  	unit=" RPM"
				  	tickCount={8}
				  	domain={[20000, 75000]}/>
				  <YAxis
				  	dataKey="torque"
				  	name="Torque"
				  	unit=" gf.cm"
				  	domain={[0, 2000]} />
				  <ZAxis
				  	dataKey="z"
				  	name="Form Factor"
				  	range={this.state.zRange} />
				  <ReferenceArea
				  	x1={31000}
				  	x2={38000}
				  	y1={200}
				  	y2={1300}
				  	stroke="black"
				  	strokeOpacity={0.1}
				  	label={"Good Flywheel Motors"} />
				  <ReferenceArea
				  	x1={45000}
				  	x2={56000}
				  	y1={600}
				  	y2={1800}
				  	stroke="black"
				  	strokeOpacity={0.1}
				  	label={"Good Eclipse Motors"} />
			  	<ReferenceArea
				  	x1={50000}
				  	x2={70000}
				  	y1={300}
				  	y2={1900}
				  	stroke="black"
				  	strokeOpacity={0.1}
				  	label={"Good FTW Motors - short darts"} />
			  	<ReferenceArea
				  	x1={65000}
				  	x2={75000}
				  	y1={400}
				  	y2={2000}
				  	stroke="black"
				  	strokeOpacity={0.1}
				  	label={"Good FTW Motors - full length"} />
				  <Tooltip
				  	cursor={{ strokeDasharray: '3 3' }}
				  	wrapperStyle={{
				  		backgroundColor: "white",
				  		paddingLeft: 10,
				  		paddingRight: 10,
				  		paddingBottom: 10,
				  		border: "1px solid #939393"
				  	}}
				  	content={
				  		<MotorChartTooltip
					  		findDataOfNode={this.findDataOfNode}
					  		DATA={this.props.DATA}/>} 
		  		/>
				  {allScatters.cell2}
				  {allScatters.cell3}
				  {allScatters.cell4}
				  {allScatters.cell5}
				  {allScatters.ovrCell2}
				  {allScatters.ovrCell3}
				  {allScatters.ovrCell4}
				  {allScatters.ovrCell5}
				  {allScatters.underCell2}
				  {allScatters.underCell3}
				  {allScatters.underCell4}
				  {allScatters.underCell5}
				</ScatterChart>
				<Legend
					getVoltingStateFromShape={this.props.getVoltingStateFromShape}
					handleChangeHiddenMotorData={this.handleChangeHiddenMotorData.bind(this)}
					hiddenMotorParams={this.state.hiddenMotorParams}/>
      </div>
    )
  }

  renderScatters() {
  	let allScatter = {};
  	let cells = [2, 3, 4, 5];

  	for (var i = 0; i < cells.length; i++) {
  		let batteryKey = i + 2;		//"translate" iterator to cell
	  	//get nominally volted battery stats
  		allScatter["cell" + batteryKey] = this.renderScatter(batteryKey + "S",
				this.findMotors({battery: batteryKey}, 0),
				this.props.dotColors[i], 
				true,
				"circle");

  		//get overvolted battery stats
  		allScatter["ovrCell" + batteryKey] = this.renderScatter(batteryKey + "S",
				this.findMotors({battery: batteryKey}, 1),
				this.props.dotColors[i], 
				true,
				"square");

  		//get undervolted battery stats
  		allScatter["underCell" + batteryKey] = this.renderScatter(batteryKey + "S",
				this.findMotors({battery: batteryKey}, -1),
				this.props.dotColors[i], 
				true,
				"triangle");
  	}

  	return allScatter;
  }

  //voltage key determines whether searching for overvoltage/undervoltage/nominal
  //under = -1, nom = 0, over = 1
  findMotors(toFind, voltageKey) {
  	let foundMotors = [];

		for (var i = 0; i < this.state.motorDataToDisplay.length; i++) {
			let currentMotor = Object.assign({}, this.state.motorDataToDisplay[i]);

			if (toFind.formFactor) {	  	//search for motors based on form factor
				if (currentMotor.formFactor === toFind.formFactor) {
  				foundMotors.push(currentMotor);
  			}
			} else if (toFind.battery) {			//search based on battery
				//toFind,battery arr, so much search for battery in arr
				if (currentMotor.battery === toFind.battery) {
					//check for under/over volting
					if (voltageKey === currentMotor.voltingState) {
						//data only gives specs at lowest battery, so make sure to fix specs for motors on more cells
						foundMotors.push(currentMotor);
					} else if (voltageKey === currentMotor.voltingState) {
						foundMotors.push(currentMotor);
					} else if (voltageKey === currentMotor.voltingState) {
						foundMotors.push(currentMotor);
					}
					
				}
			}
			
		}

  	return foundMotors;
  }

  renderScatter(name, data, fillP, isVisible, shape) {
  	return (
	  	<Scatter
		  	name={name}
		  	data={isVisible ? data : {}}
		  	fill={isVisible ? fillP : "#66000000"}
		  	stroke={isVisible ? "#939393" : "#66000000"}
		  	onClick={isVisible ? this.onClickNode : () => {}}
		  	fillOpacity={0.6}
	  		shape={shape}/>
		)
  }

  //handle all the changes to whatever motor data that can be visible on chart
  handleChangeHiddenMotorData(toChange, volting) {
  	//remove "S" from LiPo cell count
  	let newHiddenMotorParams = this.changeHiddenMotorParams(toChange, volting);

  	//create new arr that doesn't include the stuff we won't want to see
  	let newMotorChartData = this.changeMotorChartData(newHiddenMotorParams);

  	//z domain might change because of motor sizes changing
  	let newZRange = this.getNewZRange(newHiddenMotorParams);


  	this.setState({
  		motorDataToDisplay: newMotorChartData,
  		hiddenMotorParams: newHiddenMotorParams,
  		zRange: newZRange});

  }

  //this.state.hiddenMotorParams array of strings
  changeHiddenMotorParams(toChange, volting) {
  	let newHiddenMotorParams = _.map(this.state.hiddenMotorParams, _.clone);		//edit copy of hiddenMotorParams

  	//check to see if volting, so toChange will be obj: {cell: x, volting: y}
  	if (volting || volting === 0) {
  		toChange = {
  			battery: toChange,
  			volting: volting
  		}
  	}

  	//check to see if hiddenMotorParams has what's needed to change.
  	//if it does have toChange, then remove toChange.
  	//if doens't have, add toChange
  	if (helper.isItemInArr(newHiddenMotorParams, toChange)) {
  		_.remove(newHiddenMotorParams, function (item) {
  			return _.isEqual(item, toChange)
  		});
  	} else {
  		newHiddenMotorParams.push(toChange);
  	}

  	return newHiddenMotorParams;

  }

	//change what dots to be seen on chart based on which legend items clicked
  changeMotorChartData(newHiddenMotorParams) {
  	let newMotorChartData = this.props.DATA.map(
  		(indivMotorData) => this.getNewMotorChartData(indivMotorData, newHiddenMotorParams));

  	//remove all undefines from newMotorChartData
  	_.remove(newMotorChartData, function (item) {
  			return !item;
  		});


  	return newMotorChartData;
  }

  //get new motor chart data based on newHiddenMotorParams. 
	//If motor matches anything on newHiddenMotorParams, it shouldn't be displayed
	//so don't push it to arr of motors to be diplayed 
  getNewMotorChartData(indivMotorData, newHiddenMotorParams) {
  	let toReturn = true;		//flag to see if to return the individual motor
		for (var i = 0; i < newHiddenMotorParams.length; i++) {				
			if (typeof newHiddenMotorParams[i] === "object") {	
				if (indivMotorData.battery == newHiddenMotorParams[i].battery
					&& indivMotorData.voltingState === newHiddenMotorParams[i].volting) {
					toReturn = false;
					break;
				}
			} else  {
				//don't want to check type (using ===) b/c toChange string
	  		if (indivMotorData.formFactor == newHiddenMotorParams[i]
	  			|| indivMotorData.battery == newHiddenMotorParams[i]) {
	  			toReturn = false;
	  			break;
	  		}
	  	}
  	}

  	if (toReturn) {
  		return indivMotorData;
  	}
  }

  getNewZRange(hiddenMotorParams) {
  	let newZRange = [];
  	let formFactorsToShow = this.getFormFactorsToShow(
  		this.getFormFactorsToHide(hiddenMotorParams));

  	// translate formFactorsToShow to z coords
  	if (formFactorsToShow.length === 0) {		//no form factors need to be shown
  		return [400, 400];
  	} else if (formFactorsToShow.length >= 1) {			//only 1 form factor to be shown
  		newZRange.push(this.props.getZCoordFromFormFactor(formFactorsToShow[0]));
  	}

  	newZRange.push(
  		this.props.getZCoordFromFormFactor(
  			formFactorsToShow[formFactorsToShow.length - 1]));

  	return newZRange;

  }

  getFormFactorsToHide(hiddenMotorParams) {
  	return (_.remove(
  		_.map(hiddenMotorParams, _.clone), function (item) {
				return item > 100;
	  	})
  		.sort()				//sort highest to lowest
  		.map(Number)		//convert to Number
		);
  }

  //return array of form factors to show by removing form factors that should be hidden
	//basically removing formFactorsToHide from allFormFactors
  getFormFactorsToShow(formFactorsToHide) {
  	let allFormFactors = [130, 132, 180];

  	return (
  		_.remove(
  			allFormFactors,
	  		function (item) {
	  			//find matches between allFormFactors and formFactorsToHide
	  			return _.indexOf(formFactorsToHide, item) < 0
	  		})
		  	.sort()				//sort highest to lowest
	  		.map(Number)		//convert to Number
		);
  }

  onClickNode(info, proxy) {
  	this.setState(
			{motorInfo: this.findDataOfNode(info.node, this.props.DATA)}, () => {
				this.props.handleMotorInfoChange(this.state.motorInfo);
		});
  }

  //find data of node given node coords
  findDataOfNode(node, DATA) {
		for (var n = 0; n < DATA.length; n++) {		//iterate through all data
			if (DATA[n].speed === node.x && DATA[n].torque === node.y) {
				return DATA[n];
			}		//if
  	}		//for

  }


}

import React from 'react';
import DropdownFilter from './DropdownFilter';

import Helper from 'Helper';


const helper = new Helper();


const NumberOfStagesFilter = props => {
  const { filter, onFilterChange } = props;
  
  return (
    <DropdownFilter
      title={"Number of Stages"}
      placeholder={"Number of Stages"}
      filter={filter}

      SELECTIONS={[1, 2, 3, 4, 5, 6, 7, 8 ,9, 10].map(stageQty => {
        return helper.generateOptionForReactSelect(stageQty) 
      })}

      onChange={selectedOptions => onFilterChange("numberOfStages", selectedOptions)}
    />

  )
}

export default NumberOfStagesFilter;
import React from 'react';

const motorCredits = props => {
	return (
		<div>
			<p className="center-align-text">All images and corresponding motor information credit to their respective shops under their permission</p>
			<p className="center-align-text">Motor data from  <a href="https://docs.google.com/spreadsheets/d/12gOU-GbuqdGVzpOjZJvQ_gq1Nmw63c_sd13Wt4bfn1E/edit#gid=0">Motor Database Archive</a></p>
		</div>
	)
}

export default motorCredits;
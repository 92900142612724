import React from 'react';
import Select from 'react-select';

const FlywheelCageMaterialEntry = props => {
  const { flywheelCageMaterialInForm, 
    errorText,
    updateFlywheelSetupForm,
    getUpdatedSetup,
    styles,

    FLYWHEEL_CAGE_MATERIALS,
    generateOptionForReactSelect } = props;
  
  return (
    <div>
      <Select className="select-entry"
        styles={styles}

        placeholder={"Flywheel Cage Material *"}
        value={
          // Only assign Select.value as flywheels if a flywheels has been entered
          flywheelCageMaterialInForm.value === "" ?
            null :
            generateOptionForReactSelect(flywheelCageMaterialInForm.value)
        }
        options={
          FLYWHEEL_CAGE_MATERIALS.map(flywheelCageMaterial => {
            return generateOptionForReactSelect(flywheelCageMaterial) 
          })
        }
        onChange={selectedFlywheelCageMaterial => {
          let updatedSetup = getUpdatedSetup(
            { flywheelCageMaterial: { 
              value: { $set: selectedFlywheelCageMaterial.value },
              isNew: { $set: !!selectedFlywheelCageMaterial.__isNew__ } } 
            }
          );

          updateFlywheelSetupForm(updatedSetup);
        }}
      />

      <p className="setup-entry-help">
        Enter your flywheel cage material. If your cage is 3D printed,  please specify the exact material. If your material isn't here, feel free to type in a new one to add it to the database! If your cage is stock, please select 'Stock' instead of 'Injection Molded' to help differentiate aftermarket injection molded flywheel cages from stock cages.
      </p>

      <p className="center-text error-text">{errorText}</p>
    </div>
  )

}

export default FlywheelCageMaterialEntry;
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Image from '../Functional/Image';
import Links from './Links';

import './index.scss';

export default class Navbar extends Component {
  render () {
    return (
      <header id="Navbar">
        <div id="header" className="flex-container">
          <Link id="logo" className="flex-container-left" to="/">
            <Image id="logo-img" src={require(`assets/Suild Logo.png`)}/>
            <h1 id="logo-heading">Suild</h1>
          </Link>

          <Links id="navbar-link-container" className="flex-container-left"/>

          <Link to="/cart" id="nav-cart-container" className="flex-container-right">
            <div id="nav-cart-text-container">
              <p className="nav-cart-text" id="nav-cart-small-text">Your cart</p> 
              <p className="nav-cart-text" id="nav-cart-med-text">{this.props.cartSize + " items"}</p>
            </div>
            <img id="cart-img" src={require(`assets/cart.png`)}/>
          </Link>
        </div>

        <div id="mobile-header">
          <Links id="mobile-navbar-link-container"/>
        </div>


      </header>
    )
  }

}
import React from 'react';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';


// Custom components
import SetupScroll from './SetupScroll';
import Loading from '../../../Loading';


import scrollToElement from 'scroll-to-element';
import _ from 'lodash';


import './index.scss';


const Setups = React.memo(props => {
  const { isLoading, 
    
    motorHelper, 
    searchID, 
    setups, 
    
    getMoreSetups, 
    hasMoreSetups, 
    
    finishScrollToSetup } = props;

  const [ setupFound, setSetupFound ] = React.useState(false); 
  const [ isSnackbarOpen, setIsSnackbarOpen ] = React.useState(!!searchID && searchID.length > 3); 

  
  // Enable scrolling to setup
  React.useEffect(() => {
    // After setups are done loading, wait 0.5 sec, then scroll to setup in search
    if (!isLoading) {
      setTimeout( 
        () => {
          // Don't even attempt search if searchID not specified, otherwise 
          // on() callback wont work
          if (!!searchID) {
            let scrollEvent = scrollToElement(`#setup-${searchID}`)
            
            // Make sure scroll event exists before trying to execute callback,
            // scroll event can be null sometimes (but idk why)
            if (!!scrollEvent) {
              scrollEvent.on('end', finishScrollToSetup);    // Callback when finish scrolling
            }
          }   // If
        }
        , 1000)
    }
  }, [searchID, isLoading]);


  if (!isLoading) {
    return (
      <div id="Setups">
        <p>{setups.length + " Setups"}</p>
        <SetupScroll
          getMoreSetups={getMoreSetups}
          hasMoreSetups={hasMoreSetups}
      
          setups={setups}
      
          searchID={searchID}
          setupFound={setupFound}
          setSetupFound={setSetupFound}
      
          setIsSnackbarOpen={setIsSnackbarOpen}
          
          motorHelper={motorHelper}
        />

        <Snackbar className="pointer-on-hover"
          open={isSnackbarOpen} 
          onClose={() => setIsSnackbarOpen(false)}
          autoHideDuration={6000}
          onClick={() => setIsSnackbarOpen(false)}
        >
          <Alert severity="error">
            <p style={{margin: 0}}>Setup ID not found</p>
          </Alert>
        </Snackbar>
        


      </div>
    )
  } 

  return <Loading/>



}, 
// Callback to skip update. Return true to skip update, false otherwise.
// This is basically shouldComponentUpdate
// Should update if:
//  - setups changes (to display the new setups)
//  - hasMoreSetups changes (update infinte scroller)
//  - isLoading changes (fetching setups)
(oldProps, newProps) => {
  return (_.isEqual(oldProps.setups, newProps.setups) 
    && (oldProps.hasMoreSetups === newProps.hasMoreSetups)
    && (oldProps.isLoading === newProps.isLoading))

});

export default Setups;
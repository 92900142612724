import React, { Component } from 'react';

import DataDependantComponent from '../../DataDependantComponent';

import MotorSelector from '../Functional/MotorSelector';
import MotorInfoTab from '../Functional/MotorInfoTab';
import MotorCredits from '../Functional/MotorCredits';

import MotorHelper from 'MotorHelper';
import Helper from 'Helper';

import './index.scss';


let motorHelper = new MotorHelper();
let helper = new Helper();

export default class MotorSpecFinder extends DataDependantComponent {
  constructor (props) {
    super(props);

    this.motorHelper = new MotorHelper;

    this.state = {
      isLoading: true,
      DATA: [],
      ALL_MOTOR_DATA: [],
      selectedMotor: {}
    }
  }

  afterDataRetrieved() {
    let ALL_MOTOR_DATA = motorHelper.applyNominalVoltage(
      motorHelper.applyBattery(
        motorHelper.applyIsSelected(
          helper.sortArrayInAlphabeticalOrder(this.state.DATA.data),
        false)));

    this.motorHelper.setData(ALL_MOTOR_DATA);

    this.setState({
      ALL_MOTOR_DATA: ALL_MOTOR_DATA 
    });
  }

  renderChildComponent () {  
    if (!this.state.isLoading && !!this.state.ALL_MOTOR_DATA) {       //make sure to get data first
      return (
        <div id="MotorSpecFinder">
          <h1 className="center-text">Motor Spec Finder</h1>
          <p className="center-text">Hover over an image to see the quick specs. Click and scroll down to see everthing else.</p>
          <MotorSelector 
            DATA={this.state.ALL_MOTOR_DATA} 
            onClick={this.selectMotor.bind(this)}/>
          <MotorInfoTab 
            motorInfo={this.state.selectedMotor}
            otherShopData={this.props.otherShopData}/>
          <MotorCredits/>
        </div>
      )
    } else {
      return (
        <div id="MotorSpecFinder"></div>
      )
    }

  }

  selectMotor(e, motorData) {
    this.setState({
      ALL_MOTOR_DATA: motorHelper.setIsSelected(this.state.ALL_MOTOR_DATA, [motorData]),
      selectedMotor: motorData
    });
  }


}
import React from 'react';
import DropdownFilter from './DropdownFilter';



const MuzzleVelocityFilter = props => {
  const { FPS_SELECTIONS, filter, onFilterChange } = props;

  return (
    <DropdownFilter
      title={"Muzzle Velocity"}
      placeholder={"Muzzle Velocity"}
      filter={filter}
      SELECTIONS={FPS_SELECTIONS}

      onChange={selectedOptions => onFilterChange("fps", selectedOptions)}

    />
      

  )
}

export default MuzzleVelocityFilter;
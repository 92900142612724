import React, { useState } from 'react';
import { Collapse } from '@material-ui/core';

import CellCount from '../CellCount';
import Icons from './Icons';


import numberToWords from 'number-to-words';


let toWords = numberToWords.toWords;


const PusherDisplay = props => {
  const { SETUP_DATA, motorHelper } = props;

  const [ isPusherInfoExpanded, setIsPusherInfoExpanded ] = useState(true);

  const { pusher } = SETUP_DATA;

  const cellCountInWords = toWords(pusher.batteryCellCount);

  return (
    <div className="setup-display-card-body-section grey-border-bottom" >
      <h3 className="pointer-on-hover"
        onClick={e => setIsPusherInfoExpanded(prev => !prev)}
      >
        Pusher Info
      </h3>
      <Collapse in={isPusherInfoExpanded}>

        {/* Pusher Mechanism */}
        <div className="setup-stage-spec">
          <p className="inline-text setup-stage-key-text">Mechanism:</p>
          <p className="inline-text setup-stage-value-text">
            {pusher.pusherMechanism}
          </p>
        </div>

        {/* Pusher Motor */}
        { pusher.pusherMotor.length > 1 &&
          <div className="setup-stage-spec">
            <p className="inline-text setup-stage-key-text">Motor:</p>
            <p className="inline-text setup-stage-value-text">
              {pusher.pusherMotor}
            </p>
          </div>
        }

        {/* Solenoid */}
        { pusher.solenoid.length > 1 &&
          <div className="setup-stage-spec">
            <p className="inline-text setup-stage-key-text">Solenoid:</p>
            <p className="inline-text setup-stage-value-text">
              {pusher.solenoid}
            </p>
          </div>
        }

        {/* Battery */}
        <div className="setup-stage-spec flex-container-left">
          <p className="inline-text setup-stage-key-text">Battery:</p>
          <CellCount
            cellCount={pusher.batteryCellCount}
            cellCountInWords={cellCountInWords}
            ish3={true}
            containerStyle={{
              marginTop: "0.6em"
            }}
          />
          <p className="setup-stage-value-text"
            style={{
              fontSize: "0.8em",
              marginLeft: "0.5em"}}>
            {pusher.batteryCellCount === SETUP_DATA.cellCount 
              ? "(same as flywheels)"
              : "(different from flywheels)"}
          </p>
        </div>

        {/* Icons, only if there's a pusher motor */}
        {
          pusher.pusherMechanism !== "Solenoid" && 
          <Icons
            motorHelper={motorHelper}
            pusherData={pusher}
          />
        } 

      </Collapse>
    </div>
  )
}


export default PusherDisplay;
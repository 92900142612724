import React from 'react';
import DropdownLoadSelector from '../../../../../../../Functional/DropdownLoadSelector';

const MotorEntry = props => {
  const { motorsInForm, 
    errorText,
    updateFlywheelSetupForm,
    getUpdatedSetup,
    styles,

    ALL_MOTOR_DATA,
    generateOptionForReactSelect } = props;
  
  return (
    <div className="stage-entry-form-item">
      {/* Motor Selection */}
      <DropdownLoadSelector
        ALL_MOTOR_DATA={ALL_MOTOR_DATA}
        additionalLoads={[ 
          {
            name: "Stock Motors",
            nominalBattery: [2]
          }
        ]}
        styles={styles}

        placeholder={"Flywheel Motors *"}
        value={ !!motorsInForm.value && 
          generateOptionForReactSelect(motorsInForm.value)}
          
        onChange={selectedMotor => {
          let updatedSetup = getUpdatedSetup(
            { motors: { value: { $set: selectedMotor.value } } }
          );

          updateFlywheelSetupForm(updatedSetup);
        }}
      />

      <p className="setup-entry-help">
        Flywheel motors as a pair. Stock motors are listed under 2S nominal.
      </p>

      <p className="center-text error-text">{errorText}</p>
    </div>
  )

}

export default MotorEntry;
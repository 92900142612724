import React, { Component } from 'react';

import './index.css';

export default class Message extends Component {
	render () {
		let { message } = this.props;

		if (!!message && message !== "") {
			return (
	      <div id="Message">
	      	<div id="message-wrapper" className="center-div">
	      		<p id="message-text" className="center-text">
							{this.props.message}
						</p>
	      	</div>
	      </div>
			);
		} else {
			return (
	      <div></div>
			);
		}
	}
}

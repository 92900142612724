// Class to represent a muzzle velocity input, includes fps itself and dart 
// type, both of which are required
// User can input as many of these as desired

// Returns new 
// returns blank object in proper formatting for a muzzle velocity input with 
// fps and dart type fields
//
// index (int): Index of this particular muzzle velocity input in array notation
//  (start at 0)
export default class MuzzleVelocity {
  constructor(index) {
    return {
      isRequired: true,
      index: index,
      value: {
        fps: {
          isRequired: true,
					key: "FPS",
					value: ""
        },
        powerLevel: {
          isRequired: false,
					key: "false",
					value: ""
        },
        dartType: {
          isRequired: true,
					key: "Dart Type",
					value: ""
        }
      }
    }

  }


}
import React, { Component } from 'react';
import { Link } from 'react-router-dom'

import CartItems from '../Items';

import Helper from 'Helper';

import './index.css';

let helper = new Helper();


export default class CartPage extends Component {

	render () {
		return (
			<div id="CartPage">
				<h1 className="center-text">Cart</h1>
				<CartItems 
          shopData={this.props.shopData} 
          cart={this.props.cart} 
          removeFromCart={this.removeFromCart}
          changeQuantity={this.changeQuantity}
          costInfo={this.props.costInfo}
          toRenderCheckoutBtn={true}
          canChange={true}/>

				{this.renderProceedToCheckoutBtn()}
			</div>
		)
	}

	renderProceedToCheckoutBtn() {
		if (this.props.cart.cartLogistics.cart.length > 0) {
			return (
				<div className="right-fb">
					<h4><Link 
					  to="/checkout"
					  className="button-style-1"
					  id="proceed-to-checkout-btn">Checkout</Link>
				  </h4>
			  </div>
			)
		}
	}

}

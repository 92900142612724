import React, { Component } from 'react';
import './index.css';

export default class Carousel extends Component {
	constructor (props) {
		super(props);

    this.smallImgClicked = this.smallImgClicked.bind(this);
    this.renderSmallImgs = this.renderSmallImgs.bind(this);
    this.mouseLeave = this.mouseLeave.bind(this);
    this.mouseEnter = this.mouseEnter.bind(this);
    this.mouseMoved = this.mouseMoved.bind(this);

		this.state = {
			"focusedSmallImgKey": 0,
			"smallImgsVisible": true,

			"mouseX": 0,
			"mouseY": 0,

			"dy": 0,
			"dx": 0
		}
	}

	getInitialState () {
		return {hover: false}
	}

	render () {
    const imgs = this.props.imgs;
		const options = this.props.options;
		
    let smallImgs = imgs.map(this.renderSmallImgs);
    if (options.smallImgs){
    	const thiz = this;	//jank ass js scoping has underfined ref to carousel as 'this'
    	smallImgs = imgs.map(function(item) {
    		return thiz.renderSmallImgs(item, thiz); 
    	});
    } 

    let zoomScale = options.zoomScale ? options.zoomScale : 1.5
    let transformState = this.state.hover ? "scale(" + zoomScale  + ")": "";

    let bigImgStyle = options.style;

    return (
      <div id="Carousel">
        <div className="fluid-carousel-big-img-container">
        	<img 
        		className="carousel-big-img"
        		style={bigImgStyle}
        		src={require(`assets/${imgs[this.state.focusedSmallImgKey].src}`)}
        		onMouseEnter={this.mouseEnter} 
        		onMouseLeave={this.mouseLeave}
        		onMouseMove={this.mouseMoved}/>
        </div>
      	<div className="carousel-small-imgs-container">
      		{smallImgs}
      	</div>
      </div>
    )
  }

  renderSmallImgs (item, thizScope) {
  	return(
  		<div className="carousel-small-img-container">
  			<CarouselSmallImg 
  				data={item} 
  				focusedKey={this.state.focusedSmallImgKey}
					clicked={thizScope.smallImgClicked}
					visible={this.state.smallImgsVisible}/>
  		</div>
		)
  }

  smallImgClicked (key) {
  	this.setState({"focusedSmallImgKey": key});
  }

  mouseEnter (e) {
    // this.setState({
    //   "hover": true,
    //   "smallImgsVisible": false
    // });
  }

  mouseLeave(e) {
    // this.setState({
    //   "hover": false,
    //   "smallImgsVisible": true
    // });
  }

  mouseMoved (e) {
    this.setState({
      "mouseX": e.screenX,
      "mouseY": e.screenY});
  }

};

class CarouselSmallImg extends Component {
	constructor (props) {
		super(props);

		this.imgSrc = this.props.data.src;
		this.imgKey = this.props.data.key;

		this.carouselSmallImgStyleState = "carousel-small-img"
	}

	render () {
		this.checkIsFocused();

		return (
			<img 
				className={this.carouselSmallImgStyleState} 
				src={require(`assets/${this.imgSrc}`)}
				onClick={(e) => this.smallImgClicked(e)}/>
		)
	}

	checkIsFocused () {
		if (!this.props.visible) {
			this.carouselSmallImgStyleState = "hidden";
		} else if (this.props.focusedKey === this.imgKey) {
			this.carouselSmallImgStyleState = "carousel-small-img-selected carousel-small-img";
		} else {
			this.carouselSmallImgStyleState = "carousel-small-img";
		}
	}

	smallImgClicked (e) {
		this.props.clicked(this.imgKey);
	}
}
import React, { Component } from 'react';

import VoltingShape from '../../../Functional/VoltingShape';

import Helper from 'Helper';


import './index.css';

let helper = new Helper();

export default class Legend extends Component {
	render() {
		return (
			<div id="MotorChartLegend">
        {/* Cell count legend items */}
			  <div>
          {/* Legend items for nominally volted items */}
          <div className="cell-legend-container flex-container-center">
            {this.renderLegendItem("2S", "medium", "circle")}
            {this.renderLegendItem("3S", "medium", "circle")}
          </div>

          {/* Legend items for over-/under- volted items */}
          <p className="center-align-text" style={{marginBottom: 0}}>Undervolted (triangle) and overvolted (square) motors</p>
          <div className="cell-legend-container flex-container-center">
            {this.renderLegendItem("2S", "medium", "triangle")}
            {this.renderLegendItem("3S", "medium", "square")}
            {this.renderLegendItem("4S", "medium", "square")}
            {this.renderLegendItem("5S", "medium", "square")}
          </div>
        </div>

        {/* Size legend items */}
				<div id="size-legend-container" className="flex-container-center">
          {this.renderLegendItem("130", "small", "circle")}
          {this.renderLegendItem("132", "medium", "circle")}
          {this.renderLegendItem("180", "large", "circle")}
        </div>
			</div>
		)
	}

  renderLegendItem(cellCount, size, shape) {
  	let volting = this.props.getVoltingStateFromShape(shape, cellCount);		//var to keep track of under/over/nominal volting

  	return (
      <VoltingShape
        cellCount={cellCount}

        containerOnClick={
          (e) => {
            this.onClickLegend({value: cellCount}, volting)
          } 
        }

        size={size}
        shape={shape}
        shapeContainerClassName={"legend-shape " +
          (this.isOptionVisible(
            cellCount, volting) ? "visible" : "hidden")} 
      >
        <p className="legend-label">{cellCount}</p>
      </VoltingShape>
		)

  }

  //dots on legend clicked
  //make sure info.value is string
  onClickLegend(info, volting) {
  	this.props.handleChangeHiddenMotorData(info.value.replace("S", ""), volting);
  }

  //check if certain option (cell count, form factor) is visible on chart
  isOptionVisible (option, volting) {
  	if (volting || volting === 0) {
  		return !helper.isItemInArr(this.props.hiddenMotorParams, 
  			{battery: option.replace("S", ""), volting: volting});
  	}

  	//if can't find motor, return true b/c that means to show
  	return !helper.isItemInArr(this.props.hiddenMotorParams, option.replace("S", ""));
  }
}
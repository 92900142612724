import React, { Component } from 'react';
import { Link } from 'react-router-dom'

import Carousel from '../Functional/Carousel';
import Image from '../Functional/Image';

import './index.css';


export default class Home extends Component {
  render () {
    const imgs = [
      {
        key: 0,
        src: "Home Carousel/Smart Cam.jpg"
      },
      {
        key: 1,
        src: "Home Carousel/Smart Stryfe.jpg"
      }
    ]

    const carouselOptions = {
      "smallImgs": true,
      "zoomOnHover": true,
      "zoomScale": 2
    }

    let featuredContent = this.props.data.featured.map(this.renderfeaturedContent);
    return (
      <div id="Home">
        <Carousel imgs={imgs} options={carouselOptions}/>
        <div id="section--icons">

        </div>
        <h1 className="center-text" id="home-featured-text">Featured</h1>
        <div id="featured-content-container" className="flex-container-center">
          {featuredContent}
        </div>
      </div>
    );
  }

  renderfeaturedContent (item) {
    return (
      <div className="feature-content-item">
        <Link
          className="featured-content-link"
          to={item.link}>
        <Image
          className="feature-content-item-img"
          src={require(`assets/${item.imgSrc}`)} />
          <h4 className="center-align-text featured-title">{item.title}</h4>
        </Link>
      </div>
    );
  }
}

// <header
//           id="header"
//           style={{backgroundImage: 'url(' + require('assets/stryfe-ammo-counter.jpg') + ')'}}> 
//         </header>
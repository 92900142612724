import React from 'react';

import Voting from './Voting';
import CommentIcon from './CommentIcon';
import ShareIcon from './ShareIcon';
import FullscreenIcon from './FullscreenIcon';

  
const Bar = props => {
  const { 
    comments,
    upvotes,
    downvotes,
    id,
    isPreviewMode,

    areCommentsExpanded,
    setAreCommentsExpanded,

    isFullscreen,
    setIsFullscreen,

    onVote
  } = props;


  return (
    <div className="setup-display-card-footer-bar super-light-grey-bg flex-container-center flex-container-vertical-center">
      <Voting
        upvotes={upvotes}
        downvotes={downvotes}

        onVote={onVote}
      />
      
      <CommentIcon
        comments={comments}

        areCommentsExpanded={areCommentsExpanded}
        setAreCommentsExpanded={setAreCommentsExpanded} 
        
        isFullscreen={isFullscreen}
      />

      <ShareIcon
        id={id}
      />

      <FullscreenIcon
        isFullscreen={isFullscreen} 
        setIsFullscreen={setIsFullscreen}
      
        setAreCommentsExpanded={setAreCommentsExpanded}
      />
    </div>
  )
}

export default Bar;
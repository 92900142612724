import React from 'react';
import { Button } from '@material-ui/core';

import FPSEntry from './FPSEntry';
import DartTypeEntry from './DartTypeEntry';


// External utilities/helpers
import update from 'immutability-helper';



const MuzzleVelocityEntry = props => {
  const { FPS_SELECTIONS,
    DART_TYPES,
    MuzzleVelocity,


    updateFormWrapper,
    generateOptionForReactSelect,
    generateErrorText,

    muzzleVelocities,
    errors,   // errors.muzzleVelocities
    errorStyles } = props;


  return (
    <div id="MuzzleVelocityEntry">
      {
        muzzleVelocities.map((singleMuzzleVelocityEntry, index) => {
          // Render fps and dart type entry for each muzzleVelocity item

          // Generate error text for this entire muzzle velocity entry. Each  
          // individual component's errors (fps or dart type) is saved as a 
          // member of errors props
          let muzzleVelocityEntryErrors = generateErrorText(
            // Set errors for this entry if this entry's errors exist
            // If this entry doesn't exist, then the errors default to just an 
            // empty object
            // Default can't be null because muzzleVelocityEntryErrors.something
            // is passed into fps entry and dart type entry, so if default is 
            // null/undefined, would cause error
            (!!errors && !!errors[index]) ? errors[index] : {}
          );

          // Gets updated muzzle velocities based on an update to a specific muzzle
          // velocity and returns an object in the correct formatting that can be used
          // in updateFlywheelSetupForm
          // The return value of this must be passed into updateFlywheelSetupForm() to
          // fully update the setup
          // 
          // updateValue (object) should be in proper format for immutable-helper: 
          // 	{blaster: {
          // 		name: {$set: selectedOption.value},
          // 		isNew: {$set: !!selectedOption.__isNew__}
          // 	}}
          // 
          function getNewMuzzleVelocities(updateValue) {
            // I can't update motors in the stage directly because it's too deep
            // in an array, so I'll just update a copy of the entire stage and 
            // store that new copy of the entire stage (in it's correct index) 
            let updatedMuzzleVelocity = update(singleMuzzleVelocityEntry, { value: updateValue });

            // Replace the old stage with updatedStage
            return {
              muzzleVelocities: { $splice: [[index, 1, updatedMuzzleVelocity]] }
            }

          }

          return (
            <div className="stage-selection-entry-form">
              <h3>{`Muzzle Velocity Entry #${index + 1}`}</h3>
              <FPSEntry
                fps={singleMuzzleVelocityEntry.value.fps}

                getNewMuzzleVelocities={getNewMuzzleVelocities}
                updateFormWrapper={updateFormWrapper}

                FPS_SELECTIONS={FPS_SELECTIONS}
                generateOptionForReactSelect={generateOptionForReactSelect}
                errorText={muzzleVelocityEntryErrors.fps}
                styles={ !!generateErrorText(muzzleVelocityEntryErrors.fps) ? errorStyles : {} }
              />

              <DartTypeEntry
                dartType={singleMuzzleVelocityEntry.value.dartType}

                getNewMuzzleVelocities={getNewMuzzleVelocities}
                updateFormWrapper={updateFormWrapper}

                DART_TYPES={DART_TYPES}
                generateOptionForReactSelect={generateOptionForReactSelect}
                errorText={muzzleVelocityEntryErrors.dartType}
                styles={ !!generateErrorText(muzzleVelocityEntryErrors.dartType) ? errorStyles : {} }
              />

              
              {/* Remove a Muzzle Velocity Entry button */}
              {/* Only render if there is more than 1 muzzle velocity entry */}
              {(muzzleVelocities.length > 1) && 
                <Button className="remove-btn-color setup-entry-form-btn" variant="contained"
                  onClick={() => {
                    // Remove this state from the state
                    updateFormWrapper({
                      muzzleVelocities: { 
                        $splice: [[index, 1]]
                      }
                    });
                  }}
                >
                  Remove this Muzzle Velocity Entry
                </Button>
              }
            </div>
          )
        })
      }
      
      {/* Add a Stage button */}
      <Button 
        className="add-btn-color" 
        variant="contained"
        style={{ marginTop: "2em" }}
        onClick={() => {
          // Push a new muzzle velocity into the state
          updateFormWrapper({
            muzzleVelocities: { 
              // $push requires an array, all items in array will be pushed 
              $push: [
                new MuzzleVelocity(muzzleVelocities.length + 1) // Pass in next index
              ] 
            }
          });
        }}
      >
        + Add a Muzzle Velocity Entry
      </Button>
    </div>
  )

}

export default MuzzleVelocityEntry;
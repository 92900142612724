import React from 'react';
import Select from 'react-select';

const PriceEntry = props => {
  const { price, 
    onChange, 
    PRICE_SELECTIONS,
    generateOptionForReactSelect,
    errorText,
    styles } = props;

  return (
    <div className="setup-entry-form-section">
      <h2>Price</h2>
      <Select className="small-select-dropdown"
        styles={styles}
        placeholder={"Price *"}
        value={!!price.value && generateOptionForReactSelect(price.value)}
        options={PRICE_SELECTIONS}
        onChange={(selectedPrice) => {
          // Find group/power level that selected fps is in
          let selectedPriceGroup = ""
          PRICE_SELECTIONS.map(
            priceGroup => {
              priceGroup.options.map(priceGroupOption => {
                if (priceGroupOption === selectedPrice) {
                  selectedPriceGroup = priceGroup.label;
                }
              })
            });

          onChange(
            { 
              price: { 
                value: {
                  $set: selectedPrice.value
                }
              },
              priceGroup: { 
                value: {
                  $set: selectedPriceGroup
                }
              }
            }	// obj passed into updateFlywheelSetupForm()
          )	// updateFlywheelSetupForm()
        }}
      />
      
      <p className="setup-entry-help">
        Prices in USD, for only the parts used in the setup (wheels, motors, cages, pusher). Please don't include costs of the blaster, wire, battery, switches, tools, etc. Because parts may vary by seller, price ranges are provided. Even if you 3D printed the cage, include the cost of the cage as if you were to purchase it. This provides a more reaslistic cost to those without 3D printers.
      </p>

      <p className="center-text error-text">{errorText}</p>

    </div>
  )
}

export default PriceEntry;
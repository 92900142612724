import React, { Component } from 'react';
import { Redirect } from 'react-router';

import CartItems from '../Cart/Items';
import Form from '../Functional/Form';
import Checkbox from '../Functional/Checkbox';

import Helper from 'Helper';

import './index.css';

let helper = new Helper();

const formItemDefaults =[
  {
    label: "First Name",
    name: "first-name",
    placeHolder: "John",
    isRequired: true,
    formResultsRef:"firstName",
    key: 0
  },
  {
    label: "Last Name",
    name: "last-name",
    placeHolder: "Doe",
    isRequired: true,
    formResultsRef:"lastName",
    key: 1
  },
  {
    label: "Street Address (House number & street name)",
    name: "street-address",
    placeHolder: "123 Main St.",
    isRequired: true,
    formResultsRef:"streetAddress",
    key: 2
  },
  {
    label: "Apartment, suite, unit etc. (optional)",
    name: "apartment-number",
    placeHolder: "453 W",
    isRequired: false,
    formResultsRef:"apartmentNumber",
    key: 3
  },
  {
    label: "City/town",
    name: "city",
    placeHolder: "San Francisco",
    isRequired: true,
    formResultsRef:"city",
    key: 4
  },
  {
    label: "State/Region",
    name: "state",
    placeHolder: "CA",
    isRequired: true,
    formResultsRef:"state",
    key: 5
  },
  {
    label: "Country",
    name: "country",
    placeHolder: "USA",
    isRequired: true,
    formResultsRef:"country",
    key: 6
  },
  {
    label: "ZIP",
    name: "zip",
    placeHolder: "12345",
    isRequired: true,
    formResultsRef:"zip",
    key: 7
  },
  {
    label: "Phone number",
    name: "phone-number",
    placeHolder: "(415) 123-4567",
    isRequired: true,
    formResultsRef:"phoneNumber",
    key: 8
  },
  {
    label: "Email",
    name: "email",
    placeHolder: "john@doe.com",
    isRequired: true,
    formResultsRef:"email",
    type: "email",
    key: 9
  },
  {
    label: "Order Notes",
    name: "order-notes",
    formResultsRef:"orderNotes",
    isTextArea: true,
    key: 10
  }
];

const initialState = {
  formResults: {
    firstName: "",
    lastName: "",
    streetAddress: "",
    apartmentNumber: "",
    city: "",
    state: "",
    country: "",
    zip: "",
    phoneNumber: "",
    email: "",
    orderNotes: ""
  },

  toIncludeInvoice: false,
  isInternationalOrder: false,

  fireRedirect: false
}

export default class Checkout extends Component {
  constructor (props) {
    super(props);

    this.state = {
      ...initialState
    } 

  }

  render () {
    return (
      <div id="Checkout">
        <h1 className="center-text">Checkout</h1>
        <div id="cart-items-container">
          <CartItems 
            cart={this.props.cart}/>
        </div>        
        {(this.props.cart.cartLogistics.cart.length > 0) && (
          <div>
            <div id="checkout-form">
              <Form
                formTitle={"Shipping Details"}
                submitButtonText={"Proceed to Payment"}
                formItemsData={formItemDefaults}
                formResults={this.state.formResults}
                updateParentOnFormUpdate={true}
                handleFormUpdate={((updatedForm) => {
                  this.setState({formResults: updatedForm});
                }).bind(this)}
                handleFormSubmit={this.handleShippingFormSubmit.bind(this)}>

                <p>A digital copy of your invoice will be set when your item ships</p>
                <Checkbox 
                  label={"Include paper invoice with order"}
                  isChecked={this.state.toIncludeInvoice}
                  handleChange={(e, isChecked) => this.setState({toIncludeInvoice: isChecked})}
                />

                <Checkbox 
                  label={"International order"}
                  isChecked={this.state.isInternationalOrder}
                  handleChange={(e, isChecked) => {
                    this.setState({isInternationalOrder: isChecked});
                    this.props.cart.isInternationalOrder = isChecked;
                    this.props.cart.updateCosts();
                  }}
                />
              </Form>
            </div>
          </div>)}
        {this.state.fireRedirect && (
          <Redirect to={'/payment/'}/>
        )}

      </div>
    )
  }

  handleShippingFormSubmit (formResults) {
    if (this.props.cart.cartLogistics.subtotal > 0) {
      this.props.onShippingFormSubmit(this.generateCheckoutFormData(formResults));

      this.setState({...initialState, fireRedirect: true});
    }
  }

  //sets checkout form to proper formatting so it's compatible with backend
  //trims form data
  //adds id
  //adds cost info to seperate object
  //adds date
  generateCheckoutFormData (formResults) {
    //newFormResults without beginning or ending whitespaces
    let finalFormResults = Object.assign({}, formResults);

    finalFormResults.name = formResults.firstName + " " + formResults.lastName;

    for (let prop in finalFormResults) {
      finalFormResults[prop] = (finalFormResults[prop] + "").trim();
    }

    finalFormResults.cart = this.props.cart.cartLogistics.cart;
    finalFormResults.date = new Date();
    finalFormResults.toIncludeInvoice = this.state.toIncludeInvoice;
    finalFormResults.isInternationalOrder = this.state.isInternationalOrder;

    //for backend to send proper email
    finalFormResults.costInfo = {
      subtotal: this.props.cart.cartLogistics.subtotal,
      shippingCost: this.props.cart.cartLogistics.shippingCost,
      totalCost: this.props.cart.cartLogistics.totalCost
    }

    return finalFormResults;
  }

}

import React, { Component } from 'react';

import './index.css';

export default class Custom extends Component {
	render() {
		return (
			<div className="cart-item">
				<div className="cart-item-column-first">
          <div>
            <h4 className="cart-item-title cart-item-first-row">Custom Order</h4>
          </div>
        </div>
        <div className="cart-item-column">
          <p className="cart-item-price-txt cart-item-first-row">Quantity:</p>
          1
        </div>
        <div className="cart-item-column">
          <p className="cart-item-price-txt cart-item-first-row">Price:</p>
          <p className="cart-item-price-val">${this.props.item.price}</p>
        </div>
			</div> 
		)
		
	}

}

import React from 'react';
import { Collapse } from '@material-ui/core';


import FPSPerformanceAndCellCount from './FPSPerformanceAndCellCount';
import CollapsibleTextTrigger from '../../../../Functional/CollapsibleTextTrigger';
import StageDisplay from './StageDisplay';
import SeeMoreOrLess from '../../../../Functional/SeeMoreOrLess';
import ExpandedSetup from './ExpandedSetup';


import Helper from 'Helper';



const helper = new Helper();


const Body = props => {
  const { setupData, cellCountInWords, motorHelper } = props;

  let [ areStagesExpanded, setAreStagesExpanded ] = React.useState(true);
  let [ isSetupExpanded, setIsSetupExpanded ] = React.useState(false);


  const hasPusherInfo = setupData.pusher.pusherMechanism.length > 1;
  const hasAdditionalSetupNotes = setupData.additionalSetupNotes.length > 1;

  
  return (
    <div className={"setup-display-card-body"}>
      <FPSPerformanceAndCellCount
        muzzleVelocities={setupData.muzzleVelocities}
        cellCount={setupData.cellCount}
        cellCountInWords={cellCountInWords}/>

      {/* Price */}  
      <div className={"setup-display-card-body-section center-text grey-border-bottom"}>
        <h2 style={{ marginBottom: "0.5em"}}>
          { setupData.price } 
        </h2>

        <p className="setup-entry-help"
          style={{ marginBottom: "1em"}}
        >
          {setupData.priceGroup}
        </p>
      </div>

      {/* Rate of Fire */}  
      <div className={"setup-display-card-body-section center-text grey-border-bottom"}>
        <h3>{ setupData.rateOfFire } </h3>
      </div>

      {/* Stage */}  
      <div 
        className="setup-display-card-body-section setup-display-card-stages"
      >
        <CollapsibleTextTrigger
          isExpanded={areStagesExpanded}
          setIsExpanded={setAreStagesExpanded}
          containerClassName="flex-container-center"
        >
          <div>
            <h2 className="center-text" style={{marginBottom: 0}}>Stages</h2>
            <p className="center-text"
              style={{
                margin: 0,
                fontSize: "0.8em"
              }}
            >
              { `(${setupData.setup.length} ${helper.pluralizer("stage", setupData.setup.length)})` }
            </p>

          </div>

          
        </CollapsibleTextTrigger>
          
        <Collapse in={areStagesExpanded}>
          { 
            setupData.setup.map((stage) => {
              return (
                <StageDisplay 
                  setupData={setupData}
                  stageData={stage}
                  motorHelper={motorHelper} 
                />
              )
            })
          }
        </Collapse>
      </div>

      { // Only render expanded stuff if setup has pusher and additional setup stuff
        (hasPusherInfo || hasAdditionalSetupNotes) && 
        <div>
          <SeeMoreOrLess
            control={isSetupExpanded}
            setControl={setIsSetupExpanded}
          />

          {/* Expanded Setup */}
          <Collapse in={isSetupExpanded}>
            <ExpandedSetup 
              SETUP_DATA={setupData}
              motorHelper={motorHelper} 
            />
          </Collapse> 
        </div>

      }

    </div>
  )
}

export default Body;
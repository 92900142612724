import React from 'react';

import { Info } from '@material-ui/icons';


const CommentInfo = props => {
  const { infoText } = props;

  const [ isExpanded, setIsExpanded ] = React.useState(false);

  return (
    <div className="flex-container-left comment-entry-info-container">
      <Info className="pointer-on-hover"
        onClick={e => setIsExpanded(prev => !prev)}
        color="action"
      />

      {isExpanded && 
        <p className="setup-entry-help comment-entry-info inline-text">
          {infoText}
        </p>
      }
      
      
    </div>
  )
}

export default CommentInfo;
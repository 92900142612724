import React from 'react';
import { Fullscreen, FullscreenExit, } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';


const FullscreenIcon = props => {
  const { isFullscreen, 
    setIsFullscreen,
  
    setAreCommentsExpanded} = props;

  return (
    <div className="pointer-on-hover flex-container-right item" 
      onClick={e => {
        setIsFullscreen(prev => !prev)
      }}
      style={{flexGrow: 1}}
    >
      { // Enter Full screen
        !isFullscreen && 
        <Tooltip title="Enter fullscreen" placement="top">
          <Fullscreen fontSize="small"/>
        </Tooltip>
        
      }
      { // Exit Full screen
        isFullscreen && 
        <Tooltip title="Exit fullscreen" placement="top">
          <FullscreenExit fontSize="small"/>
        </Tooltip>
      }
    </div>
  )
}

export default FullscreenIcon;
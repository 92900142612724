import _ from 'lodash';

import Helper from 'Helper';

let helper = new Helper();

export default class CartHelper {
  constructor() {
    this.initCartLogistics();

    this.isInternationalOrder = false;

    this.addToCart = this.addToCart.bind(this);
    this.findItemInCart = this.findItemInCart.bind(this);
  }

  //init stuff to keep track of cart logistics
  initCartLogistics() {
    this.cartLogistics = {
      subtotal: 0,
      numOfItems: 0,
      shippingCost: 0,
      totalCost: 0,
      cart: []
    }

    return this;
  }

  //update app cart will write to cart stored in state of App
  //this means that App will re-render appropriately when the cart has updated
  //will pass in cart and cart costs
  initCart(updateAppCart) {
    this.updateAppCart = updateAppCart;

    return this;
  }

  // Set this.SHOP_DATA that should be raw data from db
  // shopData (arr): Array of all products (objs). Each product arr should be a 
  //  document from the shop-data collection from the db
  setShopData(shopData) {
    // Only set this.SHOP_DATA if it hasn't already been set and if the shopData
    // being passed in is valid
    if (!this.SHOP_DATA || shopData.length > 0) {
      this.SHOP_DATA = shopData;
    }
  }

  //copy cart to do stuff with carts without mutating original cart
  copyCart() {
    return (
      this.cartLogistics.cart.map(function (item) {
        return Object.assign({}, item);
      })
    )
  }

  updateCart(newCart) {
    this.cartLogistics.cart = newCart;

    this.updateAppCart(this.cartLogistics.cart, this.updateCosts());
  }

  //q = qty
  //pk = product key
  //ovr = overwrite qty
  addToCart (q, pk, price, selectedOptions) {
    //make new cart object to prevent mutation in orignal cart
    let newCart = this.copyCart();

    //function to check cart for duplicate items if added to cart, then just change qty
    if (this.checkAddToCartForDuplItems(newCart, q, pk, selectedOptions)) {
      return;
    }
    
    //push obj of new item
    newCart.push(this.generateNewCartItem(q, pk, price, selectedOptions));
  
    this.updateCart(newCart);
  }

  //function to check cart for duplicate items if added to cart, then just change qty
  //returns true if duplicate items found
  checkAddToCartForDuplItems(cart, q, pk, selectedOptions) {
    //if matching item exists, item already in cart
    if (!!this.findItemInCartFromPK(cart, pk, selectedOptions)) {
      //add to qty if needed
      if (!selectedOptions) {   //no options
        this.changeQuantity(q, pk, false);        //just change qty
        return true;
      } else {    //there are options
        this.changeQuantity(q, pk, false, selectedOptions);        //just change qty
        return true;
      }
    }
  }

  generateNewCartItem(q, pk, price, selectedOptions) {
    const ITEM_DATA = this.findDataOfItem(pk);

    let newCartItem = {
      "quantity": q,
      "productKey": pk,
      "price": price,
      "name": ITEM_DATA.name
    }

    // Shipping message
    if (!!ITEM_DATA.shippingMessage) {
      newCartItem.shippingMessage = ITEM_DATA.shippingMessage;
    }

    //not custom order 
    if (pk !== -1) {
      //doesn't have options but can have options
      if (selectedOptions === -1) {
        newCartItem.selectedOptions = -1
      // Has options
      } else if (selectedOptions.length > 0) {
        newCartItem.selectedOptions = selectedOptions
      // No options
      } else {

      }
    //custom order
    } else if (pk === -1) {
      newCartItem.name = "Custom Order"
    }

    return newCartItem;
  }

  removeFromCart(pk, selectedOptions) {
    let newCart = this.copyCart();

     //remove item from cart
    _.remove(newCart, function (cartObj) {
      return cartObj.productKey === pk && _.isEqual(selectedOptions, cartObj.selectedOptions)
    });

    this.updateCart(newCart);

  }

  //q = qty
  //pk = product key
  //ovr = overwrite qty
  changeQuantity (q, pk, ovr, selectedOptions) {
    //create copy of cart
    let newCart = this.copyCart(); 

    //find item want to change qty in cart
    for (var i = 0; i < newCart.length; i++) {
      if (newCart[i].productKey === pk) {     //if item in cart matches item trying to change qty of
        //only update qty if: no options, options match
        if (!selectedOptions || _.isEqual(selectedOptions, newCart[i].selectedOptions)) {   
          if (ovr) {   //if overwrite qty
            newCart[i].quantity = q;
          } else {    //don't overwrite qty, just add to it
            newCart[i].quantity += q;
          }
        } 
        
      }
      
    }

    this.updateCart(newCart);
  }

  updateCosts () {
    let newCostInfo, subtotal = 0, numOfItems = 0;

    //calculate subtotal  
    for (var i in this.cartLogistics.cart) {
      subtotal += this.cartLogistics.cart[i].price * this.cartLogistics.cart[i].quantity; 
      numOfItems += this.cartLogistics.cart[i].quantity;
    }

    let shippingCost = this.calculateShippingCost(numOfItems);

    this.cartLogistics.subtotal = subtotal.toFixed(2);
    this.cartLogistics.numOfItems = numOfItems;
    this.cartLogistics.shippingCost = shippingCost.toFixed(2);
    this.cartLogistics.totalCost = (shippingCost + subtotal).toFixed(2)

    return {
      subtotal: this.cartLogistics.subtotal,
      numOfItems: this.cartLogistics.numOfItems,
      shippingCost: this.cartLogistics.shippingCost,
      totalCost: this.cartLogistics.totalCost
    }

  }

  calculateShippingCost (numOfItems) {
    // Domestic shipping
    if (!this.isInternationalOrder) {
      if (numOfItems <= 0) {
        return 0;
      } else {
        if (numOfItems <= 2) {
          return 3.5;
        } else {
          return 3.5 + (0.3 * Math.floor((numOfItems-2)/1))
        }
      }
    }

    // International shipping
    return 15;
  }

  //item param can be obj with prop key or just the key
  //searches from data in db
  findDataOfItem (item) {
    for (var i in this.SHOP_DATA) {
      if (item.productKey === this.SHOP_DATA[i].key || item === this.SHOP_DATA[i].key) {
        return this.SHOP_DATA[i];
      }
    }
  }

  //should be used if has reference to entire item
  //searches cartLogistics.cart
  //returns item in cart
  findItemInCart(item) {
    for (var i = 0; i < this.cartLogistics.cart.length; i++) {
      if (item.productKey === this.cartLogistics.cart[i].productKey) {    //find item based on productKey, if productKeys match
        if (!!item.selectedOptions) {    //if item has options, verify if items match by checking if options match
          if (_.isEqual(item.selectedOptions, this.cartLogistics.cart[i].selectedOptions)) {    //options match
            return this.cartLogistics.cart[i];    
          }
        } else {  
          return this.cartLogistics.cart[i];
        }
      }
    }
  }

  //should be used if doesn't have reference to entire item
  //searches cart passed in
  //returns item in cart
  findItemInCartFromPK(cart, pk, selectedOptions) {
    for (var i = 0; i < cart.length; i++) {
     if (cart[i].productKey === pk && (!selectedOptions || _.isEqual(selectedOptions, cart[i].selectedOptions))) {      //if item already in cart
        return cart[i];
     }
    }

  }

  
}
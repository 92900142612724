import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import axios from 'axios';
import _ from 'lodash';
import ReactGA from 'react-ga';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import './index.css';

//componenets
import Navbar from '../Navbar';
import Home from '../Home';
import Docs from '../Docs';
import DocsDetail from '../Docs/Detail';
import Shop from '../Shop';
import ShopItem from '../Shop/Item';
import Checkout from '../Checkout';
import ComingSoon from '../ComingSoon';
import NotFound from '../NotFound';
import Payment from '../Payment';
import PaymentSuccess from '../PaymentSuccess';

import Footer from '../Footer';
import Message from '../Message';
import CartPage from '../Cart/Page';
import CustomOrders from '../CustomOrders';
import Loading from '../Loading';
import StripeCheckoutComponent from '../StripeCheckout';
import Changelog from '../Changelog';
import About from '../About';

import Tools from '../Tools/App';
import ToolsDetail from '../Tools/Detail';

import Contact from '../Contact';
import ContactSuccess from '../Contact/Success';

//private components
import StoreToolsLogin from '../StoreToolsLogin';


//funcitonal classes
import Helper from 'Helper';
import Cart from 'Cart';
import BackendDataRetreiver from 'BackendDataRetreiver';

let helper = new Helper();
let cart = new Cart();

ReactGA.initialize('UA-XXXXXXXX');

const theme = createMuiTheme({
  typography: {
    fontSize: 16,
    fontFamily: [
      'Varela Round',
      'sans-serif'
    ].join(','),
  },
});

export default class App extends Component {
  constructor (props) {
    super(props);
    
    this.onShippingFormSubmit = this.onShippingFormSubmit.bind(this);
    this.onPaymentSuccess = this.onPaymentSuccess.bind(this);
    this.submitContactData = this.submitContactData.bind(this);
    this.onLoggedInToStoreToolsLogin = this.onLoggedInToStoreToolsLogin.bind(this);

    this.setDataToState = this.setDataToState.bind(this);

    cart.initCart(this.updateCart.bind(this));

    this.state = {
      isLoading: true,

      "cart": [

      ],

      "costInfo": {
        "subtotal": 0,
        "numOfItems": 0,
        "shippingCost": 0,
        "totalCost": 0
      },

      "hasCheckedout": false,
      "devServerURL": "https://hidden-forest-71418.herokuapp.com/api",
      "DATA": "",

      "paymentDetails": {},

      "hasLoggedInToStoreTools": false
    }
  }

  componentDidMount () {
    //need promise b/c retreiving info from db that's asynch
    //will set state after retreivedData is populated w/ data from requested db collection
    //rs is resolved, rj is rejected
    let retreivedData = new Promise((rs, rj) => {
      rs(new BackendDataRetreiver().retreiveData("serve-up-data"));
    })
    .then((data) => {
      //set state after reteiving data
      this.setState({
        isLoading: false,
        "DATA": data[0]});
    });
 
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!!nextState.DATA.shopData && !cart.SHOP_DATA) {    //when shopData retrieved from db and cart.SHOP_DATA not set
      // cart.initCart(nextState.DATA.shopData, this.updateCart.bind(this));
    }

    return true;
  }

  render() {
  	if (!this.state.isLoading && this.state.DATA) {
	    return (
        <ThemeProvider theme={theme}>
  	      <BrowserRouter onUpdate={this.fireTracking}>
  	        <div id="App">
              <Message message={this.state.DATA.message}/>
  	          <Navbar 
                cartSize={this.state.cart.length}/>
  	          <div className="switch">
  	            <Switch>
                  <Route 
                    exact path="/" 
                    render = {(props) => (
                      <Home {...props} 
                        data={this.state.DATA.homeData}/>)} />
                   <Route 
                    exact path="/docs" 
                    render = {(props) => (
                      <Docs {...props} 
                        setDataToState={this.setDataToState}
                        data={this.state.DATA.docsData}
                        collectionName={"docs-data"}/>)} />
                    <Route 
                      exact path="/docs/:id" 
                      render = {(props) => (
                        <DocsDetail {...props} 
                          setDataToState={this.setDataToState}
                          data={this.state.DATA.docsData}
                          isItemComponent={true}
                          collectionName={"docs-data"}/>)} />
                    <Route 
                      exact path="/shop" render ={(props) => (
                        <Shop {...props} 
                          setDataToState={this.setDataToState}
                          data={this.state.DATA.shopData}
                          collectionName={"shop-data"}/>)} />
                    <Route 
                      exact path="/shop/:id" 
                      render = {
                        props => (
                          <ShopItem {...props} 
                            cart={cart}
                          />
                        )
                      } 
                    />
                    <Route 
                      path="/cart" 
                      render = {(props) => (
                        <CartPage {...props} 
                          cart={cart} 
                          shopData={this.state.DATA.shopData}/>)} />
                    <Route 
                      path="/checkout" 
                      render = {(props) => (
                        <Checkout {...props} 
                          cart={cart} 
                          onShippingFormSubmit={this.onShippingFormSubmit}/>)} />
                    <Route 
                      path="/payment" 
                      render = {(props) => (
                        <Payment {...props} 
                          cart={cart}
                          hasCheckedout={this.state.hasCheckedout}
                          onPaymentSuccess={this.onPaymentSuccess}/>)} />
                    <Route 
                      exact path="/payment/:id" 
                      render = {(props) => (
                        <Payment {...props} 
                          cart={cart}
                          hasCheckedout={this.state.hasCheckedout}
                          onPaymentSuccess={this.onPaymentSuccess}/>)} />
                    <Route 
                      path="/payment-success" 
                      render = {(props) => (
                        <PaymentSuccess/>)} />
                    <Route 
                      path="/contact" 
                      render = {(props) => (
                        <Contact {...props} 
                          submitContactData={this.submitContactData}/>)} />
                    <Route 
                      path="/contact-success" 
                      render = {(props) => (
                        <ContactSuccess/>)} />
                  <Route  path="/contact" component={Contact} />
                  <Route  path="/coming-soon" component={ComingSoon} />
                  <Route 
                    path="/store-tools-login" 
                    render = {(props) => (
                      <StoreToolsLogin {...props} 
                        hasLoggedIn = {this.state.hasLoggedInToStoreTools}
                        onLoggedIn={this.onLoggedInToStoreToolsLogin}/>)} />
                  <Route 
                    exact path="/custom-orders/:id" 
                    render = {(props) => (
                      <CustomOrders {...props} 
                        addToCart={this.addToCart}/>)} />
                  {/*<Route 
                    exact path="/motor-chart" 
                    render = {(props) => (
                      <MotorChartApp {...props} 
                        devServerURL={this.state.devServerURL}
                        collectionName={"charts"}
                        otherShopData={this.state.DATA.otherShopData}
                        setDataToState={this.setDataToState}/>)} />
                  <Route 
                    exact path="/lipo-checker" 
                    render = {(props) => (
                      <LiPoChecker {...props} 
                        devServerURL={this.state.devServerURL}
                        collectionName={"charts"}
                        setDataToState={this.setDataToState}/>)} />*/}
                  <Route 
                    exact path="/tools" 
                    render = {(props) => (
                      <Tools {...props} />)} />
                  <Route 
                    exact path="/tools/:toolID" 
                    render = {(props) => (
                      <ToolsDetail {...props}
                        collectionName={"charts"}
                        otherShopData={this.state.DATA.otherShopData}
                        setDataToState={this.setDataToState} />)} />
                  <Route 
                    exact path="/tools/:toolID/:id" 
                    render = {(props) => (
                      <ToolsDetail {...props}
                        collectionName={"charts"}
                        otherShopData={this.state.DATA.otherShopData}
                        setDataToState={this.setDataToState} />)} />
                  <Route 
                    exact path="/changelog" 
                    render = {(props) => (
                      <Changelog {...props}
                        setDataToState={this.setDataToState}
                        data={this.state.DATA.changelogData}
                        collectionName={"changelog"} />)} />
                  <Route 
                    exact path="/about" 
                    render = {(props) => (
                      <About {...props}/>)} />
                  <Route path="/*" component={NotFound} />
                  <Route path="/*" component={NotFound} />
                </Switch>
  	          </div>
  	          <Footer />
              {this.state.hasCheckedout}
  	        </div>
  	      </BrowserRouter>
        </ThemeProvider>
	    );
	  } else {
	  	return (
	  		<Loading/>
  		)
	  }
  }

  fireTracking() {
    ReactGA.pageview(window.location.hash);
  }

  //set state DATA to dat retrieved and passed in
  setDataToState(data, prefix) {
    let newData = this.state.DATA;
    newData[prefix + "Data"] = data;

    this.setState({
      DATA: newData
    });
  }




  updateCart(newCart, newCostInfo) {
    this.setState({ 
      cart: newCart,
      costInfo: newCostInfo }); 

  }

  onShippingFormSubmit (obj) {
    console.log("submit")
  	this.setState({
  		"hasCheckedout": true,
  		paymentDetails: obj});
  	
  }

  onPaymentSuccess (paypalData) {
  	if (this.state.hasCheckedout) {
      //add data from paypal form send to backend
      let newPaymentDetails = Object.assign(this.state.paymentDetails);
      newPaymentDetails.paypalData = paypalData;

      this.setState({
        paymentDetails: newPaymentDetails,
      }, function () {    //callback b/c setState asynch
        //send data to backend
        axios.post((this.state.devServerURL + "/checkout-form-data"), newPaymentDetails)
        .catch(err => {
          console.error("error bla", err);
        });

        //reset payment stuff
        this.setState({
          "hasCheckedout": false,
          "cart": [],
          paymentDetails: {}});
      });

      
	  }
  }

  submitContactData (contactFormResults) {
    axios.post((this.state.devServerURL + "/contact-form-data"), contactFormResults)
      .catch(err => {
        console.error("error bla", err);
    });
  }

  onLoggedInToStoreToolsLogin (loginStatus) {
    this.setState({
      "hasLoggedInToStoreTools": loginStatus
    });
  }

}

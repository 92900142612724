//class to retreive data from backend 

import axios from 'axios';

import CONSTANTS from 'CONSTANTS';

export default class BackendDataRetreiver {
	constructor () {
		this.dataServerURL = new CONSTANTS().getDataServerURL();
	}

	//collection should be string that matches collection name of collection from DB
	retreiveData(collection, ) {
		//need promise here b/c return won't be executed at runtime
		return (
			new Promise((rs, rj) => {
				rs(axios.get(this.dataServerURL + "/" + collection)
  				.then(res => {
  					//return data[0] which can be accessed from val in then()
  					return res.data
      		})		//axios then
    		);	//rs
			})
			.then((val) => {		//called after promise fulfulled
				return val
			})
		)		//return
	}

}

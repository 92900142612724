import React, { Component } from 'react';
import Markdown from 'react-remarkable';

const Options = props => {	
	return (
    <div id="Shop-item-description-options"> 
    	<Markdown source={props.optionsData} options={{html: true}}/>
    </div>
	)
	
}

export default Options;
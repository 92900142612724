import React, { Component } from 'react';
import { Redirect } from 'react-router'

import './index.css';
import Form from '../Functional/Form';

export default class Contact extends Component {
  constructor (props) {
    super(props);
    
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    
    this.state = {
      contactFormItemData: [
        {
          key: 0,
          label: "Name",
          name: "name",
          placeHolder: "",
          isRequired: true,
          formResultsRef: "name"
        },
        {
          key: 1,
          label: "Email",
          name: "email",
          type: "email",
          placeHolder: "",
          isRequired: true,
          formResultsRef: "email"
        },
        {
          key: 2,
          label: "Message Topic",
          name: "message-topic",
          placeHolder: "",
          isRequired: true,
          formResultsRef: "messageTopic"
        },
        {
          key: 3,
          label: "Message",
          name: "message",
          placeHolder: "",
          isRequired: true,
          isTextArea: true,
          formResultsRef: "message"
        }
      ],
      
      formResults: {
        name: "",
        email: "",
        messageTopic: "",
        message: ""
      },
      
      fireRedirect: false
    }
  }
  
  render () {
    return (
      <div id="Contact"> 
        <h1>Contact</h1>
        <p>If you do not receive a response after 48 hours, please submit a contact form again.</p>
        <p>For faster response times on quick questions, consider contacting me through Facebook (@suildtech), Reddit (u/MingBatt), Instagram (@suildtech), Discord (MingBatt - Suild#5561), or directly through email (suildcontact@gmail.com).</p>
        <div id="contact-form">
          <Form
            submitButtonText={"Submit"}
            formItemsData={this.state.contactFormItemData}
            formResults={this.state.formResults}
            handleFormSubmit={this.handleFormSubmit}/>
          </div>

        {this.state.fireRedirect && (
          <Redirect to={'/contact-success'}/>
        )}
      </div>
    )    
  }
  
  handleFormSubmit (formResults) {
    this.props.submitContactData(formResults);
    
    this.setState({
      formResults: {
        name: "",
        email: "",
        messageTopic: "",
        message: ""
      },
      fireRedirect: true
    });
  }


}
import React, { Component } from 'react';
import { Link } from 'react-router-dom'

import './index.scss';

export default class Image extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true
    }
  }

	render () {
		return (
      <img className={(this.state.isLoading ? "img" : "loading-img") + " " + this.props.className} 
        id={this.props.id}
        height={this.props.height}
        width={this.props.width}
        alt={this.props.alt}
        src={this.props.src}
        onLoad={() => this.setState({isLoading: false})}/>
		);
	}

}


import React from 'react';
import { TextField } from '@material-ui/core';

const SetupNameEntry = props => {
  const { setupName, onChange, errorText } = props;

  return (
    <div>
      <TextField 
        className="outlined-basic setup-entry-text-field" 
        label="Setup Name" 
        variant="outlined"
        required={true}
        value={setupName.value}
        onChange={
          (e) => onChange(
            {setupName: { value: {$set: e.target.value}}}
          )
        }
        error={!!errorText && errorText.length > 0}
      />

      <p className="center-text error-text">{errorText}</p>

    </div>
  )
}

export default SetupNameEntry;
import React from 'react';
import { 
  FilterTiltShift, 
  Settings,
  RadioButtonChecked,
  FlashOn,
  BatteryFull,
  Edit } from '@material-ui/icons';


import _ from "lodash";




const Icons = props => {
  const { MOTOR_DATA, motorHelper, setupData, stageData } = props;

  // Each "half-container" is left/right half

  return (
    <div className="flex-container-center">

      <div className="setup-stage-icons-half-container">

        {/* Motor Size form factor icon */}
        <div className="flex-container-vertical-center setup-stage-icon-container">
          <RadioButtonChecked fontSize={"small"}/>
          <p className="inline-text setup-stage-icon-text">
            {`${MOTOR_DATA.formFactor} form factor`}
          </p>
        </div>

        {/* Motor Volting Icon */}
        <div className="flex-container-vertical-center setup-stage-icon-container">                
          <FlashOn 
            className={""}
            fontSize={"small"}
          />
          <p className="inline-text setup-stage-icon-text">
            {
              _.startCase(
                _.toLower(
                  motorHelper.voltingText(MOTOR_DATA, setupData.cellCount)))
            }
          </p>
        </div>

        {/* Nominal Motor Battery Icon */}
        <div className="flex-container-vertical-center setup-stage-icon-container">                
        <BatteryFull 
            className={""}
            fontSize={"small"}
          />
          <p className="inline-text setup-stage-icon-text">
            {
              motorHelper.nominalCellCountToText(MOTOR_DATA) + " nominal"
            }
          </p>
        </div>

      </div>
    
      <div className="setup-stage-icons-half-container">

        {/* Microflywheel icon */}
        <div className="flex-container-vertical-center setup-stage-icon-container" 
          style={{
            color: (stageData.isMicroFlywheelSetup ? "" : "#e6e6e6")
          }}
        >
          <FilterTiltShift />
          <p className="inline-text setup-stage-icon-text">Microwheels</p>
        </div>

        {/* Flywheel Cage Material icon */}
        <div className="flex-container-vertical-center setup-stage-icon-container">
          <Settings fontSize={"small"}/>
          <p className="inline-text setup-stage-icon-text">
            {`${stageData.flywheelCageMaterial} cage`}
          </p>
        </div>

        {/* Dart guide icon */}
        <div className="flex-container-vertical-center setup-stage-icon-container"
          style={{
            color: (!!stageData.dartGuide ? "" : "#e6e6e6")
          }}
        >
          <Edit fontSize={"small"}/>
          <p className="inline-text setup-stage-icon-text">
            {`${!!stageData.dartGuide ? stageData.dartGuide : "No Dart Guide"}`}
          </p>
        </div>
        
      </div>

    </div>
  )
}

export default Icons
import _ from 'lodash';

import BackendDataSender from 'BackendDataSender';


const validateCommentForm = async formValues => {
  let errors = {};

  if (!formValues.username) {
    errors.username = "Required username";
  }

  if (!formValues.comment) {
    errors.comment = "Required comment body";
  }

  // Username validation to check username's email, only needs to be done if
  // username entered
  if (!!formValues.username){ 
    // BackendDataSender().sendData() returns data received, use await to keep 
    // code looking sync when it's actually async
    let { auth } = await new BackendDataSender().sendData('simple-auth', {
      username: formValues.username,
      email: formValues.email
    })

    // username and email combo failed auth
    if (!auth) {
      errors.username = "Invalid username and email combination"
    }
  }

  return errors


}




export default validateCommentForm;
import React from 'react';
import DropdownFilter from './DropdownFilter';

import Helper from 'Helper';


const helper = new Helper();


const PusherBatteryCellCountFilter = props => {
  const { filter, onFilterChange } = props;

  return (
    <DropdownFilter
      title={"Pusher Battery Cell Count"}
      placeholder={"Pusher Battery Cell Count"}
      filter={filter}

      SELECTIONS={["Same as Flywheels", "1S", "2S", "3S", "4S", "5S"].map(cellCount => {
          return helper.generateOptionForReactSelect(cellCount) 
        })
      }

      onChange={selectedOptions => onFilterChange("pusherBatteryCellCount", selectedOptions)}

    />

  )
}

export default PusherBatteryCellCountFilter;
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import PaypalExpressBtn from 'react-paypal-express-checkout';

import CartItems from '../Cart/Items';

import './index.css';

export default class Checkout extends Component {
  constructor (props) {
    super(props);

    this.renderPaymentBtn = this.renderPaymentBtn.bind(this);

    this.props.cart.updateCosts();

    this.state = {
      hasPaid: false,
      env: 'production',
      client: {
        sandbox:    'Ad7TWVl9h0l1mTh75FqiSAaDAcHWej-c3b2ltzK9vPC50p8whxxJFEiVLHHZOQYKw9mkvnrTj-ilCd-v',
        production: 'AVpXRsyNSfzG14wK-yvUI1vK2-NJVNMZeDpHLXdhX9PLvlqbTBsi_K2qGvfZz5KKzP1L-q8T6VLK9xhN'
      },
      currency: "USD",
      commit: true
    }
  }

  payment(data, actions) {
    return actions.payment.create({
      transactions: [
        {
          amount: { total: this.props.cart.cartLogistics.totalCost, currency: 'USD' }
        }
      ]
    });
  }

  render () {
    // window.location.href = 'https://paypal.me/suild/' + this.props.costInfo.totalCost;

    //payment completed
    if (this.state.hasPaid) {
      return <Redirect to={'/payment-success'}/>

    //has checked out, needs to pay
    } else if (this.props.hasCheckedout) {  
      return this.renderPaymentBtn(this);

    //somehow got to payment page without checking out first
    } else {  
      return (
        <div id="Payment">
          <h1 className="center-text" id="payment-failure-text">Something went wrong!</h1>
          {this.props.hasCheckedout}
          <h3 className="center-text"><Link to="/" className="button-style-1">Go Home</Link></h3>
        </div>
      )
    }

    
  }

  renderPaymentBtn (thiz) {
    function onAuthorize(data, actions) {
      return actions.payment.execute().then(function(paymentData) {
          // Show a success page to the buyer
      });
    }

    var onSuccess = function (payment) {
      // Congratulation, it came here means everything's fine!
      // console.log("The payment was succeeded!", payment);
      thiz.props.onPaymentSuccess(payment);
      thiz.setState({hasPaid: true});
      // You can bind the "payment" object's value to your state or props or whatever here, please see below for sample returned data
    }   
    
    function onCancel (data) {
      // User pressed "cancel" or close Paypal's popup!
      // console.log('The payment was cancelled!', data);
      // You can bind the "data" object's value to your state or props or whatever here, please see below for sample returned data
    } 
    
    function onError (err) {
      // The main Paypal's script cannot be loaded or somethings block the loading of that script!
      // console.log("Error!", err);
      // Because the Paypal's main script is loaded asynchronously from "https://www.paypalobjects.com/api/checkout.js"
      // => sometimes it may take about 0.5 second for everything to get set, or for the button to appear     
    }

    return (
      <div id="Payment">
        <div id="cart-items-container">
          <CartItems 
            cart={this.props.cart}
            canChange={false}/>
        </div>
        <div id="paypal-btn-container">
          <PaypalExpressBtn 
            env={this.state.env} 
            client={this.state.client} 
            currency={this.state.currency} 
            total={parseFloat(this.props.cart.cartLogistics.totalCost)} 
            onError={onError} 
            onSuccess={onSuccess} 
            onCancel={onCancel} />
          <p>A PayPal account is not required to complete a purchase</p>
        </div>
      </div>
    )   //return
    
  }   //renderPaymentBtn

}   //class
import React from 'react';
import DropdownFilter from './DropdownFilter';

import Helper from 'Helper';


const helper = new Helper();


const FlywheelsFilter = props => {
  const { FLYWHEELS, filter, onFilterChange } = props;

  return (
    <DropdownFilter
      title={"Flywheels"}
      placeholder={"Flywheels"}
      filter={filter}

      SELECTIONS={FLYWHEELS.map(flywheel => {
        return helper.generateOptionForReactSelect(flywheel) 
      })}

      onChange={selectedOptions => onFilterChange("flywheels", selectedOptions)}
    />

  )
}

export default FlywheelsFilter;
import React from 'react';
import { Link } from 'react-router-dom';

import './index.css'; 

const NavLink = props => {	
	const url = window.location.pathname;
	const lowecaseTxt = props.txt.toLowerCase();

	const className =("navbar-link " + (url.includes(lowecaseTxt) ? "selected-navbar-link" : "" ));

	return (
			<p>
				<Link to={"/" + lowecaseTxt} 
					className={className}>
					{props.txt}
				</Link>
			</p>
	)
}

export default NavLink;
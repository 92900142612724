import React from 'react';

import CommentsDisplay from './CommentsDisplay';
import Entry from './Entry';


import './index.scss';



const Comments = props => {
  const { setupData, onCommentSubmit, isPreviewMode } = props;


  return (
    <div className="setup-comments center-div">
      <CommentsDisplay
        comments={setupData.comments}
      />
      <Entry
        setupData={setupData}
        onCommentSubmit={onCommentSubmit}

        isPreviewMode={isPreviewMode}
      />
    </div>
  )

}

export default Comments;
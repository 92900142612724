import React from 'react';
import { Link } from 'react-router-dom';
import ReactImageAppear from 'react-image-appear';

import Loading from '../Loading';

import useDataFromBackend from '../../Hooks/useDataFromBackend';


import './index.css';



const Shop = props => {
  const [ SHOP_ITEM_DATA, isShopItemDataLoading ] = useDataFromBackend("shop-data");

  if (!isShopItemDataLoading) { 
    return (
      <div id="Shop">
        <h1>Shop</h1>
        <p>The highest quality electronics and firmware available on the market.</p>
        <div id="shop-items" className="flex-container-center">

          {
            SHOP_ITEM_DATA.map(
              (shopItem => {
                let isItemOutOfStock = shopItem.stock > 0 ? false : true;

                if (shopItem.active) {
                  return (
                    <div className="shop-item">
                      <Link 
                        className="shop-item-link"
                        to={"/shop/" + shopItem.key}
                      >
                        <ReactImageAppear  
                          className={`shop-item-img ${isItemOutOfStock ? "out-of-stock" : ""}`}
                          src={require(`assets/` + shopItem.imgs[0].src)}
                        />
                        <h3 className="shop-item-title">{shopItem.name}</h3>
                        <p className="no-top-margin">
                          {`$ ${shopItem.price} ${isItemOutOfStock ? "- Out of Stock" : ""}`}
                        </p>
                      </Link>
                    </div>
                  )
                } else {
                  return null
                }

              })  // map callback
            )
          }
        </div>
      </div>
    )
  } else {
    return <Loading/>
  }
}

export default Shop;



// export default class Shop extends DataDependantComponent {
//   renderChildComponent() {
//     return (
//       <div id="Shop">
//         <h1>Shop</h1>
//         <p>The highest quality electronics and firmware available on the market.</p>
//         <div id="shop-items" className="flex-container-center">
//           {this.state.DATA.map(this.renderShopItems)}
//         </div>
//       </div>
//     )
//   }

//   renderShopItems (item) {
//     let isItemOutOfStock = item.stock > 0 ? false : true;

//     if (item.active) {
//       return (
//         <div className="shop-item">
//           <Link className="shop-item-link"
//             to={"/shop/" + item.key}>
//             <Image className={"shop-item-img " + (isItemOutOfStock ? "out-of-stock" : "")} 
//               src={require(`assets/` + item.imgs[0].src)}/>
//             <h3 className="shop-item-title">{item.name}</h3>
//             <p className="no-top-margin">{"$"+ item.price + " " + (isItemOutOfStock ? "- Out of Stock" : "")}</p>
//           </Link>
//         </div>
//       )
//     }
  	
//   }

// }
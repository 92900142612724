import React from 'react';
import Creatable from 'react-select/creatable';

const DartTypeEntry = props => {
  const { dartType,

    getNewMuzzleVelocities, 
    updateFormWrapper,

    DART_TYPES,
    generateOptionForReactSelect,
    errorText,
    styles } = props;

    
  return (
    <div>
      <Creatable className="small-select-dropdown"
        styles={styles}
        placeholder={"Dart Type *"}
        value={ 
          !!dartType.value && 
          generateOptionForReactSelect(dartType.value)
        }
        options={
          DART_TYPES.map(blaster => {
            return generateOptionForReactSelect(blaster) 
          })
        }
        onChange={(selectedDartType) => {
          const newMuzzleVelocities = getNewMuzzleVelocities({
            dartType: { 
              value: { $set: selectedDartType.value },
              isNew: { $set: !!selectedDartType.__isNew__ }
            }
          });

          updateFormWrapper(newMuzzleVelocities)


        }}
      />
      
      <p className="setup-entry-help">
        What darts did you use to measure the muzzle velocity? Dart types can matter a lot when measuring muzzle velocities. Please use fresh darts to get the most accurate readings. If you have multiple muzzle velocity measurements with various different dart types, feel free to enter those as well by adding another muzzle velocity entry. 
      </p>
      
      <p className="setup-entry-help">
        Muzzle Velocity Entry #1 will be the first and biggest muzzle velocity value displayed. Feel free to use the real-time preview feature after filling out the entire form to make sure everything is the way you want. 
      </p>

      <p className="setup-entry-help">
        If your dart type is a variant of a dart that's already listed, please enter the dart that's already listed, not your exact variant. This will make it easier for people to find performance for a specific dart type. If you dart type isn't here, type in a new one to add it to the database! 
      </p>

      <p className="center-text error-text">{errorText}</p>
    </div>
  )

}

export default DartTypeEntry;
import React from 'react';
import { Collapse } from '@material-ui/core';

import CollapsibleTextTrigger from '../../../../../../../Functional/CollapsibleTextTrigger';
import TimeAgoText from '../../../../../../../Functional/TimeAgoText';


import './index.scss';


const Comment = props => {
  const { comment } = props;

  const [ isExpanded, setIsExpanded ] = React.useState(true);


  return (
    <div className="setup-comment">
      <CollapsibleTextTrigger
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
      >
        <span className="info">
          <p className="username inline-txt"><strong>{comment.username}</strong></p>

          <p className="setup-entry-help middot">·</p>

          <TimeAgoText
            time={comment.id} 
            formattedTime={comment.formattedDate}
            textClassName={"setup-entry-help inline-txt"}
          />
        </span>
      </CollapsibleTextTrigger>

      <Collapse in={isExpanded}>
        <p className="comment text-with-newlines">{comment.comment}</p>
      </Collapse>
      
    </div>
  )
}

export default Comment;
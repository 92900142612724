import React from 'react';
import DropdownFilter from './DropdownFilter';

import Helper from 'Helper';


const helper = new Helper();


const DartGuideFilter = props => {
  const { DART_GUIDES, filter, onFilterChange } = props;
  
  return (
    <DropdownFilter
      title={"Dart Guides"}
      placeholder={"Dart Guides"}
      filter={filter}

      SELECTIONS={DART_GUIDES.map(dartGuide => {
        return helper.generateOptionForReactSelect(dartGuide) 
      })}

      onChange={selectedOptions => onFilterChange("dartGuide", selectedOptions)}
    />

  )
}

export default DartGuideFilter;
import React from 'react';

import './index.css'

const MoreOptionsBtn = props => {
	return (
		<div>
			<div id="more-options-btn" className="flex-container-left"
      	onClick={() => props.onClick()}>
      	<h3 style={{
      		marginRight: ".5em",
      		fontWeight: "normal"
      	}}>More options</h3>
      	<span className="dropdown-caret"></span>
      </div>
		</div>
	)
}

export default MoreOptionsBtn;
import React from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';

const SemuAutoFilter = props => {
  const { filter, onFilterChange } = props;

  
  return (
    <FormControlLabel
      control={
        <Checkbox 
          checked={!!filter.isSemiAuto}
          color="primary"
          onChange={e => {onFilterChange("isSemiAuto", e.target.checked)}}
        />
      }
      classes={{
        root: "select-entry",
        label: "setup-entry-checkbox-label"
      }}
      label="Only Semi Auto Setups"
    />
  )

}

export default SemuAutoFilter;